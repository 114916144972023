@switch (level) { @case (1) {
<h1 edr-typography [typographyAs]="style">
	<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</h1>
} @case (2) {
<h2 edr-typography [typographyAs]="style">
	<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</h2>
} @case (3) {
<h3 edr-typography [typographyAs]="style">
	<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</h3>
} @case (4) {
<h4 edr-typography [typographyAs]="style">
	<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</h4>
} @case (5) {
<h5 edr-typography [typographyAs]="style">
	<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</h5>
} @case (6) {
<h6 edr-typography [typographyAs]="style">
	<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</h6>
} }

<ng-template #contentTemplate>
	@if (text) {
	<span [innerHTML]="text"></span>
	}
	<ng-content></ng-content>
</ng-template>
