<div class="m2m-gifting">
	<div class="gifting">
		<div class="gifting__info">
			<edr-circle size="10" backgroundColor="secondary--charcoal">
				<edr-icon name="rewards-gift-card" color="primary--white" size="6"></edr-icon>
			</edr-circle>
			<div class="gifting__text">
				<edr-heading [level]="5">{{ title }}</edr-heading>
				@if ((isSavingForOccasion$ | async) === true) {
				<p data-testid="gifting-title">To gift your points to another member, switch your Rewards choice to Everyday Rewards vouchers.</p>
				} @else if(description){
				<edr-contentful-rich-text [document]="description" class="description" />
				}
			</div>
		</div>
		<edr-button
			[disable]="(isSavingForOccasion$ | async) === true"
			class="gifting__action-button"
			buttonStyle="secondary"
			[reversed]="true"
			buttonSize="small"
			(click)="openGiftingFormModal()"
		>
			<edr-icon name="rewards-gift-card" color="secondary--charcoal" size="4"></edr-icon>
			<span class="gifting__action-button__text">{{ ctaText }}</span>
		</edr-button>
	</div>
</div>
