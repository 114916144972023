import { createSelector } from '@ngrx/store';
import { DynamicContentState, selectDynamicContentState } from '../dynamic-content.selector';
import { NavItemsState } from './nav-items.reducer';

export const selectNavItemsState = createSelector(selectDynamicContentState, (state: DynamicContentState) => state.navItems);
export const selectHeaderNavItems = createSelector(
	selectNavItemsState,
	(state: NavItemsState) => state.content.find((navigation) => navigation.location === 'header')?.navigationItems
);
export const selectFooterNavItems = createSelector(
	selectNavItemsState,
	(state: NavItemsState) => state.content.find((navigation) => navigation.location === 'footer')?.navigationItems
);
