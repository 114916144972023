import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncate',
	standalone: true,
})
export class TruncatePipe implements PipeTransform {
	public transform(value: string, limit = 100): string {
		let workingValue = value.trim();

		if (workingValue.length > limit && limit > -1) {
			workingValue = workingValue.substring(0, limit);
			workingValue = `${workingValue.substring(0, workingValue.lastIndexOf(' '))} ...`;
		}

		return workingValue;
	}
}
