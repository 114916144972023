import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as MemberSelectors from '../../member/+state/profile';
import * as BalanceOperators from '../../member/+state/balances';
import { ShortcodeService } from '@edr/styleguide';

@Injectable()
export class AppShortcodeService implements ShortcodeService {
	public firstName$ = this.store.select(MemberSelectors.selectFirstName);
	public totalPointsBalance$ = this.store.select(BalanceOperators.selectTotalPointsBalance);

	constructor(private readonly store: Store) {}
}
