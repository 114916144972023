import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '@edr/styleguide';
import { RouterLink } from '@angular/router';
import { APP_ROUTES } from '../../../../routes';

@Component({
	selector: 'edr-app-card-boost-all',
	templateUrl: './card-boost-all.component.html',
	styleUrls: ['./card-boost-all.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, IconComponent],
})
export class CardBoostAllComponent {
	public boostPageUrl = `/${APP_ROUTES.boosts}`;
}
