import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { ButtonComponent, MODAL_SERVICE_TOKEN, ModalComponent, ModalServiceBase } from '@edr/styleguide';
import { AsyncPipe, I18nPluralPipe } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';
import { GiftingConfirmationModalStore, PointsGifting } from './m2m-gifting-confirmation.store';
import { M2MGiftingFormModalComponent } from '../m2m-gifting-form-modal/m2m-gifting-form-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'edr-app-m2m-gifting-confirmation-modal',
	templateUrl: './m2m-gifting-confirmation-modal.component.html',
	styleUrls: ['./m2m-gifting-confirmation-modal.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [GiftingConfirmationModalStore],
	imports: [ModalComponent, ButtonComponent, AsyncPipe, I18nPluralPipe],
})
export class M2MGiftingConfirmationModalComponent implements OnDestroy {
	public giftingPoints: number;
	public giftingCardNumber: string;
	public pointsGifting: PointsGifting = { isLoading: false, succeeded: undefined };

	constructor(
		private giftingConfirmationModalStore: GiftingConfirmationModalStore,
		@Inject(MODAL_SERVICE_TOKEN) private modalService: ModalServiceBase,
		public dialogRef: DialogRef
	) {
		this.giftingPoints = this.dialogRef.config.data.giftingPoints;
		this.giftingCardNumber = this.dialogRef.config.data.giftingCardNumber;

		this.giftingConfirmationModalStore.pointsGifting$.subscribe((pointsGifting) => {
			this.pointsGifting = pointsGifting;
		});

		this.dialogRef.backdropClick.pipe(untilDestroyed(this)).subscribe(() => {
			if (!this.pointsGifting.isLoading) {
				this.handleClose();
			}
		});
	}

	public get giftingCardNumberFormatted(): string {
		return this.giftingCardNumber.replace(/(\d{1})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');
	}

	public ngOnDestroy(): void {
		this.dialogRef.config.data.pointsGiftingSubmitHandler({
			...this.pointsGifting,
			giftingCardNumber: this.giftingCardNumber,
			giftingPoints: this.giftingPoints,
		});
	}

	public handleClose(): void {
		if (!this.pointsGifting.isLoading) {
			this.modalService.close();
		}
	}

	public handleCancel(): void {
		if (!this.pointsGifting.isLoading) {
			this.modalService.close();
			this.openPreviousModal();
		}
	}

	public openPreviousModal(): void {
		// open gifting form modal
		this.modalService.open({
			ref: M2MGiftingFormModalComponent,
			id: 'm2m-gifting-form-modal',
			config: {
				data: {
					pointsBalance: this.dialogRef.config.data.pointsBalance,
					pointsGiftingSubmitHandler: this.dialogRef.config.data.pointsGiftingSubmitHandler,
					giftingPoints: this.dialogRef.config.data.giftingPoints,
					giftingCardNumber: this.dialogRef.config.data.giftingCardNumber,
				},
			},
		});
	}

	public handleSubmit(): void {
		this.giftingConfirmationModalStore.giftPointsToMember({ cardNumber: this.giftingCardNumber, points: this.giftingPoints });
	}
}
