import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { AppSettingsService, selectUserAuthData } from '@edr-core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { Router } from '@angular/router';
import { AUTH_CONSTANTS } from './auth.constant';
import { APP_ROUTES } from '../../routes';

@Component({
	selector: 'edr-app-silent-auth',
	templateUrl: './silent-auth.component.html',
	standalone: true,
	imports: [],
})
export class SilentAuthComponent implements OnInit {
	public checkSSOStatusUrl?: SafeResourceUrl;
	public userAuthData$ = this.store.select(selectUserAuthData);

	constructor(
		private store: Store,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
		private domSanitizer: DomSanitizer,
		private appSettingsService: AppSettingsService
	) {}

	public ngOnInit(): void {
		this.userAuthData$.pipe(take(1)).subscribe((authData) => {
			if (this.router.url?.includes(`/${APP_ROUTES.partnerLink}`)) {
				return;
			}
			if (!authData || (authData.isLoggedIn === false && !authData.hasError)) {
				this.appSettingsService
					.getEndpoint('auth')
					.pipe(take(1))
					.subscribe((authUrl) => {
						const returnUrl = encodeURIComponent(`${this.document.location.origin}/assets/silent-check-sso.html`);
						this.checkSSOStatusUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
							`${authUrl}/login?returnUrl=${returnUrl}&kc_idp_hint=gigya-ci&${AUTH_CONSTANTS.noPromptParamName}=true`
						);
					});
			}
		});
	}
}
