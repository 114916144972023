<span
	[edrApplyStyles]="
		bgColour && textColour
			? [
					{ styleType: 'color', key: 'backgroundColor', value: bgColour },
					{ styleType: 'color', key: 'color', value: textColour }
			  ]
			: []
	"
>
	<ng-content select="[tag-start]"></ng-content>
	<ng-content></ng-content>
	<ng-content select="[tag-end]"></ng-content>
</span>
