import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreakpointService, ContentBaseComponent } from '@edr/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import { AccordionItemCardComponent, AccordionItemDefaultComponent } from '../accordion-item';
import { ComponentRefService } from '../../services';

/**
 * Example usage:
 * <edr-accordion [multiExpand]="true" id="myId123">
 *  <edr-accordion-item id="myId123" heading="Accordion Item 1" description="This is a very cool accordion, holy wows!" iconName="test-icon-left">
 *    <div>Accordion item 1 content</div>
 *  </edr-accordion-item>
 *  <edr-accordion-item id="myId123" heading="Accordion Item 2" iconName="test-icon-right">
 *    <div>Accordion item 2 content</div>
 *  </edr-accordion-item>
 *  <edr-accordion-item id="myId123" heading="Accordion Item 3" description="This is a very cool accordion, holy wows!">
 *    <div>Accordion item 3 content</div>
 *  </edr-accordion-item>
 * </edr-accordion>
 */

@UntilDestroy()
@Component({
	selector: 'edr-accordion',
	styleUrls: ['./accordion.component.scss'],
	templateUrl: './accordion.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class AccordionComponent extends ContentBaseComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() public id = '';

	// TODO: Add missing properties to Contentful: https://woolworths-agile.atlassian.net/browse/CLSE-2595
	/**
	 * If true, multiple accordion items can be expanded at the same time.
	 * If false, only one accordion item can be expanded at a time.
	 */
	@Input() public multiExpand = true;
	@Input() public disabledToggleOnXL = false;

	public hasViewInit = false;

	constructor(private breakpointService: BreakpointService, private componentRefService: ComponentRefService) {
		super();
	}

	public collapseAll(): void {
		this.getAccordionList().forEach((accordionItem) => accordionItem.collapse());
	}

	public collapseAllChildAccordionItems(): void {
		this.getAccordionList()
			.filter((item) => item.isChildAccordion)
			.forEach((item) => item.collapse());
	}

	public ngOnInit(): void {
		this.componentRefService.register(this.id, this);
	}

	public ngOnDestroy(): void {
		this.componentRefService.deregister(this.id, this);
	}

	public ngAfterViewInit(): void {
		this.hasViewInit = true;

		if (!this.disabledToggleOnXL) {
			return;
		}
		this.delegateToggleDisabledSetting();
	}

	/**
	 * Disables the toggle functionality on the accordion items when rendering on a larger breakpoint.
	 * @private
	 */
	private delegateToggleDisabledSetting(): void {
		this.breakpointService
			.getActiveBreakpoint()
			.pipe(delay(1), untilDestroyed(this))
			.subscribe((breakpoint) => {
				this.getAccordionList().forEach((accordionItem) => {
					accordionItem.updateToggleDisabled(['xxl', 'xl'].includes(breakpoint));
				});
			});
	}

	private getAccordionList(): (AccordionItemCardComponent | AccordionItemDefaultComponent)[] {
		return this.componentRefService.getComponentRefs(this.id)?.filter((item) => item !== this) || [];
	}
}
