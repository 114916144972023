import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRTypographyStyle, EDRHeadingLevel } from '../../types';
import { CommonModule } from '@angular/common';
import { TypographyComponent } from '../typography/typography.component';

/**
 * Example usage:
 * <edr-heading level="2">Heading 2</edr-heading>
 * <edr-heading level="3" style="heading--1">Heading 3 styled as heading 1</edr-heading>
 */

@Component({
	selector: 'edr-heading',
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TypographyComponent, CommonModule],
})
export class HeadingComponent {
	@Input() public level: EDRHeadingLevel = 1;
	@Input() public style: EDRTypographyStyle | undefined;
	@Input() public text = '';
	@HostBinding('class') public get headingClass(): string {
		return `heading heading--${this.level}`;
	}
}
