import { EDR_CONSTANTS } from './edr.constant';

export const EDR_LUXON_DATE_FORMATS = {
	parse: {
		dateInput: EDR_CONSTANTS.dateFormat,
	},
	display: {
		dateInput: EDR_CONSTANTS.dateFormat,
		monthYearLabel: 'MMMM yyyy',
		dateA11yLabel: EDR_CONSTANTS.dateFormat,
		monthYearA11yLabel: 'MMMM yyyy',
	},
};
