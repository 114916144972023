import { Action, createReducer, on } from '@ngrx/store';
import * as BalancesActions from './balances.actions';
import { BalanceResponseV2 } from '@edr/bff-api-models';

export const BALANCES_STATE_KEY = 'balances';

export interface BalancesState {
	value: BalanceResponseV2 | undefined;
	isLoading: boolean;
	error?: unknown;
}

export const initialMemberBalancesState: BalancesState = {
	value: undefined,
	isLoading: false,
	error: undefined,
};

const reducer = createReducer(
	initialMemberBalancesState,
	on(BalancesActions.loadBalances, (state): BalancesState => ({ ...state, error: undefined, isLoading: true })),
	on(BalancesActions.loadBalancesSuccess, (state, { balances }): BalancesState => ({ ...state, value: balances, isLoading: false })),
	on(BalancesActions.loadBalancesFailure, (state, { error }): BalancesState => ({ ...state, isLoading: false, error, value: undefined }))
);

export function memberBalancesReducer(state: BalancesState | undefined, action: Action): BalancesState {
	return reducer(state, action);
}
