import { Inject, Injectable } from '@angular/core';
import { CustomWindow } from '@edr/shared';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor(@Inject(WINDOW) private window: CustomWindow) {}

	public get hasLocalStorage(): boolean {
		return !!this.window.localStorage;
	}

	public get localStorage(): Storage {
		return this.window.localStorage;
	}

	public setItem(name: string, value: string): false | undefined {
		if (!this.hasLocalStorage) {
			return false;
		}

		this.localStorage.setItem(name, value);
		return undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getItem(name: string): string | any | undefined {
		if (!this.hasLocalStorage) {
			return;
		}

		const item = this.localStorage.getItem(name);

		if (!item) {
			return;
		}

		try {
			return JSON.parse(item);
		} catch (e) {
			return item;
		}
	}

	public removeItem(name: string): boolean | undefined {
		if (!this.hasLocalStorage) {
			return false;
		}

		this.localStorage.removeItem(name);
		return undefined;
	}
}

// These can be added when we have experiments to do
export const StorageKeys: Record<string, string> = {};
