<div>
	<table cdk-table [dataSource]="dataSource$">
		@for (column of columnList; track column) {
		<ng-container [cdkColumnDef]="column.name">
			<th cdk-header-cell *cdkHeaderCellDef>{{ column.displayName }}</th>
			<td cdk-cell *cdkCellDef="let element">{{ element[column.name] }}</td>
		</ng-container>
		}

		<tr cdk-header-row *cdkHeaderRowDef="columnNameList"></tr>
		<tr cdk-row *cdkRowDef="let row; columns: columnNameList"></tr>
	</table>
</div>
