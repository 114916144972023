import { Component, HostBinding, Input } from '@angular/core';
import { EDRColor, EDRSpacing } from '../../types';
import { SpinnerComponent } from '../spinner/spinner.component';
import { IconComponent } from '../icon/icon.component';

import { EDRTrackingData, IconProps } from '../../models';
import { TrackingDirective } from '../../directives';

export type EDRButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'link';
export type EDRButtonSize = 'small' | 'medium' | 'large';

@Component({
	selector: 'edr-button, [edr-button]',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	standalone: true,
	imports: [SpinnerComponent, IconComponent],
	hostDirectives: [
		{
			directive: TrackingDirective,
			inputs: ['trackingData'],
		},
	],
})
export class ButtonComponent {
	@HostBinding('attr.buttonStyle') @Input() public buttonStyle: EDRButtonStyle = 'primary';
	@HostBinding('attr.reversed') @Input() public reversed = false;
	@HostBinding('attr.buttonSize') @Input() public buttonSize: EDRButtonSize = 'large';
	@HostBinding('attr.isLoading') @Input() public isLoading = false;
	@HostBinding('attr.loadingColor') @Input() public loadingColor: EDRColor = 'primary--orange';
	@HostBinding('attr.spinnerHeight') @Input() public spinnerHeight: EDRSpacing = '6';
	@HostBinding('attr.spinnerMarginHorizontal') @Input() public spinnerMarginHorizontal: EDRSpacing = '6';
	@HostBinding('attr.showLeftIcon') @Input() public showLeftIcon = false;
	@HostBinding('attr.showRightIcon') @Input() public showRightIcon = false;
	@HostBinding('attr.fullwidth') @Input() public fullWidth = false;
	@HostBinding('attr.inline') @Input() public inline = false;
	@HostBinding('attr.disable') @Input() public disable = false;
	@HostBinding('attr.tabIndex') public tabIndex = 0;
	@Input() public iconLeft?: IconProps;
	@Input() public iconRight?: IconProps;
	@Input() public trackingData?: EDRTrackingData;
}
