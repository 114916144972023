<footer>
	<div class="footer__nav">
		<div class="footer__nav-container">
			<p data-testid="copyright" edr-typography typographyAs="body--xs">© {{ currentYear }} Everyday Rewards. All rights reserved.</p>
			<nav>
				<ul>
					@for (navItem of navItems$ | async; track navItem) {
					<li>
						<a data-testid="nav-item" [routerLink]="navItem.url" edr-typography typographyAs="body--xxs">{{ navItem.label }}</a>
					</li>
					}
				</ul>
			</nav>
		</div>
	</div>
</footer>
