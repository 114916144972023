import { Inject, Injectable } from '@angular/core';
import { Observable, map, switchMap, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { AuthUserResponse } from '@edr/bff-api-models';
import { WINDOW } from '@ng-web-apis/common';
import { cleanQueryParams, CustomWindow } from '@edr/shared';
import { AUTH_CONSTANTS } from '../../auth';

export interface EncryptedTokenResponse {
	token: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService, @Inject(WINDOW) private window: CustomWindow) {}

	public fetchIsAuthenticated(): Observable<AuthUserResponse> {
		return this.appSettingsService
			.getEndpoint('auth')
			.pipe(switchMap((endpoint) => this.apiService.get<AuthUserResponse>(`${endpoint}/is-authenticated`)));
	}

	public fetchJWEToken(partnerId: string): Observable<EncryptedTokenResponse> {
		return this.appSettingsService
			.getEndpoint('auth')
			.pipe(switchMap((endpoint) => this.apiService.get<EncryptedTokenResponse>(`${endpoint}/token?partnerId=${partnerId}`)));
	}

	public loginUrl(returnUrl = '/', noPrompt = false, deleteSignedInParam = false): Observable<string> {
		return this.appSettingsService.getEndpoint('auth').pipe(
			map((endpoint) => {
				let queryReturnUrl = returnUrl;
				if (deleteSignedInParam) {
					queryReturnUrl = cleanQueryParams(returnUrl, [AUTH_CONSTANTS.signedInParamName]);
				}
				const loginUrl = `${endpoint}/login?returnUrl=${encodeURIComponent(queryReturnUrl)}`;
				return noPrompt ? `${loginUrl}&${AUTH_CONSTANTS.noPromptParamName}=true` : loginUrl;
			})
		);
	}

	public startLoginFlow(returnUrl = '/'): Observable<void> {
		return this.loginUrl(returnUrl).pipe(
			tap((loginUrl) => {
				if (!this.window.location) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(this.window as any).location = { href: '' };
				}
				this.window.location.href = loginUrl;
			}),
			map(() => undefined)
		);
	}

	public startLogoutFlow(returnUrl = '/'): Observable<void> {
		return this.appSettingsService.getEndpoint('auth').pipe(
			tap((endpoint) => {
				const logoutUrl = `${endpoint}/logout?returnUrl=${encodeURIComponent(returnUrl)}`;
				if (!this.window.location) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(this.window as any).location = { href: '' };
				}
				this.window.location.href = logoutUrl;
			}),
			map(() => undefined)
		);
	}
}
