import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService } from '../../../core/services';
import { GiftPointsRequest, GiftPointsResponse } from '@edr/bff-api-models';
import { Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GiftingService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	public giftPointsToMember(cardNumber: string, points: number): Observable<GiftPointsResponse> {
		const requestBody: GiftPointsRequest = {
			receiverCardNumber: cardNumber,
			amount: points,
		};
		return this.appSettingsService
			.getEndpoint('giftPointsToMember')
			.pipe(switchMap((endPoint) => this.apiService.post<GiftPointsRequest, GiftPointsResponse>(endPoint, requestBody)));
	}
}
