import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { ApiService, AppSettingsService } from '..';
import { PartnerPropResponse, PartnersResponse } from '@edr/bff-api-models';

@Injectable({
	providedIn: 'root',
})
export class PartnersService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	public fetchPartners(): Observable<PartnerPropResponse[]> {
		return this.appSettingsService.getEndpoint('partners').pipe(
			switchMap((endPoint) => this.apiService.get<PartnersResponse>(endPoint)),
			map((response) => response.partners)
		);
	}
}
