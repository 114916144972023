<div edr-typography>
	@if (hasLabel) {
		<label edr-typography typographyAs="body--xs" [attr.for]="inputId" class="label">
			{{ label }}
		</label>
	}
	@if (hasSubLabel) {
		<label edr-typography typographyAs="body--xxs" [attr.for]="inputId" class="sub-label">
			{{ subLabel }}
		</label>
	}
	<div class="input-wrapper" [attr.data-valid]="!isError" [attr.disabled]="disabled">
		<ng-content select="[inputPrefix]"></ng-content>

		<input
			#input
			(blur)="onBlur($event)"
			(focus)="onFocus($event)"
			(keyup)="onKeyUp($event)"
			(keydown)="onKeyDown($event)"
			(input)="onInput($event)"
			[value]="value"
			[attr.aria-label]="ariaLabel"
			[disabled]="disabled"
			[pattern]="pattern"
			[required]="required"
			[readOnly]="readOnly"
			[id]="inputId"
			[name]="name || formControlName"
			[placeholder]="placeholder"
			[type]="type"
			[attr.autocomplete]="autocomplete"
			[mask]="mask"
			[suffix]="maskOptions.suffix"
			[prefix]="maskOptions.prefix"
			[thousandSeparator]="maskOptions.thousandSeparator"
			[decimalMarker]="maskOptions.decimalMarker"
			[showMaskTyped]="maskOptions.showMaskTyped"
			[shownMaskExpression]="maskOptions.shownMaskExpression"
			[specialCharacters]="maskOptions.specialCharacters"
			[hiddenInput]="maskOptions.hiddenInput"
			[allowNegativeNumbers]="maskOptions.allowNegativeNumbers"
			[inputTransformFn]="maskOptions.inputTransformFn"
			[patterns]="maskOptions.patterns"
		/>
		<ng-content select="[inputSuffix]"></ng-content>
	</div>
</div>
