import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AsyncPipe, Location, NgClass } from '@angular/common';
import { Router } from '@angular/router';

import { selectRouteParams } from '../../router.selector';
import { ServerSideResponseService } from '../../core/services/server-side-response/server-side-response.service';
import { MetaDataService } from '@edr-core';

import { Store } from '@ngrx/store';
import { Observable, map, take, tap } from 'rxjs';
import { PageContentError, PageContentErrorCta } from './error.model';
import { pageContent } from './error.content';
import { WINDOW } from '@ng-web-apis/common';
import { CustomWindow } from '@edr/shared';
import { ButtonComponent, HeadingComponent, SectionComponent } from '@edr/styleguide';

@Component({
	selector: 'edr-app-error.page',
	templateUrl: './error.page.component.html',
	styleUrls: ['./error.page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, AsyncPipe, SectionComponent, HeadingComponent, ButtonComponent],
})
export class ErrorPageComponent {
	public canGoBack: boolean;
	public pageContent$: Observable<PageContentError> = this.store.select(selectRouteParams).pipe(
		take(1),
		map((routerParams) => (routerParams && routerParams['errorCode'] ? routerParams['errorCode'] : '404')),
		map((errorCode) => pageContent[errorCode] ?? pageContent['404']),
		tap((content) => this.setServerSideErrorCode(content)),
		tap((content) => this.setPageMetadata(content))
	);

	constructor(
		private readonly store: Store,
		private readonly serverSideResponseService: ServerSideResponseService,
		private readonly location: Location,
		private readonly router: Router,
		private readonly metadataService: MetaDataService,
		@Inject(WINDOW) private readonly window: CustomWindow
	) {
		// History will always have 2 entires. More than 2 = Can go back
		this.canGoBack = this.window.history.length > 2;
	}

	public handleCtaClick(cta: PageContentErrorCta): void {
		if (this.canGoBack && cta.linkUrl === '..') {
			this.location.back();
			return;
		}

		this.router.navigateByUrl(cta.linkUrl === '..' ? '/' : cta.linkUrl);
	}

	private setServerSideErrorCode(content: PageContentError): void {
		this.serverSideResponseService.setServerResponse(content.errorCode, content.errorCodeDescription);
	}

	private setPageMetadata(content: PageContentError): void {
		this.metadataService.setPageMetaData({
			title: `${content.errorCodeDescription} | ${content.errorCode}`,
			isIndexable: false,
			metaTitle: content.title,
			metaDescription: content.description.join('\n'),
		});
	}
}
