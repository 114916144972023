import { createSelector } from '@ngrx/store';
import { selectMemberState } from '../member.selectors';
import { MemberState } from '../member.interface';

export const selectRedemptionPreferencesState = createSelector(selectMemberState, (state: MemberState) => state.redemptionPreferences);
export const selectIsLoadingRedemptionPreferences = createSelector(selectRedemptionPreferencesState, (state) => state.isLoadingRedemptionPreferences);
export const selectRedemptionPreference = createSelector(selectRedemptionPreferencesState, (state) => state.redemptionPreference);
export const selectSaveForOccasionDate = createSelector(selectRedemptionPreferencesState, (state) => state.saveForOccasionDate);
export const selectIsUpdatingRedemptionPreferences = createSelector(selectRedemptionPreferencesState, (state) => state.updatingRedemptionPreferences);
export const selectUpdatedRedemptionPreferences = createSelector(selectRedemptionPreferencesState, (state) => state.updatedRedemptionPreferences);
export const selectRedemptionPreferencesError = createSelector(selectRedemptionPreferencesState, (state) => state.error);
