import { Component, HostBinding, Input } from '@angular/core';
import { EDRFont } from '../../types/font.type';
import { ButtonComponent, EDRButtonSize, EDRButtonStyle } from '../button/button.component';
import { AnchorTarget, ContentBaseComponent } from '@edr/shared';
import { RouterLink } from '@angular/router';

import { TrackingDirective } from '../../directives';
import { IconProps } from '../../models';

/**
 * Use this to display a link:
 * <edr-link linkText="privacy policy" link="/info/privacy-policy" />
 *
 * Use this to display a link as a button:
 * <edr-link linkText="hello" link="https://google.com" target="_blank" [asButton]="true" />
 *
 * Use this to style buttons as links
 * <button type="button" edr-link>I look like a link</button>
 */
@Component({
	selector: 'edr-link, [edr-link]',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss'],
	standalone: true,
	imports: [RouterLink, ButtonComponent],
	hostDirectives: [
		{
			directive: TrackingDirective,
		},
	],
})
export class LinkComponent extends ContentBaseComponent {
	@HostBinding('attr.font') @Input() public linkFont: EDRFont = 'Roboto';
	@HostBinding('attr.size') @Input() public size: EDRButtonSize = 'large';
	@Input() public text = '';
	@Input() public linkUrl = '';

	@Input() public asImage = false;
	@Input() public target: AnchorTarget = '_self';

	// Use this to display a link as a button
	@HostBinding('attr.as-button') @Input() public asButton = false;
	@Input() public buttonStyle: EDRButtonStyle = 'primary';
	@Input() public buttonStyleReversed = false;
	@Input() public showLeftIcon = false;
	@Input() public iconLeft?: IconProps;
	@Input() public showRightIcon = false;
	@Input() public iconRight?: IconProps;

	public isExternalLink(): boolean {
		return this.linkUrl?.startsWith('http');
	}
}
