import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRColor } from '../../types';
import { ApplyStylesDirective } from '../../directives';

export type TagType = 'default' | 'boost';
export type TagSize = 'small' | 'medium';

@Component({
	selector: 'edr-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ApplyStylesDirective],
})
export class TagComponent {
	@HostBinding('attr.type') @Input() public type: TagType = 'default';
	@HostBinding('attr.size') @Input() public size: TagSize = 'medium';
	@Input() public bgColour: EDRColor | undefined;
	@Input() public textColour: EDRColor | undefined;
}
