import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, switchMap } from 'rxjs';
import { LoggingService } from '@edr/shared';
import { Router } from '@angular/router';
import { ActivityDetailsResponse } from '@edr/bff-api-models';
import { PointsAndActivityService } from '../../../features/my-account/services';
import { APP_ROUTES } from '../../../routes';

export interface PointsDetailsTableState {
	transactionDetails: ActivityDetailsResponse;
	error: unknown;
	transactionIsLoading: boolean;
}

export const defaultState: PointsDetailsTableState = {
	transactionDetails: {
		activityDetails: [],
	},
	error: null,
	transactionIsLoading: true,
};

export interface ActivityDetailsFetchParams {
	activityId: string;
	transactionReference: string;
}

@Injectable()
export class PointsDetailsTableStore extends ComponentStore<PointsDetailsTableState> {
	/** Selectors */
	public readonly transactionDetails$ = this.select((state) => state.transactionDetails.activityDetails);
	public readonly transactionRedeemed$ = this.select((state) => state.transactionDetails.redeemed);
	public readonly transactionIsLoading$ = this.select((state) => state.transactionIsLoading);

	/** Updaters */
	public readonly updateTransactionDetails = this.updater(
		(state, transactionDetails: ActivityDetailsResponse): PointsDetailsTableState => ({
			...state,
			transactionDetails: transactionDetails,
			transactionIsLoading: false,
		})
	);

	/** Effects */
	public readonly fetchTransactionDetails = this.effect((activityDetailsFetchParams$: Observable<ActivityDetailsFetchParams>) =>
		activityDetailsFetchParams$.pipe(
			switchMap((activityDetailsFetchParams) =>
				this.pointsAndActivityService
					.fetchActivityDetails(activityDetailsFetchParams.activityId, activityDetailsFetchParams.transactionReference)
					.pipe(
						tapResponse(
							(response) => {
								this.updateTransactionDetails(response);
							},
							(error) => {
								this.logger.error(error);
								this.router.navigate(['/', APP_ROUTES.error, '500']);
							}
						)
					)
			)
		)
	);

	constructor(private pointsAndActivityService: PointsAndActivityService, private logger: LoggingService, private router: Router) {
		super(defaultState);
	}
}
