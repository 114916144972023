@if (!formControl) {
<p edr-typography typographyAs="body--xs" class="error-message">
	<edr-icon color="alert--error-red" name="alert-error" size="3" />
	<ng-content></ng-content>
</p>
} @if (formControl && (formControl.touched || formControl.dirty)) { @for (errorMessage of formControlErrorMessages; track errorMessage) {
<p edr-typography typographyAs="body--xs" class="error-message">
	<edr-icon color="alert--error-red" name="alert-error" size="3" />
	{{ errorMessage }}
</p>
} }
