@if (pointsTableTemplateData$ | async; as pointsTableData) { @if (!pointsTableData.isLoading) { @if (pointsTableData.hasActivityList) { @for
(activityList of pointsTableData.activityListByMonth; track activityList) {
<ng-container
	[ngTemplateOutlet]="(isMobileDevice$ | async) ? mobileTableTemplate : desktopTableTemplate"
	[ngTemplateOutletContext]="{ activityList }"
/>
}
<div class="all-caught-up">
	<img src="/assets/images/rewards-balloon.svg" alt="Rewards balloon" />
	<p class="all-caught-up__title">You're all caught up</p>
	<p class="all-caught-up__subtitle">
		If you think you're missing any transactions, contact Customer Care on <a href="tel:0800 969 337">0800 969 337</a>
	</p>
</div>
} @else {
<div class="no-activity">
	<img src="/assets/images/rewards-rocket-bw.svg" alt="nothing to show" class="no-activity__image" />
	<p class="no-activity__title">
		Nothing to show yet! Start shopping with your Everyday Rewards Card and check back soon to see your points and activity.
	</p>
</div>
} } @else {
<edr-spinner><h3 edr-typography>Loading</h3></edr-spinner>
} }

<ng-template #mobileTableTemplate let-activityList="activityList">
	<edr-app-points-card-list [dataSource]="activityList.activities" [columnList]="activityList.columns" />
</ng-template>

<ng-template #desktopTableTemplate let-activityList="activityList">
	<edr-app-points-table-desktop [columnList]="activityList.columns" [dataSource]="activityList.activities" [detailsColumnsList]="detailsColumns" />
</ng-template>
