import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { PartnersFacade } from '../../../features/partners/+state';
import { AuthService, selectAuthState } from '@edr-core';
import { Store } from '@ngrx/store';
import { WINDOW } from '@ng-web-apis/common';
import { filter } from 'rxjs/operators';
import { APP_ROUTES } from '../../../routes';

// We use an interface to allow dynamic key checking with `PARTNER_IDS[key]` as well as static typing with `PARTNER_IDS.BP`
// Without interface, there will be linting errors
interface PartnerIds {
	[key: string]: string;
	Asb: string;
	BP: string;
	TeamBenefits: string;
	VineOnline: string;
	Petstock: string;
	Milkrun: string;
}

export const PARTNER_IDS: PartnerIds = {
	Asb: '2013',
	TeamBenefits: '2009',
	BP: '2011',
	VineOnline: '2012',
	Petstock: '2014',
	Milkrun: '2015',
};

export const partnerLinkGuard =
	(): CanActivateFn =>
	(activatedRouteSnapshot: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean | UrlTree> => {
		const router = inject(Router);
		const partnersFacade = inject<PartnersFacade>(PartnersFacade);
		const authService = inject(AuthService);
		const route = activatedRouteSnapshot;
		const store = inject(Store);
		const window = inject(WINDOW);

		// Get Partner Id
		const partnerId: string = route.queryParams['partnerid'];
		if (!partnerId || !Object.keys(PARTNER_IDS).find((key) => PARTNER_IDS[key] === partnerId)) {
			router.navigateByUrl('/', { replaceUrl: true });
			return of(false);
		}

		const queryParams = new URLSearchParams({ partnerid: partnerId });
		Object.entries(route.queryParams)
			.filter(([key, value]) => value && /^utm_|^partnerstate/i.test(key))
			.forEach(([key, value]) => queryParams.append(key, value));

		const forceLogout$ = partnersFacade.getPartnerById(partnerId).pipe(map((partner) => partner?.forceLogout));
		const authState$ = store.select(selectAuthState);

		return combineLatest([forceLogout$, authState$]).pipe(
			filter(([forceLogout, { initialising }]) => !initialising && forceLogout !== undefined),
			switchMap(([forceLogout]) => {
				const ignoreForceLogout = window.location.href.indexOf('ignoreForceLogout') > -1;
				if (forceLogout && !ignoreForceLogout) {
					queryParams.set('ignoreForceLogout', 'true');
					const logoutRedirectUrl = `${window.location.origin ?? ''}/${APP_ROUTES.partnerLink}?${queryParams}`;
					return authService.startLogoutFlow(logoutRedirectUrl).pipe(map(() => false));
				}

				// Build Auth Flow Return URL
				const memberLinkPath = `/${APP_ROUTES.memberLink}?${queryParams}`;
				return of(router.parseUrl(memberLinkPath));
			})
		);
	};
