import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as EnvActions from './env.actions';
import * as EnvSelectors from './env.selectors';
import { BreakpointService, EDRBreakpoint, notUndefined } from '@edr/shared';
import { Observable, filter, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnvFacade {
	/**
	 * Combine pieces of state using createSelector,
	 * and expose them as observables through the facade.
	 */
	public breakpoint$: Observable<EDRBreakpoint> = this.store.select(EnvSelectors.selectEnvBreakpoint).pipe(filter(notUndefined));

	constructor(private breakpointService: BreakpointService, private store: Store) {}

	public breakpointMatches(breakpoints: EDRBreakpoint[]): Observable<boolean> {
		return this.breakpoint$.pipe(map((currentBreakpoint) => breakpoints.includes(currentBreakpoint)));
	}

	public monitorBreakpoint(): void {
		this.breakpointService.getActiveBreakpoint().subscribe((breakpoint) => {
			this.store.dispatch(EnvActions.updateEnvBreakpoint({ breakpoint }));
		});
	}
}
