import { OrderAction, PageContentEverydayRewardsCard } from './page-content-everyday-rewards-card.model';

export const pageContent: PageContentEverydayRewardsCard = {
	accordionItems: [
		{
			id: OrderAction.PreOrderPlasticCard,
			title: 'Pre-order a physical card',
			description: `We're aiming to reduce the amount of physical cards we print. If you'd like one, please pre-order now, and we'll post it to you before we launch on 1 February.`,
			icon: 'rewards-card',
			button: {
				id: 'preOrderCard',
				label: 'Pre-order a card',
			},
			addressFinder: {
				title: 'Pre-order a physical card',
				description:
					'Pre-ordered cards will be sent out in January.<br/>' +
					`We'll only use your mailing address to send your card. It won't be recorded in our database.`,
			},
			orderSuccess: {
				title: 'Pre-order a physical card',
				subTitle: 'Get ready for your Everyday Rewards Card',
				description: `If you got your card in-store, keep it safe until 1 February. If you ordered your card online, we'll email you when it's on the way. In the meantime, continue using your Onecard until 31 January.`,
			},
		},
		{
			id: OrderAction.OrderPlasticCard,
			title: 'Order a physical card',
			description: `Prefer a physical card? Order one here and we'll post it to you. We're aiming to reduce the amount of physical cards we print, so they aren't available in stores.`,
			icon: 'rewards-card',
			button: {
				id: 'orderCard',
				label: 'Order a card',
			},
			addressFinder: {
				title: 'Order a physical card',
				description: `We'll only use your mailing address to send you your card. These details won't be saved for other uses.`,
				buttonText: 'Order card',
			},
			orderSuccess: {
				title: 'Order a physical card',
				subTitle: `We've received your request`,
				description: `Your new card will arrive in 7 to 10 business days. If it doesn't arrive after 10 days, please contact us.`,
			},
		},
		{
			id: OrderAction.ReplacePlasticCard,
			title: 'Replace a lost, stolen or damaged card',
			description: 'If your card has been lost, stolen or damaged, you can order a replacement.',
			icon: 'rewards-card',
			button: {
				id: 'replaceCard',
				label: 'Order a replacement card',
			},
			addressFinder: {
				title: 'Order a replacement card',
				description: `We'll only use your mailing address to send you your card. These details won't be saved for other uses.`,
				buttonText: 'Order card',
			},
			orderSuccess: {
				title: 'Order a replacement card',
				subTitle: `We've received your request`,
				description:
					'Your current card has been cancelled and can no longer be scanned.<br/>' +
					`Your new card will arrive in 7 to 10 business days. If it doesn't arrive after 10 days, please contact us.`,
			},
		},
	],
};
