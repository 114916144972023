export const TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS = [
	{
		name: 'amount',
		displayName: 'Purchased',
	},
	{
		name: 'bonusPoints',
		displayName: 'Bonus points',
	},
	{
		name: 'totalPoints',
		displayName: 'Points',
	},
];

export const TABLE_COLUMNS_DEFAULT = [
	{
		displayName: 'This Month',
		name: 'partnerDetails',
	},
	...TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS,
];

export const DETAILS_TABLE_COLUMNS = [
	{
		name: 'description',
		displayName: 'Description',
	},
	{
		name: 'amount',
		displayName: 'Purchased',
	},
	{
		name: 'bonusPoints',
		displayName: 'Bonus points',
	},
	{
		name: 'totalPoints',
		displayName: 'Points',
	},
];
