import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { TypographyComponent } from '../typography/typography.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { EDRColor, EDRIcon, EDRTypographyStyle } from '../../types';
import { TooltipPosition } from '../../enums';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const ARROW_SAFE_MARGIN = 6;
const LEFT_RIGHT_POSITION: TooltipPosition[] = [TooltipPosition.Left, TooltipPosition.Right];

@UntilDestroy()
@Component({
	selector: 'edr-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TypographyComponent, IconComponent, NgClass, NgTemplateOutlet],
})
export class TooltipComponent implements OnInit {
	@Input() public typographyStyle: EDRTypographyStyle = 'body--xs';
	@Input() public iconName?: EDRIcon | undefined;
	@Input() public iconColor?: EDRColor | undefined;
	@Input() public ctaText?: string;
	@Input() public ctaLink?: string;
	public isMessageTemplateRef = false;

	@HostBinding('attr.showCloseButton')
	@Input()
	public showCloseButton = false;

	@Output() public dismissed = new EventEmitter<string | undefined>();

	public arrowBaseClassName = 'tooltip-arrow';

	@ViewChild('tooltipArrow') private tooltipArrow: ElementRef<HTMLElement> | undefined;

	private _position: TooltipPosition = TooltipPosition.Right;
	private _arrowOffset = 0;

	private _stringMessage?: string;
	private _templateMessage?: TemplateRef<unknown>;

	constructor(public changeDetectorRef: ChangeDetectorRef, public elementRef: ElementRef<HTMLElement>) {
		this.subscribeToTriggerEvents();
	}

	public get templateMessage(): TemplateRef<unknown> | null {
		return this._templateMessage ?? null;
	}

	public get stringMessage(): string | null {
		return this._stringMessage ?? null;
	}

	@Input()
	public set message(value: string | TemplateRef<unknown>) {
		if (value instanceof TemplateRef) {
			this._templateMessage = value;
			this.isMessageTemplateRef = true;
		} else {
			this._stringMessage = value;
			this.isMessageTemplateRef = false;
		}
	}

	public get positionClass(): string {
		return `${this.arrowBaseClassName}__${this.position}`;
	}

	public get position(): TooltipPosition {
		return this._position;
	}

	@Input()
	public set position(value: TooltipPosition) {
		this._position = value;
		this.setArrowStyles();
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	public get arrowOffset(): number {
		return this._arrowOffset;
	}

	@Input()
	public set arrowOffset(value: number) {
		this._arrowOffset = value;
		this.setArrowStyles();
	}

	public ngOnInit(): void {
		this.elementRef.nativeElement.tabIndex = 0;
	}

	public subscribeToTriggerEvents(): void {
		fromEvent(this.elementRef.nativeElement, 'blur')
			.pipe(untilDestroyed(this))
			.subscribe((event) => {
				this.dismissTooltip(event.type);
			});
		fromEvent(this.elementRef.nativeElement, 'mouseleave')
			.pipe(untilDestroyed(this))
			.subscribe((event) => {
				this.dismissTooltip(event.type);
			});
	}

	public getArrowSize(): { width?: number; height?: number } {
		return {
			width: this.tooltipArrow?.nativeElement?.clientWidth,
			height: this.tooltipArrow?.nativeElement?.clientHeight,
		};
	}

	public dismissTooltip(event?: string): void {
		this.dismissed.emit(event);
	}

	public markForCheck(): void {
		this.changeDetectorRef.markForCheck();
	}

	private setArrowStyles(): void {
		if (!this.tooltipArrow) {
			return;
		}

		let safeAlignmentOffset = this.arrowOffset;
		let newTop: number, newLeft: number;

		if (LEFT_RIGHT_POSITION.includes(this.position)) {
			// Ensure sufficient corner paddings for arrow offset
			if (safeAlignmentOffset < ARROW_SAFE_MARGIN) {
				safeAlignmentOffset = ARROW_SAFE_MARGIN;
			} else if (
				this.elementRef.nativeElement.clientHeight - (safeAlignmentOffset + this.tooltipArrow.nativeElement.clientHeight) <
				ARROW_SAFE_MARGIN
			) {
				const newSafeOffset = this.elementRef.nativeElement.clientHeight - this.tooltipArrow.nativeElement.clientHeight - ARROW_SAFE_MARGIN;
				safeAlignmentOffset = Math.max(newSafeOffset, ARROW_SAFE_MARGIN); // Ensure we do not go less than safe margins
			}

			newLeft = 0;
			newTop = safeAlignmentOffset;
		} else {
			// Ensure sufficient corner paddings for arrow offset
			if (safeAlignmentOffset < ARROW_SAFE_MARGIN) {
				safeAlignmentOffset = ARROW_SAFE_MARGIN;
			} else if (
				this.elementRef.nativeElement.clientWidth - (safeAlignmentOffset + this.tooltipArrow.nativeElement.clientWidth) <
				ARROW_SAFE_MARGIN
			) {
				const newSafeOffset = this.elementRef.nativeElement.clientWidth - this.tooltipArrow.nativeElement.clientWidth - ARROW_SAFE_MARGIN;
				safeAlignmentOffset = Math.max(newSafeOffset, ARROW_SAFE_MARGIN); // Ensure we do not go less than safe margins
			}

			newLeft = safeAlignmentOffset;
			newTop = 0;
		}

		this.tooltipArrow.nativeElement.style.top = newTop > 0 ? `${newTop}px` : '';
		this.tooltipArrow.nativeElement.style.left = newLeft > 0 ? `${newLeft}px` : '';
	}
}
