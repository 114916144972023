import { createFeatureSelector } from '@ngrx/store';
import { NAV_ITEMS_STATE_KEY, NavItemsState } from './nav-items/nav-items.reducer';
import { PAGE_CONTENT_STATE_KEY, PageContentState } from './page-content/page-content.reducer';
import { DYNAMIC_CONTENT_STATE_KEY } from './dynamic-content.state-key';

export interface DynamicContentState {
	[PAGE_CONTENT_STATE_KEY]: PageContentState;
	[NAV_ITEMS_STATE_KEY]: NavItemsState;
}

export const selectDynamicContentState = createFeatureSelector<DynamicContentState>(DYNAMIC_CONTENT_STATE_KEY);
