import { Component, Input } from '@angular/core';
import { EDRColor } from '../../types';
import { TypographyComponent } from '../typography/typography.component';

@Component({
	selector: 'edr-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	standalone: true,
	imports: [TypographyComponent],
})
export class SpinnerComponent {
	@Input() public color: EDRColor = 'primary--orange';
}
