<a [routerLink]="[partnerLink]">
	<edr-circle class="outer-circle" borderWidth="var(--spacing-4)">
		<edr-circle size="14" backgroundColor="primary--white" borderColor="secondary--mid-grey" borderWidth="1px">
			<edr-image
				[src]="partnerLogo"
				[altText]="partnerName + ' logo'"
				[imageDeviceTransformConfig]="imageDeviceTransformConfig"
			/>
		</edr-circle>
	</edr-circle>
	<edr-link [linkUrl]="partnerLink" [text]="partnerName" target="_blank" size="small" />
</a>
