import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { getDefaultTrackingData } from '../../../helpers';
import { EDRTrackingData } from '../../models';

@Directive({
    selector: '[edrTrackEvent]',
    standalone: true,
})
export class TrackingDirective implements AfterViewInit {
    @Input()
    public trackingData: EDRTrackingData = {};

    constructor(private elementRef: ElementRef, private tealiumUtagService: TealiumUtagService) {
    }

    @HostListener('click', ['$event'])
    public track(): void {
        this.tealiumUtagService.link(this.trackingData);
    }

    public ngAfterViewInit(): void {
        if (Object.keys(this.trackingData).length === 0) {
            this.trackingData = getDefaultTrackingData(this.elementRef);
        }
    }
}
