import { replaceAlignShortCode } from './align/align-short-code';
import { replaceAnchorPointShortCode } from './anchor-point/anchor-point-short-code';
import { replaceSpacingShortCode } from './spacing/spacing-short-code';
import { replaceTypographyShortCode } from './typography/typography-short-code';

export const SHORT_CODE_DEFINITIONS = {
	CODE: 'code',
	VALUE: 'value',
};
/**
 * Regular Expression to find Short Codes. Explanation:
 * - Starts with `[` - `\[`
 * - Contains 1 or more lowercase/uppercase letters, and Group with name of `SHORT_CODE_DEFINITIONS.CODE` - `(?<${SHORT_CODE_DEFINITIONS.CODE}>[a-z]{1,})`
 * - Optional or 1+ occurrences - `...(...){0,}`:
 *   - 1 space character - `[ ]{1}`
 *   - Contains 1 or more lowercase/uppercase letters; numbers; - (Dash); or space character, and Group with name of `SHORT_CODE_DEFINITIONS.VALUE` - `[ ]{1}(?<${SHORT_CODE_DEFINITIONS.VALUE}>[0-9a-z- ]{1,})`
 * - Ends with `]` - `]`
 * - Global (`g`) and MultiLine (`m`) flags, to search across multiple lines, and return all occurrences.
 */
export const SHORT_CODE_REGEX = new RegExp(
	`\\[(?<${SHORT_CODE_DEFINITIONS.CODE}>[a-zA-Z]{1,})([ ]{1}(?<${SHORT_CODE_DEFINITIONS.VALUE}>[0-9a-zA-Z- ]{1,})){0,}]`,
	'gm'
);

// NB! All ShortCodes should be documented in confluence: https://woolworths-agile.atlassian.net/wiki/spaces/LASE/pages/32579879725/Contentful+Rich+Text+Short+Codes
const ShortCodes: Record<string, (content: string, regexMatch: RegExpMatchArray) => string> = {
	align: replaceAlignShortCode,
	anchorpoint: replaceAnchorPointShortCode,
	spacing: replaceSpacingShortCode,
	typography: replaceTypographyShortCode,
};
const ValidShortCodes: string[] = Object.keys(ShortCodes);

export const replaceShortCodes = (content: string): string => {
	let updatedContent = content;
	const matches = updatedContent.matchAll(SHORT_CODE_REGEX);

	for (const match of matches) {
		if (match.groups && match.groups[SHORT_CODE_DEFINITIONS.CODE]) {
			const code = match.groups[SHORT_CODE_DEFINITIONS.CODE].toLowerCase();
			if (ValidShortCodes.includes(code)) {
				updatedContent = ShortCodes[code](updatedContent, match);
			}
		}
	}
	return updatedContent;
};
