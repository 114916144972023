import { Component, Input, OnInit } from '@angular/core';
import { RedemptionPreference } from '@edr/bff-api-models';
import { Observable, filter, take } from 'rxjs';
import { MemberFacade, RedemptionPreferencesState } from '../../../member';
import { AsyncPipe, DatePipe } from '@angular/common';
import { SpinnerComponent } from '@edr/styleguide';

/**
 * Todo CLSE-1991:
 * Add expiry date to API so we can show the user if they have an expiring voucher
 */
@Component({
	selector: 'edr-app-points-dashboard-reward-tile-tooltip',
	standalone: true,
	imports: [AsyncPipe, DatePipe, SpinnerComponent],
	templateUrl: './points-dashboard-reward-tile-tooltip.component.html',
})
export class PointsDashboardRewardTileTooltipComponent implements OnInit {
	@Input() public hasVoucher = false;
	@Input() public voucherExpiryDate?: string;
	public redemptionPreferencesState$: Observable<RedemptionPreferencesState>;

	constructor(private memberFacade: MemberFacade) {
		this.redemptionPreferencesState$ = this.memberFacade.redemptionPreferencesState$;
	}

	public vouchersRewardsTileTooltip(redemptionPreference: RedemptionPreference | undefined, saveForOccasionDate?: string | null): string {
		const noVouchersMessage = 'Your vouchers will appear here as you earn them.';
		const hasVoucherAvailableMessage = 'You can spend your Rewards dollars at Woolworths and any participating bp nationwide.';
		const voucherExpiringMessage = `You have one voucher expiring on ${this.voucherExpiryDate}.`;

		if (redemptionPreference === RedemptionPreference.SaveForOccasion) {
			return saveForOccasionDate
				? `Your Everyday Rewards vouchers will be available on ${saveForOccasionDate}.`
				: 'Unlock your savings on the <a href="/account/rewards-choice">Rewards Choice</a> page.';
		}

		return !this.hasVoucher ? noVouchersMessage : this.voucherExpiryDate ? voucherExpiringMessage : hasVoucherAvailableMessage;
	}

	public ngOnInit(): void {
		this.redemptionPreferencesState$
			.pipe(
				take(1),
				filter((state) => state.redemptionPreference === RedemptionPreference.SaveForOccasion && state.saveForOccasionDate === undefined)
			)
			.subscribe(() => {
				this.memberFacade.loadRedemptionPreferences();
			});
	}
}
