import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	AUTH_CONSTANTS,
	AuthService,
	FlagKey,
	FlagService,
	FooterComponent,
	HeaderComponent,
	MandyRoundelComponent,
	ModalService,
	selectAuthHasError,
	selectAuthIsLoggedIn,
	selectAuthState,
	selectUserAuthData,
	userLogin,
	userLogOut,
} from '@edr-core';
import { combineLatest, filter, Observable, of, switchMap, take } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cleanQueryParams, CustomWindow } from '@edr/shared';
import { ModalComponent, SectionComponent, SvgDefinitionsComponent } from '@edr/styleguide';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { RewardsChoiceComponent } from './core/components/rewards-choice/rewards-choice.component';

@UntilDestroy()
@Component({
	selector: 'edr-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [
		HeaderComponent,
		RouterOutlet,
		MandyRoundelComponent,
		FooterComponent,
		SvgDefinitionsComponent,
		ModalComponent,
		RewardsChoiceComponent,
		SectionComponent,
	],
})
export class AppComponent implements OnInit {
	public title = 'Everyday Rewards';
	public isLoggedIn$: Observable<boolean> | undefined;
	public authState$ = this.store.select(selectAuthState);
	public hasAuthenticationError$ = this.store.select(selectAuthHasError);
	public userAuthData$ = this.store.select(selectUserAuthData);
	public disableSilentLoginViaIframe$: Observable<boolean> = this.flagService.isFlagEnabled(FlagKey.featureEdrDisableSilentLoginViaIframe);

	@ViewChild('mainContent') private mainContent: ElementRef<HTMLElement> | undefined;
	@ViewChild('authErrorModal') private authErrorModal: TemplateRef<HTMLElement> | undefined;

	constructor(
		private store: Store,
		private authService: AuthService,
		@Inject(WINDOW) private window: CustomWindow,
		private modalService: ModalService,
		private router: Router,
		private route: ActivatedRoute,
		private tealiumService: TealiumUtagService,
		private cookieService: CookieService,
		private flagService: FlagService,
		@Inject(DOCUMENT) private document: Document
	) {}

	public ngOnInit(): void {
		this.window.addEventListener('message', this.handleMessage.bind(this));
		this.isLoggedIn$ = this.store.select(selectAuthIsLoggedIn);
		this.onAuthError();
		this.initTracking();
		this.autoLogin();
	}

	public initTracking(): void {
		if (this.userAuthData$) {
			this.userAuthData$.pipe(untilDestroyed(this)).subscribe((userAuthData) => {
				this.tealiumService.setSharedData({ login_status: userAuthData.isLoggedIn, scvid: userAuthData.scvId });
			});
		}
		this.router.events.subscribe((routerEvent) => {
			if (routerEvent instanceof NavigationEnd) {
				this.tealiumService.view({
					tealium_event: 'page_view',
				});
				/**
				 * This cookie is used by Tealium
				 */
				this.cookieService.set('appsettings-previousUrl', routerEvent.url);
			}
		});
	}

	public autoLogin(): void {
		const referrer = this.document.referrer;
		const signedInParamName = AUTH_CONSTANTS.signedInParamName;
		const signedInParamExists = this.window.location.href.indexOf(signedInParamName) > -1;
		const triggerLogin = signedInParamExists || this.route.snapshot.queryParams[signedInParamName] || referrer.includes('countdown.co.nz');

		combineLatest([this.authState$, this.disableSilentLoginViaIframe$])
			.pipe(
				filter(([{ initialising }]) => !initialising),
				switchMap(([{ isLoggedIn }, disableSilentLoginViaIframe]) => {
					if (!isLoggedIn && triggerLogin && disableSilentLoginViaIframe) {
						this.store.dispatch(userLogin({ noPrompt: true, deleteSignedInParam: true }));
					} else {
						if (signedInParamExists) {
							//need to remove signedIn param as it interferes with the logout process which again triggers login
							this.window.location.href = cleanQueryParams(this.window.location.href, [signedInParamName]);
						}
					}
					return of(undefined);
				})
			)
			.pipe(untilDestroyed(this))
			.subscribe();
	}

	public handleLogin(): void {
		this.authService.startLoginFlow(this.window.location.href).subscribe();
	}

	public handleLogout(): void {
		this.store.dispatch(userLogOut());
	}

	public handleClose(): void {
		this.modalService.close();
	}

	public skipToContent(): void {
		this.mainContent?.nativeElement.focus();
	}

	public onAuthError(): void {
		this.hasAuthenticationError$.pipe(filter(Boolean), take(1)).subscribe(() => {
			if (this.authErrorModal) {
				this.modalService.open({ id: 'authErrorModal', ref: this.authErrorModal });
			}
		});
	}

	private handleMessage(event: MessageEvent<string>): void {
		if (event.origin !== this.window.location.origin) {
			return;
		}

		if (event.origin === this.window.location.origin && event.data === 'silentLoginCheckComplete') {
			this.store.dispatch(userLogin({}));
		}
	}
}
