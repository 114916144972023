import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { ButtonComponent } from '../button/button.component';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CalendarHeaderService } from '../calendar-header/calendar-header.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { DateTime } from 'luxon';
import { EDR_LUXON_DATE_FORMATS } from '@edr/shared';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, ButtonComponent, ReactiveFormsModule, ErrorMessageComponent],
	providers: [provideLuxonDateAdapter(EDR_LUXON_DATE_FORMATS)],
	selector: 'edr-calendar',
	standalone: true,
	styleUrls: ['./calendar.component.scss'],
	templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit {
	@Input() public minDate?: Date;
	@Input() public maxDate?: Date;
	@Input() public errorMessage?: string;
	@Output() public dateSelected = new EventEmitter<Date>();
	@Output() public isValid = new EventEmitter<boolean>();

	public edrCalendarHeader = CalendarHeaderComponent;
	public activeDate?: Date | string;

	public dateFormControl: FormControl<Date | string | null>;
	public hasDateBeenApplied = false;

	@Input()
	public get selectedDate(): Date | string | undefined {
		return this._selectedDate;
	}

	public set selectedDate(value: Date | string | undefined) {
		this._selectedDate = value;
		if (value) {
			this.dateFormControl.setValue(value);
		} else {
			this.dateFormControl.reset();
		}
	}

	private _selectedDate?: Date | string;

	constructor(calendarHeaderService: CalendarHeaderService, cdr: ChangeDetectorRef) {
		this.dateFormControl = new FormControl(null, Validators.required);

		calendarHeaderService.activeDate$.pipe(takeUntilDestroyed()).subscribe((date: Date) => {
			this.activeDate = date;
			setTimeout(() => cdr.detectChanges(), 0);
		});
	}

	public ngOnInit(): void {
		if (this.errorMessage) {
			this.hasDateBeenApplied = true;
			this.dateFormControl.setErrors({ invalidDate: true });
		}

		if (this.selectedDate) {
			this.dateFormControl.setValue(this.selectedDate);
		}
	}

	public onCancelPressed(event: Event): void {
		// Simulate a click on the srcElement
		if (event.target) {
			const target = event.target as HTMLElement;
			target.click();
		}
	}

	public onCancelClicked(): void {
		this.activeDate = undefined;
	}

	public onApplyPressed(event: Event): void {
		// Simulate a click on the srcElement
		if (event.target && this.activeDate) {
			this.dateFormControl.setValue(this.activeDate);
			const target = event.target as HTMLElement;
			target.click();
		}
	}

	public onApplyClicked(): void {
		this.applySelectedDate();
	}

	public onDateChanged(event: MatDatepickerInputEvent<DateTime>): void {
		if (event.value) {
			this.activeDate = event.value.toJSDate();
		}
	}

	/**
	 * Handle keyboard events (Enter and Blur) on the date input
	 */
	public onManualDateEntered(): void {
		this.hasDateBeenApplied = true;

		// Check if the date entered is a valid date and if not,, set an error on the form control
		if (!this.dateFormControl.value) {
			this.dateFormControl.setErrors({ invalidDate: true });
		} else {
			this.activeDate = this.dateFormControl.value;
			this.applySelectedDate();
		}

		this.updateErrorMessage();
	}

	private applySelectedDate(): void {
		if (this.activeDate) {
			this.hasDateBeenApplied = true;

			// If this.activeDate type is string, then convert it to JavaScript Date
			if (typeof this.activeDate === 'string') {
				this.activeDate = DateTime.fromISO(this.activeDate).toJSDate();
			}

			this.dateSelected.emit(this.activeDate);
			this.dateFormControl.setValue(this.activeDate);
		}

		this.updateErrorMessage();
	}

	private updateErrorMessage(): void {
		if (this.dateFormControl.hasError('required')) {
			this.errorMessage = 'You must select a date';
		} else if (this.dateFormControl.hasError('invalidDate')) {
			this.errorMessage = 'Not a valid date';
		} else if (this.minDate && this.dateFormControl.hasError('matDatepickerMin')) {
			this.errorMessage = `The date can not be before ${DateTime.fromJSDate(this.minDate).toFormat('dd/MM/yyyy')}`;
		} else if (this.maxDate && this.dateFormControl.hasError('matDatepickerMax')) {
			this.errorMessage = `The date can not be after ${DateTime.fromJSDate(this.maxDate).toFormat('dd/MM/yyyy')}`;
		} else {
			this.errorMessage = undefined;
		}

		this.isValid.emit(!this.errorMessage);
	}
}
