import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ApiService, AppSettingsService } from '../../../core';
import { MemberRedemptionPreferencesResponse } from '@edr/bff-api-models';

@Injectable({
	providedIn: 'root',
})
export class RedemptionPreferencesService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	public fetchRedemptionPreferences(): Observable<MemberRedemptionPreferencesResponse> {
		return this.appSettingsService
			.getEndpoint('redemptionPreferences')
			.pipe(switchMap((endPoint) => this.apiService.get<MemberRedemptionPreferencesResponse>(endPoint)));
	}

	public updateRedemptionPreferences(redemptionPreferences: MemberRedemptionPreferencesResponse): Observable<unknown> {
		return this.appSettingsService
			.getEndpoint('redemptionPreferences')
			.pipe(switchMap((endPoint) => this.apiService.put(endPoint, redemptionPreferences)));
	}
}
