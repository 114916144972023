import { APP_ROUTES } from '../../routes';
import { PageContentError } from './error.model';

const serverErrorContent: PageContentError = {
	title: 'There’s a glitch in the matrix.',
	subTitle: 'What if we told you',
	description: ["We're not quite sure what went wrong. You can go back, reload the page or look on our Help & FAQs if you need a hand."],
	errorCode: 500,
	errorCodeDescription: 'Server Error',
	ctas: [
		{
			text: 'Reload',
			linkUrl: '..',
			buttonStyle: 'primary',
		},
		{
			text: 'Visit the Help & FAQs',
			linkUrl: `/${APP_ROUTES.help}`,
			buttonStyle: 'primary',
			buttonReversed: true,
		},
	],
};

export const pageContent: Record<string, PageContentError> = {
	'500': {
		...serverErrorContent,
	},
	'502': {
		...serverErrorContent,
		errorCode: 502,
	},
	'503': {
		...serverErrorContent,
		errorCode: 503,
	},
	'404': {
		title: "You've stumbled upon an intergalactic wormhole to nowhere!",
		description: [
			"The page you're looking for was either moved, removed, renamed, or never existed in this cosmic expanse. But fear not! Our skilled navigational crew is on high alert, scanning the stars to reunite you with the missing page.",
			'In the meantime, we suggest heading back to our home page, where the stars align with a galaxy of information, tantalising offers, and a stellar shopping experience!',
		],
		errorCode: 404,
		errorCodeDescription: 'Not Found',
		ctas: [
			{
				text: 'Back to the Mothership',
				linkUrl: `/${APP_ROUTES.home}`,
				buttonStyle: 'primary',
			},
			{
				text: 'I’ve got more questions',
				linkUrl: `/${APP_ROUTES.help}`,
				buttonStyle: 'secondary',
				buttonReversed: true,
			},
		],
	},
};
