import { Component, Input } from '@angular/core';
import { CtaType } from '../../enums';

import { FlexDirection, FlexDirectionEnum } from '../../types';
import { AnchorTargetMap, ContentBaseComponent } from '@edr/shared';

export class CtaGroupDefaults {
	public static readonly CtaType = CtaType.Button;
	public static readonly AnchorTarget = AnchorTargetMap.self;
	public static readonly MobileCtaDirection = FlexDirectionEnum.Column;
	public static readonly TabletCtaDirection = FlexDirectionEnum.Column;
	public static readonly DesktopCtaDirection = FlexDirectionEnum.Column;
}

@Component({
	selector: 'edr-cta-group',
	templateUrl: './cta-group.component.html',
	styleUrls: ['./cta-group.component.scss'],
	standalone: true,
})
export class CtaGroupComponent extends ContentBaseComponent {
	/**
	 * The flex direction of the call to action group for each display type
	 */
	@Input() public mobileCtaDirection: FlexDirection = CtaGroupDefaults.MobileCtaDirection;

	@Input() public tabletCtaDirection: FlexDirection = CtaGroupDefaults.TabletCtaDirection;

	@Input() public desktopCtaDirection: FlexDirection = CtaGroupDefaults.DesktopCtaDirection;
}
