@if (action !== 'Boost all') {
<button
	(click)="clickAction()"
	type="button"
	edr-button
	[buttonSize]="size"
	[buttonStyle]="buttonStyle"
	[reversed]="buttonStyleReversed"
	[iconLeft]="iconLeft"
	[showLeftIcon]="showLeftIcon"
	[iconRight]="iconRight"
	[showRightIcon]="showRightIcon"
>
	{{ text }}
</button>
} @else {
<edr-app-boost-all-button
	[size]="size"
	[buttonStyle]="buttonStyle"
	[reversed]="buttonStyleReversed"
	[text]="text"
	data-testid="boost-all-button"
></edr-app-boost-all-button>
}
