import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import {
	AccordionComponent,
	AccordionItemCardComponent,
	ButtonComponent,
	CardComponent,
	CtaProps,
	DeviceStyleConfig,
	HeadingComponent,
	IconProps,
	SectionComponent,
	SpinnerComponent,
} from '@edr/styleguide';
import { AccordionItemProps, CardComponentProps, rewardsChoiceComponentContent } from '../rewards-choice.component.content';
import { AsyncPipe } from '@angular/common';
import { ModalService } from '@edr-core';
import {
	RewardsChoiceConfirmationModalComponent,
	RewardsChoiceConfirmationModalData,
} from '../../rewards-choice-confirmation-modal/rewards-choice-confirmation-modal.component';
import { ContentfulToHtmlPipe } from '@edr/contentful';
import { ContentBaseComponent } from '@edr/shared';
import { RedemptionPreference } from '@edr/bff-api-models';

@Component({
	selector: 'edr-app-spend-now',
	templateUrl: './spend-now.component.html',
	styleUrls: ['./spend-now.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AccordionComponent,
		AccordionItemCardComponent,
		CardComponent,
		ButtonComponent,
		SectionComponent,
		AsyncPipe,
		HeadingComponent,
		SpinnerComponent,
		ContentfulToHtmlPipe,
	],
})
export class SpendNowComponent extends ContentBaseComponent implements OnInit {
	@Input() public isLoading = false;
	@Input() public isSelected = false;
	@Input() public accordionItem: AccordionItemProps;
	@Input() public howItWorksCards: CardComponentProps[];
	@Input() public chooseCta: CtaProps;
	@Input() public selectedHeaderDescription: string;

	public icon: IconProps | undefined;
	public description = signal('');
	public content = rewardsChoiceComponentContent;
	public howItWorksTitleSpace: DeviceStyleConfig = { desktop: '6', tablet: '4', mobile: '4' };
	public howItWorksCardSpace: DeviceStyleConfig = { desktop: '6', tablet: '4', mobile: '4' };

	constructor(private modalService: ModalService) {
		super();

		this.accordionItem = this.content.spendNow.accordionItem;
		this.howItWorksCards = this.content.spendNow.howItWorksCards;
		this.chooseCta = this.content.spendNow.chooseCta;
		this.selectedHeaderDescription = this.content.spendNow.selectedHeaderDescription;
		this.icon = this.accordionItem.icon;
	}

	public ngOnInit(): void {
		if (this.isSelected) {
			this.description.set(this.selectedHeaderDescription);
		} else {
			this.description.set(this.accordionItem.description ?? '');
		}
	}

	public onSelectSpendNow(): void {
		const iconName = this.accordionItem?.icon?.name ?? 'rewards-dollar-sign';

		const modalData: RewardsChoiceConfirmationModalData = {
			redemptionPreference: RedemptionPreference.SpendNow,
			iconName,
			saveForOccasionDate: undefined,
			isCurrentPreference: this.isSelected,
		};

		this.modalService.open({
			ref: RewardsChoiceConfirmationModalComponent,
			id: 'rewards-choice-confirmation-modal',
			config: {
				data: { modalData },
			},
		});
	}
}
