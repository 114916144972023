<edr-input
	#textInput
	[id]="id"
	[placeholder]="placeholder"
	[disabled]="disabled"
	[value]="searchText"
	[ariaLabel]="ariaLabel"
	[required]="required"
	[isError]="isError"
	[label]="label"
	[name]="name"
	(focused)="openDropdownPanel()"
	(click)="openDropdownPanel()"
	(blurred)="handleBlurred()"
	autocomplete="off"
>
	@if (searchText && !isSearching) {
	<edr-icon name="system-close" color="secondary--charcoal" size="4" inputSuffix class="clear-icon" (click)="clearSelectedValue()" />
	} @if (isSearching) {
	<edr-spinner class="input-spinner" inputSuffix />
	}
</edr-input>

<ng-template #panelTemplateRef>
	@if (hasOptions$ && (hasOptions$ | async)) {
	<div class="edr-autocomplete-panel" role="listbox" [attr.aria-label]="ariaLabel || null">
		<ng-content></ng-content>
	</div>
	}
</ng-template>
