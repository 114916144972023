import { Routes } from '@angular/router';
import { ErrorPageComponent } from './error.page.component';
import { APP_ROUTES } from '../../routes';

export const errorRoutes: Routes = [
	{
		path: `${APP_ROUTES.error}/:errorCode`,
		component: ErrorPageComponent,
		children: [],
	},
];
