import { Action, createReducer, on } from '@ngrx/store';
import * as NavItemsActions from './nav-items.actions';
import { NavigationResponse } from '@edr/bff-api-models';

export const NAV_ITEMS_STATE_KEY = 'navItems';

export interface NavItemsState {
	isLoading: boolean;
	content: NavigationResponse[];
	error: string | undefined;
}

export const defaultNavItemsState: NavItemsState = {
	isLoading: false,
	content: [],
	error: undefined,
};

const reducer = createReducer(
	defaultNavItemsState,
	on(NavItemsActions.loadNavItems, (state): NavItemsState => ({ ...state, error: undefined, isLoading: true, content: [] })),
	on(
		NavItemsActions.loadNavItemsSuccess,
		(state, action): NavItemsState => ({ ...state, error: undefined, isLoading: false, content: action.content })
	),
	on(NavItemsActions.loadNavItemsFailure, (state, action): NavItemsState => ({ ...state, error: action.error, isLoading: false, content: [] }))
);

export function navItemsReducer(state: NavItemsState | undefined, action: Action): NavItemsState {
	return reducer(state, action);
}
