import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { TypographyComponent } from '@edr/styleguide';
import { DynamicContentFacade } from '../../+state/dynamic-content';
import { Observable, filter } from 'rxjs';
import { NavigationItemResponse } from '@edr/bff-api-models';

@Component({
	selector: 'edr-app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, AsyncPipe, TypographyComponent],
})
export class FooterComponent {
	public currentYear = new Date().getFullYear();
	public navItems$: Observable<NavigationItemResponse[]>;

	constructor(private dynamicContentFacade: DynamicContentFacade) {
		this.navItems$ = this.dynamicContentFacade.footerNavItems$.pipe(filter(Boolean));
	}
}
