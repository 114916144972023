import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({ name: 'pointsDisplay', standalone: true })
export class PointsDisplayPipe implements PipeTransform {
	private decimalPipe = inject(DecimalPipe);

	public transform(value: number | string | undefined | null, columnName: 'points' | 'bonusPoints'): string {
		if (!value) {
			return `-`;
		}
		return (columnName === 'bonusPoints' ? '+' : '') + this.decimalPipe.transform(value) + `${Number(value) === 1 ? 'pt' : 'pts'}`;
	}
}
