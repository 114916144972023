import { APP_INITIALIZER, PLATFORM_ID, Provider } from '@angular/core';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { Store } from '@ngrx/store';
import { authInitialise } from '../../auth';
import { EnvFacade } from '@edr/env';
import { PartnersFacade } from '../../../features/partners/+state';
import { FlagService } from '../optimizely/flag.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { DynamicContentFacade } from '../../+state/dynamic-content';
import { ContentService } from '../content/content.service';
import { Route, Router } from '@angular/router';
import { appRoutes } from '../../../app.routes';
import { APP_ROUTES } from '../../../routes';

export function initialiseApp(
	appSettingsService: AppSettingsService,
	store: Store,
	envFacade: EnvFacade,
	partnerFacade: PartnersFacade,
	flagService: FlagService,
	tealiumUtagService: TealiumUtagService,
	platformId: object,
	dynamicContentFacade: DynamicContentFacade,
	contentService: ContentService,
	router: Router
): () => Observable<true> {
	return () => {
		envFacade.monitorBreakpoint();
		return appSettingsService.initialize().pipe(
			tap((appSettings) => {
				if (isPlatformBrowser(platformId)) {
					tealiumUtagService.setConfig({
						account: appSettings.tealiumAccount,
						profile: appSettings.tealiumProfile,
						environment: appSettings.tealiumEnvironment,
					});
					return flagService.initialize(appSettings.optimizelyKey);
				}
				return;
			}),
			tap(() => {
				if (isPlatformServer(platformId)) {
					return;
				}
				store.dispatch(authInitialise());
				dynamicContentFacade.loadNavItems();
				partnerFacade.loadPartners();
			}),
			switchMap(() =>
				contentService.fetchRoutes().pipe(
					catchError(() => of([])),
					tap((routes) => {
						updateRoutes(router, routes);
					})
				)
			),
			map(() => true)
		);
	};
}

export const startupServiceProvider: Provider = {
	provide: APP_INITIALIZER,
	useFactory: initialiseApp,
	deps: [
		AppSettingsService,
		Store,
		EnvFacade,
		PartnersFacade,
		FlagService,
		TealiumUtagService,
		PLATFORM_ID,
		DynamicContentFacade,
		ContentService,
		Router,
	],
	multi: true,
};

function updateRoutes(router: Router, routes: Route[]): void {
	router.resetConfig([
		...appRoutes,
		...routes,
		{
			path: '**',
			redirectTo: `${APP_ROUTES.error}/404`,
		},
	]);
}
