import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	IconComponent,
	StripeComponent,
	TableComponent,
	HeadingComponent,
	EDRIcon,
	CircleComponent,
	ApplyStylesDirective,
	TooltipDirective,
	TooltipAlignment,
	TooltipPosition,
	TooltipTrigger,
} from '@edr/styleguide';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { AmountDisplayPipe } from '@edr/shared';
import { PointsDisplayPipe } from '../../pipes';
import { ActivityDetailsUomType, ActivityListResponse, RedeemType, RewardType } from '@edr/bff-api-models';
import { PartnersFacade } from '../../../features/partners/+state';
import { Observable } from 'rxjs';
import { PointsTransactionTable } from '../points-table/points-table.model';
import { PointsDetailsTableComponent } from '../points-details-table/points-details-table.component';

@Component({
	selector: 'edr-app-points-table-desktop',
	templateUrl: './points-table-desktop.component.html',
	styleUrls: ['./points-table-desktop.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		TableComponent,
		IconComponent,
		StripeComponent,
		PointsDetailsTableComponent,
		HeadingComponent,
		CommonModule,
		CircleComponent,
		CdkTableModule,
		ApplyStylesDirective,
		AmountDisplayPipe,
		PointsDisplayPipe,
		TooltipDirective,
	],
})
export class PointsTableDesktopComponent extends TableComponent {
	@Input() public detailsColumnsList: { name: string; displayName: string }[] = [];
	@Input() public override dataSource: PointsTransactionTable[] = [];
	@Input() public override columnList: { name: string; displayName: string }[] = [];
	public partnerLogo$: Observable<{ src: string; alt: string } | undefined> | undefined;
	public TooltipPosition = TooltipPosition;
	public TooltipTrigger = TooltipTrigger;
	public TooltipAlignment = TooltipAlignment;
	public ActivityDetailsUomType = ActivityDetailsUomType;

	constructor(private partnersFacade: PartnersFacade) {
		super();
	}

	public shouldShowStripe(_: number, transaction: ActivityListResponse): boolean {
		return !!(transaction.rewards?.length || transaction.redeemed?.length);
	}

	public shouldShowPointsDetailsTable(_: number, transaction: ActivityListResponse): boolean {
		return !!transaction.isActivityDetailsAvailable;
	}

	public getRedeemedStripeIcon(redeemedType: RedeemType): EDRIcon | undefined {
		switch (redeemedType) {
			case RedeemType.Fuel:
				return 'rewards-fuel';
			case RedeemType.Voucher:
				return 'rewards-auto-renew';
			default:
				return undefined;
		}
	}

	public getRewardStripeIcons(rewardTypes: RewardType): { left: EDRIcon | undefined; right: EDRIcon | undefined } {
		switch (rewardTypes) {
			case RewardType.Fuel:
			case RewardType.BehaviouralOffer:
			case RewardType.Voucher:
			case RewardType.Gift:
				return { left: 'rewards-sparkle', right: 'everyday-extra-perks' };
			default:
				return { left: undefined, right: undefined };
		}
	}

	public getLogo(transaction: PointsTransactionTable): Observable<{
		src: string;
		alt: string;
	}> {
		return this.partnersFacade.getPartnerLogo(transaction);
	}
}
