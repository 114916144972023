import { Action, createReducer, on } from '@ngrx/store';
import * as MemberActions from './redemption-preferences.actions';
import * as BalancesActions from '../balances/balances.actions';
import { MemberProfileResponse, RedemptionPreference } from '@edr/bff-api-models';

export const REDEMPTION_PREFERENCES_STATE_KEY = 'redemptionPreferences';

export interface RedemptionPreferencesState {
	value: MemberProfileResponse | undefined;
	redemptionPreference?: RedemptionPreference;
	saveForOccasionDate?: Date | string;
	isLoadingRedemptionPreferences: boolean;
	updatingRedemptionPreferences: boolean;
	updatedRedemptionPreferences: boolean;
	error?: unknown;
}

export const initialRedemptionPreferencesState: RedemptionPreferencesState = {
	value: undefined,
	redemptionPreference: undefined,
	isLoadingRedemptionPreferences: false,
	updatingRedemptionPreferences: false,
	updatedRedemptionPreferences: false,
};

const reducer = createReducer(
	initialRedemptionPreferencesState,
	on(
		MemberActions.loadRedemptionPreferences,
		(state): RedemptionPreferencesState => ({
			...state,
			error: undefined,
			isLoadingRedemptionPreferences: true,
		})
	),
	on(
		MemberActions.loadRedemptionPreferencesSuccess,
		(state, { redemptionPreferences }): RedemptionPreferencesState => ({
			...state,
			isLoadingRedemptionPreferences: false,
			redemptionPreference: redemptionPreferences.redemptionPreference,
			saveForOccasionDate: redemptionPreferences.saveForOccasionDate,
		})
	),
	on(
		MemberActions.loadRedemptionPreferencesFailure,
		(state, { error }): RedemptionPreferencesState => ({
			...state,
			isLoadingRedemptionPreferences: false,
			error,
			value: undefined,
		})
	),
	on(
		BalancesActions.loadBalancesSuccess,
		(state, { balances }): RedemptionPreferencesState => ({
			...state,
			isLoadingRedemptionPreferences: false,
			redemptionPreference: balances.redemptionPreference,
		})
	),
	on(
		MemberActions.updateRedemptionPreferences,
		(state): RedemptionPreferencesState => ({
			...state,
			updatingRedemptionPreferences: true,
			updatedRedemptionPreferences: false,
			error: undefined,
		})
	),
	on(
		MemberActions.updateRedemptionPreferencesSuccess,
		(state, { redemptionPreferences }): RedemptionPreferencesState => ({
			...state,
			redemptionPreference: redemptionPreferences.redemptionPreference,
			saveForOccasionDate: redemptionPreferences.saveForOccasionDate,
			updatingRedemptionPreferences: false,
			updatedRedemptionPreferences: true,
		})
	),
	on(
		MemberActions.updateRedemptionPreferencesFailure,
		(state, { error }): RedemptionPreferencesState => ({
			...state,
			updatingRedemptionPreferences: false,
			error,
		})
	),
	on(MemberActions.resetUpdatedState, (state): RedemptionPreferencesState => ({ ...state, updatedRedemptionPreferences: false, error: undefined }))
);

export function redemptionPreferencesReducer(state: RedemptionPreferencesState | undefined, action: Action): RedemptionPreferencesState {
	return reducer(state, action);
}
