import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Router, UrlTree } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { AuthService } from '../../services';
import { selectAuthState } from '../../auth';

/**
 * Pass { startLoginFlow: true } in order to start the login flow if a user is not logged in.
 * Pass { redirectPath: '/some/path' } to redirect a user that is not logged in to a specific page.
 */
export const isAuthenticatedUserGuard =
	({ startLoginFlow, redirectPath }: { startLoginFlow: boolean; redirectPath?: string }) =>
	(): Observable<boolean | UrlTree> => {
		const router = inject(Router);
		const store = inject(Store);
		const authService = inject(AuthService);
		const window = inject(WINDOW);

		return store.select(selectAuthState).pipe(
			filter(({ initialising }) => !initialising),
			switchMap(({ isLoggedIn }) => {
				if (!isLoggedIn && startLoginFlow) {
					return authService.startLoginFlow(window.location.href).pipe(
						map(() => false) // User is not logged in yet
					);
				}
				return of(!isLoggedIn && redirectPath ? router.parseUrl(redirectPath) : isLoggedIn);
			})
		);
	};
