import { Injectable } from '@angular/core';
import { LoggingService } from '@edr/shared';

/**
 * This service can be used to keep references to instances of components and access/use them elsewhere.
 *
 * Typically used if you need to access child components from a parent, or the other way around
 * and can't or don't want to use @ContentChildren
 *
 * Usage:
 *	- Pass the same string as an id to the parent and child component: @Input() public id: string;
 * 	Parent component (or any other component for that matter):
 * 		- Add the ChildrenService to the constructor
 * 		- Call childrenService.getChildren(this.id) to get the instance of each of the children
 * 	Child component:
 * 		- Add the ChildrenService to the constructor
 * 		- In the constructor call childrenService.register(id, this)
 * 	  - In the onDestroy hook call childrenService.deregister(id, this)
 */
@Injectable({
	providedIn: 'root',
})
export class ComponentRefService {
	/* eslint-disable @typescript-eslint/no-explicit-any */
	private refMap = new Map<string, any[]>();

	constructor(private loggingService: LoggingService) {}

	public register<T>(id: string, item: T): void {
		if (!id) {
			this.loggingService.warn('Called ComponentRefService without an id.');
		}
		const children = this.refMap.get(id) || [];
		this.refMap.set(id, [...children, item]);
	}

	public deregister<T>(id: string, item: T): void {
		if (!this.refMap.has(id)) {
			return;
		}
		const updatedChildren = (this.refMap.get(id) || []).filter((child) => child !== item);
		if (!updatedChildren.length) {
			this.refMap.delete(id);
			return;
		}
		this.refMap.set(id, updatedChildren || []);
	}

	public getComponentRefs(id: string): any[] | undefined {
		return this.refMap.get(id);
	}
}
