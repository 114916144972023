import { inject } from '@angular/core';
import { FlagService } from '../../services/optimizely/flag.service';
import { FlagKey } from '../../services/optimizely/flag-key.enum';
import { Observable, map } from 'rxjs';
import { Router, UrlTree } from '@angular/router';

export const isFlagVariationActiveGuard =
	({ flagKey, variationKeys, redirectPath }: { flagKey: FlagKey; variationKeys: string[]; redirectPath?: string }) =>
	(): Observable<boolean | UrlTree> => {
		const router = inject(Router);
		const flagService = inject(FlagService);

		return flagService
			.someFlagVariationActive(flagKey, variationKeys)
			.pipe(map((canAccess) => (!canAccess && redirectPath ? router.parseUrl(redirectPath) : canAccess)));
	};
