import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SpinnerComponent } from '@edr/styleguide';
import { AsyncPipe } from '@angular/common';
import { DynamicContentComponent } from '@edr/dc';
import { FlagKey, FlagService } from '../../services';
import { Observable, filter, map } from 'rxjs';
import { SilentAuthComponent } from '../../auth/silent-auth.component';
import { DynamicContentFacade } from '../../+state/dynamic-content';
import { ComponentResponse, mapToDynamicComponent } from './dynamic-content.factory';
import { ActivatedRoute } from '@angular/router';
import { ContentfulLivePreview } from '@contentful/live-preview';

/**
 * Use the Dynamic Page Component to display pages created with the FlexiblePageContent content type in Contentful
 * In your router config add:
 * 	{
 *	  path: 'some-path',
 *	  component: DynamicPageComponent,
 *    children: [],
 *    data: { slug: 'flexible-example' },
 *    pathMatch: 'full',
 *  },
 */
ContentfulLivePreview.init({ locale: 'en-NZ' });

@Component({
	selector: 'edr-app-dynamic-page',
	templateUrl: './dynamic-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	styleUrls: ['./dynamic-page.component.scss'],
	imports: [SpinnerComponent, DynamicContentComponent, AsyncPipe, SilentAuthComponent],
})
export class DynamicPageComponent implements OnInit {
	public pageContent$ = this.dynamicContentFacade.pageContent$.pipe(
		filter(Boolean),
		map(({ content }) => mapToDynamicComponent(content as ComponentResponse[]))
	);
	public dynamicContentState$ = this.dynamicContentFacade.pageContentState$;
	public disableSilentLoginViaIframe$: Observable<boolean> = this.flagService.isFlagEnabled(FlagKey.featureEdrDisableSilentLoginViaIframe);

	constructor(
		private flagService: FlagService,
		private dynamicContentFacade: DynamicContentFacade,
		private activatedRoute: ActivatedRoute
	) {}

	public ngOnInit(): void {
		this.dynamicContentFacade.loadPageContent(
			this.activatedRoute.snapshot.data['pageId'],
			this.activatedRoute.snapshot.data['isPreview'] ?? false,
			this.activatedRoute.snapshot.data['verificationKey'] ?? '',
			this.activatedRoute.snapshot.data['env'] ?? null
		);
	}
}
