import { AccordionItemCardComponent, CardComponent, CtaProps } from '@edr/styleguide';

export type CardComponentProps = Partial<CardComponent>;
export type AccordionItemProps = Partial<AccordionItemCardComponent>;

export interface SpendNowProps {
	accordionItem: AccordionItemProps;
	howItWorksCards: CardComponentProps[];
	chooseCta: CtaProps;
	selectedHeaderDescription: string;
}

export interface SaveForOccasionProps {
	accordionItem: AccordionItemProps;
	howItWorksCards: CardComponentProps[];
	chooseCta: CtaProps;
	maxMonthsInFuture: number;
	selectedHeaderDescription: string;
}

export interface RewardsChoiceComponentContent {
	currentRewardsChoiceTitle: string;
	otherRewardsChoicesTitle: string;
	spendNow: SpendNowProps;
	saveForOccasion: SaveForOccasionProps;
}

export const rewardsChoiceComponentContent: RewardsChoiceComponentContent = {
	currentRewardsChoiceTitle: 'Your current Rewards choice',
	otherRewardsChoicesTitle: 'Other Rewards choices',
	spendNow: {
		accordionItem: {
			heading: 'Spend',
			description: 'Spend your vouchers at a participating Everyday Rewards partner.',
			icon: {
				name: 'rewards-dollar-sign',
				color: 'extended--charcoal',
				size: '8',
				circleColor: 'extended--grey-light-90',
				circleSize: '10',
			},
		},
		howItWorksCards: [
			{
				cardType: 'icon',
				cardBorder: 'none',
				icon: {
					name: 'rewards-card',
					color: 'primary--orange',
					size: '8',
					circleColor: 'extended--orange-light-90',
					circleSize: '10',
				},
				title: 'Collect Everyday Rewards points',
				description:
					'Collect 1 point for every dollar you spend with Everyday Rewards partners. Use your Weekly and Everyday Boosts to collect points even faster.',
				ctaType: 'link',
				primaryCta: {
					text: 'Learn more about Boosts',
					linkUrl: '/how-boosts-work',
				},
			},
			{
				cardType: 'icon',
				cardBorder: 'none',
				icon: {
					name: 'rewards-point-dollar',
					color: 'primary--orange',
					size: '8',
					circleColor: 'extended--orange-light-90',
					circleSize: '10',
				},
				title: 'Redeem Everyday Rewards vouchers',
				description:
					'For every 2,000 points you collect, you’ll get a $15 voucher added to your account. Redeem your vouchers at checkout in-store and online.',
				ctaType: 'link',
				primaryCta: {
					text: 'Where can you spend your voucher?',
					linkUrl: '/help',
				},
			},
		],
		chooseCta: {
			text: 'Choose this option',
			linkUrl: '/rewards-choice',
			style: 'secondary',
		},
		selectedHeaderDescription: 'Spend your vouchers at a participating Everyday Rewards partner.',
	},
	saveForOccasion: {
		accordionItem: {
			heading: 'Save for an occasion',
			description: 'Choose a date and save your Everyday Rewards vouchers until you’re ready to use them.',
			icon: {
				name: 'rewards-dollar-sign-with-piggy',
				color: 'extended--charcoal',
				size: '8',
				circleColor: 'extended--grey-light-90',
				circleSize: '10',
			},
		},
		howItWorksCards: [
			{
				cardType: 'icon',
				cardBorder: 'none',
				icon: {
					name: 'rewards-card',
					color: 'primary--orange',
					size: '8',
					circleColor: 'extended--orange-light-90',
					circleSize: '10',
				},
				title: 'Choose your date',
				description: 'You can save your Everyday Rewards vouchers for up to 12 months. You can also modify your date if your plans change.',
				ctaType: 'link',
				primaryCta: {
					text: 'Learn more about Boosts',
					linkUrl: '/how-boosts-work',
				},
			},
			{
				cardType: 'icon',
				cardBorder: 'none',
				icon: {
					name: 'rewards-point-dollar',
					color: 'primary--orange',
					size: '8',
					circleColor: 'extended--orange-light-90',
					circleSize: '10',
				},
				title: 'Save your vouchers',
				description:
					'You’ll collect vouchers as normal, and will see how much you’ve saved in your dashboard. Your vouchers will be ready to spend once you’ve reached your date. ',
				ctaType: 'link',
			},
		],
		chooseCta: {
			text: 'Choose Save for an occasion',
			linkUrl: '/#not-used',
			style: 'secondary',
		},
		maxMonthsInFuture: 12,
		selectedHeaderDescription: 'Your Everyday Rewards vouchers will be available from <b>[[saveForOccasionDate]]</b>.',
	},
};
