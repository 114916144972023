@if ({ isAllOffersBoosted: isAllOffersBoosted$ | async, isUpdatingOffers: isUpdatingOffers$ | async }; as data) {
<button
	data-testid="edr-boost-all-button"
	type="button"
	edr-button
	[buttonSize]="size"
	[buttonStyle]="buttonStyle"
	[reversed]="reversed"
	(click)="!!data.isAllOffersBoosted || !!data.isUpdatingOffers ? undefined : boostAll()"
	[disable]="!!data.isAllOffersBoosted"
	[showLeftIcon]="!!data.isAllOffersBoosted"
	[iconLeft]="{
		name: 'alert-tick-thin',
		size: '3',
		color: 'extended--charcoal-light-20'
	}"
	[isLoading]="!!data.isUpdatingOffers"
	spinnerHeight="4"
>
	{{ data.isAllOffersBoosted ? 'Boosted' : text }}
</button>
}
