import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Input, NgZone, ViewChild } from '@angular/core';
import { FireworksParticle } from './fireworks-particle';
import { WINDOW } from '@ng-web-apis/common';
import { CustomWindow } from '@edr/shared';

@Component({
	selector: 'edr-fireworks',
	templateUrl: './fireworks.component.html',
	styleUrls: ['./fireworks.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class FireworksComponent implements AfterViewInit {
	/**
	 * Maximum number of particles to display on screen
	 */
	@Input() public maximumParticles = 500;

	/**
	 * Probability of firing a new firework if below maximum number of particles
	 */
	@Input() public spawnProbability = 0.04;

	@ViewChild('canvas')
	public canvas!: ElementRef<HTMLCanvasElement>;

	private ctx!: CanvasRenderingContext2D | null;
	private w!: number;
	private h!: number;
	private particles: FireworksParticle[] = [];

	constructor(@Inject(WINDOW) private window: CustomWindow, private zone: NgZone) {}

	@HostListener('window:resize', ['$event'])
	public onWindowResize(): void {
		this.resizeCanvas();
	}

	public ngAfterViewInit(): void {
		this.ctx = this.canvas.nativeElement.getContext('2d');
		this.resizeCanvas();
		this.zone.runOutsideAngular(() => {
			requestAnimationFrame(() => this.updateWorld());
		});
	}

	private resizeCanvas(): void {
		if (this.canvas) {
			this.w = this.canvas.nativeElement.width = this.window.innerWidth;
			this.h = this.canvas.nativeElement.height = this.window.innerHeight;
		}
	}

	private updateWorld(): void {
		this.zone.runOutsideAngular(() => {
			this.update();
			this.paint();
			requestAnimationFrame(() => this.updateWorld());
		});
	}

	private update(): void {
		if (this.particles.length < this.maximumParticles && Math.random() < this.spawnProbability) {
			// shoots off a new firework
			this.generateFirework();
		}

		// updates positions of particles in existing fireworks
		const alive: FireworksParticle[] = [];
		for (const particle of this.particles) {
			if (particle.move()) {
				// only include visible particles
				alive.push(particle);
			}
		}
		this.particles = alive;
	}

	private paint(): void {
		if (!this.ctx) {
			return;
		}

		// clears screen
		this.ctx.clearRect(0, 0, this.w, this.h);

		// draws the particles with their current position
		for (const particle of this.particles) {
			particle.draw(this.ctx);
		}
	}

	private generateFirework(): void {
		// spawn position
		const x = Math.random() * (this.w - 200) + 100;
		const y = Math.random() * (this.h - 200) + 100;

		// create particles
		const nFire = Math.random() * 50 + 100;

		for (let i = 0; i < nFire; i++) {
			const particle = new FireworksParticle(x, y);
			particle.color = 'rgb(253, 100, 0)';
			const vy = Math.sqrt(25 - particle.vx * particle.vx);
			if (Math.abs(particle.vy) > vy) {
				particle.vy = particle.vy > 0 ? vy : -vy;
			}
			this.particles.push(particle);
		}
	}
}
