import { Component, Input, OnInit } from '@angular/core';
import { PointsTransactionTable } from '../../points-table/points-table.model';
import {
	CircleComponent,
	HeadingComponent,
	IconComponent,
	StripeComponent,
	TooltipAlignment,
	TooltipDirective,
	TooltipPosition,
	TooltipTrigger,
} from '@edr/styleguide';
import { AmountDisplayPipe } from '@edr/shared';
import { PointsDisplayPipe } from '../../points-table/points-display-pipe/points-display.pipe';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { DETAILS_TABLE_COLUMNS } from '../../points-table/points-table.mock';
import { ActivityDetailsUomType } from '@edr/bff-api-models';
import { PartnersFacade } from '../../../../features/partners/+state';
import { Observable } from 'rxjs';
import { PointsDetailsTableComponent } from '../../points-details-table/points-details-table.component';

@Component({
	selector: 'edr-app-points-card',
	templateUrl: './points-card.component.html',
	styleUrls: ['./points-card.component.scss'],
	standalone: true,
	imports: [
		StripeComponent,
		HeadingComponent,
		IconComponent,
		PointsDetailsTableComponent,
		CircleComponent,
		AmountDisplayPipe,
		PointsDisplayPipe,
		NgClass,
		DatePipe,
		TooltipDirective,
		AsyncPipe,
	],
})
export class PointsCardComponent implements OnInit {
	@Input() public transaction: PointsTransactionTable | undefined;
	public partnerAlt: string | null | undefined;
	public partnerLogo$: Observable<{ src: string; alt: string }> | undefined;
	public TooltipAlignment = TooltipAlignment;
	public TooltipPosition = TooltipPosition;
	public TooltipTrigger = TooltipTrigger;
	public ActivityDetailsUomType = ActivityDetailsUomType;

	public detailsColumnsList: {
		name: string;
		displayName: string;
	}[] = [...DETAILS_TABLE_COLUMNS];
	constructor(private partnersFacade: PartnersFacade) {}

	public ngOnInit(): void {
		this.partnerLogo$ = this.partnersFacade.getPartnerLogo(this.transaction);
	}
}
