import { MatButtonModule } from '@angular/material/button';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IconComponent } from '../icon/icon.component';
import { SvgDefinitionsComponent } from '../svg-definitions/svg-definitions.component';
import { CalendarHeaderService } from './calendar-header.service';
import { DateTime } from 'luxon';

/**
 * Angular Material has a default datepicker header component, but it doesn't align 100% with the EDR toolkit design for the calendar
 * component.
 * The Angular Material datepicker component does allow a custom header to be implemented, so this is the customised implementation
 * which has the following format "<  Month Year >" with the month and year being clickable to change the view to the month/year
 */
@Component({
	selector: 'edr-calendar-header',
	styleUrls: ['./calendar-header.component.scss'],
	templateUrl: './calendar-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatButtonModule, IconComponent, SvgDefinitionsComponent],
})
export class CalendarHeaderComponent<D> implements OnDestroy {
	private _destroyed = new Subject<void>();
	private _activeDate: DateTime;
	private _hasSelectedDate = false;

	constructor(
		public _calendar: MatCalendar<D>,
		private _dateAdapter: DateAdapter<D>,
		@Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
		cdr: ChangeDetectorRef,
		calendarHeaderService: CalendarHeaderService
	) {
		this._hasSelectedDate = !!this._calendar.selected;
		this._activeDate = this._calendar.activeDate as DateTime;

		_calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
			const newDate = this._calendar.activeDate as DateTime;

			if (!this._activeDate.hasSame(newDate, 'millisecond') || this._hasSelectedDate !== !!this._calendar.selected) {
				this._hasSelectedDate = !!this._calendar.selected;
				this._activeDate = newDate;

				if (this._hasSelectedDate) {
					calendarHeaderService.setActiveDate(this._activeDate.toJSDate());
				}
			}

			cdr.markForCheck();
		});
	}

	public get periodLabel(): string {
		return this._dateAdapter.format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel).toString();
	}

	public ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.complete();
	}

	public previousMonthClicked(): void {
		this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
	}

	public nextMonthClicked(): void {
		this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
	}

	public onSelectYearMonth(): void {
		this._calendar.currentView = 'multi-year';
	}
}
