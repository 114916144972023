import { ChangeDetectionStrategy, Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import {
	AccordionComponent,
	AccordionItemCardComponent,
	ButtonComponent,
	CalendarComponent,
	CardComponent,
	DeviceStyleConfig,
	EDRIcon,
	HeadingComponent,
	IconCircleGroupComponent,
	IconCircleGroupOptions,
	SectionComponent,
	SpinnerComponent,
} from '@edr/styleguide';
import { MemberFacade } from '../../member';
import { filter, Observable, tap } from 'rxjs';
import { SaveForOccasionProps, SpendNowProps, rewardsChoiceComponentContent } from './rewards-choice.component.content';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ContentfulToHtmlPipe } from '@edr/contentful';
import { ContentBaseComponent } from '@edr/shared';
import { RedemptionPreference } from '@edr/bff-api-models';
import { SpendNowComponent } from './spend-now/spend-now.component';
import { SaveForOccasionComponent } from './save-for-occasion/save-for-occasion.component';
import { FlagKey, FlagService } from '@edr-core';

export interface RewardsChoiceIconOptions extends IconCircleGroupOptions {
	redemptionPreference: RedemptionPreference;
}

@Component({
	selector: 'edr-app-rewards-choice',
	templateUrl: './rewards-choice.component.html',
	styleUrls: ['./rewards-choice.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AccordionComponent,
		AccordionItemCardComponent,
		CardComponent,
		ButtonComponent,
		SectionComponent,
		CalendarComponent,
		AsyncPipe,
		DatePipe,
		HeadingComponent,
		SpinnerComponent,
		ContentfulToHtmlPipe,
		NgTemplateOutlet,
		SpendNowComponent,
		SaveForOccasionComponent,
		IconCircleGroupComponent,
		NgClass,
	],
})
export class RewardsChoiceComponent extends ContentBaseComponent implements OnInit {
	@Input() public currentRewardsChoiceTitle: string;
	@Input() public otherRewardsChoicesTitle!: string;
	@Input() public spendNow!: SpendNowProps;
	@Input() public saveForOccasion!: SaveForOccasionProps;
	@Input() public rewardsChoiceIconOptions: RewardsChoiceIconOptions[] | undefined;

	public isLoading$: Observable<boolean>;
	public redemptionPreference$: Observable<RedemptionPreference | undefined>;
	public saveForOccasionFeatureFlag$: Observable<boolean> = this.flagService.isFlagEnabled(FlagKey.featureEdrSaveForOccasion);
	public content = rewardsChoiceComponentContent;
	public howItWorksTitleSpace: DeviceStyleConfig = { desktop: '6', tablet: '4', mobile: '4' };
	public selectedIconName: WritableSignal<EDRIcon> = signal('rewards-dollar-sign');

	constructor(
		private memberFacade: MemberFacade,
		private flagService: FlagService
	) {
		super();
		this.isLoading$ = this.memberFacade.isLoadingRedemptionPreferences$;
		this.redemptionPreference$ = this.memberFacade.redemptionPreference$.pipe(
			filter((redemptionPreference) => redemptionPreference !== undefined),
			tap((redemptionPreference) => {
				const iconOption = this.rewardsChoiceIconOptions?.find((option) => option.redemptionPreference === redemptionPreference);
				this.selectedIconName.set(iconOption?.iconName ?? 'rewards-dollar-sign');
			})
		);

		// Initialize default content
		this.currentRewardsChoiceTitle = this.content.currentRewardsChoiceTitle;
		this.otherRewardsChoicesTitle = this.content.otherRewardsChoicesTitle;
	}

	public ngOnInit(): void {
		this.memberFacade.loadRedemptionPreferences();
	}
}
