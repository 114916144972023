import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, ViewChild } from '@angular/core';
import { BarcodeFormat, FontOptions, TextAlign, TextPosition } from '../../enums/barcode.type';
import * as JsBarcode from 'jsbarcode';

@Component({
	selector: 'edr-barcode',
	templateUrl: './barcode.component.html',
	styleUrls: ['./barcode.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class BarcodeComponent implements OnChanges {
	@ViewChild('barcode_container', { static: true }) public barcodeContainer: ElementRef | undefined;

	@Input() public value = '';
	@Input() public format = BarcodeFormat.CODE128;
	@Input() public lineColour = '#000000';
	@Input() public backgroundColour = '#ffffff';
	@Input() public width = 2;
	@Input() public height = 100;
	@Input() public displayValue = false;
	@Input() public fontOptions = FontOptions.NONE;
	@Input() public font = 'monospace';
	@Input() public textAlign = TextAlign.CENTER;
	@Input() public textPosition = TextPosition.BOTTOM;
	@Input() public textMargin = 2;
	@Input() public fontSize = 20;
	@Input() public marginTop = 10;
	@Input() public marginBottom = 10;
	@Input() public marginLeft = 10;
	@Input() public marginRight = 10;

	private _valid = false;

	constructor(private renderer: Renderer2) {}

	public get valid(): boolean {
		return this._valid;
	}

	public get options(): JsBarcode.Options {
		return {
			format: this.format,
			lineColor: this.lineColour,
			background: this.backgroundColour,
			width: this.width,
			height: this.height,
			displayValue: this.displayValue,
			fontOptions: this.fontOptions,
			font: this.font,
			textAlign: this.textAlign,
			textPosition: this.textPosition,
			textMargin: this.textMargin,
			fontSize: this.fontSize,
			marginTop: this.marginTop,
			marginBottom: this.marginBottom,
			marginLeft: this.marginLeft,
			marginRight: this.marginRight,
			valid: (valid) => {
				this._valid = valid;
			},
		};
	}

	public ngOnChanges(): void {
		try {
			if (this.barcodeContainer) {
				this.generateBarcode(this.value, this.barcodeContainer);
			}
		} catch {
			/* fail silently */
		}
	}

	public generateBarcode(value: string, element: ElementRef): void {
		const barcodeChild = this.renderer.createElement('svg', 'svg');
		this.renderer.addClass(barcodeChild, 'barcode-svg');

		JsBarcode(barcodeChild, value, this.options);

		for (const node of element.nativeElement.childNodes) {
			this.renderer.removeChild(element.nativeElement, node);
		}

		this.renderer.appendChild(element.nativeElement, barcodeChild);
	}
}
