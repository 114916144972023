import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions';
import { switchMap, catchError, of } from 'rxjs';
import { AppSettingsService } from '../..';

@Injectable()
export class AppEffects {
	public loadAppSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AppActions.initAppSettings),
			switchMap(() => this.appSettingsService.initialize()),
			switchMap((appSettings) => of(AppActions.loadAppSettingsSuccess({ appSettings }))),
			catchError((error) => of(AppActions.loadAppSettingsFailure({ error })))
		)
	);

	constructor(private appSettingsService: AppSettingsService, private actions$: Actions) {}
}
