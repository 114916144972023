<form [formGroup]="addressForm" (ngSubmit)="handleSubmit()" id="addressForm">
	<edr-modal [title]="title" (closeModal)="closeModal()" class="address-lookup">
		<div modal-content>
			@if (isLoading) {
			<edr-spinner></edr-spinner>
			} @if (!isLoading) {
			<p [innerHTML]="description"></p>
			@if (isManual) {
			<div class="address-form">
				<div class="form-input">
					<edr-input name="addressLine1" label="Street" placeholder="" id="addressLine1" type="text" formControlName="addressLine1"></edr-input>
					<edr-error-message controlName="addressLine1" controlLabel="street" />
				</div>
				<input name="addressLine2" label="addressLine2" placeholder="" id="addressLine2" type="hidden" formControlName="addressLine2" />
				<div class="form-input">
					<edr-input name="suburb" label="Suburb" placeholder="" formControlName="suburb"></edr-input>
					<edr-error-message controlName="suburb" />
				</div>
				<div class="columns">
					<div class="form-input">
						<edr-input name="city" label="City" formControlName="city"></edr-input>
						<edr-error-message controlName="city" />
					</div>
					<div class="form-input">
						<edr-input name="postcode" label="Postcode" placeholder="" formControlName="postcode"></edr-input>
						<edr-error-message controlName="postcode" />
					</div>
				</div>
			</div>
			} @if (!isManual) {
			<div class="address-form">
				<div class="form-input">
					<edr-autocomplete
						(searchTextChange)="handleSearchTextChange($event)"
						(optionSelected)="optionSelected($event)"
						(dropdownClosed)="handleDropdownClosed()"
						[label]="'Find your street address'"
						[required]="true"
						[isSearching]="isSearching"
						name="selectedAddressId"
						id="selectedAddressId"
						[isError]="lookupError"
						[disabled]="valueSelected"
						[placeholder]="'e.g. 32 Main Street, Suburb, Town/City'"
					>
						@for (option of autocompleteOptions; track option) {
						<edr-option [value]="option.id">
							{{ option.address }}
						</edr-option>
						}
					</edr-autocomplete>
					@if (lookupError && !searchText) {
					<edr-error-message>Please enter your address</edr-error-message>
					} @if (searchText && (!autocompleteOptions || autocompleteOptions.length < 1)) {
					<edr-error-message>No exact match found for {{ searchText }}</edr-error-message>
					}
				</div>
				@if (valueSelected) {
				<div class="address-result">
					<div class="address-result-list">
						@for (addressLine of searchText.split(','); track addressLine) {
						<span edr-typography typographyAs="body">{{ addressLine.trim() }}</span>
						}
					</div>
					<edr-icon name="alert-tick-thin" size="4" color="alert--success-green"></edr-icon>
				</div>
				} @if (showManualLink && !valueSelected) {
				<button type="button" edr-button buttonStyle="link" (click)="setIsManualTrue($event)">Enter address manually</button>
				}
			</div>
			} }
		</div>

		<button edr-button type="button" modal-footer-secondary buttonStyle="secondary" [reversed]="true" buttonSize="medium" (click)="closeModal()">
			Cancel
		</button>
		<button edr-button type="submit" modal-footer-primary form="addressForm" buttonSize="medium">{{ buttonText ?? 'Pre-order card' }}</button>
	</edr-modal>
</form>
