@if ({ isLoading: isLoading$ | async, preferenceGroups: preferenceGroups$ | async }; as templateData) { @if (!templateData.isLoading) {
<edr-accordion [disabledToggleOnXL]="true" id="preferences-list">
	@for (preferenceGroup of templateData.preferenceGroups; track preferenceGroup) {
	<edr-accordion-item-card
		[heading]="preferenceGroup.title"
		[description]="preferenceGroup.description ?? ''"
		[class]="preferenceGroup.isPartnerPreferences ? 'partnerCommunications' : ''"
		[expanded]="true"
		id="preferences-list"
		class="heading--no-border"
	>
		<div class="accordion-item__content">
			@for (preference of preferenceGroup.preferences; track preference) {
			<ng-container
				*ngTemplateOutlet="
					preferenceTemplate;
					context: {
						id: preference.id,
						iconName: preference.iconName,
						logo: preference.logo,
						title: preference.title,
						description: preference.description,
						value: preference.value,
						reverseValue: preference.reverseValue,
						disabled: preference.disabled
					}
				"
			>
			</ng-container>
			}
		</div>
	</edr-accordion-item-card>
	}
</edr-accordion>
} @else {
<edr-spinner><edr-heading [level]="3">Loading</edr-heading></edr-spinner>
} }

<ng-template
	#preferenceTemplate
	let-title="title"
	let-description="description"
	let-iconName="iconName"
	let-logo="logo"
	let-id="id"
	let-value="value"
	let-reverseValue="reverseValue"
	let-disabled="disabled"
>
	<div class="preference">
		<div class="icon">
			@if (logo) {
			<img [src]="logo.src" [alt]="logo.altText" />
			} @if (!logo) {
			<edr-icon [name]="iconName" color="secondary--charcoal" size="8"></edr-icon>
			}
		</div>
		<div class="preference__text">
			<p>{{ title }}</p>
			@if (description) {
			<p edr-typography typographyAs="body--xs">
				{{ description }}
			</p>
			}
		</div>
		<div>
			<edr-toggle
				[id]="id"
				[checked]="reverseValue ? !value : value"
				(toggleChange)="preferenceChanged(id, reverseValue ? !$event : $event)"
				[disabled]="disabled"
				[attr.data-testid]="id"
			></edr-toggle>
		</div>
	</div>
</ng-template>
