import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AmountDisplayPipe, BreakpointService, DeviceType } from '@edr/shared';
import { ButtonComponent, IconComponent, SpinnerComponent, StripeComponent, TableComponent } from '@edr/styleguide';
import { Observable } from 'rxjs';
import { PointsDetailsTableStore } from './points-details-table.store';
import { ActivityDetails, ActivityDetailsUomType, ActivityListResponse, Redeem } from '@edr/bff-api-models';
import { PointsDisplayPipe } from '../../pipes';

@Component({
	selector: 'edr-app-points-details-table',
	templateUrl: './points-details-table.component.html',
	styleUrls: ['./points-details-table.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		TableComponent,
		IconComponent,
		StripeComponent,
		SpinnerComponent,
		CommonModule,
		CdkTableModule,
		AmountDisplayPipe,
		PointsDisplayPipe,
	],
	providers: [PointsDetailsTableStore],
})
export class PointsDetailsTableComponent extends TableComponent {
	@Input({ required: true }) public transaction!: ActivityListResponse;

	public ActivityDetailsUomType = ActivityDetailsUomType;

	public transactionsDataSource$: Observable<ActivityDetails[]> = this.pointsDetailsStore.transactionDetails$;
	public transactionRedeemed$: Observable<Redeem[] | undefined> = this.pointsDetailsStore.transactionRedeemed$;

	public isLoading$: Observable<boolean> = this.pointsDetailsStore.transactionIsLoading$;

	public showPointsDetails = false;
	public isMobileDevice$: Observable<boolean>;

	constructor(private breakpointService: BreakpointService, private pointsDetailsStore: PointsDetailsTableStore) {
		super();
		this.isMobileDevice$ = this.breakpointService.breakpointMatchesCustom([DeviceType.Mobile]);
	}

	public togglePointsDetailsTable(): void {
		if (!this.showPointsDetails) {
			this.fetchPointsDetailsData();
		}

		this.showPointsDetails = !this.showPointsDetails;
	}

	public fetchPointsDetailsData(): void {
		this.pointsDetailsStore.fetchTransactionDetails({
			activityId: this.transaction.id,
			transactionReference: this.transaction.basketKey,
		});
	}
}
