@if ({
	isMobileBreakpoint: isMobileBreakpoint$ | async,
	balancesState: balancesState$ | async,
	isRewardsModalActive: isRewardsModalActive$ | async,
	redemptionPreference: redemptionPreference$ | async,
};
	as data) {
	<div class="text-info">
		@if (title) {
			<edr-contentful-rich-text [document]="title" class="title" data-testid="text-greeting" />
		}
		@if (!data.isMobileBreakpoint) {
			<ng-container [ngTemplateOutlet]="descriptionTemplate" />
		}
		@if (!data.isMobileBreakpoint) {
			<ng-container [ngTemplateOutlet]="primaryCallToAction" />
		}
	</div>
	<div class="points-info">
		@if (data.balancesState && !data.balancesState.isLoading) {
			<div class="points-and-rewards">
				<edr-points-dial
					[pointsBalance]="
						data.isRewardsModalActive ? data.balancesState.value?.pointsPerReward ?? 2000 : data.balancesState.value?.pointsBalance || 0
					"
					[totalPointsRequired]="data.balancesState.value?.pointsPerReward || 0"
					[isError]="!!data.balancesState.error"
					[helpToolTip]="helpTooltip"
				/>
				<div class="reward-tiles">
					@if (data.balancesState.value && data.balancesState.value.voucherDollars) {
						<edr-reward-tile
							[rewardLine]="'$' + (data.balancesState.value.voucherDollars | number)"
							[textLine]="data.redemptionPreference === RedemptionPreferenceType.SpendNow ? 'vouchers' : 'Saved for an occasion'"
							[iconName]="data.redemptionPreference === RedemptionPreferenceType.SpendNow ? voucherIcon : vouchersLockedIcon"
							[helpToolTip]="{
								trigger: tooltipTrigger.Hover,
								alignment: tooltipAlignment.Middle,
								message: pointsTooltipContent,
							}"
							[isError]="!!data.balancesState.error"
						/>
						<ng-template #pointsTooltipContent>
							<edr-app-points-dashboard-reward-tile-tooltip
								data-testid="points-dashboard-reward-tile-tooltip"
								[hasVoucher]="!!data.balancesState.value.voucherDollars"
							/>
						</ng-template>
					}
					@if (!data.balancesState.value?.voucherDollars) {
						<edr-reward-tile
							[textLine]="(data.balancesState.value?.pointsUntilNextReward | number) + ' points to next reward'"
							[iconName]="voucherIcon"
							[helpToolTip]="{
								trigger: tooltipTrigger.Hover,
								alignment: tooltipAlignment.Middle,
								message: 'Your vouchers will appear here as you earn them',
							}"
							[isError]="!!data.balancesState.error"
						/>
					}
					<edr-reward-tile textLine="Save at least 6c off per litre at bp" [iconName]="fuelRewardsIcon"
									 [isError]="!!data.balancesState.error" />
				</div>
			</div>
		} @else {
			<edr-spinner />
		}
		@if (data.isMobileBreakpoint) {
			<ng-container [ngTemplateOutlet]="descriptionTemplate" />
		}
		@if (data.isMobileBreakpoint) {
			<ng-container [ngTemplateOutlet]="primaryCallToAction" />
		}
	</div>
}

<ng-template #primaryCallToAction>
	@if (button) {
		<edr-link [text]="button.text" [asButton]="true" [buttonStyle]="button.style" [linkUrl]="button.linkUrl" class="call-to-action" />
	}
</ng-template>

<ng-template #descriptionTemplate>
	@if (description) {
		<edr-contentful-rich-text [document]="description" class="description" />
	}
</ng-template>
