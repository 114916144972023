import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '../../../../express.tokens';
import { Response } from 'express';

@Injectable({
	providedIn: 'root',
})
export class ServerSideResponseService {
	private response: Response;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(@Optional() @Inject(RESPONSE) response: any) {
		this.response = response;
	}

	public setNotFound(message = 'not found'): this {
		if (this.response) {
			this.response.statusCode = 404;
			this.response.statusMessage = message;
		}
		return this;
	}

	public setServerError(message = 'server error'): this {
		if (this.response) {
			this.response.statusCode = 500;
			this.response.statusMessage = message;
		}
		return this;
	}

	public setServerResponse(code: number, message: string): this {
		if (this.response) {
			this.response.statusCode = code;
			this.response.statusMessage = message;
		}
		return this;
	}
}
