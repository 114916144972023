import { EDRIcon } from '@edr/styleguide';
import { ContentDescription } from './everyday-rewards-card.store';

export enum OrderAction {
	PreOrderPlasticCard = 'preOrderPlasticCard',
	OrderPlasticCard = 'orderPlasticCard',
	ReplacePlasticCard = 'replacePlasticCard',
}

export interface AccordionItem {
	id: OrderAction;
	title: string;
	description?: string;
	icon: EDRIcon;
	button: {
		id: string;
		label: string;
	};
	addressFinder: ContentDescription;
	orderSuccess: ContentDescription;
}

export interface PageContentEverydayRewardsCard {
	accordionItems: {
		id: OrderAction;
		title: string;
		description?: string;
		icon: EDRIcon;
		button: {
			id: string;
			label: string;
		};
		addressFinder: ContentDescription;
		orderSuccess: ContentDescription;
	}[];
}
