import { createReducer, on, Action } from '@ngrx/store';

import * as MemberActions from './profile.actions';
import { MemberProfileResponse } from '@edr/bff-api-models';

export const PROFILE_STATE_KEY = 'profile';

export interface ProfileState {
	value: MemberProfileResponse | undefined;
	isLoading: boolean;
	isUpdating: boolean | undefined;
	successMessage: string | undefined;
	error: boolean;
	errorCode?: number;
	errorStatusCode?: number;
}

export const initialMemberProfileState: ProfileState = {
	value: undefined,
	isLoading: false,
	isUpdating: undefined,
	error: false,
	successMessage: undefined,
};

const reducer = createReducer(
	initialMemberProfileState,
	// Load Profile
	on(MemberActions.loadProfile, (state): ProfileState => ({ ...state, error: false, isLoading: true })),
	on(MemberActions.loadProfileSuccess, (state, { profile }): ProfileState => ({ ...state, value: profile, isLoading: false })),
	on(MemberActions.loadProfileFailure, (state): ProfileState => ({ ...state, error: true, isLoading: false })),

	// Update Profile
	on(MemberActions.updateProfile, (state): ProfileState => ({ ...state, isUpdating: true, error: false, successMessage: undefined })),
	on(
		MemberActions.updateProfileSuccess,
		(state, { profile, successMessage }): ProfileState => ({
			...state,
			value: profile,
			isUpdating: false,
			error: false,
			successMessage,
		})
	),
	on(MemberActions.updateProfileFailure, (state, { errorStatusCode, errorCode }): ProfileState => ({ ...state, isUpdating: false, error: true, errorStatusCode: errorStatusCode, errorCode: errorCode })),
	on(MemberActions.resetUpdateProfile, (state): ProfileState => ({ ...state, isUpdating: undefined }))
);

export function memberProfileReducer(state: ProfileState | undefined, action: Action): ProfileState {
	return reducer(state, action);
}
