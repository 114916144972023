import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRColor, EDRIcon, EDRSpacing, IconRotate } from '../../types';
import { ApplyStylesDirective } from '../../directives';

/**
 * If you need to set a hover color for the icon you need to set the color to undefined (by not providing one for the color input)
 * Then you can set the color and hover color on the parent element.
 */

@Component({
	selector: 'edr-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ApplyStylesDirective],
})
export class IconComponent {
	@Input() public name?: EDRIcon;
	@Input() public color?: EDRColor;
	@Input() public size?: EDRSpacing;
	@HostBinding('attr.rotate') @Input() public rotate: IconRotate | undefined = '0';
}
