import { ElementRef } from '@angular/core';
import { EDRTrackingData } from '../lib/models';

export function getDefaultTrackingData(elementRef: ElementRef): EDRTrackingData {
	const content_type = isCTA(elementRef) ? 'CTA' : isTextLink(elementRef) ? 'Text link' : 'Default tracking';
	return {
		tealium_event: 'select_content',
		content_type,
		content_name: getContentName(elementRef),
		content_component: getComponentName(elementRef),
		content_text: elementRef.nativeElement.textContent,
	};
}

function getContentName(elementRef: ElementRef): string {
	const el = elementRef.nativeElement;
	if (isCTA(elementRef)) {
		const boostItems = ['Boost all', 'Boost', 'Boosted'];
		const cleanText = el.textContent.replace(/\s+/g, ' ').trim();
		if (boostItems.includes(cleanText)) {
			return 'Boost';
		}
	}
	return el.textContent;
}

function getComponentName(elementRef: ElementRef): string {
	const el = elementRef.nativeElement;
	if (el.className) {
		return el.className;
	}
	if (el.id) {
		return el.id;
	}
	if (el.hasAttribute('edr-button') || el.getAttribute('as-button') || el.tagName.toUpperCase() === 'BUTTON') {
		return 'Button';
	}
	if (el.localName) {
		return el.localName;
	}

	return '';
}

function isCTA(elementRef: ElementRef): boolean {
	const el = elementRef.nativeElement;
	return el.hasAttribute('edr-button') || el.getAttribute('as-button') || el.tagName.toUpperCase() === 'BUTTON';
}

function isTextLink(elementRef: ElementRef): boolean {
	const el = elementRef.nativeElement;
	return el.tagName.toUpperCase() === 'A';
}
