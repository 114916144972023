import { Injectable } from '@angular/core';
import { ImageDeviceTransformConfig, ImageDimensions, ImageFocusArea, ImageFormat, ImageResizeFit, ImageTransformConfig } from '../../typescript';

/**
 * We want to provide an image transformer service which can interact with the Contentful Image API
 * to transform images to the desired format.
 * Refer to: https://www.contentful.com/developers/docs/references/images-api
 * This service will support the following transformations:
 * - Resize
 * - Crop
 * - Format
 * - Quality
 * - Crop (Only radius  crop is supported by Contentful)
 * - Background color (e.g "rb=rgb:ffffff");
 * - Focus
 */
@Injectable({
	providedIn: 'root',
})
export class ImageTransformerService {
	/**
	 * Contentful Image API is used to transform images.
	 * @private
	 */
	private readonly IMAGE_API_URL = '//images.ctfassets.net/';

	/**
	 * Take the image options and aspect ratio and return the transformed image options
	 * adjusting the height based on the aspect ratio in relation to the width.
	 * The current width value will stay unchanged.
	 * @param imageTransformConfig
	 * @param aspectRatio
	 */
	public transformImageOptionsToAspectRatio(imageTransformConfig: ImageTransformConfig, aspectRatio: number): ImageTransformConfig {
		if (!imageTransformConfig?.width) {
			return imageTransformConfig;
		}

		const transformedHeight = Math.round(imageTransformConfig.width / aspectRatio);
		return { ...imageTransformConfig, height: transformedHeight };
	}

	/**
	 * This function will take the image URL and transformations object and return the transformed image URL.
	 * If the Image URL is not a Contentful image URL, then it will return the same URL.
	 * @param imageUrl
	 * @param transformations
	 */
	public transformImage(imageUrl: string, transformations: ImageTransformConfig): string {
		// Only transform the image if the image Url  contains the base URL
		if (!imageUrl.toLowerCase().includes(this.IMAGE_API_URL)) {
			return imageUrl;
		}

		// Remove any existing query parameters from the image URL
		const [urlWithoutQueryParams] = imageUrl.split('?');
		imageUrl = urlWithoutQueryParams;

		const transformationsString = this.getTransformationsString(transformations);
		return `${imageUrl}?${transformationsString}`;
	}

	/**
	 * Adjusts the image aspect ratio based on the original aspect ratio of the image.
	 * SVG images are excluded from this transformation.
	 * @param imageDimensions
	 * @param imageDeviceTransformConfig
	 */
	public adjustImageAspectRatio(
		imageDimensions: ImageDimensions,
		imageDeviceTransformConfig: ImageDeviceTransformConfig
	): ImageDeviceTransformConfig {
		if (!imageDimensions.width || !imageDimensions.height) {
			return imageDeviceTransformConfig;
		}

		const adjustedImageDeviceOptions = { ...imageDeviceTransformConfig };

		// If the image props contains the asset width & height information, then calculate the correct height
		// based on the original aspect ratio of the image.
		const aspectRatio = imageDimensions.width / imageDimensions.height;

		const roundedAspectRation = Math.round(aspectRatio * 100) / 100;

		if (roundedAspectRation !== 1.5) {
			if (adjustedImageDeviceOptions?.desktop?.width) {
				if (adjustedImageDeviceOptions.desktop.width > imageDimensions.width) {
					adjustedImageDeviceOptions.desktop.width = imageDimensions.width;
				}

				adjustedImageDeviceOptions.desktop = this.transformImageOptionsToAspectRatio(adjustedImageDeviceOptions.desktop, roundedAspectRation);
			}

			if (adjustedImageDeviceOptions?.tablet?.width) {
				if (adjustedImageDeviceOptions.tablet.width > imageDimensions.width) {
					adjustedImageDeviceOptions.tablet.width = imageDimensions.width;
				}

				adjustedImageDeviceOptions.tablet = this.transformImageOptionsToAspectRatio(adjustedImageDeviceOptions.tablet, roundedAspectRation);
			}

			if (adjustedImageDeviceOptions?.mobile?.width) {
				if (adjustedImageDeviceOptions.mobile.width > imageDimensions.width) {
					adjustedImageDeviceOptions.mobile.width = imageDimensions.width;
				}

				adjustedImageDeviceOptions.mobile = this.transformImageOptionsToAspectRatio(adjustedImageDeviceOptions.mobile, roundedAspectRation);
			}
		}

		return adjustedImageDeviceOptions;
	}

	/**
	 * Get a transformation config that will resize the image to a smaller size to be used
	 * with components like the banner component, card component, etc.
	 */
	public static GetSmallerImageTransformConfig(): ImageDeviceTransformConfig {
		return {
			desktop: {
				format: ImageFormat.Avif,
				width: 801,
				height: 534,
				resizeFit: ImageResizeFit.Fill,
				quality: 100,
			},
			tablet: {
				format: ImageFormat.Avif,
				width: 600,
				height: 400,
				resizeFit: ImageResizeFit.Fill,
				quality: 100,
			},
			mobile: {
				format: ImageFormat.Avif,
				width: 462,
				height: 308,
				resizeFit: ImageResizeFit.Fill,
				quality: 100,
			},
		};
	}

	/**
	 * The default image type & max image width to be loaded for the EDR website per device type
	 * @constructor
	 */
	public static GetImageTransformConfig(): ImageDeviceTransformConfig {
		return {
			desktop: {
				format: ImageFormat.Avif,
				width: 1680,
				resizeFit: ImageResizeFit.Fill,
				quality: 100,
			},
			tablet: {
				format: ImageFormat.Avif,
				width: 1280,
				resizeFit: ImageResizeFit.Fill,
				quality: 100,
			},
			mobile: {
				format: ImageFormat.Avif,
				width: 480,
				resizeFit: ImageResizeFit.Fill,
				quality: 100,
			},
		};
	}

	/**
	 * This function will take the transformation config and convert it into a string that can be appended to the image URL
	 * @param imageTransformConfig
	 * @private
	 */
	private getTransformationsString(imageTransformConfig: ImageTransformConfig): string {
		let transformationsString = '';
		let appendCharacter = '';

		if (imageTransformConfig.format && imageTransformConfig.format !== ImageFormat.Original) {
			switch (imageTransformConfig.format) {
				case ImageFormat.ProgressiveJpg:
					// Progressive Jpg has a special 'fl' query parameter
					transformationsString += `${appendCharacter}fm=jpg&fl=progressive`;
					break;

				default:
					transformationsString += `${appendCharacter}fm=${imageTransformConfig.format}`;
					break;
			}

			appendCharacter = '&';
		}

		if (imageTransformConfig.width) {
			transformationsString += `${appendCharacter}w=${imageTransformConfig.width}`;
			appendCharacter = '&';
		}

		if (imageTransformConfig.height) {
			transformationsString += `${appendCharacter}h=${imageTransformConfig.height}`;
			appendCharacter = '&';
		}

		if (imageTransformConfig.resizeFit) {
			transformationsString += `${appendCharacter}fit=${imageTransformConfig.resizeFit}`;
			appendCharacter = '&';
		}

		if (imageTransformConfig.quality) {
			transformationsString += `${appendCharacter}q=${imageTransformConfig.quality}`;
			appendCharacter = '&';
		}

		if (imageTransformConfig.backgroundColor) {
			transformationsString += `${appendCharacter}bg=${imageTransformConfig.backgroundColor}`;
			appendCharacter = '&';
		}

		// If a predefined focus option is provided, then add the focus query parameter
		if (imageTransformConfig.focusArea && imageTransformConfig.focusArea !== ImageFocusArea.None && imageTransformConfig.focusArea !== ImageFocusArea.Custom) {
			transformationsString += `&f=${imageTransformConfig.focusArea}`;
		}

		return transformationsString;
	}
}
