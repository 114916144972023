import { createAction, props } from '@ngrx/store';

// noPrompt is used to determine if the user should be prompted to log in
export const userLogin = createAction('[Authentication] User Initiated Log In', props<{ noPrompt?: boolean; deleteSignedInParam?: boolean }>());
export const userLogOut = createAction('[Authentication] User Initiated Log Out');
export const userLogOutComplete = createAction('[Authentication] User Initiated Log Out Complete');
export const userLoggedOut = createAction('[Authentication] User Logged Out');

export const authInitialise = createAction('[Authentication] Initialise');
export const authRefresh = createAction('[Authentication] Refresh');
export const authInitialiseFailed = createAction('[Authentication] Initialise Failed');
export const authInitialiseComplete = createAction('[Authentication] Initialise Complete');
export const fetchAuthStateFailed = createAction('[Authentication] Fetch Auth State Failed');
export const fetchAuthStateComplete = createAction(
	'[Authentication] Fetch Auth State Complete',
	props<{ isAuthenticated?: boolean; scvId?: string }>()
);
