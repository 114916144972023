<section [edrApplyStyles]="sectionStyles">
	@if (fullWidthContent) {
	<ng-container *ngTemplateOutlet="content"></ng-container>
	} @if (!fullWidthContent) {
	<div class="section__content">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</div>
	}

	<ng-template #content>
		<ng-content></ng-content>
	</ng-template>
</section>
