import { GiftingService } from './../../../../features/my-account/services/gifting.service';
import { Inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { LoggingService } from '@edr/shared';
import { Observable, switchMap, tap } from 'rxjs';
import { ModalService } from '@edr-core';
import { GiftPointsResponse } from '@edr/bff-api-models';
import { MODAL_SERVICE_TOKEN } from '@edr/styleguide';
import { MemberFacade } from '../../../../core/member';

export interface PointsGifting {
	isLoading: boolean;
	succeeded: boolean | undefined;
	error?: string;
	pointsBalance?: number;
}

export interface PointsGiftingWithDetails extends PointsGifting {
	giftingCardNumber?: string;
	giftingPoints?: number;
}

export interface GiftingConfirmationModalState {
	pointsGifting: PointsGifting;
}

export const defaultState: GiftingConfirmationModalState = {
	pointsGifting: {
		isLoading: false,
		succeeded: undefined,
		error: undefined,
		pointsBalance: undefined,
	},
};

interface ErrorResponse {
	error: {
		errors: {
			UserIdentifier: [string];
		};
	};
}

@Injectable()
export class GiftingConfirmationModalStore extends ComponentStore<GiftingConfirmationModalState> {
	/** Selectors */
	public readonly pointsGifting$ = this.select((state) => state.pointsGifting);

	/** Updaters */
	public readonly updatePointsGifting = this.updater(
		(state, pointsGifting: Partial<PointsGifting>): GiftingConfirmationModalState => ({
			...state,
			pointsGifting: {
				...state.pointsGifting,
				...pointsGifting,
			},
		})
	);

	/** Effects */
	public readonly giftPointsToMember = this.effect((trigger$: Observable<{ cardNumber: string; points: number }>) =>
		trigger$.pipe(
			tap(() => this.updatePointsGifting({ isLoading: true })),
			switchMap(({ cardNumber, points }) => this.giftingService.giftPointsToMember(cardNumber, points)),
			tapResponse(
				(giftPointsResponse: GiftPointsResponse) => {
					this.updatePointsGifting({ isLoading: false, succeeded: true, pointsBalance: giftPointsResponse.totalPointsBalance });
					this.memberFacade.conditionallyLoadBalances(true);
					this.modalService.close();
				},
				(errorResponse) => {
					this.logger.error(errorResponse);
					const errorResponseParsed = errorResponse as ErrorResponse;
					let message = '';
					if (
						errorResponseParsed.error &&
						errorResponseParsed.error.errors &&
						errorResponseParsed.error.errors.UserIdentifier &&
						errorResponseParsed.error.errors.UserIdentifier.length > 0
					) {
						message = errorResponseParsed.error.errors.UserIdentifier[0];
					}

					this.updatePointsGifting({ isLoading: false, succeeded: false, error: message });
					this.modalService.close();
				}
			)
		)
	);

	constructor(
		private readonly logger: LoggingService,
		private readonly giftingService: GiftingService,
		@Inject(MODAL_SERVICE_TOKEN) private readonly modalService: ModalService,
		private readonly memberFacade: MemberFacade
	) {
		super(defaultState);
	}
}
