import { Component, HostBinding, Input } from '@angular/core';
import { StyleConfig, FlexAlign, FlexDirection, DevicePlatform, DevicePlatformService, ApplyStylesDirective } from '@edr/styleguide';
import { DynamicComponent, DynamicContentComponent } from '@edr/dc';
import { ConditionalDisplayPipe, ConditionalDisplaySettings } from '../../pipes';
import { AsyncPipe } from '@angular/common';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss'],
	standalone: true,
	imports: [ConditionalDisplayPipe, AsyncPipe, DynamicContentComponent, ApplyStylesDirective],
})
export class ContainerComponent extends ContentBaseComponent {
	@HostBinding('attr.align-content') @Input() public alignContent: FlexAlign | undefined;
	@HostBinding('attr.flex-direction') @Input() public flexDirection: FlexDirection | undefined;
	@Input() public showOnlyOnPlatform: DevicePlatform | undefined;
	@Input() public spacing: StyleConfig[] = [];
	@Input() public width: StyleConfig[] = [];
	@Input() public height: StyleConfig[] = [];
	@Input() public minHeight: StyleConfig[] = [];
	@Input() public content: DynamicComponent[] = [];
	@Input() public conditions: ConditionalDisplaySettings | undefined;

	constructor(public devicePlatformService: DevicePlatformService) {
		super();
	}

	@HostBinding('attr.is-visible') public get showInCurrentDevicePlatform(): boolean {
		const devicePlatform = this.devicePlatformService.getPlatform();
		return !this.showOnlyOnPlatform || !devicePlatform || this.showOnlyOnPlatform === devicePlatform;
	}

	public get styleConfig(): StyleConfig[] {
		return [...(this.spacing || []), ...(this.width || []), ...(this.height || []), ...(this.minHeight || [])];
	}
}
