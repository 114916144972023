import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthIsLoggedIn, selectUserAuthData } from './auth.selectors';
import { AuthStatusService } from '../../pipes/conditional-display/conditional-display.pipe';
import { Observable } from 'rxjs';

export interface UserAuthData {
	isLoggedIn: boolean;
	scvId: string | undefined;
	hasError: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthFacade implements AuthStatusService {
	public isLoggedIn$: Observable<boolean> = this.store.select(selectAuthIsLoggedIn);
	public userAuthData$: Observable<UserAuthData> = this.store.select(selectUserAuthData);

	constructor(private readonly store: Store) {}
}
