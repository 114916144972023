import { Offer } from '@edr/bff-api-models';
import { createAction, props } from '@ngrx/store';

export const loadFirstTimeBoost = createAction('[Boosts] Load First Time Boost');
export const loadFirstTimeBoostFailed = createAction('[Boosts] Load First Time Boost failed');
export const loadFirstTimeBoostComplete = createAction('[Boosts] Load First Time Boost complete', props<{ hasBoosted: boolean }>());

export const updateFirstTimeBoost = createAction('[Boosts] Update First Time Boost', props<{ hasBoosted: boolean }>());
export const updateFirstTimeBoostFailed = createAction('[Boosts] Update First Time Boost failed');
export const updateFirstTimeBoostComplete = createAction('[Boosts] Update First Time Boost complete', props<{ hasBoosted: boolean }>());

export const loadBoostOffers = createAction('[Boosts] Load Boost Offers');
export const loadBoostOffersFailed = createAction('[Boosts] Load Boost Offers failed');
export const loadBoostOffersComplete = createAction('[Boosts] Load Boost Offers complete', props<{ offers: Offer[] }>());

export const activateBoostOffer = createAction('[Boosts] Activate Boost Offer', props<{ id: string }>());
export const activateBoostOfferFailed = createAction('[Boosts] Activate Boost Offer failed', props<{ id: string }>());
export const activateBoostOfferComplete = createAction('[Boosts] Activate Boost Offer complete', props<{ offer: Offer }>());

export const activateAllBoostOffers = createAction('[Boosts] Activate All Boost Offers');
export const activateAllBoostOffersFailed = createAction('[Boosts] Activate All Boost Offers failed');
export const activateAllBoostOffersComplete = createAction('[Boosts] Activate All Boost Offers complete', props<{ offers: Offer[] }>());
