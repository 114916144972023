import { InjectionToken } from '@angular/core';
import { Modal } from './modal.interface';

export const MODAL_SERVICE_TOKEN = new InjectionToken<ModalServiceBase>('ModalService');

export interface ModalServiceBase {
	open(modal: Modal): void;
	openModalRoute(modal: Modal): void;
	close(): void;
	clearQueue(): void;
}
