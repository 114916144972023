export class AppSettings {
	// Endpoints needs to be alphabetical by sections
	public static endpoints = {
		// Misc
		auth: 'Auth',
		// Members
		activityList: 'members/my/activity-list',
		activityDetails: 'members/my/activity-details',
		balances: 'members/my/balances',
		boostOffers: 'members/my/offers',
		customerPreferences: 'members/my/preferences',
		redemptionPreferences: 'members/my/redemption-preferences',
		customerProfile: 'members/my/profile',
		rewardsCard: 'members/my/card',
		rewardsCardCmm: 'members/my/card/cmm',
		requestPhysicalCard: 'members/my/physical-cards',
		requestReplacementCard: 'members/my/card-replacement',
		giftPointsToMember: 'members/my/gift-points',
		// CMS
		content: 'content',
		// Partners
		partners: 'partners',
		// Address Lookup
		addressLookupSearch: 'addresslookup/search',
		addressLookupDetails: 'addresslookup/detail',
	};
}
