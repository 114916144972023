import { ObjectValues } from '@edr/shared';

export const BannerMediaAlignment = {
	Top: 'top',
	Middle: 'middle',
	Bottom: 'bottom',
} as const;
export type BannerMediaAlignment = ObjectValues<typeof BannerMediaAlignment>;

export const BannerContentAlignment = {
	Left: 'left',
	Center: 'center',
} as const;

export type BannerContentAlignment = ObjectValues<typeof BannerContentAlignment>;

export const BannerType = {
	/**
	 * Standard hero banner used as a title banner at the top of a page.  The typography is larger than the other banner types.
	 */
	Hero: 'hero',
	/**
	 * A banner in the shape of a leaf with the top left and bottom right corners rounded.
	 */
	Leaf: 'leaf',
	/**
	 * A banner in the shape of a card with all corners rounded and with half of the content info background color and the other half white background (like a card).
	 */
	Card: 'card',
	/**
	 * The hero banner used with regular content, so the typography is smaller than the standard hero banner, but the same as the Card & Leaf banners.
	 */
	HeroContent: 'hero-content',
} as const;

export type BannerType = ObjectValues<typeof BannerType>;
