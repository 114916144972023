import { createReducer, on, Action } from '@ngrx/store';

import * as BoostsActions from './boosts.actions';
import { Offer, OfferStatus } from '@edr/bff-api-models';

export const BOOSTS_FEATURE_KEY = 'boosts';

export interface FirstTimeBoostState {
	hasBoosted: boolean;
	isLoading: boolean;
	hasLoaded: boolean;
	hasError: boolean;
	isModalOpen: boolean | undefined;
}

export interface BoostOffersState {
	isLoading: boolean;
	hasLoaded: boolean;
	isUpdating: boolean;
	hasError: boolean;
	offers: OfferState[];
}

export interface OfferState {
	offer: Offer;
	isBoosting: boolean;
	hasError: boolean;
}

export interface BoostsState {
	firstTimeBoost: FirstTimeBoostState;
	offersData: BoostOffersState;
}

export const boostsInitialState: BoostsState = {
	firstTimeBoost: {
		hasBoosted: false,
		isLoading: false,
		hasLoaded: false,
		hasError: false,
		isModalOpen: undefined,
	},
	offersData: {
		isLoading: false,
		hasLoaded: false,
		isUpdating: false,
		hasError: false,
		offers: [],
	},
};

const reducer = createReducer(
	boostsInitialState,
	on(
		BoostsActions.loadFirstTimeBoost,
		(state: BoostsState): BoostsState => ({
			...state,
			firstTimeBoost: { hasBoosted: false, isLoading: true, hasLoaded: false, hasError: false, isModalOpen: undefined },
		})
	),
	on(
		BoostsActions.loadFirstTimeBoostComplete,
		(state: BoostsState, { hasBoosted }): BoostsState => ({
			...state,
			firstTimeBoost: {
				hasBoosted,
				isLoading: false,
				hasLoaded: true,
				hasError: false,
				isModalOpen: undefined,
			},
		})
	),
	on(
		BoostsActions.loadFirstTimeBoostFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			firstTimeBoost: { hasBoosted: false, isLoading: false, hasLoaded: false, hasError: true, isModalOpen: undefined },
		})
	),
	on(
		BoostsActions.updateFirstTimeBoost,
		(state: BoostsState): BoostsState => ({
			...state,
			firstTimeBoost: { ...state.firstTimeBoost, isLoading: true, hasLoaded: false, hasError: false },
		})
	),
	on(
		BoostsActions.updateFirstTimeBoostComplete,
		(state: BoostsState, { hasBoosted }): BoostsState => ({
			...state,
			firstTimeBoost: {
				...state.firstTimeBoost,
				hasBoosted,
				isLoading: false,
				hasLoaded: true,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.updateFirstTimeBoostFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			firstTimeBoost: { ...state.firstTimeBoost, isLoading: false, hasLoaded: false, hasError: true },
		})
	),
	on(
		BoostsActions.loadBoostOffers,
		(state: BoostsState): BoostsState => ({
			...state,
			offersData: { ...state.offersData, isLoading: true, hasError: false },
		})
	),
	on(
		BoostsActions.loadBoostOffersComplete,
		(state: BoostsState, { offers }): BoostsState => ({
			...state,
			offersData: {
				...state.offersData,
				offers: offers.map((offer) => ({ offer, isBoosting: false, hasError: false })),
				isLoading: false,
				hasLoaded: true,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.loadBoostOffersFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			offersData: { ...state.offersData, isLoading: false, hasError: true },
		})
	),
	on(
		BoostsActions.activateBoostOffer,
		(state: BoostsState, { id }): BoostsState => ({
			...state,
			offersData: {
				...state.offersData,
				offers: state.offersData.offers.map((item) => (item.offer.id === id ? { ...item, isBoosting: true, hasError: false } : item)),
			},
		})
	),
	on(
		BoostsActions.activateBoostOfferComplete,
		(state: BoostsState, { offer }): BoostsState => ({
			...state,
			firstTimeBoost: { ...state.firstTimeBoost, isModalOpen: true },
			offersData: {
				...state.offersData,
				offers: state.offersData.offers.map((item) =>
					item.offer.id === offer.id
						? {
								offer,
								isBoosting: false,
								hasError: false,
						  }
						: { ...item }
				),
			},
		})
	),
	on(
		BoostsActions.activateBoostOfferFailed,
		(state: BoostsState, { id }): BoostsState => ({
			...state,
			offersData: {
				...state.offersData,
				offers: state.offersData.offers.map((item) => (item.offer.id === id ? { ...item, isBoosting: false, hasError: true } : { ...item })),
			},
		})
	),
	on(
		BoostsActions.activateAllBoostOffers,
		(state: BoostsState): BoostsState => ({
			...state,
			offersData: {
				...state.offersData,
				offers: state.offersData.offers.map((item) =>
					item.offer.offerStatus === OfferStatus.NotActivated ? { ...item, isBoosting: true, hasError: false } : { ...item }
				),
				isUpdating: true,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.activateAllBoostOffersComplete,
		(state: BoostsState, { offers }): BoostsState => ({
			...state,
			firstTimeBoost: { ...state.firstTimeBoost, isModalOpen: true },
			offersData: {
				...state.offersData,
				offers: state.offersData.offers.map((item) => {
					const updatedOffer = offers.find((offer) => offer.id === item.offer.id);
					return updatedOffer
						? {
								offer: updatedOffer,
								isBoosting: false,
								hasError: false,
						  }
						: { ...item, isBoosting: false, hasError: false };
				}),
				isUpdating: false,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.activateAllBoostOffersFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			offersData: {
				...state.offersData,
				offers: state.offersData.offers.map((item) =>
					item.offer.offerStatus !== OfferStatus.Activated ? { ...item, isBoosting: false, hasError: true } : { ...item }
				),
				isUpdating: false,
				hasError: true,
			},
		})
	)
);

export function boostsReducer(state: BoostsState | undefined, action: Action): BoostsState {
	return reducer(state, action);
}
