import { ObjectValues } from '../helpers';

export const ImageFormat = {
	Avif: 'avif',
	Webp: 'webp',
	Jpg: 'jpg',
	Png: 'png',
	Gif: 'gif',
	Svg: 'svg',
	Original: 'original',
	ProgressiveJpg: 'progressive jpg',
} as const;

export type ImageFormat = ObjectValues<typeof ImageFormat>;
