@if (src) {
	@if (lazyLoad && !isSvg) {
		@defer (on viewport) {
			<!-- defer on viewport as well as it's @placeholder can only have one
			 	 root element -->
			<div class="image__container">
				<ng-container *ngTemplateOutlet="image" />
				@if (!isLoaded) {
					<ng-container *ngTemplateOutlet="imagePlaceholder" />
				}
			</div>
		} @loading () {
			<edr-spinner></edr-spinner>
		} @placeholder {
			<div class="image__container">
				<ng-container *ngTemplateOutlet="imagePlaceholder" />
			</div>
		}
	} @else {
		<div class="image__container">
			<ng-container *ngTemplateOutlet="image" />
		</div>
	}
}

<ng-template #image>
	<!-- Use the picture element to provide multiple versions of an image for
		 different display scenarios. It will also fallback to the <img> src if
		 none of the image types requested by the <source> elements are supported
		 by the browser -->
	<picture>
		<source media="(min-width:{{desktopBreakpointWidth}}px)" srcset="{{desktopSrc}}">
		<source media="(min-width:{{tabletBreakpointWidth}}px)" srcset="{{tabletSrc}}">
		<source srcset="{{mobileSrc}}">
		<img [edrApplyStyles]="styles" [src]="fallbackSrc" [alt]="altText" (load)="onImageLoaded()" />
	</picture>

</ng-template>

<ng-template #imagePlaceholder>
	<img [edrApplyStyles]="styles" [src]="placeholderSrc" [alt]="altText" style="width: auto;" />
</ng-template>
