@if (state$ | async; as state) {
<div class="account-settings-container">
	<!-- Notification Panel for Personal Details Edit Form -->
	@if (state.passwordResetState === 'success') {
	<edr-notification notificationType="success" title="Your password has been reset" />
	} @if (state.passwordResetState === 'failed') {
	<edr-notification
		notificationType="error"
		title="Your password wasn't updated"
		description="There was an error connecting to the server. Please try again later."
		[canDismiss]="false"
	/>
	}
	<div class="account-settings-section privacy-and-security">
		<edr-heading [level]="5" class="heading">Privacy and security</edr-heading>
		<div class="section-body">
			<p>It's best to use a strong password you don't use anywhere else.</p>
			<button edr-button type="button" (click)="openPasswordResetModal()">Reset your password</button>
		</div>
	</div>
	<div class="account-settings-section">
		<edr-heading [level]="5">Close your account</edr-heading>
		<div class="section-body">
			<p>
				To close your account please call our Customer Care team on <a href="tel: +64 800 969 337"><b>0800 969 337</b></a
				>.
				<br />
				<br />
				Please note: Closing your Everyday Rewards account will also close your Woolworths account.
			</p>
		</div>
	</div>
</div>
}

<ng-template #modalResetPassword>
	<edr-modal title="Important" (closeModal)="closePasswordResetModal()" icon="alert-warning" iconColor="alert--alert-yellow">
		<div modal-content>Resetting your password here will also update your Woolworths account password.</div>

		<button
			type="button"
			edr-button
			modal-footer-secondary
			buttonStyle="secondary"
			[reversed]="true"
			buttonSize="medium"
			(click)="closePasswordResetModal()"
		>
			Cancel
		</button>
		<button type="button" edr-button modal-footer-primary buttonStyle="secondary" buttonSize="medium" (click)="goToPasswordReset()">Update</button>
	</edr-modal>
</ng-template>

<ng-template #loadingTemplate>
	<edr-spinner><edr-heading [level]="3">Loading</edr-heading></edr-spinner>
</ng-template>
