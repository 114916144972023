export enum SVGIcon {
	ActionAccountLoginOutlined = 'action-account-login-outlined',
	AdditionalYourAccount = 'additional-your-account',
	AlertError = 'alert-error',
	AlertErrorOutlined = 'alert-error-outlined',
	AlertInfo = 'alert-info',
	AlertInfoOutlined = 'alert-info-outlined',
	AlertSuccess = 'alert-success',
	AlertSuccessOutlined = 'alert-success-outlined',
	AlertTickThin = 'alert-tick-thin',
	AlertWarning = 'alert-warning',
	AlertWarningOutlined = 'alert-warning-outlined',
	CommsCard = 'comms-card',
	CommsMobile = 'comms-mobile',
	EverydayExtraPerks = 'everyday-extra-perks',
	Rewards = 'rewards',
	RewardsAutoRenew = 'rewards-auto-renew',
	RewardsAutoSavings = 'rewards-auto-savings',
	RewardsBoost = 'rewards-boost',
	RewardsCard = 'rewards-card',
	RewardsDollarSign = 'rewards-dollar-sign',
	RewardsDollarSignWithPiggy = 'rewards-dollar-sign-with-piggy',
	RewardsDollarSignWithPiggyLocked = 'rewards-dollar-sign-with-piggy-locked',
	RewardsEverydayRewardsCard = 'rewards-everyday-rewards-card',
	RewardsExtras = 'rewards-extras',
	RewardsFifteenDollar = 'rewards-fifteen-dollar',
	RewardsFuel = 'rewards-fuel',
	RewardsGiftCard = 'rewards-gift-card',
	RewardsMemberExclusives = 'rewards-member-exclusives',
	RewardsPhoneRewards = 'rewards-phone-rewards',
	RewardsPoint = 'rewards-point',
	RewardsPointDollar = 'rewards-point-dollar',
	RewardsProfile = 'rewards-profile',
	RewardsRedeemed = 'rewards-redeemed',
	RewardsRocket = 'rewards-rocket',
	RewardsShopBasket = 'rewards-shop-basket',
	RewardsShopIntoPoints = 'rewards-shop-into-points',
	RewardsSmileFace = 'rewards-smile-face',
	RewardsSneakPeek = 'rewards-sneak-peek',
	RewardsSparkle = 'rewards-sparkle',
	RewardsTap = 'rewards-tap',
	SocialFacebook = 'social-facebook',
	SocialInstagram = 'social-instagram',
	SystemCalendar = 'system-calendar',
	SystemChatOutline = 'system-chat-outline',
	SystemChevron = 'system-chevron',
	SystemClose = 'system-close',
	SystemCommentOutline = 'system-comment-outline',
	SystemDrinksCrate = 'system-drinks-crate',
	SystemEmailOffers = 'system-email-offers',
	SystemEmailUnsubscribe = 'system-email-unsubscribe',
	SystemExternalLink = 'system-external-link',
	SystemLiveChatOutlined = 'system-live-chat-outlined',
	SystemLogout = 'system-logout',
	SystemMenu = 'system-menu',
	SystemMessageOptOut = 'system-message-opt-out',
	SystemPhoneOutline = 'system-phone-outline',
	SystemTooltipHelp = 'system-tooltip-help',
}
