import { Injectable } from '@angular/core';
import { readFileSync } from 'fs';
import { join } from 'path';

@Injectable({
	providedIn: 'root',
})
export class SystemFileService {
	public readFileUTF8(filePath: string): string {
		return readFileSync(filePath, 'utf8');
	}

	public buildPathFromWorkingDir(...paths: string[]): string {
		return join(process.cwd(), ...paths);
	}
}
