<div class="modal__header" mat-dialog-title>
	@if (!useCustomHeader) {
		<div class="modal__header-content">
			@if (icon) {
				<edr-icon [name]="icon" [color]="iconColor ?? 'secondary--charcoal'" size="5"></edr-icon>
			}
			@if (title) {
				<h3 edr-typography>{{ title }}</h3>
			}
		</div>
	}
	<ng-content select="[modal-header]"></ng-content>
	@if (!useCustomHeader && !disableClose) {
		<button type="button" class="modal__header-close" (click)="close()">
			<edr-icon name="system-close" color="secondary--charcoal" size="5"></edr-icon>
		</button>
	}
</div>

<div class="modal__content" mat-dialog-content>
	<ng-content select="[modal-content]"></ng-content>
</div>

<div class="modal__footer" mat-dialog-actions>
	<ng-content select="[modal-footer-primary]"></ng-content>
	<ng-content select="[modal-footer-secondary]"></ng-content>
	<div>
		<ng-content select="[modal-footer]"></ng-content>
	</div>
</div>
