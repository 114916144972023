import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakpointService, DevModeService, LoggingService } from './services';
import { WindowSizeService } from './services/window-size/window-size.service';

const providers = [BreakpointService, DevModeService, LoggingService, WindowSizeService];

@NgModule({
	imports: [CommonModule],
})
export class SharedModule {
	public static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers,
		};
	}
}
