import { AsyncPipe, DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Document } from '@contentful/rich-text-types';
import { CtaPropsResponse, RedemptionPreference } from '@edr/bff-api-models';
import { RichTextComponent } from '@edr/contentful';
import { BreakpointService, ContentBaseComponent, DeviceType } from '@edr/shared';
import {
	EDRIcon,
	LinkComponent,
	PointsDialComponent,
	RewardTileComponent,
	SpinnerComponent,
	TooltipAlignment,
	TooltipPosition,
	TooltipProps,
	TooltipTrigger,
} from '@edr/styleguide';
import { Observable } from 'rxjs';
import { BalancesState, MemberFacade } from '../../member';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RewardsVoucherService } from '../../services/rewards-voucher/rewards-voucher.service';
import { APP_ROUTES } from '../../../routes';
import { PointsDashboardRewardTileTooltipComponent } from './points-dashboard-reward-tile-tooltip/points-dashboard-reward-tile-tooltip.component';

@UntilDestroy()
@Component({
	selector: 'edr-app-points-dashboard',
	templateUrl: './points-dashboard.component.html',
	styleUrls: ['./points-dashboard.component.scss'],
	standalone: true,
	imports: [
		AsyncPipe,
		DecimalPipe,
		NgTemplateOutlet,
		PointsDialComponent,
		RichTextComponent,
		LinkComponent,
		RewardTileComponent,
		SpinnerComponent,
		PointsDashboardRewardTileTooltipComponent,
	],
})
export class PointsDashboardComponent extends ContentBaseComponent implements OnInit {
	@Input() public title: Document | undefined;
	@Input() public description: Document | undefined;
	@Input() public button: CtaPropsResponse | undefined;
	public isMobileBreakpoint$: Observable<boolean>;
	public balancesState$: Observable<BalancesState>;
	public helpTooltip: TooltipProps = {
		message: `You can keep track of your earnings under`,
		alignment: TooltipAlignment.Middle,
		trigger: TooltipTrigger.Hover,
		ctaText: 'Points & activity',
		ctaLink: `/${APP_ROUTES.account.base}/${APP_ROUTES.account.pointsAndActivity}`,
	};

	public tooltipAlignment = TooltipAlignment;
	public tooltipTrigger = TooltipTrigger;
	public tooltipPosition = TooltipPosition;
	public isRewardsModalActive$: Observable<boolean>;
	public redemptionPreference$: Observable<RedemptionPreference | undefined>;
	public voucherIcon: EDRIcon = 'rewards-auto-renew';
	public vouchersLockedIcon: EDRIcon = 'rewards-dollar-sign-with-piggy-locked';
	public fuelRewardsIcon: EDRIcon = 'rewards-fuel';

	public RedemptionPreferenceType = RedemptionPreference;

	constructor(
		private breakpointService: BreakpointService,
		private memberFacade: MemberFacade,
		private rewardsVoucherService: RewardsVoucherService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
		this.isMobileBreakpoint$ = this.breakpointService.isCustomBreakpointActive(DeviceType.Mobile);
		this.balancesState$ = this.memberFacade.balancesState$;
		this.isRewardsModalActive$ = this.rewardsVoucherService.isRewardsVoucherModalOpen$;
		this.redemptionPreference$ = this.memberFacade.redemptionPreference$;
	}

	public ngOnInit(): void {
		this.memberFacade.conditionallyLoadBalances(true);
		this.rewardsVoucherService.tryOpenCelebratoryModal();
		this.changeDetectorRef.detectChanges();
	}
}
