import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IconRotate } from '../../types/icon-rotate.type';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'edr-carousel-button',
	templateUrl: './carousel-button.component.html',
	styleUrls: ['./carousel-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IconComponent],
})
export class CarouselButtonComponent {
	@Input() public rotate: IconRotate = '90';
	@Input() public ariaLabel = '';
	@HostBinding('attr.isResponsive') @Input() public isResponsive = true;
	@HostBinding('attr.color') @Input() public color: 'primary--orange' | 'secondary--charcoal' = 'secondary--charcoal';
	@HostBinding('attr.size') @Input() public size: 'small' | 'large' = 'large';
	@HostBinding('attr.disabled') @Input() public disabled = false;
	@Output() public buttonClick = new EventEmitter<void>();
}
