import { Component, Input, Inject } from '@angular/core';
import { ButtonComponent, EDRButtonSize, EDRButtonStyle, IconProps } from '@edr/styleguide';
import { userLogOut, userLogin } from '../../auth';
import { Store } from '@ngrx/store';
import { BoostAllButtonComponent } from '../../../features/boosts';

import { WINDOW } from '@ng-web-apis/common';
import { ContentBaseComponent, CustomWindow } from '@edr/shared';

export type EDRAction = 'Login' | 'Logout' | 'Boost all' | 'Open chat';

@Component({
	selector: 'edr-app-action-button',
	templateUrl: './action-button.component.html',
	styleUrls: ['./action-button.component.scss'],
	standalone: true,
	imports: [ButtonComponent, BoostAllButtonComponent],
})
export class ActionButtonComponent extends ContentBaseComponent {
	@Input() public action: EDRAction | undefined;
	@Input() public text = '';
	@Input() public size: EDRButtonSize = 'medium';
	@Input() public buttonStyle: EDRButtonStyle = 'primary';
	@Input() public buttonStyleReversed = false;
	@Input() public showLeftIcon = false;
	@Input() public iconLeft?: IconProps;
	@Input() public showRightIcon = false;
	@Input() public iconRight?: IconProps;

	constructor(private store: Store, @Inject(WINDOW) private window: CustomWindow) {
		super();
	}

	public clickAction(): void {
		switch (this.action) {
			case 'Login':
				return this.login();
			case 'Logout':
				return this.logout();
			case 'Open chat':
				return this.openChat();
			default:
				return;
		}
	}

	private login(): void {
		this.store.dispatch(userLogin({}));
	}

	private logout(): void {
		this.store.dispatch(userLogOut());
	}

	private openChat(): void {
		if (!this.window.chatWidget) {
			return;
		}
		this.window.chatWidget.onStartChat();
	}
}
