<edr-section [paddingTop]="{ mobile: '20' }">
	<section class="error__section">
		@if (pageContent$ | async; as pageContent) {
		<div class="error__container" [ngClass]="'error__container__' + pageContent.errorCode">
			<img src="/assets/illustrations/rocket-crash.svg" alt="Rocket Crash" width="194" height="162" />
			<div class="error__title">
				@if (pageContent.subTitle) {
				<edr-heading [level]="6">{{ pageContent.subTitle }}</edr-heading>
				}
				<edr-heading [level]="1">{{ pageContent.title }}</edr-heading>
			</div>
			<div class="error__description">
				@for (description of pageContent.description; track description) {
				<edr-heading [level]="6">
					{{ description }}
				</edr-heading>
				}
			</div>
			<div class="error__actions">
				@for (cta of pageContent.ctas; track cta) {
				<button type="button" edr-button [buttonStyle]="cta.buttonStyle" [reversed]="cta.buttonReversed ?? false" (click)="handleCtaClick(cta)">
					{{ cta.text }}
				</button>
				}
			</div>
		</div>
		}
	</section>
</edr-section>
