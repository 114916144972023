import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ComponentRefService, MultiStepComponent } from '@edr/styleguide';
import { MemberFacade } from '../../core/member';
import { ModalService } from '@edr-core';
import { DynamicComponent, DynamicContentComponent } from '@edr/dc';
import { take } from 'rxjs';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-app-welcome-tutorial',
	templateUrl: './welcome-tutorial.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [DynamicContentComponent],
})
export class WelcomeTutorialComponent extends ContentBaseComponent implements AfterViewInit, OnDestroy {
	@Input() public id = '';
	@Input() public content?: DynamicComponent[] | null = [];
	@ViewChild('welcomeTutorialModal') public welcomeTutorialModal: TemplateRef<Element> | undefined;
	public multiStepComponent?: MultiStepComponent;
	public ctaActionSubscribed = false;

	constructor(
		private modalService: ModalService,
		private componentRefService: ComponentRefService,
		private memberFacade: MemberFacade,
		private ngZone: NgZone
	) {
		super();
		this.memberFacade.loadTutorialViewedState();
	}

	public ngAfterViewInit(): void {
		this.showTutorialModal();
	}

	public ngOnDestroy(): void {
		this.welcomeTutorialModal = undefined;
		this.ctaActionSubscribed = false;
		if (this.multiStepComponent && this.multiStepComponent?.completeAction.unsubscribe) {
			this.multiStepComponent?.completeAction.unsubscribe();
			this.multiStepComponent?.skipAction.unsubscribe();
		}
	}

	public showTutorialModal(): void {
		this.memberFacade.hasViewedTutorial().subscribe((hasViewed) => {
			const showTutorialModal = !hasViewed;
			if (showTutorialModal && this.welcomeTutorialModal) {
				this.modalService.open({ ref: this.welcomeTutorialModal, id: 'welcome-tutorial-modal' });

				// We need to wait for the modal to open before we can access the multistep component
				this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
					this.registerTutorialActions();
				});
			}
		});
	}

	public getMultiStepComponent(): MultiStepComponent | undefined {
		return this.componentRefService.getComponentRefs(this.id)?.find((ref) => ref instanceof MultiStepComponent);
	}

	public registerTutorialActions(): void {
		this.multiStepComponent = this.getMultiStepComponent();
		if (this.multiStepComponent && !this.ctaActionSubscribed) {
			this.multiStepComponent.completeAction.subscribe(() => {
				this.memberFacade.updateTutorialViewed(true);
			});
			this.multiStepComponent.skipAction.subscribe(() => {
				this.memberFacade.updateTutorialViewed(true);
			});
			this.ctaActionSubscribed = true;
		}
	}
}
