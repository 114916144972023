import { Injectable } from '@angular/core';
import { LoggingService, notUndefined, PreferenceIds } from '@edr/shared';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { PreferenceResponse } from '@edr/bff-api-models';
import { concatLatestFrom } from '@ngrx/effects';
import { EDRCommunicationPreferenceGroup } from './edr-communication-preference.model';
import { CustomerPreferencesService } from '../../../features/my-account/services';

export interface CommunicationPreferencesState {
	preferenceGroups: EDRCommunicationPreferenceGroup[] | undefined;
	preferences: PreferenceResponse[];
	lastUpdatedPreference: PreferenceResponse | undefined;
	isLoading: boolean;
	error?: unknown;
	isErrorUpdatingPreference: boolean;
}

export const defaultState: CommunicationPreferencesState = {
	preferenceGroups: undefined,
	preferences: [],
	lastUpdatedPreference: undefined,
	isLoading: true,
	isErrorUpdatingPreference: false,
};

@Injectable()
export class CommunicationPreferencesStore extends ComponentStore<CommunicationPreferencesState> {
	/** Selectors */
	public readonly preferences$ = this.select((state) => state.preferences);
	public readonly lastUpdatedPreference$ = this.select((state) => state.lastUpdatedPreference);
	public readonly isErrorUpdatingPreference$ = this.select((state) => state.isErrorUpdatingPreference);

	public readonly isLoading$ = this.select((state) => state.isLoading);
	public readonly preferenceGroups$ = this.select((state) => state.preferenceGroups).pipe(filter(notUndefined));

	/** Updaters */
	public readonly updatePreferenceGroups = this.updater(
		(state, preferenceGroups: EDRCommunicationPreferenceGroup[]): CommunicationPreferencesState => ({
			...state,
			preferenceGroups,
		})
	);

	public readonly updatePreference = this.updater((state, updatedPreference: PreferenceResponse): CommunicationPreferencesState => {
		const updatedPreferences = [...state.preferences];
		const preferenceIndex = updatedPreferences.findIndex((x) => x.id === updatedPreference.id);
		updatedPreferences.splice(preferenceIndex, 1, updatedPreference);

		return {
			...state,
			preferences: updatedPreferences,
			lastUpdatedPreference: updatedPreference,
		};
	});

	public readonly updatePreferences = this.updater(
		(state, preferences: PreferenceResponse[]): CommunicationPreferencesState => ({
			...state,
			preferences,
			isLoading: false,
		})
	);

	public readonly updateError = this.updater(
		(state, error?: unknown): CommunicationPreferencesState => ({
			...state,
			error,
			isLoading: false,
		})
	);

	public readonly updateIsErrorUpdatingPreference = this.updater(
		(state, isErrorUpdatingPreference: boolean): CommunicationPreferencesState => ({
			...state,
			isLoading: false,
			isErrorUpdatingPreference: isErrorUpdatingPreference,
		})
	);

	public readonly refreshPreferenceGroups = this.updater((state): CommunicationPreferencesState => {
		if (!state.preferenceGroups) {
			return state;
		}
		const isUnsubscribeAll = !(state.preferences.find((x) => x.id === PreferenceIds.EDRAllowContactAll)?.value ?? true);
		const preferenceGroups: EDRCommunicationPreferenceGroup[] = [...state.preferenceGroups];

		preferenceGroups.forEach((group) => {
			group.preferences.forEach((preference) => {
				const apiValue = state.preferences.find((x) => x.id === preference.id)?.value ?? preference.value;
				if (preference.id === PreferenceIds.EDRAllowContactAll) {
					preference.value = apiValue;
				} else {
					preference.value = isUnsubscribeAll ? false : apiValue;
					preference.disabled = isUnsubscribeAll;
				}

				if (preference.isAlcohol && state.preferences.find((x) => x.id === PreferenceIds.EDRInterestAlcoholEmail)?.value === false) {
					preference.value = false;
					preference.disabled = true;
				}
			});
		});

		return {
			...state,
			preferenceGroups,
		};
	});

	/** Effects */
	public readonly fetchMemberPreferences = this.effect(() =>
		this.preferenceGroups$.pipe(
			concatLatestFrom(() => this.isLoading$),
			switchMap(([preferenceGroups, isLoading]) => {
				if (!isLoading) {
					return of(true);
				}
				const partnerPreferenceIds = preferenceGroups
					.map((g) => g.preferences)
					.reduce((acc: string[], preference): string[] => acc.concat(preference.map((p) => p.id)), []);

				return this.customerPreferencesService.fetchCustomerPreferences(partnerPreferenceIds).pipe(
					map((preferencesResponse) => {
						this.updatePreferences(preferencesResponse);
					})
				);
			}),
			catchError((error) => {
				this.logger.error(error);
				this.updateError(error);
				return of(false);
			})
		)
	);

	private readonly persistPreferences = this.effect(() =>
		this.lastUpdatedPreference$.pipe(
			filter(notUndefined),
			switchMap((preference: PreferenceResponse) =>
				this.customerPreferencesService.updateCustomerPreferences([preference]).pipe(
					tapResponse(
						() => {
							this.updateIsErrorUpdatingPreference(false);
						},
						(err) => {
							this.logger.error(err);
							this.updateError(err);
							this.updateIsErrorUpdatingPreference(true);
						}
					)
				)
			)
		)
	);

	private readonly updatePageContentFromPreferences = this.effect(() => this.preferences$.pipe(tap(() => this.refreshPreferenceGroups())));

	constructor(private readonly customerPreferencesService: CustomerPreferencesService, private readonly logger: LoggingService) {
		super(defaultState);
	}
}
