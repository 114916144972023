import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { HeadingComponent } from '../heading/heading.component';
import { EDRColor, EDRIcon } from '../../types';
import { ApplyStylesDirective } from '../../directives';
import { SvgDefinitionsComponent } from '../svg-definitions/svg-definitions.component';
import { TypographyComponent } from '../typography/typography.component';
import { BreakpointService } from '@edr/shared';
import { Observable } from 'rxjs';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { TooltipProps } from '../../models/tooltip-props.model';
import { TooltipAlignment } from '../../enums';

@Component({
	selector: 'edr-reward-tile',
	templateUrl: './reward-tile.component.html',
	styleUrls: ['./reward-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [AsyncPipe, ApplyStylesDirective, HeadingComponent, IconComponent, SvgDefinitionsComponent, TooltipDirective, TypographyComponent],
})
export class RewardTileComponent {
	@Input() public iconName: EDRIcon = 'rewards-auto-renew';
	@Input() public iconColor: EDRColor = 'primary--orange';
	@Input() public rewardLine = '';
	@Input() public textLine = '';
	@Input() public helpToolTip?: TooltipProps;

	@HostBinding('attr.isError') @Input() public isError = false;

	public tooltipAlignment = TooltipAlignment.End;
	public isSmallDevice$: Observable<boolean>;

	constructor(private breakpointService: BreakpointService) {
		this.isSmallDevice$ = this.breakpointService.breakpointMatches(['xxs', 'xs', 'sm', 'md']);
	}
}
