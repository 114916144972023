import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { ContentDescription, EverydayRewardsCardState, EverydayRewardsCardStore } from './everyday-rewards-card.store';
import { filter, map, Observable, take } from 'rxjs';
import { PhysicalCardRequestReason, RewardsCardAddress } from '../../../features/my-account/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectCleanPath, selectFragment } from '../../../router.selector';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { AccordionItem, OrderAction } from './page-content-everyday-rewards-card.model';
import {
	AccordionComponent,
	AccordionItemCardComponent,
	BarcodeComponent,
	ButtonComponent,
	NotificationComponent,
	SectionComponent,
	SpinnerComponent,
	TypographyComponent,
	heroBannerDefaultSpacing,
} from '@edr/styleguide';
import { BarcodeCardType, PhysicalCardOrderStatus } from '@edr/bff-api-models';
import { pageContent } from './everyday-rewards-card.content';
import { FlagKey, FlagService, ModalService } from '../../services';
import { AsyncPipe } from '@angular/common';
import {
	OrderCardAddressModalComponent,
	OrderCardReasonModalComponent,
	OrderCardSuccessfulModalComponent,
} from '../../../features/my-account/components';
import { APP_ROUTES } from '../../../routes';
import { ContentBaseComponent } from '@edr/shared';

@UntilDestroy()
@Component({
	selector: 'edr-app-everyday-rewards-card',
	templateUrl: './everyday-rewards-card.component.html',
	styleUrls: ['./everyday-rewards-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [EverydayRewardsCardStore],
	standalone: true,
	imports: [
		AsyncPipe,
		RouterLink,
		NotificationComponent,
		SectionComponent,
		BarcodeComponent,
		AccordionComponent,
		AccordionItemCardComponent,
		ButtonComponent,
		SpinnerComponent,
		TypographyComponent,
		OrderCardReasonModalComponent,
		OrderCardAddressModalComponent,
		OrderCardSuccessfulModalComponent,
	],
})
export class EverydayRewardsCardComponent extends ContentBaseComponent {
	@ViewChild('modalReplacementReason') public modalReplacementReason: TemplateRef<Element> | undefined;
	@ViewChild('modalAddressForm') public modalAddressForm: TemplateRef<Element> | undefined;
	@ViewChild('modalReplacementSuccessful') public modalReplacementSuccessful: TemplateRef<Element> | undefined;

	public showStaffCard$: Observable<boolean>;
	public pageContent = pageContent;
	public accordionItems = pageContent.accordionItems;
	public contactRoute = APP_ROUTES.contact;
	public addressFinderContent$: Observable<ContentDescription> = this.edrCardStore.addressFinderContent$.pipe(filter(Boolean));
	public orderSuccessContent$: Observable<ContentDescription> = this.edrCardStore.orderSuccessContent$.pipe(filter(Boolean));
	public isLoading$: Observable<boolean> = this.edrCardStore.isLoading$;
	public currentUrl$: Observable<string | undefined> = this.store.select(selectCleanPath);
	public isOrderingCard$: Observable<boolean> = this.edrCardStore.selectIsOrderingCard$;
	public cardRequestReason$: Observable<PhysicalCardRequestReason | undefined> = this.edrCardStore.selectCardRequestReason$;
	public readonly showCardOrderNotification$: Observable<boolean> = this.edrCardStore.showCardOrderNotification$;
	public useNewBarcode$: Observable<boolean> = this.flagService.isFlagEnabled(FlagKey.featureEdrSwapBarcode);
	public state$: Observable<EverydayRewardsCardState> = this.edrCardStore.selectState$;
	public heroBannerDefaultSpacing = heroBannerDefaultSpacing;

	constructor(
		private readonly edrCardStore: EverydayRewardsCardStore,
		private readonly modalService: ModalService,
		private readonly store: Store,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private flagService: FlagService
	) {
		super();
		this.store
			.select(selectFragment)
			.pipe(
				untilDestroyed(this),
				concatLatestFrom(() => this.state$)
			)
			.pipe(filter(([fragment, state]) => fragment === `retry-${state.orderAction}`))
			.subscribe(([_, state]) => this.retryOrder(state));

		this.showStaffCard$ = this.edrCardStore.selectCardType$.pipe(map((cardType) => cardType === BarcodeCardType.EDRPLUS));
	}

	public getCardActions(cardOrderStatus: PhysicalCardOrderStatus | undefined): AccordionItem[] {
		const showOrderCard = cardOrderStatus === PhysicalCardOrderStatus.OrderCardAllowed;
		const showReplacePlasticCard = cardOrderStatus === PhysicalCardOrderStatus.ReplaceCardAllowed;

		return pageContent.accordionItems.filter((item) =>
			showReplacePlasticCard ? item.id === OrderAction.ReplacePlasticCard : showOrderCard ? item.id === OrderAction.OrderPlasticCard : false
		);
	}

	public retryOrder(state: EverydayRewardsCardState): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			preserveFragment: false,
			replaceUrl: true,
		});
		const orderAction = state.orderAction ?? OrderAction.PreOrderPlasticCard;
		const accordionItem = this.pageContent.accordionItems.find((x) => x.id === orderAction);
		this.openModalForm(orderAction, accordionItem?.addressFinder, accordionItem?.orderSuccess);
	}

	public openModal(modal: 'cardRequestReason' | 'addressForm' | 'replacementSuccessful'): void {
		if (modal === 'cardRequestReason' && this.modalReplacementReason) {
			this.modalService.open({ ref: this.modalReplacementReason, id: modal });
		}
		if (modal === 'addressForm' && this.modalAddressForm) {
			this.modalService.open({ ref: this.modalAddressForm, id: modal });
		}
		if (modal === 'replacementSuccessful' && this.modalReplacementSuccessful) {
			this.modalService.open({ ref: this.modalReplacementSuccessful, id: modal });
		}
	}

	public handleSelectReason(reason: PhysicalCardRequestReason): void {
		this.edrCardStore.updateCardRequestReason(reason);
		this.modalService.close();
		this.openModal('addressForm');
	}

	public handleSelectAddress(address: RewardsCardAddress): void {
		this.edrCardStore.orderCardEffect(address);
		this.edrCardStore.selectHasOrderedCard$.pipe(untilDestroyed(this), filter(Boolean), take(1)).subscribe(() => {
			this.modalService.close();
			this.openModal('replacementSuccessful');
		});
		this.edrCardStore.selectIsCardOrderError$.pipe(untilDestroyed(this), filter(Boolean), take(1)).subscribe(() => {
			this.modalService.close();
		});
	}

	public handleAccordionAction(id: OrderAction, addressFinderContent?: ContentDescription, orderSuccessContent?: ContentDescription): void {
		this.openModalForm(id, addressFinderContent, orderSuccessContent);
	}

	public openModalForm(id: OrderAction, addressFinderContent?: ContentDescription, orderSuccessContent?: ContentDescription): void {
		this.edrCardStore.updateOrderActionId(id);
		if (id === OrderAction.ReplacePlasticCard) {
			// Set lostOrStolen as reason for Feb launch that would skip replacement reason selection.
			this.edrCardStore.updateCardRequestReason(PhysicalCardRequestReason.lostOrStolen);
			this.edrCardStore.updateContentDescription({ addressFinderContent, orderSuccessContent });
			this.openModal('addressForm');
		}
		if ([OrderAction.OrderPlasticCard, OrderAction.PreOrderPlasticCard].includes(id)) {
			this.edrCardStore.updateCardRequestReason(PhysicalCardRequestReason.new);
			this.edrCardStore.updateContentDescription({ addressFinderContent, orderSuccessContent });
			this.openModal('addressForm');
		}
	}

	public resetCardOrder(): void {
		this.edrCardStore.updateResetCardOrderState();
	}
}
