import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { AppSettingsService, ModalService } from '../..';
import { AccountSettingsStore } from './account-settings.store';
import {
	CardType,
	SectionComponent,
	ModalComponent,
	ButtonComponent,
	HeadingComponent,
	SpinnerComponent,
	NotificationComponent,
} from '@edr/styleguide';
import { ContentBaseComponent, CustomWindow } from '@edr/shared';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { APP_ROUTES } from '../../../routes';

@Component({
	selector: 'edr-app-account-settings',
	templateUrl: './account-settings.component.html',
	styleUrls: ['./account-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [AccountSettingsStore],
	standalone: true,
	imports: [AsyncPipe, RouterLink, ModalComponent, ButtonComponent, HeadingComponent, NotificationComponent, SpinnerComponent, SectionComponent],
})
export class AccountSettingsComponent extends ContentBaseComponent {
	@ViewChild('modalResetPassword') public modalResetPassword: TemplateRef<Element> | undefined;

	public contactRoute = APP_ROUTES.contact;
	public state$ = this.store.state$;
	public cardTypeEnum = CardType;

	constructor(
		private modalService: ModalService,
		private appSettingsService: AppSettingsService,
		private store: AccountSettingsStore,
		@Inject(WINDOW) private window: CustomWindow
	) {
		super();
	}

	public openPasswordResetModal(): void {
		if (this.modalResetPassword) {
			this.modalService.open({ ref: this.modalResetPassword, id: 'passwordReset' });
		}
	}

	public closePasswordResetModal(): void {
		this.modalService.close();
	}

	public goToPasswordReset(): void {
		this.closePasswordResetModal();
		this.appSettingsService.getSettings().subscribe((appSettings) => {
			const currentUrl = this.window.location.href;
			this.window.location.href = `${appSettings.ssuBaseUrl}/?action=resetPassword&client=edr&redirectUrl=${encodeURIComponent(currentUrl)}`;
		});
	}
}
