import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CalendarHeaderService {
	public activeDate$: Observable<Date>;
	private activeDate = new Subject<Date>();

	constructor() {
		this.activeDate$ = this.activeDate.asObservable();
	}

	public setActiveDate(date: Date): void {
		this.activeDate.next(date);
	}
}
