import { AsyncPipe, SlicePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CarouselComponent, CarouselItemComponent, HeadingComponent, SpinnerComponent } from '@edr/styleguide';
import { Observable, map, of } from 'rxjs';
import { CardBoostComponent } from '../card-boost/card-boost.component';
import { mapBoostCardProps } from '../../utils';
import { BoostsFacade } from '../../+state';
import { CardBoostAllComponent } from '../card-boost-all/card-boost-all.component';
import { NoBoostOffersComponent } from '../no-boost-offers/no-boost-offers.component';
import { BreakpointService, ContentBaseComponent, DeviceType } from '@edr/shared';

@Component({
	selector: 'edr-app-boost-offers-grid',
	templateUrl: 'boost-offers-carousel.component.html',
	styleUrls: ['./boost-offers-carousel.component.scss'],
	standalone: true,
	imports: [
		AsyncPipe,
		SlicePipe,
		CarouselComponent,
		CarouselItemComponent,
		NoBoostOffersComponent,
		SpinnerComponent,
		HeadingComponent,
		CardBoostComponent,
		CardBoostAllComponent,
	],
})
export class BoostOffersCarouselComponent extends ContentBaseComponent implements OnInit {
	@Input() public numberOfOffers?: number;
	@Input() public numberOfOffersTablet?: number;
	@Input() public numberOfOffersMobile?: number;
	@Input() public title: string | undefined;
	@Input() public isOtherOffers = false;
	@Input() public everydayOffers: Partial<CardBoostComponent>[] | undefined;

	public numberOfOffersToShow$: Observable<number> = this.breakpointService.getActiveCustomBreakpoint().pipe(
		map((deviceType) => {
			switch (deviceType) {
				case DeviceType.Desktop:
					return this.numberOfOffers ?? 5;
				case DeviceType.Tablet:
					return this.numberOfOffersTablet ?? 3;
				case DeviceType.Mobile:
					return this.numberOfOffersMobile ?? 2;
				default:
					return 0;
			}
		})
	);

	public boostOffers$: Observable<Partial<CardBoostComponent>[] | undefined> = of(undefined);
	public isLoadingOffers$;

	constructor(private boostsFacade: BoostsFacade, private breakpointService: BreakpointService) {
		super();
		this.isLoadingOffers$ = this.boostsFacade.isLoadingOffers$;
	}

	public ngOnInit(): void {
		this.boostsFacade.loadBoostOffers();
		if (!this.isOtherOffers) {
			this.boostsFacade.loadHasBoostedBefore();
			this.boostOffers$ = this.boostsFacade.boostOffers$.pipe(map((offers) => (!offers ? offers : offers.map(mapBoostCardProps))));
		} else {
			this.boostOffers$ = this.boostsFacade.otherOffers$.pipe(map((offers) => (!offers ? offers : offers.map(mapBoostCardProps))));
		}
	}

	public identify(index: number, item: Partial<CardBoostComponent>): string {
		return item.partnerProductBoost?.id ?? `${index}`;
	}

	public activateBoostOffer(id: string): void {
		this.boostsFacade.activateBoostOffer(id);
	}

	public numberOfEverydayOffers(numberOfOffers: number, numberOfOffersToShow: number): number {
		if (numberOfOffersToShow === 0) {
			return 999;
		}
		return numberOfOffersToShow > numberOfOffers ? numberOfOffersToShow - numberOfOffers : 0;
	}
}
