import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRColor, EDRSpacing } from '../../types';

@Component({
	selector: 'edr-circle',
	templateUrl: './circle.component.html',
	styleUrls: ['./circle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class CircleComponent {
	@Input() public size?: EDRSpacing;
	@Input() public backgroundColor?: EDRColor;
	@Input() public borderColor?: EDRColor;
	@HostBinding('style.borderWidth') @Input() public borderWidth = '0'; // '1px' for example

	@HostBinding('attr.role') public role = 'figure';
	@HostBinding('style.backgroundColor') public get background(): string | undefined {
		return this.backgroundColor ? `var(--color-${this.backgroundColor})` : undefined;
	}
	@HostBinding('style.borderColor') public get circleBorderColor(): string | undefined {
		return this.borderColor ? `var(--color-${this.borderColor})` : undefined;
	}
	@HostBinding('style.height') public get height(): string | undefined {
		return this.size ? `var(--spacing-${this.size})` : undefined;
	}
	@HostBinding('style.width') public get width(): string | undefined {
		return this.size ? `var(--spacing-${this.size})` : undefined;
	}
}
