import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LoggingService, PreferenceIds, stringToQueryParams } from '@edr/shared';
import { CustomerPreferencesService } from '../../../../features/my-account/services';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../+state';
import { APP_ROUTES } from '../../../../routes';

export function authCallbackResolver(route: ActivatedRouteSnapshot): Observable<boolean> {
	const customerPreferencesService = inject(CustomerPreferencesService);
	const router = inject(Router);
	const cookieService = inject(CookieService);
	const store = inject(Store);
	const loggingService = inject(LoggingService);
	const fullRedirectPath = decodeURIComponent(route.queryParams['r'] || '');
	const [redirectPath, redirectPathQueryParamsString] =
		fullRedirectPath && fullRedirectPath.includes('?') ? fullRedirectPath.split('?') : [fullRedirectPath, ''];
	const redirectPathQueryParams = stringToQueryParams(redirectPathQueryParamsString);

	// If the redirect path is a member link, redirect to the member link
	if (redirectPath && redirectPath.indexOf(`${APP_ROUTES.memberLink}`) >= 0) {
		router.navigate([redirectPath], { queryParams: redirectPathQueryParams });
		return of(true);
	}

	function notFirstLoginFlow(): Observable<boolean> {
		redirectPath ? router.navigate([redirectPath], { queryParams: redirectPathQueryParams }) : router.navigate(['/']);
		return of(true);
	}

	function errorFlow(): Observable<boolean> {
		router.navigate(['/', APP_ROUTES.error, 500]);
		return of(true);
	}

	return store.select(selectAuthState).pipe(
		filter(({ initialising }) => !initialising), // Waiting for initialise complete
		switchMap(({ scvId, isLoggedIn, hasError }) => {
			if (!isLoggedIn || hasError) {
				return errorFlow();
			}

			/**
			 * If this is the first login, redirect to the signup confirmation page
			 * If there is a redirect path in the queryParams, redirect to the the redirect path
			 * Otherwise redirect to the homepage
			 */
			const cookieName = `EDRFirstLogin_${scvId}`;

			/**
			 * When the user is trying to complete the partner linking flow, we want to skip the sign up confirmation page
			 */
			if (cookieService.get(cookieName) || redirectPath.includes(APP_ROUTES.partnerLink)) {
				return notFirstLoginFlow();
			}
			// The user information including customer preferences will need to be stored into global store.
			// Then the data will be managed in multiple places in the app with selector and actions.
			return customerPreferencesService.fetchCustomerPreferences([PreferenceIds.EDRCustomerAppStateAPI]).pipe(
				tap((response) => {
					cookieService.set(cookieName, 'true');
					const isNotFirstTime = response.find((x) => x.id === PreferenceIds.EDRCustomerAppStateAPI)?.value ?? false;
					if (isNotFirstTime) {
						return notFirstLoginFlow();
					}
					const signUpConfirmationPath = [APP_ROUTES.account.base, APP_ROUTES.account['signupConfirmation']];
					return redirectPath
						? router.navigate(signUpConfirmationPath, {
								queryParams: {
									r: redirectPath,
									redirectPathQueryParams: redirectPathQueryParamsString,
								},
						  })
						: router.navigate(signUpConfirmationPath);
				}),
				catchError((error) => {
					// redirect home page not to block login
					loggingService.log(error);
					return notFirstLoginFlow();
				}),
				map(() => true)
			);
		})
	);
}
