import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SignupConfirmationStore } from './signup-confirmation.store';
import { EnvFacade } from '@edr/env';
import { Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { FlagKey, FlagService, MetaDataService } from '@edr-core';
import { CardNumberPipe, stringToQueryParams } from '@edr/shared';
import { BarcodeComponent, ButtonComponent, HeadingComponent, NotificationComponent, SpinnerComponent } from '@edr/styleguide';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'edr-app-signup-confirmation.page',
	templateUrl: './signup-confirmation.page.component.html',
	styleUrls: ['./signup-confirmation.page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [SignupConfirmationStore],
	standalone: true,
	imports: [AsyncPipe, CardNumberPipe, HeadingComponent, ButtonComponent, BarcodeComponent, SpinnerComponent, NotificationComponent],
})
export class SignupConfirmationPageComponent {
	public rewardsCard$ = this.store.rewardsCard$;
	public notification$ = this.store.notification$;
	public isMobileLayout$: Observable<boolean> = this.store.select((state) => state.isMobileLayout);
	public loadingComplete$: Observable<boolean> = this.store.select((state) => state.loadingComplete);
	public useNewBarcode$: Observable<boolean> = this.flagService.isFlagEnabled(FlagKey.featureEdrSwapBarcode);

	constructor(
		public readonly store: SignupConfirmationStore,
		private router: Router,
		private envFacade: EnvFacade,
		private metaDataService: MetaDataService,
		private flagService: FlagService
	) {
		this.metaDataService.setPageMetaData({
			title: 'Sign Up Confirmed | Everyday Rewards',
			isIndexable: false,
		});

		this.store.handleBreakpointChange(this.envFacade.breakpointMatches(['xxs', 'xs']));
	}

	public handleCta(): void {
		this.store.queryParams$.pipe(take(1)).subscribe((params) => {
			const redirectPath = decodeURIComponent(params['r'] || '');
			const redirectPathQueryParams = decodeURIComponent(params['redirectPathQueryParams'] || '');
			this.router.navigate([redirectPath], { queryParams: stringToQueryParams(redirectPathQueryParams) });
		});
	}

	public dismissNotification(): void {
		this.store.updateNotification(null);
	}
}
