@if (showNotification && showInsideSection) {
<edr-section [disablePaddingBottom]="true" [disablePaddingTop]="!hasSectionPaddingTop" [backgroundColor]="backgroundColor">
	<ng-container [ngTemplateOutlet]="notification" />
</edr-section>
} @else { @if (showNotification) {
<div
	[attr.data-type]="notificationType"
	[attr.aria-labelledby]="alertLabelId"
	[attr.aria-describedby]="alertDescriptionId"
	[attr.role]="role"
	[class.notification--candismiss]="canDismiss"
	class="notification"
	[ngClass]="animationCssClassName || ''"
>
	@if (showIcon && iconName) {
	<edr-icon [color]="iconColor" [name]="edrIconName" [size]="iconSize" class="notification__icon"></edr-icon>
	}
	<div class="notification__content">
		@if (title) {
		<p edr-typography typographyAs="heading--6" [innerHTML]="title" class="notification--title" id="{{ alertLabelId }}"></p>
		}
		<!-- Option 1 - Add description via input property -->
		@if (description.length > 0) {
		<p edr-typography typographyAs="body--xs" data-e2e="notification--description" id="{{ alertDescriptionId }}">
			<span [innerHTML]="description"></span>
		</p>
		}
		<!-- Option 2 - Add description content via ng-content -->
		<ng-content select="[description]"></ng-content>
		@if (cta && cta.text && cta.linkUrl) {
		<div class="notification--cta">
			<a [href]="cta.linkUrl" edr-typography typographyAs="heading--6" aria-label="Call to action link" [target]="cta.target || '_self'">
				<span>{{ cta.text || '' }}</span>
			</a>
		</div>
		}
	</div>
	@if (canDismiss) {
	<button (click)="closeAlert()" aria-label="Dismiss notification" class="notification--close">
		<edr-icon name="system-close" size="4" color="secondary--charcoal"></edr-icon>
	</button>
	}
</div>
} }

<ng-template #notification>
	@if (showNotification) {
	<div
		[attr.data-type]="notificationType"
		[attr.aria-labelledby]="alertLabelId"
		[attr.aria-describedby]="alertDescriptionId"
		[attr.role]="role"
		[class.notification--candismiss]="canDismiss"
		class="notification"
		[ngClass]="animationCssClassName || ''"
	>
		@if (showIcon && iconName) {
		<edr-icon [color]="iconColor" [name]="edrIconName" [size]="iconSize" class="notification__icon"></edr-icon>
		}
		<div class="notification__content">
			@if (title) {
			<p edr-typography typographyAs="heading--6" [innerHTML]="title" class="notification--title" id="{{ alertLabelId }}"></p>
			}
			<!-- Option 1 - Add description via input property -->
			@if (description.length > 0) {
			<p edr-typography typographyAs="body--xs" data-e2e="notification--description" id="{{ alertDescriptionId }}">
				<span [innerHTML]="description"></span>
			</p>
			}
			<!-- Option 2 - Add description content via ng-content -->
			<ng-content select="[description]"></ng-content>
			@if (cta && cta.text && cta.linkUrl) {
			<div class="notification--cta">
				<a [href]="cta.linkUrl" edr-typography typographyAs="heading--6" aria-label="Call to action link" [target]="cta.target || '_self'">
					<span>{{ cta.text || '' }}</span>
				</a>
			</div>
			}
		</div>
		@if (canDismiss) {
		<button (click)="closeAlert()" aria-label="Dismiss notification" class="notification--close">
			<edr-icon name="system-close" size="4" color="secondary--charcoal"></edr-icon>
		</button>
		}
	</div>
	}
</ng-template>
