import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { AuthService } from '../../../../core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, combineLatest, filter, map, of, switchMap, tap } from 'rxjs';
import { cleanQueryParams } from '@edr/shared';
import { PartnersFacade } from '../../+state';

// We use an interface to allow dynamic key checking with `PARTNER_IDS[key]` as well as static typing with `PARTNER_IDS.BP`
// Without interface, there will be linting errors
interface PartnerIds {
	[key: string]: string;
	Asb: string;
	BP: string;
	TeamBenefits: string;
	VineOnline: string;
	Petstock: string;
	Milkrun: string;
}
export const PARTNER_IDS: PartnerIds = {
	Asb: '2013',
	TeamBenefits: '2009',
	BP: '2011',
	VineOnline: '2012',
	Petstock: '2014',
	Milkrun: '2015',
};

export const memberLinkResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot): Observable<boolean> => {
	const authService = inject(AuthService);
	const router = inject(Router);
	const window = inject<Window>(WINDOW);
	const partnersFacade = inject<PartnersFacade>(PartnersFacade);
	const partnerId = route.queryParams['partnerid'];
	const partnerState = route.queryParams['partnerstate'] ?? '';
	const callbackURL$ = partnersFacade.getPartnerById(partnerId).pipe(map((partner) => partner?.partnerCallbackUrl));

	if (!partnerId) {
		router.navigateByUrl('/', { replaceUrl: true });
		return of(false);
	}

	return combineLatest([callbackURL$, partnersFacade.isLoading$]).pipe(
		filter(([_, isLoadingPartners]) => !isLoadingPartners),
		switchMap(([callbackURL]) => {
			if (!callbackURL) {
				router.navigateByUrl('/', { replaceUrl: true });
				return of(false);
			}
			return authService.fetchJWEToken(partnerId).pipe(
				map(({ token }) => callbackURL.replace('#{TOKEN}#', token).replace('#{PARTNERSTATE}#', partnerState)),
				tap((redirectLink) => {
					window.location.href = cleanQueryParams(redirectLink);
				}),
				map(() => true)
			);
		})
	);
};
