@if (iconName) {
	<div class="tooltip-icon">
		<edr-icon [name]="iconName" [color]="iconColor" size="6" />
	</div>
}

<p edr-typography [typographyAs]="typographyStyle" class="tooltip-content">
	@if (isMessageTemplateRef) {
		<span>
			<ng-container *ngTemplateOutlet="templateMessage"></ng-container>
		</span>
	} @else {
		<span [innerHTML]="stringMessage"></span>
	}
</p>

@if (ctaText && ctaLink) {
	<p edr-typography [typographyAs]="typographyStyle" class="tooltip-cta">
		<a [href]="ctaLink">{{ ctaText }}</a>
	</p>
}
@if (showCloseButton) {
	<button type="button" aria-label="close" class="tooltip-close-icon" (click)="dismissTooltip()">
		<edr-icon name="system-close" color="secondary--charcoal" size="3" />
	</button>
}

<img
	src="/assets/illustrations/tooltip-arrow.svg"
	alt="Tooltip Arrow"
	width="11"
	height="20"
	#tooltipArrow
	[ngClass]="[arrowBaseClassName, positionClass]"
/>
