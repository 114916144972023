import { Component, Input } from '@angular/core';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ButtonComponent, IconComponent, ModalComponent, TypographyComponent } from '@edr/styleguide';

@Component({
	selector: 'edr-app-order-card-successful-modal',
	templateUrl: './order-card-successful-modal.component.html',
	styleUrls: ['./order-card-successful-modal.component.scss'],
	standalone: true,
	imports: [ButtonComponent, IconComponent, ModalComponent, TypographyComponent],
})
export class OrderCardSuccessfulModalComponent {
	@Input() public title = '';
	@Input() public subTitle = '';
	@Input() public description = '';

	constructor(private modalService: ModalService) {}

	public closeModal(): void {
		this.modalService.close();
	}
}
