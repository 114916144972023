@if (state$ | async; as state) {
<header [class.secondaryNavActive]="(visibleSecondaryNavItems$ | async)?.length">
	@if (state.hasError) { Couldn't load navigation, please refresh the page to retry. } @if (!state.hasError) {
	<div class="header__container--main">
		<button type="button" class="skip-to-content-link" (click)="skipToContent.emit()">Skip to content</button>
		<div class="header__row--primary">
			<div class="header__container">
				<!-- mobile layout -->
				@if (state.isMobileLayout) {
				<a routerLink="/">
					<img class="header__logo" src="/assets/images/everyday-rewards-logo-mobile.svg" alt="Everyday Rewards Logo" />
				</a>
				<nav class="nav__mobile">
					@if ((isAuthenticated$ | async) === false) {
					<ul class="nav__mobile--logged-out" data-testid="sign-in-or-register">
						<li>
							<a href="#sign-in-register" (click)="handleLogin()" edr-typography data-testid="sign-in-or-register-button">Sign in or register </a>
						</li>
					</ul>
					}
					<button
						type="button"
						aria-label="Toggle navigation"
						(click)="toggleMobileNav(!state.isMobilePrimaryNavExpanded, $event)"
						class="nav__mobile-toggle"
					>
						@if (state.isMobilePrimaryNavExpanded) {
						<edr-icon name="system-close" size="6" color="secondary--charcoal"></edr-icon>
						} @if (!state.isMobilePrimaryNavExpanded) {
						<edr-icon name="system-menu" size="8" color="secondary--charcoal"></edr-icon>
						}
					</button>
				</nav>
				}
				<!-- desktop layout -->
				@if (!state.isMobileLayout) {
				<a routerLink="/">
					<img class="header__logo" src="/assets/images/everyday-rewards-logo.svg" alt="Everyday Rewards Logo" />
				</a>
				<nav class="nav__main">
					<edr-app-primary-navigation
						[state]="state"
						[navItems]="visiblePrimaryNavItems$ | async"
						[accountItems]="visibleAccountNavItems$ | async"
						[isAuthenticated]="isAuthenticated$ | async"
						(toggleMyAccountDesktop)="toggleMyAccount($event)"
						(closeAllNavs)="closeAllNavs()"
						(logout)="handleLogout()"
						(login)="handleLogin()"
					></edr-app-primary-navigation>
				</nav>
				}
			</div>
		</div>
		<div class="header__row--secondary">
			<!-- mobile main nav -->
			@if (state.isMobileLayout && state.isMobilePrimaryNavExpanded) {
			<div class="nav__main--mobile">
				<div class="header__container">
					<nav (edrClickOutside)="closeAllNavs()">
						<edr-app-primary-navigation
							[state]="state"
							[navItems]="visiblePrimaryNavItems$ | async"
							[isAuthenticated]="isAuthenticated$ | async"
							(toggleMyAccountMobile)="toggleMyAccountMobile($event)"
							(closeAllNavs)="closeAllNavs()"
							(logout)="handleLogout()"
							(login)="handleLogin()"
						></edr-app-primary-navigation>
					</nav>
				</div>
			</div>
			}
			<!-- mobile secondary nav -->
			@if (state.isMobileLayout) {
			<div class="nav__secondary-mobile">
				<div class="header__container">
					@if (!state.isMyAccountExpanded && (visibleSecondaryNavItems$ | async)?.length) {
					<div>
						<button
							type="button"
							class="nav__secondary-mobile-toggle"
							(click)="toggleMobileSecondaryNav(!state.isMobileSecondaryNavExpanded, $event)"
						>
							{{ (activeSubNav$ | async)?.label }}
							<edr-icon name="system-chevron" [rotate]="state.isMobileSecondaryNavExpanded ? '180' : '0'" size="4" color="primary--white"></edr-icon>
						</button>
					</div>
					} @if (state.isMobileSecondaryNavExpanded && (visibleSecondaryNavItems$ | async)?.length) {
					<nav (edrClickOutside)="closeAllNavs()">
						<ul aria-label="Secondary navigation" role="menubar">
							@for (navItem of visibleSecondaryNavItems$ | async; track navItem) {
							<li [class.active]="navItem.isActive" role="none">
								@if (!navItem.isActive) {
								<a edr-typography typographyAs="body" [routerLink]="navItem.url" (click)="closeAllNavs()" role="menuitem">
									{{ navItem.label }}
								</a>
								} @if (navItem.isActive) {
								<button edr-typography typographyAs="body" role="menuitem" type="button" (click)="closeAllNavs()">
									{{ navItem.label }}
								</button>
								}
							</li>
							}
						</ul>
					</nav>
					}
					<!-- mobile my account nav -->
					@if (state.isMyAccountExpanded) {
					<div>
						<button type="button" class="nav__secondary-mobile-toggle" (click)="toggleMyAccount(!state.isMyAccountExpanded, $event)">
							My Account
							<edr-icon name="system-chevron" [rotate]="state.isMyAccountExpanded ? '180' : '0'" size="4" color="primary--white"></edr-icon>
						</button>
					</div>
					} @if (state.isMyAccountExpanded) {
					<nav (edrClickOutside)="closeAllNavs()">
						<ul aria-label="My account navigation" role="menubar">
							@for (navItem of visibleAccountNavItems$ | async; track navItem) {
							<li [class.active]="navItem.isActive" role="none">
								@if (!navItem.isActive) {
								<a edr-typography typographyAs="body" [routerLink]="navItem.url" role="menuitem">{{ navItem.label }}</a>
								} @if (navItem.isActive) {
								<button edr-typography typographyAs="body" role="menuitem" type="button" (click)="closeAllNavs()">
									{{ navItem.label }}
								</button>
								}
							</li>
							}
						</ul>
					</nav>
					}
				</div>
			</div>
			}
			<!-- desktop secondary nav -->
			@if (!state.isMobileLayout) {
			<div class="nav__secondary-desktop">
				<div class="header__container">
					<nav>
						<ul aria-label="Secondary navigation" role="menubar">
							@for (navItem of visibleSecondaryNavItems$ | async; track navItem) {
							<li [class.active]="navItem.isActive" role="none" [attr.data-testid]="navItem.id">
								@if (!navItem.isActive) {
								<a edr-typography typographyAs="body" [routerLink]="navItem.url" role="menuitem">{{ navItem.label }}</a>
								} @if (navItem.isActive) {
								<button edr-typography typographyAs="body" role="menuitem" type="button" (click)="closeAllNavs()">
									{{ navItem.label }}
								</button>
								}
							</li>
							}
						</ul>
					</nav>
				</div>
			</div>
			}
		</div>
	</div>
	}
</header>
}
