<mat-form-field class="edr-calendar-form-field" appearance="outline">
	<input matInput [formControl]="dateFormControl" [min]="minDate" [max]="maxDate" placeholder="DD/MM/YYYY" [matDatepicker]="datepicker"
		   (dateChange)="onDateChanged($event)"
		   (keyup.enter)="onManualDateEntered()"
		   (blur)="onManualDateEntered()"
	>
	<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
	<mat-datepicker #datepicker [calendarHeaderComponent]="edrCalendarHeader">
		<mat-datepicker-actions>
			<edr-button buttonSize="medium" buttonStyle="secondary" [reversed]="true" matDatepickerCancel (keyup.enter)="onCancelPressed($event)"
						(click)="onCancelClicked()"
						aria-label="Cancel date selection"
						data-testid="cancel-date-selection-button"
			>
				Cancel
			</edr-button>
			<edr-button buttonSize="medium" matDatepickerApply
						(keyup.enter)="onApplyPressed($event)" (click)="onApplyClicked()"
						[disable]="!activeDate" aria-label="Apply selected date"
						data-testid="apply-date-selection-button">Apply
			</edr-button>
		</mat-datepicker-actions>
	</mat-datepicker>
</mat-form-field>
@if (hasDateBeenApplied && dateFormControl.invalid && this.errorMessage) {
	<edr-error-message>{{ errorMessage }}</edr-error-message>
}

