import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EDRColor, EDRIcon } from '../../types';
import { IconComponent } from '../icon/icon.component';
import { ApplyStylesDirective } from '../../directives';

export enum StripeType {
	Primary = 'primary',
	Secondary = 'secondary',
}

@Component({
	selector: 'edr-stripe',
	templateUrl: './stripe.component.html',
	styleUrls: ['./stripe.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IconComponent, ApplyStylesDirective],
})
export class StripeComponent {
	@Input({ required: true }) public text!: string;
	@Input({ required: true }) public textColor!: EDRColor;
	@Input({ required: true }) public backgroundColor!: EDRColor;
	@Input() public iconLeft: EDRIcon | undefined;
	@Input() public iconRight: EDRIcon | undefined;

	private _iconLeftColor: EDRColor | undefined;
	private _iconRightColor: EDRColor | undefined;

	@Input() public get iconLeftColor(): EDRColor {
		return this._iconLeftColor ?? this.textColor;
	}
	public set iconLeftColor(value: EDRColor) {
		this._iconLeftColor = value;
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@Input() public get iconRightColor(): EDRColor {
		return this._iconRightColor ?? this.textColor;
	}
	public set iconRightColor(value: EDRColor) {
		this._iconRightColor = value;
	}
}
