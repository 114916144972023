export function cleanQueryParams(urlString: string, paramsToRemove: string[] = []): string {
	try {
		const url = new URL(urlString);
		const params = new URLSearchParams(url.search);
		const delKeys: string[] = [...paramsToRemove];
		params.forEach((value, key) => {
			if (value === null || value === undefined || value === '') {
				delKeys.push(key);
			}
		});
		delKeys.forEach((key) => params.delete(key));
		url.search = params.toString();
		return url.href;
	} catch (err) {
		return urlString;
	}
}
