import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderState } from '../header/header.component.store';
import { NavItem } from '../header/header.interface';

import { ClickOutsideDirective, IconComponent, TypographyComponent } from '@edr/styleguide';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'edr-app-primary-navigation',
	templateUrl: './primary-navigation.component.html',
	styleUrls: ['./primary-navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, IconComponent, TypographyComponent, ClickOutsideDirective],
})
export class PrimaryNavigationComponent {
	@Input() public state: HeaderState | undefined;
	@Input() public navItems: NavItem[] | undefined | null;
	@Input() public accountItems: NavItem[] | undefined | null;
	@Input() public isAuthenticated: boolean | undefined | null;
	@Output() public toggleMyAccountDesktop = new EventEmitter<boolean>();
	@Output() public toggleMyAccountMobile = new EventEmitter<boolean>();
	@Output() public closeAllNavs = new EventEmitter<void>();
	@Output() public login = new EventEmitter<void>();
	@Output() public logout = new EventEmitter<void>();

	public toggleMyAccountMenu(expanded: boolean, type: 'desktop' | 'mobile', event: MouseEvent): void {
		event.stopPropagation();
		if (type === 'desktop') {
			return this.toggleMyAccountDesktop.emit(expanded);
		}
		this.toggleMyAccountMobile.emit(expanded);
	}
}
