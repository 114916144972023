@if ((isLoadingProfile$ | async) === false) { @if (personalDetails$ | async; as personalDetails) {
	<div class="personal-details-container">
		<!-- Notification Panel for Personal Details Edit Form -->
		@if (notification$ | async; as notification) {
			<edr-notification
				[notificationType]="notification.notificationType"
				[title]="notification.title"
				[description]="notification.description ?? ''"
				[canDismiss]="notification.canDismiss ?? false"
				(dismissed)="dismissNotification()"
				data-testid="update-confirmation-banner"
			></edr-notification>
		}
		<div class="personal-details-section">
			<edr-heading [level]="5">Email and phone numbers</edr-heading>
			<ng-container
				*ngTemplateOutlet="
				editDetailsTemplate;
				context: {
					subTitle: '',
					editMode: getEditMode('email') | async,
					id: 'email',
					inputType: 'email',
					label: 'Email address',
					fieldName: 'email',
					fieldValue: personalDetails.email,
					placeholder: 'name@email.co.nz',
					editable: isEditable$ | async,
					persistingData: persistingData$ | async,
					onSave: handleSaveEmail(confirmSaveEmailModal)
				}
			"
			></ng-container>
			<ng-container
				*ngTemplateOutlet="
				editDetailsTemplate;
				context: {
					subTitle: '',
					editMode: getEditMode('mobilePhoneNumber') | async,
					id: 'mobilePhoneNumber',
					label: 'Mobile number',
					fieldName: 'mobilePhoneNumber',
					fieldValue: personalDetails.mobilePhoneNumber,
					placeholder: 'Enter your mobile number',
					editable: isEditable$ | async,
					persistingData: persistingData$ | async,
					onSave: handleSave('mobilePhoneNumber')
				}
			"
			></ng-container>
			<ng-container
				*ngTemplateOutlet="
				editDetailsTemplate;
				context: {
					editMode: getEditMode('homePhoneNumber') | async,
					id: 'homePhoneNumber',
					label: 'Home phone number',
					subTitle: 'Optional',
					fieldName: 'homePhoneNumber',
					fieldValue: personalDetails.homePhoneNumber,
					placeholder: 'Enter your Home Phone number',
					editable: isEditable$ | async,
					persistingData: persistingData$ | async,
					onSave: handleSave('homePhoneNumber')
				}
			"
			>
			</ng-container>
		</div>
		<div class="personal-details-section">
			<edr-heading [level]="5">Member details</edr-heading>
			<p>To change the name or date of birth associated with your account, please <a [routerLink]="['/', contactRoute]">contact us</a>.</p>
			<div class="personal-details-field">
				<div data-testid="personal-details-firstName">
					<edr-heading [level]="6">First name</edr-heading>
					<p edr-typography id="firstName-field" class="field-value">{{ personalDetails.firstName }}</p>
				</div>
			</div>
			<div class="personal-details-field">
				<div data-testid="personal-details-lastName">
					<edr-heading [level]="6">Last name</edr-heading>
					<p edr-typography id="lastName-field" class="field-value">{{ personalDetails.lastName }}</p>
				</div>
			</div>
			<div class="personal-details-field">
				<div data-testid="personal-details-dateOfBirth">
					<edr-heading [level]="6">Date of birth</edr-heading>
					<p edr-typography id="dateOfBirth-field" class="field-value">
						{{ personalDetails.dateOfBirth | date : dateFormat }}
					</p>
				</div>
			</div>
		</div>
	</div>
} } @else {
	<edr-spinner><edr-heading [level]="3">Loading</edr-heading></edr-spinner>
}

<ng-template
	#editDetailsTemplate
	let-label="label"
	let-subTitle="subTitle"
	let-editMode="editMode"
	let-id="id"
	let-fieldValue="fieldValue"
	let-fieldName="fieldName"
	let-inputType="inputType"
	let-placeholder="placeholder"
	let-editable="editable"
	let-persistingData="persistingData"
	let-onSave="onSave"
>
	<form [formGroup]="editPersonalDetailsForm" [id]="fieldName + '-form'" (ngSubmit)="onSave(fieldName)">
		<div class="personal-details-field edit" [ngClass]="{ editing: editMode }">
			<div class="fields">
				<div>
					<edr-heading [level]="6">{{ label }}</edr-heading>
					@if (!!subTitle) {
						<p edr-typography typographyAs="body--xxs">{{ subTitle }}</p>
					} @if (!editMode) {
					<p [id]="id + '-field'" class="field-value" [attr.data-testid]="id + '-field-value'">{{ fieldValue }}</p>
				}
				</div>

				@if (editMode) {
					<edr-input
						[id]="id"
						[attr.data-testid]="id + '-input-field'"
						[formControlName]="fieldName"
						[type]="inputType"
						[placeholder]="placeholder"
						[isError]="!isFieldValid(fieldName)"
					/>
				} @if (editMode && getValidationErrorMessage(fieldName); as errorMessage) {
				<edr-error-message>
					{{ errorMessage }}
				</edr-error-message>
			}
			</div>
			<div class="buttons">
				@if (editMode) {
					<button
						edr-button
						[id]="id + '-cancel-button'"
						[attr.data-testid]="id + '-cancel-button'"
						buttonStyle="secondary"
						[reversed]="true"
						buttonSize="small"
						[disable]="persistingData"
						(click)="cancelClicked(fieldName)"
					>
						<span class="content cancel">Cancel</span>
					</button>
				} @if (editMode) {
				<button
					edr-button
					[id]="id + '-save-button'"
					[attr.data-testid]="id + '-save-button'"
					buttonStyle="secondary"
					buttonSize="small"
					[isLoading]="persistingData"
					loadingColor="secondary--mid-grey"
					type="submit"
					spinnerHeight="4"
				>
					<span class="content save">Save</span>
				</button>
			} @if (!editMode) {
				<button
					edr-button
					type="button"
					[id]="id + '-edit-button'"
					[attr.data-testid]="id + '-edit-button'"
					buttonStyle="secondary"
					buttonSize="small"
					[reversed]="true"
					[disable]="!editable"
					(click)="editClicked(fieldName)"
				>
					<span class="content edit">Edit</span>
				</button>
			}
			</div>
		</div>
	</form>
</ng-template>

<ng-template #confirmSaveEmailModal>
	<edr-modal
		title="Important"
		icon="alert-warning"
		iconColor="alert--alert-yellow"
		(closeModal)="handleClose()"
		data-testid="warning-changeEmail-modal"
	>
		<p modal-content>Updating your email address here will also update your Woolworths Online Shopping email address and affect how you sign in.</p>
		<button
			edr-button
			type="button"
			buttonStyle="secondary"
			buttonSize="small"
			modal-footer-primary
			(click)="saveEmail()"
			data-testid="updateEmail-button-modal"
		>
			Update
		</button>
		<button
			edr-button
			type="button"
			buttonStyle="secondary"
			buttonSize="small"
			[reversed]="true"
			modal-footer-secondary
			(click)="handleClose()"
			data-testid="cancelUpdateEmail-button-modal"
		>
			Cancel
		</button>
	</edr-modal>
</ng-template>
