import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'edr-card-media',
	template: `<ng-content></ng-content>`,
	styles: [
		`
			:host ::ng-deep img {
				width: 100%;
			}
		`,
	],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardMediaComponent {
	@HostBinding('style.alignSelf') @Input() public alignSelf: 'flex-start' | 'center' | 'flex-end' | undefined;
}
