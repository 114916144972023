@if ({ isLoadingOffers: isLoadingOffers$ | async, boostOffers: boostOffers$ | async, numberOfOffersToShow: numberOfOffersToShow$ | async }; as data) {
<edr-heading [level]="3">{{ title }}</edr-heading>
@if (data.isLoadingOffers) {
<edr-spinner>
	<edr-heading [level]="3">Loading</edr-heading>
</edr-spinner>
}
<div [class.hidden]="!data.boostOffers?.length">
	<ng-content />
</div>
@if (!data.isLoadingOffers && (!!data.boostOffers?.length || !!everydayOffers?.length)) {
<edr-carousel itemWidthType="fixed" [dynamicColumnConfig]="{ xxs: 1, sm: 2, lg: 4 }" id="boost-offers-carousel" [rerenderOnChange]="data.boostOffers">
	@for (offer of data.boostOffers | slice : 0 : data.numberOfOffersToShow || 999; track identify($index, offer)) {
	<edr-carousel-item id="boost-offers-carousel">
		<edr-app-card-boost
			[isCarouselItem]="true"
			[partnerProductBoost]="offer.partnerProductBoost"
			[isProductImage]="offer.partnerProductBoost?.isProductImage ?? true"
			[buttonCta]="offer.buttonCta"
			[roundelText]="offer.roundelText"
			(boost)="activateBoostOffer(offer.partnerProductBoost!.id)"
			[isEverydayOffer]="false"
		/>
	</edr-carousel-item>
	} @for ( offer of everydayOffers | slice : 0 : numberOfEverydayOffers(data.boostOffers?.length ?? 0, data.numberOfOffersToShow ?? 0); track
	identify($index, offer)) {
	<edr-carousel-item id="boost-offers-carousel">
		<edr-app-card-boost
			[isCarouselItem]="true"
			[partnerProductBoost]="offer.partnerProductBoost"
			[isProductImage]="offer.partnerProductBoost?.isProductImage ?? true"
			[buttonCta]="offer.buttonCta"
			[roundelText]="offer.roundelText"
			[isEverydayOffer]="true"
		/>
	</edr-carousel-item>
	} @if ((data.boostOffers?.length ?? 0) + (everydayOffers?.length ?? 0) > 4) {
	<edr-carousel-item id="boost-offers-carousel">
		<edr-app-card-boost-all />
	</edr-carousel-item>
	}
</edr-carousel>
} @else { @if (!data.isLoadingOffers) {
<edr-app-no-boost-offers data-testid="no-boosts" />
} } }
