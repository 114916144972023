import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import { ShortcodePipe } from '@edr/styleguide';
import { ContentfulToHtmlPipe } from '../../pipes';
import { AsyncPipe } from '@angular/common';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-contentful-rich-text',
	templateUrl: './rich-text.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ContentfulToHtmlPipe, ShortcodePipe, AsyncPipe, ContentBaseComponent],
})
export class RichTextComponent extends ContentBaseComponent {
	@Input() public document?: Document = { nodeType: BLOCKS.DOCUMENT, data: {}, content: [] };

	/**
	 * When set to true this changes the color of links to secondar-charcoal as to increase the contrast.
	 */
	@HostBinding('attr.has-grey-background') @Input() public hasGreyBackground = false;
}
