import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { tap, filter, switchMap, zip, of, delay } from 'rxjs';
import { routerNavigatedAction } from '@ngrx/router-store';
import { DynamicContentFacade } from '../dynamic-content';
import { AnchorPointService } from '../../services/anchor-point/anchor-point.service';

@Injectable()
export class RouterEffects {
	public scrollToAnchorPoint$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(routerNavigatedAction),
				filter((action) => !!action.payload.routerState.root.fragment),
				switchMap((action) => zip(of(action), this.dynamicContentFacade.pageContentIsReady$.pipe(filter(Boolean)))),
				delay(0),
				tap(([action]) => {
					const fragments = action.payload.routerState.root.fragment?.split('+') || [];
					this.anchorPointService.scrollToAnchorPoint(fragments[fragments.length - 1]);
				})
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private dynamicContentFacade: DynamicContentFacade, private anchorPointService: AnchorPointService) {
		this.anchorPointService.scrollToAnchorPointOnNavigationSkippedEvent();
	}
}
