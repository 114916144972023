@if ({ isLoadingOffers: isLoadingOffers$ | async, boostOffers: boostOffers$ | async, otherBoostOffersCompleted: otherBoostOffersCompleted$ | async };
as data) { @if (data.boostOffers) {
<edr-heading [level]="3">{{ title }}</edr-heading>
} @if (data.isLoadingOffers) {
<edr-spinner>
	<edr-heading [level]="3">Loading</edr-heading>
</edr-spinner>
}
<div [class.hidden]="!data.boostOffers?.length">
	<ng-content />
</div>
@if (!data.isLoadingOffers && (!!data.boostOffers?.length || !!everydayOffers?.length)) {
<edr-layout-group
	itemWidthType="dynamic"
	[dynamicColumnConfig]="{ xxs: 1, sm: 2, lg: 4 }"
	[rerenderOnChange]="data.boostOffers"
	id="boost-offers-grid"
>
	@for (offer of data.boostOffers | slice : 0 : numberOfOffers || 999; track identify($index, offer)) {
	<edr-layout-group-item id="boost-offers-grid">
		<edr-app-card-boost
			[partnerProductBoost]="offer.partnerProductBoost"
			[isProductImage]="offer.partnerProductBoost?.isProductImage ?? true"
			[buttonCta]="offer.buttonCta"
			[roundelText]="offer.roundelText"
			(boost)="activateBoostOffer(offer.partnerProductBoost!.id)"
			[isEverydayOffer]="false"
		/>
	</edr-layout-group-item>
	} @for (offer of everydayOffers | slice : 0 : numberOfEverydayOffers(data.boostOffers?.length ?? 0, numberOfOffers ?? 0); track identify($index,
	offer)) {
	<edr-layout-group-item id="boost-offers-grid">
		<edr-app-card-boost
			[partnerProductBoost]="offer.partnerProductBoost"
			[isProductImage]="offer.partnerProductBoost?.isProductImage ?? true"
			[buttonCta]="offer.buttonCta"
			[roundelText]="offer.roundelText"
			[isEverydayOffer]="true"
		/>
	</edr-layout-group-item>
	} @for (offer of data.otherBoostOffersCompleted | slice : 0 : numberOfOffers || 999; track identify($index, offer)) {
	<edr-layout-group-item id="boost-offers-grid">
		<edr-app-card-boost
			[partnerProductBoost]="offer.partnerProductBoost"
			[isProductImage]="offer.partnerProductBoost?.isProductImage ?? true"
			[buttonCta]="offer.buttonCta"
			[roundelText]="offer.roundelText"
			(boost)="activateBoostOffer(offer.partnerProductBoost!.id)"
			[isEverydayOffer]="false"
		/>
	</edr-layout-group-item>
	}
</edr-layout-group>
} @else { @if (!data.isLoadingOffers) {
<edr-app-no-boost-offers data-testid="no-boosts" />
} } }
