@if (boostOffer$ | async; as boostOffer) {
<edr-modal (closeModal)="closeModal()" [useCustomHeader]="true" [class.is-boosted]="boostOffer.partnerProductBoost.isBoosted">
	<div modal-header class="boost__header">
		<div class="boost__header-image" [class.is-product-image]="isProductImage">
			@if (boostOffer) {
			<img [src]="boostOffer.partnerProductBoost.product.imageLargeUrl" [alt]="boostOffer.partnerProductBoost.product.name" />
			} @if (boostOffer.roundelText) {
			<edr-roundel [roundelText]="boostOffer.roundelText" size="lg" />
			} @if (boostOffer.partnerProductBoost.isBoosted) {
			<edr-tag type="boost" class="boost__header__tag" size="medium">Boosted</edr-tag>
			}
		</div>
		<button type="button" class="boost__header-close" (click)="closeModal()">
			<edr-icon name="system-close" size="5" color="secondary--charcoal"></edr-icon>
		</button>
		<div class="boost__header-info">
			@if (partner$ | async; as partner) { @if (partner.offerCardLabelLogo.url) {
			<img class="boost__header-partner" [src]="partner.offerCardLabelLogo.url" [alt]="partner.offerCardLabelLogo.altText" />
			} }
			<edr-heading [level]="3">{{ boostOffer.partnerProductBoost.title }}</edr-heading>
		</div>
	</div>
	<div modal-content class="boost__content">
		<p edr-typography class="boost__content-description">{{ boostOffer.partnerProductBoost.description.detailed }}</p>
		@if (boostOffer.partnerProductBoost.limit) {
		<ul class="boost__content-limits">
			<li>
				<div>
					<edr-icon name="alert-info-outlined" size="4" color="extended--charcoal-light-40"></edr-icon>
					<p>Limit {{ boostOffer.partnerProductBoost.limit }} per customer</p>
				</div>
			</li>
			<li>
				<div>
					<edr-icon name="system-calendar" size="4" color="extended--charcoal-light-40"></edr-icon>
					<p data-testid="boost-modal-end-date">Ends {{ boostOffer.partnerProductBoost.endDate | date : 'dd MMM' }}</p>
				</div>
			</li>
		</ul>
		}
		<p class="boost__content-tc-title" edr-typography typographyAs="body--xs">Terms and conditions</p>
		<p
			class="boost__content-tc-content"
			edr-typography
			typographyAs="body--xs"
			[innerHTML]="boostOffer.partnerProductBoost.termsAndConditions | linkShortCode : '_blank'"
		></p>
	</div>
	@if (!boostOffer.partnerProductBoost.isCompleted && boostOffer.buttonCta && !boostOffer.partnerProductBoost.isBoosted) {
	<button
		type="button"
		edr-button
		modal-footer-primary
		(click)="activateBoost(boostOffer.partnerProductBoost)"
		buttonSize="small"
		class="card-boost__actions--primary"
		[buttonStyle]="boostOffer.buttonCta.style ?? 'primary'"
		[reversed]="boostOffer.buttonCta.styleReversed ?? false"
		[isLoading]="boostOffer.partnerProductBoost.isBoosting"
		[disabled]="boostOffer.partnerProductBoost.isBoosting"
		spinnerHeight="3"
	>
		{{ boostOffer.buttonCta.text }}
	</button>
	} @if (!boostOffer.partnerProductBoost.isCompleted && boostOffer.partnerProductBoost.isBoosted && boostOffer.buttonCta &&
	boostOffer.buttonCta.linkUrl) {
	<edr-link
		modal-footer-primary
		id="btn-partner-shop"
		[asButton]="true"
		[buttonStyle]="boostOffer.buttonCta.style ?? 'secondary'"
		[buttonStyleReversed]="boostOffer.buttonCta.styleReversed ?? true"
		size="small"
		class="card-boost__actions--secondary"
		[linkUrl]="boostOffer.buttonCta.linkUrl"
		[text]="boostOffer.buttonCta.text"
		target="_blank"
	/>
	} @if (boostOffer.partnerProductBoost.isCompleted) {
	<button modal-footer-primary edr-button buttonStyle="primary" buttonSize="small" [disable]="true">Completed</button>
	}
</edr-modal>
}
