import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { OrderAction } from './page-content-everyday-rewards-card.model';
import { forkJoin, Observable, switchMap, take, tap } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';
import { PhysicalCardRequestReason, RewardsCardAddress } from '../../../features/my-account/models';
import { LoggingService } from '@edr/shared';
import { RewardsCardResponse, RewardsCardService } from '../../../features/my-account/services';
import {
	BarcodeCardResponse,
	FlexiblePageTemplateResponse,
	CardAvailabilityResponse,
	CardResponse,
	CreatePhysicalCardRequest,
	PhysicalCardOrderStatus,
	PhysicalCardResponse,
	ReplaceCardRequest,
	ReplaceCardResponse,
} from '@edr/bff-api-models';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { APP_ROUTES } from '../../../routes';

export interface EverydayRewardsCardState {
	isLoading: boolean;
	cardRequestReason?: PhysicalCardRequestReason;
	addressFinderContent?: ContentDescription;
	orderSuccessContent?: ContentDescription;
	isOrderingCard: boolean;
	hasOrderedCard: boolean;
	isCardOrderError: boolean;
	isCardFetchError: boolean;
	rewardsCard?: BarcodeCardResponse;
	rewardsCardCmm?: CardResponse;
	physicalCardRequestStatus?: CardAvailabilityResponse;
	orderAction?: OrderAction;
	error?: unknown;
}

export interface ContentDescription {
	title: string;
	subTitle?: string;
	description: string;
	buttonText?: string;
}

export const defaultState: EverydayRewardsCardState = {
	isLoading: true,
	isOrderingCard: false,
	isCardFetchError: false,
	hasOrderedCard: false,
	isCardOrderError: false,
};

@Injectable()
export class EverydayRewardsCardStore extends ComponentStore<EverydayRewardsCardState> {
	/** Selectors */
	public readonly selectState$ = this.select((state) => state);
	public readonly selectCardRequestReason$ = this.select((state) => state.cardRequestReason);
	public readonly selectCardType$ = this.select((state) => state.rewardsCard?.cardType);
	public readonly addressFinderContent$ = this.select((state) => state.addressFinderContent);
	public readonly orderSuccessContent$ = this.select((state) => state.orderSuccessContent);
	public readonly selectHasOrderedCard$ = this.select((state) => state.hasOrderedCard);
	public readonly selectIsCardOrderError$ = this.select((state) => state.isCardOrderError);
	public readonly selectIsOrderingCard$ = this.select((state) => state.isOrderingCard);
	public readonly showNotification$ = this.select((state) => state.hasOrderedCard || state.isCardOrderError);
	public readonly showCardOrderNotification$ = this.select((state) => state.hasOrderedCard);
	public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);

	/** Updaters */
	public readonly updatePageContent = this.updater(
		(
			state,
			data: {
				cardResponse?: RewardsCardResponse;
				physicalCardRequestStatus?: CardAvailabilityResponse;
				content?: FlexiblePageTemplateResponse;
			}
		): EverydayRewardsCardState => {
			const physicalCardRequestStatus = data.physicalCardRequestStatus ? data.physicalCardRequestStatus : state.physicalCardRequestStatus;
			const physicalCardOrdered = physicalCardRequestStatus?.cardOrderStatus === PhysicalCardOrderStatus.CardOrdered;
			const reason = physicalCardRequestStatus?.replacementCardOrdered ? PhysicalCardRequestReason.lostOrStolen : PhysicalCardRequestReason.new;
			const cardRequestReason = physicalCardOrdered ? reason : undefined;

			return {
				...state,
				rewardsCard: data.cardResponse && data.cardResponse.BarcodeCardResponse ? data.cardResponse.BarcodeCardResponse : state.rewardsCard,
				rewardsCardCmm: data.cardResponse && data.cardResponse.CardResponse ? data.cardResponse.CardResponse : state.rewardsCardCmm,
				physicalCardRequestStatus,
				cardRequestReason,
				isLoading: false,
				hasOrderedCard: physicalCardRequestStatus?.cardOrderStatus === PhysicalCardOrderStatus.CardOrdered,
			};
		}
	);

	public readonly updateCardRequestReason = this.updater(
		(state, cardRequestReason: PhysicalCardRequestReason, addressFinderContent?: ContentDescription): EverydayRewardsCardState => ({
			...state,
			cardRequestReason,
			addressFinderContent: addressFinderContent ?? state.addressFinderContent,
		})
	);

	public readonly updateOrderActionId = this.updater(
		(state, orderActionId: OrderAction): EverydayRewardsCardState => ({
			...state,
			orderAction: orderActionId,
		})
	);

	public readonly updateContentDescription = this.updater(
		(
			state,
			{
				addressFinderContent,
				orderSuccessContent,
			}: {
				addressFinderContent?: ContentDescription | undefined;
				orderSuccessContent?: ContentDescription | undefined;
			}
		): EverydayRewardsCardState => ({
			...state,
			addressFinderContent: addressFinderContent ?? state.addressFinderContent,
			orderSuccessContent: orderSuccessContent ?? state.orderSuccessContent,
		})
	);

	public readonly updateOrderingCard = this.updater(
		(
			state,
			{
				isOrderingCard,
				hasOrderedCard,
				isCardOrderError,
			}: {
				isOrderingCard: boolean;
				hasOrderedCard: boolean;
				isCardOrderError?: boolean;
			}
		): EverydayRewardsCardState => ({
			...state,
			isOrderingCard,
			hasOrderedCard,
			isCardOrderError: isCardOrderError ?? false,
		})
	);

	public readonly updateResetCardOrderState = this.updater(
		(state): EverydayRewardsCardState => ({
			...state,
			hasOrderedCard: false,
		})
	);

	public readonly updateRewardsCard = this.updater(
		(state, rewardsCard: BarcodeCardResponse): EverydayRewardsCardState => ({
			...state,
			rewardsCard: rewardsCard,
		})
	);

	public readonly updateIsLoading = this.updater(
		(state, updatedLoading: boolean): EverydayRewardsCardState => ({
			...state,
			isLoading: updatedLoading,
		})
	);

	public readonly updateError = this.updater(
		(
			state,
			{
				error,
				isCardFetchError,
			}: {
				isCardFetchError?: boolean;
				error: unknown;
			}
		): EverydayRewardsCardState => ({
			...state,
			error,
			isLoading: false,
			isCardFetchError: isCardFetchError ?? false,
		})
	);

	/** Effects */
	public readonly orderCardEffect = this.effect((address$: Observable<RewardsCardAddress>) =>
		address$.pipe(
			tap(() => {
				this.updateOrderingCard({ isOrderingCard: true, hasOrderedCard: false });
			}),
			concatLatestFrom(() => this.selectCardRequestReason$),
			switchMap(([address, reason]) => {
				const payload = {
					address: {
						addressLine1: address.addressLine1,
						addressLine2: address.addressLine2,
						suburb: address.suburb,
						city: address.city,
						postalCode: address.postcode,
					},
					requestedBy: '',
				};

				return reason === PhysicalCardRequestReason.new ? this.requestPhysicalCard(payload) : this.requestReplacementCard(payload);
			}),
			switchMap((_) =>
				forkJoin([
					this.rewardsCardService.fetchRewardsCard().pipe(take(1)),
					this.rewardsCardService.getPhysicalCardRequestStatus().pipe(take(1)),
				]).pipe(
					tapResponse(
						([cardResponse, physicalCardRequestStatus]) => {
							this.updatePageContent({
								cardResponse,
								physicalCardRequestStatus,
							});
						},
						(error) => {
							this.loggingService.error(error);
						}
					)
				)
			)
		)
	);

	public readonly fetchPageData = this.effect(() =>
		forkJoin([this.rewardsCardService.fetchRewardsCard().pipe(take(1)), this.rewardsCardService.getPhysicalCardRequestStatus().pipe(take(1))]).pipe(
			tapResponse(
				([cardResponse, physicalCardRequestStatus]) => {
					this.updatePageContent({
						cardResponse,
						physicalCardRequestStatus,
					});
				},
				(error) => {
					this.loggingService.error(error);
					this.updateError({ error, isCardFetchError: true });
					const errorStatusCode = (error as HttpErrorResponse)?.status ?? 500;
					this.router.navigate(['/', APP_ROUTES.error, errorStatusCode]);
				}
			)
		)
	);

	constructor(private loggingService: LoggingService, private rewardsCardService: RewardsCardService, private router: Router) {
		super(defaultState);
	}

	private requestPhysicalCard(payload: CreatePhysicalCardRequest): Observable<PhysicalCardResponse> {
		return this.rewardsCardService.requestPhysicalCard(payload).pipe(
			tapResponse(
				(response) => {
					if (response.success) {
						this.updateOrderingCard({ isOrderingCard: false, hasOrderedCard: true });
					} else {
						this.orderingCardError();
					}
				},
				(error) => {
					this.orderingCardError();
					this.loggingService.error(error);
				}
			)
		);
	}

	private requestReplacementCard(payload: ReplaceCardRequest): Observable<ReplaceCardResponse> {
		return this.rewardsCardService.requestReplacementCard(payload).pipe(
			tapResponse(
				(response) => {
					if (response.success) {
						this.updateOrderingCard({ isOrderingCard: false, hasOrderedCard: true });
					} else {
						this.orderingCardError();
					}
				},
				(error) => {
					this.orderingCardError();
					this.loggingService.error(error);
				}
			)
		);
	}

	private orderingCardError(): void {
		this.updateOrderingCard({
			isOrderingCard: false,
			hasOrderedCard: false,
			isCardOrderError: true,
		});
	}
}
