import { ChangeDetectorRef, EventEmitter, Inject, Injectable, NgZone, QueryList, TemplateRef, ViewContainerRef } from '@angular/core';
import {
	ConnectedPosition,
	FlexibleConnectedPositionStrategy,
	Overlay,
	OverlayConfig,
	OverlayRef,
	PositionStrategy,
	ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { delay, EMPTY, fromEvent, merge, Observable, startWith, Subscription, switchMap, take } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { _getEventTarget } from '@angular/cdk/platform';
import { KeyCode } from '../../enums';
import { OptionComponent } from '../../components/option/option.component';
import { OptionSelectedEvent } from '../../components/option/option-selected-event.model';
import { BreakpointService, DeviceType } from '@edr/shared';

export interface DropdownInitialize {
	optionList: QueryList<OptionComponent> | undefined;
	connectedElement: HTMLElement;
	template: TemplateRef<unknown>;
	viewContainerRef: ViewContainerRef;
	keyDownEventListener?: Observable<KeyboardEvent>;
	initialValue?: unknown;
}

@Injectable()
export class DropdownService {
	private portal: TemplatePortal | null = null;
	private overlayRef: OverlayRef | null = null;
	private positionStrategy: FlexibleConnectedPositionStrategy | null = null;
	private subscriptions = new Subscription();
	private closingActionsSubscription = new Subscription();
	private connectedTo: HTMLElement | null = null;
	private optionList: QueryList<OptionComponent> | undefined;
	private initialLoadSelectedOptionValue: unknown = null;
	private selectedOption: OptionComponent | null = null;
	private activeOption: OptionComponent | null = null;
	private panelOpen = false;
	private disabled = false;

	private _panelClosed = new EventEmitter<void>();
	private _optionSelected = new EventEmitter<OptionSelectedEvent | null>();

	constructor(
		private overlay: Overlay,
		private scrollStrategyOptions: ScrollStrategyOptions,
		private viewportRuler: ViewportRuler,
		private zone: NgZone,
		private changeDetector: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document,
		private breakpointService: BreakpointService
	) {}

	public destroy(): void {
		this.subscriptions.unsubscribe();
		if (this.overlayRef) {
			this.closePanel();
			this.overlayRef.dispose();
			this.overlayRef = null;
		}
	}

	public panelClosed(): Observable<void> {
		return this._panelClosed.asObservable();
	}

	public optionSelected(): Observable<OptionSelectedEvent | null> {
		return this._optionSelected.asObservable();
	}

	public getSelectedOption(): OptionComponent | null {
		return this.selectedOption;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public initialize(args: DropdownInitialize): void {
		this.optionList = args.optionList;
		this.connectedTo = args.connectedElement;

		// Initialize overlay
		this.portal = new TemplatePortal(args.template, args.viewContainerRef);
		this.overlayRef = this.overlay.create(this.getOverlayConfig(args.connectedElement));
		this.handleOverlayEvents(this.overlayRef);
		this.subscriptions.add(
			this.viewportRuler.change().subscribe(() => {
				if (this.panelOpen && this.overlayRef) {
					this.overlayRef.updateSize({ width: this.getConnectedElementWidth(args.connectedElement) });
				}
			})
		);

		if (args.initialValue) {
			this.selectedOption = this.getOptionFromValue(args.initialValue);
		}

		if (args.keyDownEventListener) {
			// Watch arrow and enter keys
			this.subscriptions.add(
				args.keyDownEventListener.subscribe((event) => {
					this.handleInputKeyDown(event);
				})
			);
		}

		if (this.optionList) {
			// Subscribe to Options changes in order to subscribe to each option `optionSelected` event
			this.subscriptions.add(
				this.optionList.changes
					.pipe(
						startWith(this.optionList),
						switchMap(() => (this.optionList ? merge(...this.optionList.map((option) => option.selectionChange)) : EMPTY))
					)
					.subscribe((e) => {
						this.handleOptionSelected(e);
						this.changeDetector.markForCheck();
					})
			);

			// Try get active and selected index from new updated list
			this.subscriptions.add(
				this.optionList.changes.pipe(startWith(this.optionList)).subscribe(() => {
					const selectedOptionValue = this.initialLoadSelectedOptionValue ?? this.selectedOption?.value ?? null;
					this.setActiveOption(this.activeOption?.value ?? selectedOptionValue);
					this.setSelectedOption(selectedOptionValue);

					if (this.initialLoadSelectedOptionValue) {
						this.initialLoadSelectedOptionValue = null;
						this._optionSelected.emit(
							!this.selectedOption
								? null
								: {
										text: this.selectedOption.getOptionText(),
										value: this.selectedOption.value,
										userInitiated: false,
								  }
						);
					}
					this.changeDetector.markForCheck();
				})
			);

			// Reposition panel once all options has been rendered
			this.subscriptions.add(
				this.optionList.changes
					.pipe(
						startWith(this.optionList),
						switchMap(() => this.zone.onStable.pipe(take(1))),
						delay(0)
					)
					.subscribe(() => {
						this.updatePanelPosition();
					})
			);
		}
	}

	public openPanel(): void {
		if (!this.disabled) {
			if (this.connectedTo) {
				this.positionStrategy?.setOrigin(this.connectedTo);
				this.overlayRef?.updateSize({ width: this.getConnectedElementWidth(this.connectedTo) });
			}

			if (this.overlayRef && !this.overlayRef.hasAttached()) {
				this.overlayRef.attach(this.portal);
				this.zone.onStable.pipe(take(1)).subscribe(() => {
					if (this.selectedOption) {
						this.scrollToOption(this.selectedOption.getNativeElement());
					}
				});
				this.subscribeToOutsideClickStream();
				this.panelOpen = true;
			}
		}
	}

	public closePanel(): void {
		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.overlayRef.detach();
		}
		this.closingActionsSubscription.unsubscribe();
		this.panelOpen = false;
		this._panelClosed.emit();
	}

	public updatePanelPosition(): void {
		if (this.panelOpen && this.positionStrategy && this.overlayRef) {
			this.positionStrategy?.reapplyLastPosition();
			this.overlayRef.updatePosition();
		}
	}

	public setSelectedOption(value: unknown): void {
		let selectedOption: OptionComponent | null = null;
		if (this.optionList) {
			this.optionList?.forEach((option) => {
				option.selected = option.value === value;
				if (option.selected) {
					selectedOption = option;
				}
			});
		} else {
			this.initialLoadSelectedOptionValue = value;
		}
		this.selectedOption = selectedOption;
	}

	public handleOptionSelected(event: OptionSelectedEvent | null): void {
		if (!this.disabled) {
			this.closePanel();
			this.setSelectedOption(event?.value);
			if (this.optionList) {
				this.setActiveOption(event?.value);
			}
			this._optionSelected.emit(event);
		}
	}

	public getOptionFromValue(value: unknown): OptionComponent | null {
		return this.optionList?.find((option) => option.value === value) ?? null;
	}

	private scrollToOption(optionElement: HTMLElement): void {
		// Unit tests don't seem to expose the `scrollIntoView` method, so adding this to allow unit tests to run
		if (optionElement.scrollIntoView) {
			optionElement.scrollIntoView({
				block: 'center',
				inline: 'center',
			});
		}
	}

	private setActiveOption(value: unknown): void {
		if (this.optionList) {
			const optionIndex = this.optionList.map((option, index) => ({ option, index })).find((x) => x.option.value === value)?.index ?? -1;
			this.optionList.forEach((option, index) => {
				option.active = optionIndex === index;
			});

			const activeOption = this.optionList.get(optionIndex) ?? null;
			if (activeOption) {
				this.scrollToOption(activeOption.getNativeElement());
			}
			this.activeOption = activeOption;
		}
	}

	private getActiveIndex(): number {
		return this.optionList?.map((option, index) => ({ option, index }))?.find((x) => x.option.active)?.index ?? -1;
	}

	private handleInputKeyDown(event: KeyboardEvent): void {
		const hasModifier = hasModifierKey(event);

		if (!this.optionList || hasModifier) {
			return;
		}

		const currentActiveIndex = this.getActiveIndex();

		// Pressed Enter while option was active and panel was open
		if (currentActiveIndex >= 0 && event.code === KeyCode.Enter && this.panelOpen) {
			event.preventDefault();
			const selectedOption = this.optionList.get(currentActiveIndex);
			if (selectedOption) {
				this.handleOptionSelected({
					text: selectedOption.getOptionText(),
					value: selectedOption.value,
					userInitiated: true,
				});
			}
		} else if (event.code === KeyCode.ArrowDown || event.code === KeyCode.ArrowUp) {
			event.preventDefault();
			if (!this.panelOpen) {
				this.openPanel();
			}

			const indexMappedOptions = this.optionList.map((option, index) => ({ index, option }));
			let nextAvailableOption: { index: number; option: OptionComponent } | null = null;

			if (event.code === KeyCode.ArrowDown) {
				nextAvailableOption = indexMappedOptions.filter((x) => x.index > currentActiveIndex && !x.option.disabled)[0];
				if (!nextAvailableOption) {
					// No new options available that is not disabled, so start from beginning
					nextAvailableOption = indexMappedOptions.filter((x) => !x.option.disabled)[0];
				}
			} else if (event.code === KeyCode.ArrowUp) {
				// Reverse the array to select only 1 up and not the first element in the array
				indexMappedOptions.reverse();
				nextAvailableOption = indexMappedOptions.filter((x) => x.index < currentActiveIndex && !x.option.disabled)[0];
				if (!nextAvailableOption) {
					// No new options available that is not disabled, so start from beginning
					nextAvailableOption = indexMappedOptions.filter((x) => !x.option.disabled)[0];
				}
			}

			if (nextAvailableOption) {
				this.setActiveOption(nextAvailableOption.option.value);
			}
		}
	}

	private getOverlayConfig(connectedElement: HTMLElement): OverlayConfig {
		return new OverlayConfig({
			width: this.getConnectedElementWidth(connectedElement),
			positionStrategy: this.getOverlayPosition(connectedElement),
			scrollStrategy: this.scrollStrategyOptions.reposition(),
			panelClass: 'dropdown-panel',
		});
	}

	private getOverlayPosition(connectedElement: HTMLElement): PositionStrategy {
		const strategy = this.overlay.position().flexibleConnectedTo(connectedElement).withFlexibleDimensions(false).withPush(false);

		this.setStrategyPositions(strategy);
		this.positionStrategy = strategy;
		return strategy;
	}

	private setStrategyPositions(positionStrategy: FlexibleConnectedPositionStrategy): void {
		// Note that we provide horizontal fallback positions, even though by default the dropdown
		// width matches the input, because consumers can override the width. See #18854.
		const belowPositions: ConnectedPosition[] = [
			{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
			{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
		];

		// The overlay edge connected to the trigger should have squared corners, while
		// the opposite end has rounded corners. We apply a CSS class to swap the
		// border-radius based on the overlay position.
		const abovePositions: ConnectedPosition[] = [
			{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
			{ originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
		];

		// force the dropdown below on mobile
		// we are forcing the input to the top of the screen elsewhere so we need the dropdown to always be below the input to avoid it being off the top of the screen in some Android models
		// this happens once the onscreen keyboard opens on a phone - so we won't be able to reproduce this just with the chrome dev tools
		if (this.breakpointService.isCustomBreakpointActive(DeviceType.Mobile)) {
			positionStrategy.withPositions([...belowPositions]);
		} else {
			positionStrategy.withPositions([...belowPositions, ...abovePositions]);
		}
	}

	private getConnectedElementWidth(element: HTMLElement): number {
		return element.getBoundingClientRect().width;
	}

	private handleOverlayEvents(overlayRef: OverlayRef): void {
		// Subscribe to ESCAPE or ALT + UP_ARROW events to close the panel
		overlayRef.keydownEvents().subscribe((event) => {
			if (
				(event.code === KeyCode.Escape && !hasModifierKey(event)) ||
				(event.code === KeyCode.ArrowUp && hasModifierKey(event, 'altKey')) ||
				event.code === KeyCode.Tab
			) {
				this.closePanel();

				// We need to stop propagation, otherwise the event will eventually
				// reach the input itself and cause the overlay to be reopened.
				event.stopPropagation();
				event.preventDefault();
			}
		});

		// Subscribe to the pointer events stream so that it doesn't get picked up by other overlays.
		overlayRef.outsidePointerEvents().subscribe();
	}

	private subscribeToOutsideClickStream(): void {
		const subscription = merge(
			fromEvent(this.document, 'click') as Observable<MouseEvent>,
			fromEvent(this.document, 'auxclick') as Observable<MouseEvent>,
			fromEvent(this.document, 'touchend') as Observable<TouchEvent>
		)
			.pipe(delay(1)) // Without delay, the event on the actual clicked element will not fire due to rapid layout shifts
			.subscribe((event) => {
				if (this.overlayRef && this.connectedTo) {
					const clickTarget = _getEventTarget<HTMLElement>(event) ?? (event.target as HTMLElement);
					if (!this.overlayRef.overlayElement.contains(clickTarget) && !this.connectedTo.contains(clickTarget)) {
						this.closePanel();
					}
				}
			});

		this.closingActionsSubscription = subscription;
	}
}
