import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	PLATFORM_ID,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { BreakpointService, ContentBaseComponent, EDRBreakpoint } from '@edr/shared';
import { NgClass, isPlatformBrowser } from '@angular/common';
import { debounceTime, delay, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EDRGroupColumns, EDRGroupItemWidthType, getAllBreakpointsColumnConfig, getFixedWidthColumnsCount } from '../../../helpers';
import { ComponentRefService } from '../../services';
import { CarouselButtonComponent } from '../carousel-button/carousel-button.component';

const MIN_COLUMNS = 1;
const DEFAULT_COLUMNS_CONFIG: EDRGroupColumns = { xxs: 1, xs: 2, sm: 3, md: 4, lg: 5, xl: 5, xxl: 6 };
const DEFAULT_ROTATE_INTERVAL_MS = 0;
const SCROLL_SNAP_MARGIN = 0.1;
/**
 * A carousel component that displays a list of items in a horizontal scrollable container.
 * Set autoRotateInterval to a number greater than 0 to enable auto rotation.
 *
 * id: pass the same unique id to the carousel as to its carousel-item children
 *
 * itemWidthType: Fixed
 * When itemWidthType it set to fixed, width of a carousel item will be determined by the width of the element that you stick in the carousel-item component
 * or use fixedItemWidth if its set,
 * Do make sure all items have the same width. The carousel items will be placed right next to eachother so give them some padding.
 * - dynamicColumnConfig is not applicable
 *
 * itemWidthType: Dynamic
 * When set to 'dynamic', the width of the items will be calculated based on the provided columns input property.
 * Within the carousel item you can choose to have your element's width set to a fixed width so the space around the items will grow/shrink or to 100% so the element itself will grow/shrink.
 * - fixedItemWidth is not applicable
 *
 * fixedItemWidth
 * Use this in combination with itemWidthType: 'fixed' to set width for each carousel items.
 *
 * dynamicColumnConfig
 * Use this in combination with itemWidthType: 'dynamic' to determine how many items should be displayed for each breakpoint.
 * You can also pass in { xxs: 1, md: 4 }, values for the other breakpoints will be extrapolated from the provided values.
 *
 * autoRotateInterval
 * Set to a number greater than 0 to enable auto rotation.
 */

@UntilDestroy()
@Component({
	selector: 'edr-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	standalone: true,
	imports: [CarouselButtonComponent, NgClass],
})
export class CarouselComponent extends ContentBaseComponent implements AfterViewInit, OnDestroy, OnChanges {
	@Input() public id = '';
	@Input() public itemWidthType: EDRGroupItemWidthType = 'fixed';
	@Input() public fixedItemWidth?: string;
	@Input() public enableNavigation = true;
	@Input() public autoRotateInterval = DEFAULT_ROTATE_INTERVAL_MS;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public rerenderOnChange: any;

	@ViewChild('carouselItemList', { read: ElementRef }) public carouselItemList: ElementRef<HTMLElement> | undefined;
	public currentPage = 0;
	public pages: void[] = new Array(0);
	private _dynamicColumnConfig: EDRGroupColumns = DEFAULT_COLUMNS_CONFIG;
	private intervalRef?: ReturnType<typeof setInterval>;
	private columns = MIN_COLUMNS;

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private cdr: ChangeDetectorRef,
		private breakpointService: BreakpointService,
		private componentRefService: ComponentRefService
	) {
		super();
	}

	public get dynamicColumnConfig(): EDRGroupColumns {
		return this._dynamicColumnConfig;
	}
	@Input() public set dynamicColumnConfig(value: Partial<EDRGroupColumns>) {
		this._dynamicColumnConfig = getAllBreakpointsColumnConfig(value);
	}

	public ngAfterViewInit(): void {
		if (!this.carouselItemList) {
			return;
		}

		this.onBreakpointChange();

		// Debounce native scrolling and update the selected nav dot once they settle
		fromEvent(this.carouselItemList.nativeElement, 'scroll')
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe(() => {
				this.updateNavigationLocation();
				this.cdr.markForCheck();
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ('rerenderOnChange' in changes) {
			// Using setTimeout because we need to make sure to call the initialise function in the next tick, after the layout-group items have been instantiated.
			setTimeout(() => {
				this.initialise(this.breakpointService.getCurrentBreakpoint());
			}, 1);
		}
	}

	public ngOnDestroy(): void {
		this.cancelAutoRotation();
	}

	public paginationClick(i: number, isDisabled: boolean): void {
		if (isDisabled) {
			return;
		}
		this.cancelAutoRotation();
		this.scrollToPage(i);
	}

	private getItemCount(): number {
		return this.componentRefService.getComponentRefs(this.id)?.length || 0;
	}

	private initialise(breakpoint: EDRBreakpoint): void {
		const itemCount = this.getItemCount();
		const columns =
			this.itemWidthType === 'dynamic'
				? (this.dynamicColumnConfig && this.dynamicColumnConfig[breakpoint]) || 1
				: getFixedWidthColumnsCount(itemCount, this.carouselItemList);
		this.updateScrollingData(columns, itemCount);
		this.componentRefService.getComponentRefs(this.id)?.forEach((item) => {
			item.columns = columns;
			item.itemWidthType = this.itemWidthType;
			item.fixedWidth = this.fixedItemWidth;
		});
		this.scrollToPage(0);
		this.cancelAutoRotation();
		this.setupAutorotation();
	}

	private onBreakpointChange(): void {
		this.breakpointService
			.getActiveBreakpoint()
			.pipe(delay(0), untilDestroyed(this))
			.subscribe((breakpoint) => {
				this.initialise(breakpoint);
			});
	}

	private updateScrollingData(columns: number, itemCount: number): void {
		if (!this.carouselItemList) {
			return;
		}
		this.columns = itemCount >= columns ? columns : itemCount;

		if (this.itemWidthType === 'dynamic') {
			this.pages = itemCount > columns ? new Array(Math.ceil(itemCount / columns)) : [];
			return;
		}

		this.pages = itemCount > this.columns ? new Array(Math.ceil(itemCount / Math.floor(this.columns < 1 ? 1 : this.columns))) : [];
	}

	private scrollToPage(pageNumber: number): void {
		if (!this.carouselItemList) {
			return;
		}
		const scrollWidth = this.carouselItemList.nativeElement.scrollWidth;
		const scrollPerElement = scrollWidth / this.getItemCount();

		/**
		 * It should only scroll as far as the width of the items that are currently completely visible,
		 * ignoring the element that is not completely visible.
		 * But it always calculates the scroll all the way from the left so we first need to calculate the scroll distance
		 * per page by multiplying by the number of elements that are completely visible by the scroll distance per element.
		 */
		const scrollPerPage = scrollPerElement * Math.floor(this.columns < 1 ? 1 : this.columns);
		this.currentPage = pageNumber;
		const scrollDistance = Math.floor(scrollPerPage * pageNumber);
		this.carouselItemList?.nativeElement.scrollTo({
			left: scrollDistance,
			behavior: 'smooth',
		});
		this.cdr.markForCheck();
	}

	private updateNavigationLocation(): void {
		if (!this.carouselItemList?.nativeElement) {
			return;
		}
		const scrollWidth = this.carouselItemList.nativeElement.scrollWidth;
		const scrollPerElement = scrollWidth / this.getItemCount();
		const scrollLeft = this.carouselItemList.nativeElement.scrollLeft;
		const scrollPerPage = scrollPerElement * Math.floor(this.columns < 1 ? 1 : this.columns);

		/**
		 * Because we have enabled scrollSnap (CSS) the actual place that is being scrolled to might differ slightly from the what we
		 * programatically scrolled to so we need to allow for some margin here. This is why we use Math.round.
		 *
		 * Except that math.round will not work for the last page when there aren't enough items to fill the whole page (so when you're showing 2 items per page for example, but the last page only has 1 item)
		 * To cater for this scenario we will assume that we scrolled to the last/next page when the user has scrolled more than 10% past the previous page.
		 */
		const pagesScrolled = scrollLeft / scrollPerPage;
		const fullPagesScrolled = Math.floor(pagesScrolled);
		const leftOverScrolled = pagesScrolled - fullPagesScrolled;

		this.currentPage =
			scrollLeft < scrollPerElement / 2 ? 0 : leftOverScrolled > SCROLL_SNAP_MARGIN ? Math.ceil(pagesScrolled) : Math.round(pagesScrolled);
	}

	private setupAutorotation(): void {
		if (isPlatformBrowser(this.platformId) && this.getItemCount() > 1 && this.autoRotateInterval > 1 && this.pages.length > 1 && !this.intervalRef) {
			this.intervalRef = setInterval(() => {
				let nextPage = this.currentPage + 1;
				nextPage = nextPage % this.pages.length;
				this.scrollToPage(nextPage);
			}, this.autoRotateInterval);
		}
	}

	private cancelAutoRotation(): void {
		if (this.intervalRef) {
			clearInterval(this.intervalRef);
			this.autoRotateInterval = 0;
			this.intervalRef = undefined;
		}
	}
}
