import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivityDetailsUomType } from '@edr/bff-api-models';

@Pipe({ name: 'amountDisplay', standalone: true })
export class AmountDisplayPipe implements PipeTransform {
	public transform(value: number | undefined, uom: ActivityDetailsUomType): string {
		if (!value) {
			return '-';
		}

		switch (uom) {
			case ActivityDetailsUomType.Litres:
				return `${value.toFixed(0)}L`;
			default:
				return formatCurrency(value, 'en-NZ', '$');
		}
	}
}
