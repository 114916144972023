import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ApiService, AppSettingsService, FlagKey, FlagService } from '../../../core';
import {
	BarcodeCardResponse,
	CardAvailabilityResponse,
	CardResponse,
	CreatePhysicalCardRequest,
	PhysicalCardResponse,
	ReplaceCardRequest,
	ReplaceCardResponse,
} from '@edr/bff-api-models';

export interface RewardsCardResponse {
	BarcodeCardResponse: BarcodeCardResponse | undefined;
	CardResponse: CardResponse | undefined;
}

@Injectable({ providedIn: 'root' })
export class RewardsCardService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService, private flagService: FlagService) {}

	public fetchRewardsCard(): Observable<RewardsCardResponse> {
		return this.flagService.isFlagEnabled(FlagKey.featureEdrSwapBarcode).pipe(
			switchMap((isEnabled) => {
				if (isEnabled) {
					return this.appSettingsService
						.getEndpoint('rewardsCard')
						.pipe(switchMap((endPoint) => this.apiService.get<BarcodeCardResponse>(endPoint)))
						.pipe(switchMap((val) => of({ BarcodeCardResponse: val, CardResponse: undefined })));
				}
				return this.appSettingsService
					.getEndpoint('rewardsCardCmm')
					.pipe(switchMap((endPoint) => this.apiService.get<CardResponse>(endPoint)))
					.pipe(switchMap((val) => of({ CardResponse: val, BarcodeCardResponse: undefined })));
			})
		);
	}

	public requestPhysicalCard(body: CreatePhysicalCardRequest): Observable<PhysicalCardResponse> {
		return this.appSettingsService
			.getEndpoint('requestPhysicalCard')
			.pipe(switchMap((endPoint) => this.apiService.post<CreatePhysicalCardRequest, PhysicalCardResponse>(endPoint, body)));
	}

	public requestReplacementCard(body: ReplaceCardRequest): Observable<ReplaceCardResponse> {
		return this.appSettingsService
			.getEndpoint('requestReplacementCard')
			.pipe(switchMap((endPoint) => this.apiService.post<ReplaceCardRequest, ReplaceCardResponse>(endPoint, body)));
	}

	public getPhysicalCardRequestStatus(): Observable<CardAvailabilityResponse> {
		return this.appSettingsService
			.getEndpoint('requestPhysicalCard')
			.pipe(switchMap((endPoint) => this.apiService.get<CardAvailabilityResponse>(`${endPoint}/status`)));
	}
}
