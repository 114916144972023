import { CdkTableModule } from '@angular/cdk/table';

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'edr-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CdkTableModule],
})
export class TableComponent implements OnChanges {
	@Input() public dataSource: unknown[] = [];
	@Input() public columnList: { name: string; displayName: string }[] = [];
	@HostBinding('attr.full-width') @Input() public fullWidth = true;
	public columnNameList = [];
	public dataSource$ = new BehaviorSubject([]);

	public ngOnChanges(changes: SimpleChanges): void {
		if ('dataSource' in changes) {
			this.dataSource$.next(changes['dataSource'].currentValue);
		}

		if ('columnList' in changes && changes['columnList'].currentValue) {
			this.columnNameList = changes['columnList'].currentValue.map((column: { name: string; displayName: string }) => column.name);
		}
	}
}
