@if ({ minTextWidthBreakpointReached: minTextWidthBreakpointReached$ | async }; as data) {
<div
	class="pointsDial"
	role="progressbar"
	aria-label="Points Balance"
	[attr.aria-valuenow]="pointsBalance"
	aria-valuemin="0"
	[attr.aria-valuemax]="totalPointsRequired"
	#progressDial
>
	<svg class="circleChart" viewBox="0 0 33.95 34.8" preserveAspectRatio="xMidYMin slice" xmlns="http://www.w3.org/2000/svg">
		<circle
			class="circleChart-background"
			stroke="#efefef"
			[attr.stroke-dasharray]="outerCircleDashedStroke"
			stroke-linecap="round"
			fill="none"
			cx="16.7"
			cy="17.75"
			r="15.91549431"
		/>
		@if (noIntersectionObserver() || (inView$ | async)) {
		<circle
			class="circleChart-circle"
			[attr.stroke-dasharray]="dashedStrokeValues"
			stroke-linecap="round"
			fill="none"
			cx="16.7"
			cy="17.75"
			r="15.91549431"
		/>
		} @if (!isError) {
		<g class="circleChart-info" edr-typography typographyAs="heading--1">
			<text
				data-testId="balanceCount"
				class="circleChart-percent"
				x="16.91549431"
				y="15.5"
				alignment-baseline="central"
				text-anchor="middle"
				font-size="7"
			>
				{{ animatingCount$ | async | number }}
			</text>
			<text class="circleChart-subline" x="16.91549431" y="20.5" alignment-baseline="central" text-anchor="middle" font-size="2.75">
				{{ pointsBalance | i18nPlural : { '=1': 'point', other: 'points' } }}
			</text>
			@if (!data.minTextWidthBreakpointReached) {
			<ng-container>
				<text class="circleChart-remaining" x="16.91549431" y="31.5" alignment-baseline="central" text-anchor="middle" font-size="1.75" dy="0">
					<tspan data-testId="pointRemaining" text-anchor="middle" x="50%">
						{{ pointsRemaining | number }} {{ pointsRemaining | i18nPlural : { '=1': 'point', other: 'points' } }} until your
					</tspan>
					<tspan text-anchor="middle" x="50%" dy="1.25em">next reward</tspan>
				</text>
				<ng-container *ngTemplateOutlet="questionMark; context: { size: 1.4, helpToolTip }"></ng-container>
			</ng-container>
			}
		</g>
		} @if (isError) {
		<g class="circleChart-error" edr-typography typographyAs="heading--1">
			<ng-container *ngTemplateOutlet="alertWarningMark; context: { size: 6 }"></ng-container>
			<ng-container>
				<text class="circleChart-error" x="16.91549431" y="31.5" alignment-baseline="central" text-anchor="middle" font-size="1.75" dy="0">
					<tspan text-anchor="middle" x="50%">There was an error</tspan>
					<tspan text-anchor="middle" x="50%" dy="1.25em">connecting to the server</tspan>
				</text>
			</ng-container>
		</g>
		}
	</svg>
	@if (data.minTextWidthBreakpointReached && !isError) {
	<div class="info-text">
		<p edr-typography typographyAs="heading--5">
			{{ pointsRemaining | number }} {{ pointsRemaining | i18nPlural : { '=1': 'point', other: 'points' } }} until your
			<ng-container *ngTemplateOutlet="questionMark; context: { size: 17, helpToolTip }"></ng-container><br />
			next reward
		</p>
	</div>
	}
</div>
}

<ng-template #questionMark let-size="size" let-helpToolTip="helpToolTip">
	@if (helpToolTip) {
	<svg
		class="help-icon"
		x="25.7"
		y="29.75"
		[attr.width]="size"
		[attr.height]="size"
		viewBox="0 0 32 32"
		fill="none"
		[edrTooltip]="helpToolTip.message"
		[tooltipAlignment]="helpToolTip.alignment"
		[tooltipTrigger]="helpToolTip.trigger"
		[tooltipPosition]="helpToolTip.position"
		[tooltipCtaText]="helpToolTip.ctaText"
		[tooltipCtaLink]="helpToolTip.ctaLink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 0C7.16 0 0 7.16 0 16C0 24.84 7.16 32 16 32C24.84 32 32 24.84 32 16C32 7.16 24.84 0 16 0ZM17.84 24.36C17.41 24.79 16.9 25 16.31 25C15.7 25 15.18 24.79 14.75 24.36C14.32 23.93 14.11 23.42 14.11 22.83C14.11 22.22 14.32 21.7 14.75 21.27C15.18 20.84 15.7 20.63 16.31 20.63C16.9 20.63 17.41 20.84 17.84 21.27C18.27 21.7 18.48 22.22 18.48 22.83C18.49 23.42 18.27 23.93 17.84 24.36ZM20.91 13.52C20.63 14.15 20.07 14.97 19.23 15.98C18.65 16.66 18.28 17.16 18.12 17.49C17.96 17.82 17.87 18.12 17.87 18.4V18.57C17.87 18.72 17.82 18.84 17.72 18.93C17.62 19.02 17.5 19.07 17.37 19.07H15.19C15.06 19.07 14.94 19.02 14.84 18.92C14.74 18.82 14.69 18.7 14.69 18.55V18C14.69 17.55 14.8 17.1 15.02 16.63C15.24 16.16 15.7 15.51 16.39 14.66C17.02 13.92 17.42 13.35 17.61 12.97C17.8 12.58 17.89 12.21 17.89 11.84C17.89 11.3 17.69 10.85 17.3 10.49C16.9 10.14 16.39 9.96 15.77 9.96C15.29 9.96 14.84 10.09 14.42 10.34C14 10.6 13.6 10.98 13.22 11.49C13.09 11.67 12.93 11.77 12.74 11.8C12.55 11.82 12.36 11.76 12.16 11.61L11.02 10.82C10.79 10.66 10.67 10.47 10.65 10.26C10.63 10.05 10.71 9.82 10.87 9.56C11.38 8.77 12.09 8.14 12.98 7.68C13.88 7.22 14.89 6.99 16.01 6.99C17.59 6.99 18.87 7.43 19.84 8.3C20.81 9.17 21.3 10.26 21.3 11.56C21.33 12.23 21.19 12.88 20.91 13.52Z"
			fill="currentColor"
		/>
	</svg>
	}
</ng-template>

<ng-template #alertWarningMark let-size="size">
	<svg
		class="alert-icon"
		x="13.91549431"
		y="12.5"
		[attr.width]="size"
		[attr.height]="size"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.472 32H3.622c-.86 0-1.66-.27-2.26-.77a3.746 3.746 0 0 1-.89-4.708l12.44-24.643C13.552.71 14.732 0 16.002 0c1.27 0 2.44.71 3.06 1.85l12.37 24.502c.67 1.08.75 2.389.22 3.609-.53 1.24-1.78 2.039-3.18 2.039ZM16.012 2.01c-.55 0-1.04.3-1.32.81L2.242 27.47c-.43.77-.25 1.69.4 2.23.24.2.58.31.98.31h24.85c.6 0 1.13-.33 1.35-.83.27-.61.24-1.26-.09-1.77-.02-.03-.04-.06-.05-.09L17.302 2.81c-.25-.49-.75-.8-1.29-.8Z"
			fill="#9DA3A7"
		/>
		<path
			d="M16.012 21.003c-1 0-1-1-1-1l-1-8.996c0-1.1.9-2 2-2s2 .9 2 2l-1 8.997s0 1-1 1ZM16.012 27.002a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
			fill="#9DA3A7"
		/>
	</svg>
</ng-template>
