import { mapDeviceBreakpointToActualBreakpoints } from '@edr/shared';
import { DeviceStyleConfig, StyleConfig, StyleType } from '../../models';

export function mapToApplyStylingConfig(styleType: StyleType, key: string, valuePerBreakpoint: DeviceStyleConfig): StyleConfig[] {
	return Object.entries(valuePerBreakpoint).map((entry) => ({
		styleType,
		key,
		value: entry[1],
		breakpoints: mapDeviceBreakpointToActualBreakpoints(entry[0]),
	}));
}
