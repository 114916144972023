import { DynamicComponent } from '@edr/dc';
import { mapResponseType } from '../dynamic-content.factory';
import { createId } from './mapper-helpers';
import { WelcomeTutorialResponse } from '@edr/bff-api-models';
import { notUndefined } from '@edr/shared';

export function mapWelcomeTutorial(response: WelcomeTutorialResponse): DynamicComponent {
	const id = createId(response.contentTypeId);
	const children = [response.content ? mapResponseType(response.content, response, id) : undefined].filter(notUndefined);

	return {
		id,
		type: 'welcomeTutorial',
		props: {
			id,
			entryId: response.entryId,
			content: children,
		},
	};
}
