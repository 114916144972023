import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { EDRGroupItemWidthType } from '../../../helpers';
import { ComponentRefService } from '../../services';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-layout-group-item, [edr-layout-group-item]',
	templateUrl: './layout-group-item.component.html',
	styleUrls: ['./layout-group-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class LayoutGroupItemComponent extends ContentBaseComponent implements OnInit, OnDestroy {
	@Input() public id = '';
	@HostBinding('attr.data-item-width-type') @Input() public itemWidthType?: EDRGroupItemWidthType;
	@HostBinding('attr.data-columns') @Input() public columns?: number;

	private _fixedWidth?: string;

	constructor(private componentRefservice: ComponentRefService) {
		super();
	}

	@HostBinding('style.width')
	public get Width(): string | undefined {
		return this.itemWidthType === 'fixed' ? this._fixedWidth : undefined;
	}
	@Input() public set fixedWidth(value: string | undefined) {
		this._fixedWidth = value;
	}

	public ngOnInit(): void {
		this.componentRefservice.register(this.id, this);
	}

	public ngOnDestroy(): void {
		this.componentRefservice.deregister(this.id, this);
	}
}
