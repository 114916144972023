import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CircleComponent } from '../circle/circle.component';
import { LinkComponent } from '../link/link.component';
import { RouterLink } from '@angular/router';
import { ContentBaseComponent, ImageFormat, ImageResizeFit, ImageDeviceTransformConfig, ImageTransformConfig } from '@edr/shared';
import { ImageComponent } from '../image/image.component';

const DEFAULT_IMAGE_TRANSFORM_CONFIG: ImageTransformConfig = {
	format: ImageFormat.Avif,
	width: 38,
	height: 38,
	resizeFit: ImageResizeFit.Fill,
	quality: 100,
};

@Component({
	selector: 'edr-partner',
	templateUrl: './partner.component.html',
	styleUrls: ['./partner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CircleComponent, LinkComponent, RouterLink, ImageComponent],
})
export class PartnerComponent extends ContentBaseComponent {
	@Input({ required: true }) public partnerLogo = '';
	@Input({ required: true }) public partnerLink = '';
	@Input({ required: true }) public partnerName = '';

	public imageDeviceTransformConfig: ImageDeviceTransformConfig = {
		desktop: {
			...DEFAULT_IMAGE_TRANSFORM_CONFIG,
		},
		tablet: {
			...DEFAULT_IMAGE_TRANSFORM_CONFIG,
		},
		mobile: {
			...DEFAULT_IMAGE_TRANSFORM_CONFIG,
		},
	};
}
