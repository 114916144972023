import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BreakpointService, CustomWindow, EDRBreakpoint, makeKebab } from '@edr/shared';
import { WINDOW } from '@ng-web-apis/common';
import { StyleConfig } from '../../models/style-config.model';

/**
 * Can be used to dynamically add type checked color or spacing to an element.
 *
 * Especially useful if you need to add styling based on input properties.
 * This can save you adding a lot of scss to your component.
 * CSS for every possible option.
 * Can be used on any element.
 * You can specify styles for specific breakpoints
 *
 * Example usage
 * <div [edrApplyStyles]="[
 * 	{ styleType: 'color', key: 'color', value: color },
 * 	{ styleType: 'spacing', key: 'width', value: '20', breakpoints: ['xxs', 'xs'] },
 * 	{ styleType: 'spacing', key: 'width', value: '40', breakpoints: ['sm', 'md'] },
 *	{ styleType: 'string', key: 'display', value: 'flex' },
 * ]">
 */

@Directive({
	selector: '[edrApplyStyles]',
	standalone: true,
})
export class ApplyStylesDirective implements OnChanges, OnInit {
	@Input() public edrApplyStyles: StyleConfig[] = [];
	private originalStyles = new Map<string, string>();

	constructor(private el: ElementRef, private breakpointService: BreakpointService, @Inject(WINDOW) private window: CustomWindow) {}

	public ngOnChanges(changes: SimpleChanges): void {
		const newStyles = changes['edrApplyStyles'].currentValue as StyleConfig[];
		if (!newStyles.length || changes['edrApplyStyles'].isFirstChange()) {
			return;
		}

		this.applyStyleConfig(this.breakpointService.getCurrentBreakpoint());

		// When the provided styles config changes some properties may have been removed/left out in this next iteration so we need to reset those to their original style
		const newStyleConfigKeys = newStyles.map((style) => style.key);
		const styleKeysToReset = [...this.originalStyles.keys()].filter((key) => !newStyleConfigKeys.includes(key));
		this.resetStyles(styleKeysToReset);
	}

	public ngOnInit(): void {
		this.updateStylesOnBreakpointChange();
	}

	private updateStylesOnBreakpointChange(): void {
		this.breakpointService.getActiveBreakpoint().subscribe((breakpoint) => {
			this.applyStyleConfig(breakpoint);
		});
	}

	private applyStyleConfig(currentBreakpoint: EDRBreakpoint): void {
		this.edrApplyStyles.sort(this.sortActiveBreakRulesLast(currentBreakpoint)).forEach((style) => this.applyOrResetStyle(style, currentBreakpoint));
	}

	private applyOrResetStyle(style: StyleConfig, currentBreakpoint: EDRBreakpoint): void {
		const styleValue = style.styleType !== 'string' ? `var(--${style.styleType}-${style.value})` : style.value;

		if (!style.breakpoints?.length || style.breakpoints.includes(currentBreakpoint)) {
			this.saveOriginalStyle(style.key);
			this.applyStyle(style.key, styleValue);
			return;
		}

		this.restoreOriginalStyle(style.key);
	}

	private applyStyle(key: string, value: string): void {
		this.el.nativeElement.style[key] = value;
	}

	private saveOriginalStyle(key: string): void {
		if (this.originalStyles.has(key)) {
			return;
		}
		this.originalStyles.set(key, this.getCurrentStyle(key));
	}

	private restoreOriginalStyle(key: string): void {
		const originalStyleValue = this.originalStyles.get(key);
		if (!originalStyleValue) {
			return;
		}
		this.applyStyle(key, originalStyleValue);
	}

	private resetStyles(keys: string[]): void {
		keys.forEach((key) => this.restoreOriginalStyle(key));
	}

	private getCurrentStyle(key: string): string {
		return this.window.getComputedStyle(this.el.nativeElement).getPropertyValue(makeKebab(key));
	}

	private sortActiveBreakRulesLast(currentBreakpoint: EDRBreakpoint): (a: StyleConfig, b: StyleConfig) => number {
		return (a: StyleConfig, b: StyleConfig) =>
			a.breakpoints?.includes(currentBreakpoint) && b.breakpoints?.includes(currentBreakpoint)
				? 0
				: a.breakpoints?.includes(currentBreakpoint)
				? 1
				: -1;
	}
}
