<form [formGroup]="reasonForm" (ngSubmit)="handleSubmit()" id="reasonForm">
	<edr-modal title="Order a physical card" (closeModal)="closeModal()">
		<div modal-content>
			<h3 edr-typography typographyAs="heading--4">Select a reason</h3>
			<edr-radio-group formControlName="reason" [options]="reasonOptions"></edr-radio-group>
		</div>

		<edr-button modal-footer-secondary buttonStyle="secondary" [reversed]="true" buttonSize="medium" (click)="closeModal()">Cancel</edr-button>
		<button edr-button modal-footer-primary form="reasonForm" type="submit" buttonSize="medium">Next</button>
	</edr-modal>
</form>
