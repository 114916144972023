import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '../../../../core/services';
import { DialogRef } from '@angular/cdk/dialog';
import {
	ButtonComponent,
	HeadingComponent,
	IconComponent,
	ModalComponent,
	RoundelComponent,
	TagComponent,
	TypographyComponent,
	LinkComponent,
	PartnerProductBoost,
} from '@edr/styleguide';
import { Observable, map, of } from 'rxjs';
import { BoostsFacade } from '../../+state';
import { DatePipe, AsyncPipe } from '@angular/common';
import { BoostProps, mapBoostCardProps } from '../../utils/boosts.factory';
import { PartnersFacade } from '../../../partners/+state';
import { PartnerPropResponse } from '@edr/bff-api-models';
import { LinkShortCodePipe } from '@edr/shared';

/**
 * Please be aware that this modal is used for both Boost offers as well as Everyday offers
 */
@Component({
	selector: 'edr-app-boost-modal',
	templateUrl: './boost-modal.component.html',
	styleUrls: ['./boost-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ButtonComponent,
		HeadingComponent,
		IconComponent,
		ModalComponent,
		TagComponent,
		RoundelComponent,
		TypographyComponent,
		AsyncPipe,
		DatePipe,
		LinkComponent,
		LinkShortCodePipe,
	],
})
export class BoostModalComponent {
	public isEverydayOffer: boolean;
	public boostOffer$: Observable<BoostProps>;
	public isProductImage = false;
	public partner$: Observable<PartnerPropResponse | undefined> = of(undefined);

	constructor(
		private modalService: ModalService,
		private dialogRef: DialogRef,
		private boostsFacade: BoostsFacade,
		private partnersFacade: PartnersFacade
	) {
		this.boostOffer$ = this.dialogRef.config.data.isEverydayOffer
			? of(this.dialogRef.config.data.boostProps)
			: this.boostsFacade.getBoostOfferById(this.dialogRef.config.data.boostProps.partnerProductBoost.id).pipe(map(mapBoostCardProps));
		this.isProductImage = this.dialogRef.config.data.isProductImage;
		this.isEverydayOffer = this.dialogRef.config.data.isEverydayOffer;

		this.boostOffer$.subscribe((offer) => {
			if (offer?.partnerProductBoost.partnerId) {
				this.partner$ = this.partnersFacade.getPartnerById(offer.partnerProductBoost.partnerId);
			}
		});
	}

	public closeModal(): void {
		this.modalService.close();
	}

	public activateBoost(partnerProductBoost: PartnerProductBoost): void {
		if (partnerProductBoost.isBoosted || partnerProductBoost.isBoosting) {
			return;
		}
		this.boostsFacade.activateBoostOffer(partnerProductBoost.id);
	}
}
