import { ObjectValues } from '../helpers';

export const ImageResizeFit = {
	Pad: 'pad',
	Fill: 'fill',
	Scale: 'scale',
	Crop: 'crop',
	Thumb: 'thumb',
} as const;

export type ImageResizeFit = ObjectValues<typeof ImageResizeFit>;
