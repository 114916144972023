import { ChangeDetectionStrategy, Component, computed, Input, signal } from '@angular/core';

@Component({
	selector: 'edr-progress-indicator',
	templateUrl: './progress-indicator.component.html',
	styleUrls: ['./progress-indicator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class ProgressIndicatorComponent {
	public progress = computed(() => `${this._percentProgress()}%`);
	private _percentProgress = signal(0);

	@Input()
	public set percentProgress(value: number) {
		this._percentProgress.set(value);
	}
}
