import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { OptionSelectedEvent } from './option-selected-event.model';

/**
 * Single option to be used inside of `<edr-autocomplete>` or `<edr-select>` components
 */
@Component({
	selector: 'edr-option',
	templateUrl: './option.component.html',
	styleUrls: ['./option.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class OptionComponent {
	@HostBinding('class.active')
	@HostBinding('attr.aria-active')
	@Input()
	public active = false;

	@HostBinding('class.selected')
	@HostBinding('attr.aria-selected')
	@Input()
	public selected = false;

	@HostBinding('attr.disabled')
	@HostBinding('attr.aria-disabled')
	@Input()
	public disabled = false;

	@HostBinding('attr.role') public role = 'option';

	@Output() public readonly selectionChange = new EventEmitter<OptionSelectedEvent>();

	@ViewChild('text', { static: true }) private textElementRef: ElementRef<HTMLElement> | undefined;

	private optionValue: unknown | undefined;

	constructor(private hostElementRef: ElementRef<HTMLElement>) {}

	@Input() public set value(val: unknown | undefined) {
		this.optionValue = val;
	}
	// eslint-disable-next-line @typescript-eslint/member-ordering
	public get value(): unknown | undefined {
		return this.optionValue ?? this.getOptionText();
	}

	@HostListener('click')
	public handleClick(): void {
		if (!this.disabled) {
			this.selectionChange.emit({
				text: this.getOptionText(),
				value: this.value,
				userInitiated: true,
			});
		}
	}

	public getNativeElement(): HTMLElement {
		return this.hostElementRef.nativeElement;
	}

	public getOptionText(): string {
		const optionText = this.textElementRef?.nativeElement?.textContent ?? '';
		return optionText.trim();
	}
}
