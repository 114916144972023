import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { WINDOW } from '@ng-web-apis/common';
import { AuthService } from '../../services';
import { of } from 'rxjs';
import * as MemberActions from '../../member/+state/profile';
import { CustomWindow } from '@edr/shared';
import { AuthFacade } from './auth.facade';

@Injectable()
export class AuthEffects {
	public onAuthInit$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.authInitialise),
			switchMap(() =>
				this.authService.fetchIsAuthenticated().pipe(
					map((payload) => AuthActions.fetchAuthStateComplete({ isAuthenticated: payload.isAuthenticated, scvId: payload.scvId })),
					catchError(() => of(AuthActions.fetchAuthStateFailed()))
				)
			)
		)
	);

	public onAuthLogin$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.userLogin),
				switchMap((action) =>
					this.authService.loginUrl(this.window.location.href, action.noPrompt, action.deleteSignedInParam).pipe(
						tap((loginUrl) => {
							this.window.location.href = loginUrl;
						})
					)
				)
			),
		{ dispatch: false }
	);

	public onLogOut$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.userLogOut),
			/**
			 * Tech debt: this is in the wrong order
			 * and you should not subscribe in the tap.
			 */
			map(() => AuthActions.userLoggedOut()),
			tap(() => {
				this.authService.startLogoutFlow(this.window.location.origin).subscribe();
			})
		)
	);

	public onAuthInitialiseComplete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.fetchAuthStateComplete),
			switchMap(() =>
				this.authFacade.isLoggedIn$.pipe(map((isLoggedIn) => (isLoggedIn ? MemberActions.loadProfile() : AuthActions.authInitialiseComplete())))
			)
		)
	);

	constructor(
		private actions$: Actions,
		private authService: AuthService,
		@Inject(WINDOW) private window: CustomWindow,
		private authFacade: AuthFacade
	) {}
}
