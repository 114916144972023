<edr-modal [disableClose]="false" [title]="(title | edrShortcode | async) ?? ''" [useCustomFooter]="true" (closeModal)="close()">
	<div modal-content>
		<ng-content></ng-content>
	</div>
	<div modal-footer>
		<div class="multistep__footer">
			<edr-progress-indicator class="multistep__progress-indicator" [percentProgress]="progressIndicator()"></edr-progress-indicator>
			@if (activeBreakpoint$ | async; as activeBreakpoint) {
			<div class="multistep__cta">
				<div class="multistep__cta-left">
					@if (backActionEnabled()) {
					<edr-button
						buttonStyle="tertiary"
						[reversed]="true"
						buttonSize="medium"
						[iconLeft]="backActionIconRight"
						[showLeftIcon]="true"
						(click)="backActionHandler()"
						data-testid="back-button"
						>Back</edr-button
					>
					}
				</div>
				<div class="multistep__cta-right">
					@if (skipActionEnabled()) {
					<edr-button buttonStyle="secondary" [reversed]="true" buttonSize="medium" (click)="skipActionHandler()" data-testid="skip-button">
						<span class="content secondary">Skip</span>
					</edr-button>
					} @if (nextActionEnabled()) {
					<edr-button
						buttonStyle="primary"
						[reversed]="false"
						buttonSize="medium"
						[iconRight]="nextActionIconRight"
						[showRightIcon]="true"
						(click)="nextActionHandler()"
						data-testid="next-button"
					>
						<span class="content primary">Next</span>
					</edr-button>
					} @if (completeActionEnabled()) {
					<edr-button buttonStyle="primary" [reversed]="false" buttonSize="medium" (click)="completeActionHandler()" data-testid="complete-button">
						<span class="content primary">{{ !completeActionTitle ? 'Get started' : completeActionTitle }}</span>
					</edr-button>
					}
				</div>
			</div>
			}
		</div>
	</div>
</edr-modal>
