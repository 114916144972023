import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { userLogin } from '../auth';
import { Store } from '@ngrx/store';
import { REQUEST } from '../../../express.tokens';
import { Request } from 'express';
import { isPlatformServer } from '@angular/common';
import { XRequestedWith } from '../services/api/api.service';
import { AppSettingsService } from '../services';

/**
 * Should be rewritten as a function and provided in app.config.ts within provideHttpClient(withInterCeptors())
 */
@Injectable()
export class CredentialInterceptor implements HttpInterceptor {
	private forwardHeaders = ['cookie'];

	constructor(
		private store: Store,
		private appSettingsService: AppSettingsService,
		@Inject(PLATFORM_ID) private platformId: object,
		@Optional() @Inject(REQUEST) private httpRequest: Request
	) {}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (['PUT', 'POST', 'GET', 'PATCH'].includes(request.method)) {
			request = request.clone({
				withCredentials: true,
			});
		}

		// Add CDXQAAccess to header if CDXAccessKey is present in App Settings
		if (this.appSettingsService.settingsSnapshot?.cdxQAAccessKey) {
			request = request.clone({
				headers: request.headers.set('CDXQAAccess', this.appSettingsService.settingsSnapshot.cdxQAAccessKey),
			});
		}

		if (isPlatformServer(this.platformId) && this.httpRequest) {
			// If optional request is provided, we are server side
			// set any forward headers on outgoing request to match original inbound request
			let headers = request.headers;
			this.forwardHeaders.forEach((h) => (headers = headers.set(h, this.httpRequest.get(h) ?? '')));

			// always set X-Requested-With to a specific SSR header
			headers = headers.set('X-Requested-With', `${XRequestedWith}.SSR`);

			request = request.clone({ headers });
		}

		return next.handle(request).pipe(catchError((err) => this.handleAuthError(err)));
	}

	private handleAuthError(err: HttpErrorResponse): Observable<HttpEvent<unknown>> {
		if (err.status === 401 || err.status === 403) {
			this.store.dispatch(userLogin({}));
			return EMPTY;
		}
		return throwError(() => err);
	}
}
