import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import {
	AccordionComponent,
	AccordionItemCardComponent,
	ButtonComponent,
	CalendarComponent,
	CardComponent,
	CtaProps,
	DeviceStyleConfig,
	HeadingComponent,
	IconProps,
	SectionComponent,
	SpinnerComponent,
} from '@edr/styleguide';
import { MemberFacade } from '../../../member';
import { Observable, filter } from 'rxjs';
import { AccordionItemProps, CardComponentProps, rewardsChoiceComponentContent } from '../rewards-choice.component.content';
import { DateTime } from 'luxon';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ModalService } from '@edr-core';
import {
	RewardsChoiceConfirmationModalComponent,
	RewardsChoiceConfirmationModalData,
} from '../../rewards-choice-confirmation-modal/rewards-choice-confirmation-modal.component';
import { ContentfulToHtmlPipe } from '@edr/contentful';
import { ContentBaseComponent } from '@edr/shared';
import { RedemptionPreference } from '@edr/bff-api-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'edr-app-save-for-occasion',
	templateUrl: './save-for-occasion.component.html',
	styleUrls: ['./save-for-occasion.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AccordionComponent,
		AccordionItemCardComponent,
		CardComponent,
		ButtonComponent,
		SectionComponent,
		CalendarComponent,
		AsyncPipe,
		HeadingComponent,
		SpinnerComponent,
		ContentfulToHtmlPipe,
		NgTemplateOutlet,
	],
})
export class SaveForOccasionComponent extends ContentBaseComponent implements OnInit {
	public content = rewardsChoiceComponentContent;

	@Input() public isLoading = false;
	@Input() public isSelected = false;
	@Input() public accordionItem: AccordionItemProps;
	@Input() public howItWorksCards: CardComponentProps[];
	@Input() public chooseCta: CtaProps;
	@Input() public maxMonthsInFuture: number;
	@Input() public selectedHeaderDescription: string;

	public icon: IconProps | undefined;
	public saveForOccasionDate$: Observable<Date | string | undefined>;
	public minDate: Date;
	public maxDate: Date;
	public hasDateChanged = false;
	public selectedDate: Date | string | undefined;
	public description = signal('');

	public howItWorksTitleSpace: DeviceStyleConfig = { desktop: '6', tablet: '4', mobile: '4' };
	public howItWorksCardSpace: DeviceStyleConfig = { desktop: '6', tablet: '4', mobile: '4' };

	constructor(
		private memberFacade: MemberFacade,
		private modalService: ModalService
	) {
		super();
		this.saveForOccasionDate$ = this.memberFacade.saveForOccasionDate$;
		this.minDate = DateTime.local().plus({ days: 1 }).toJSDate();
		this.maxDate = DateTime.local().plus({ months: this.content.saveForOccasion.maxMonthsInFuture }).toJSDate();

		this.accordionItem = this.content.saveForOccasion.accordionItem;
		this.howItWorksCards = this.content.saveForOccasion.howItWorksCards;
		this.chooseCta = this.content.saveForOccasion.chooseCta;
		this.chooseCta.text = this.content.saveForOccasion.chooseCta.text;
		this.chooseCta.style = this.content.saveForOccasion.chooseCta.style;
		this.maxMonthsInFuture = this.content.saveForOccasion.maxMonthsInFuture;
		this.selectedHeaderDescription = this.content.saveForOccasion.selectedHeaderDescription;
		this.description = signal(this.content.saveForOccasion.accordionItem.description ?? '');
		this.icon = this.content.saveForOccasion.accordionItem.icon;
	}
	public ngOnInit(): void {
		this.accordionItem = this.accordionItem ?? this.content.saveForOccasion.accordionItem;
		this.saveForOccasionDate$
			.pipe(
				untilDestroyed(this),
				filter((x) => !!x)
			)
			.subscribe((dateValue) => {
				this.selectedDate = dateValue as Date;
				let description = this.accordionItem.description ?? '';
				if (this.isSelected) {
					const date = DateTime.fromJSDate(new Date(this.selectedDate)).toFormat('dd MMM yyyy');
					description = this.selectedHeaderDescription.replace('[[saveForOccasionDate]]', `${date}`);
				}
				this.description.set(description);
			});
	}

	public onDateSelected(selectedDate: Date): void {
		const beforeDate = DateTime.fromJSDate(new Date(this.selectedDate!)).toFormat('dd MMM yyyy');
		const newDate = DateTime.fromJSDate(new Date(selectedDate!)).toFormat('dd MMM yyyy');
		this.selectedDate = selectedDate;
		this.hasDateChanged = beforeDate !== newDate;
	}

	public onSelectSaveForOccasion(): void {
		const iconName = this.accordionItem?.icon?.name ?? 'rewards-dollar-sign-with-piggy';
		const modalData: RewardsChoiceConfirmationModalData = {
			redemptionPreference: RedemptionPreference.SaveForOccasion,
			iconName,
			saveForOccasionDate: this.selectedDate ? new Date(this.selectedDate) : undefined,
			isCurrentPreference: this.isSelected,
		};

		this.modalService.open({
			ref: RewardsChoiceConfirmationModalComponent,
			id: 'rewards-choice-confirmation-modal',
			config: {
				data: { modalData },
			},
		});
	}
}
