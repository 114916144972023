import { createReducer, on, Action } from '@ngrx/store';

import * as PartnersActions from './partners.actions';
import { PartnerPropResponse } from '@edr/bff-api-models';

export const PARTNERS_STATE_KEY = 'partners';

export interface PartnersState {
	isLoading: boolean;
	hasError: boolean;
	value: PartnerPropResponse[] | undefined;
}

export const initialPartnersState: PartnersState = {
	isLoading: false,
	hasError: false,
	value: undefined,
};

const reducer = createReducer(
	initialPartnersState,
	on(PartnersActions.loadPartners, (state): PartnersState => ({ ...state, hasError: false, isLoading: true })),
	on(PartnersActions.loadPartnersSuccess, (state, { partners }): PartnersState => ({ ...state, value: partners, isLoading: false })),
	on(PartnersActions.loadPartnersFailure, (state): PartnersState => ({ ...state, hasError: true, isLoading: false }))
);

export function partnersReducer(state: PartnersState | undefined, action: Action): PartnersState {
	return reducer(state, action);
}
