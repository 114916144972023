import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { EDRColor, EDRIcon } from '../../types';
import { IconComponent } from '../icon/icon.component';

import { TypographyComponent } from '../typography/typography.component';

/**
 * This component is flexible in its usage.
 * You can use it with a simple title and message, or you can use it with a custom header and footer.
 * You can also pass data to the modal.
 *
 * You can open a modal by passing a ComponentType or a TemplateRef (and optionally some config) to the ModalService.openDialog() method.
 *
 * For real world examples, see the modal.component.stories.ts file.
 *
 * How to build your own modal:
 *
 * 1. Simple:
 * <edr-modal title="My modal title">
 *  <p modalContent>My Content</p>
 *  <button type="button" modal-footer-primary>Save</button>
 *  <button type="button" modal-footer-secondary>Cancel</button>
 * </edr-modal>
 *
 * 2. With custom header and footer:
 * <edr-modal [disableClose]="false" (closeModal)="customCloseHandler()">
 *  <ng-container modal-header>
 *    <h12>My Custom title</h12>
 *    <button type="button" (click)="closeThisModal()">Close</button>
 *  </ng-container>
 *  <p modal-content>My Content</p>
 *  <div modal-footer>
 *   Totally custom footer
 *  </div>
 * </edr-modal>
 */
@Component({
	selector: 'edr-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	standalone: true,
	imports: [IconComponent, TypographyComponent],
})
export class ModalComponent {
	@Input() public icon?: EDRIcon;
	@Input() public iconColor?: EDRColor;
	@Input() public title?: string;
	@Input() public disableClose = false;
	@Input() public useCustomHeader = false;
	@HostBinding('attr.use-custom-footer') @Input() public useCustomFooter = false;
	@Output() public closeModal = new EventEmitter<void>();

	public close(): void {
		if (this.disableClose) {
			return;
		}
		this.closeModal.emit();
	}
}
