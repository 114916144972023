import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AnimationType } from '../../enums';
import { SVGIcon } from '../svg-definitions/SVGIcon';
import { EDRColor, EDRIcon, EDRSpacing, NotificationType } from '../../types';
import { CtaProps } from '../../models';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TypographyComponent } from '../typography/typography.component';
import { SectionComponent } from '../section/section.component';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgTemplateOutlet, IconComponent, TypographyComponent, SectionComponent],
})
export class NotificationComponent extends ContentBaseComponent implements OnChanges {
	/**
	 * Emits when the notification is dismissed.
	 */
	@Output() public dismissed = new EventEmitter<boolean>(false);

	/**
	 * The notification type determines the icon and color of the notification.
	 */
	@Input() public notificationType: NotificationType = 'info';

	/**
	 * The title can contain basic HTML tags.
	 */
	@Input() public title?: string;

	/**
	 * The description can contain basic HTML tags.
	 */
	@Input() public description = '';

	/**
	 * If true, the notification can be closed by the user using the escape key or the close button.
	 */
	@Input() public canDismiss = true;

	/**
	 * Call to action text and link.
	 */
	@Input() public cta?: CtaProps;
	@Input() public animate = false;
	@Input() public animationType = AnimationType.shakeAndShow;
	@Input() public showIcon = true;
	@Input() public showInsideSection = false;
	@Input() public hasSectionPaddingTop = true;
	@Input() public backgroundColor: EDRColor | undefined;
	/**
	 * The icon name can be a predefined icon name (default based on notification type) or a custom icon name.
	 */
	@Input() public iconName?: EDRIcon | 'default' = 'default';
	@Input() public iconSize: EDRSpacing = '5';

	public iconColors: { [key in NotificationType]: EDRColor } = {
		info: 'alert--info-blue',
		success: 'alert--success-green',
		alert: 'alert--alert-yellow',
		error: 'alert--error-red',
	};

	public iconNames: { [key in NotificationType]: EDRIcon } = {
		info: SVGIcon.AlertInfo,
		success: SVGIcon.AlertSuccess,
		alert: SVGIcon.AlertWarning,
		error: SVGIcon.AlertError,
	};

	// Accessibility IDs
	public alertLabelId?: string;
	public alertDescriptionId?: string;
	public role?: string;

	// Other properties
	public edrIconName?: EDRIcon;
	public iconColor: EDRColor = 'alert--info-blue';
	public animationCssClassName?: string = '';
	private isDismissed = false;

	public get showNotification(): boolean {
		return !this.isDismissed;
	}

	public ngOnChanges(): void {
		this.alertLabelId = this.description.length ? 'alertLabel' : undefined;
		this.alertDescriptionId = this.description.length ? 'alertDescription' : undefined;

		// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alert_role#Notes
		this.role = this.canDismiss ? 'alertdialog' : 'alert';

		this.iconColor = this.iconColors[this.notificationType];

		// Use the predefined icon name for the notification type if the icon name is not provided or is set to 'default'.
		this.edrIconName = this.iconName === 'default' ? this.iconNames[this.notificationType] : this.iconName;

		this.animationCssClassName = this.animate ? `notification--animate-${this.animationType}` : '';
	}

	public closeAlert(): void {
		this.isDismissed = true;
		this.dismissed.emit(true);
	}
}
