import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { isAuthenticatedUserGuard } from '../auth/auth.guard';
import { contentfulConstants } from '@edr/shared';

export const isPreviewAuthenticationRequired = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
	const isAuthenticationRequired = route.queryParams[contentfulConstants.queryParams.isAuthenticationRequired].toLowerCase() === 'true';

	if (isAuthenticationRequired) {
		const guard = isAuthenticatedUserGuard({ startLoginFlow: true, redirectPath: '' });
		return guard();
	}

	return of(true);
};
