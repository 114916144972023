@if (isLoading) {
	<div [style.height]="'var(--spacing-' + spinnerHeight + ')'">
		<edr-spinner
			class="button-spinner"
			[style.marginLeft]="'var(--spacing-' + spinnerMarginHorizontal + ')'"
			[style.marginRight]="'var(--spacing-' + spinnerMarginHorizontal + ')'"
			[color]="loadingColor"
		>
		</edr-spinner>
	</div>
}
@if (!isLoading && showLeftIcon && iconLeft) {
	<div class="leftIcon">
		<edr-icon [name]="iconLeft.name" [size]="iconLeft.size" [color]="iconLeft.color" [rotate]="iconLeft.rotate" />
	</div>
}
@if (!isLoading) {
	<ng-content></ng-content>
}
@if (!isLoading && showRightIcon && iconRight) {
	<div class="rightIcon">
		<edr-icon [name]="iconRight.name" [size]="iconRight.size" [color]="iconRight.color" [rotate]="iconRight.rotate" />
	</div>
}
