import { ActivityDetailsUomType, ActivityDetailsResponse, ActivityListResponse, RedeemType, RewardType } from '@edr/bff-api-models';
import { PointsTransactionTable } from './points-table.model';
import { PartnerIds } from '../../models/partners.model';
import { ActivityListByMonth } from './points-table.store';

export const TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS = [
	{
		name: 'amount',
		displayName: 'Purchased',
	},
	{
		name: 'bonusPoints',
		displayName: 'Bonus points',
	},
	{
		name: 'totalPoints',
		displayName: 'Points',
	},
];

export const TABLE_COLUMNS_DEFAULT = [
	{
		displayName: 'This Month',
		name: 'partnerDetails',
	},
	...TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS,
];

export const DETAILS_TABLE_COLUMNS = [
	{
		name: 'description',
		displayName: 'Description',
	},
	{
		name: 'amount',
		displayName: 'Purchased',
	},
	{
		name: 'bonusPoints',
		displayName: 'Bonus points',
	},
	{
		name: 'totalPoints',
		displayName: 'Points',
	},
];

export const POINTS_BREAKDOWN_DATA = [
	{
		description: 'Shop items (minus <a href="/help">exclusions</a>)',
		amount: 20,
		uom: ActivityDetailsUomType.Dollars,
		basePoints: 128,
		bonusPoints: 35,
	},
	{
		description: 'Fuel',
		amount: 50,
		uom: ActivityDetailsUomType.Litres,
		basePoints: 50,
		bonusPoints: 0,
	},
];

export const POINTS_BREAKDOWN_NO_BONUS_POINTS_DATA = [
	{
		description: 'Shop items (minus <a href="/help">exclusions</a>)',
		amount: 20,
		uom: ActivityDetailsUomType.Dollars,
		basePoints: 128,
		bonusPoints: 0,
	},
	{
		description: 'Fuel',
		amount: 50,
		uom: ActivityDetailsUomType.Litres,
		basePoints: 50,
		bonusPoints: 0,
	},
];
export const ACTIVITY_DETAILS_NO_BONUS_POINTS_DATA: ActivityDetailsResponse = {
	activityDetails: [...POINTS_BREAKDOWN_NO_BONUS_POINTS_DATA],
	redeemed: [
		{
			type: RedeemType.Fuel,
			description: 'Nice! You earned 40 cents off per litre at bp',
		},
	],
};

export const ACTIVITY_DETAILS_DATA: ActivityDetailsResponse = {
	activityDetails: [...POINTS_BREAKDOWN_DATA],
	redeemed: [
		{
			type: RedeemType.Fuel,
			description: 'Nice! You earned 40 cents off per litre at bp',
		},
	],
};

export const POINTS_DATA_THIS_MONTH: ActivityListResponse[] = [
	{
		id: '000001',
		description: 'Countdown Ponsonby',
		partnerId: PartnerIds.Woolworths,
		transactionDateTime: generateTransactionDate(0),
		bonusPoints: 0,
		basePoints: 34,
		basketKey: '',
		amount: 32.23,
		totalPoints: 34,
		isActivityDetailsAvailable: false,
	},
	{
		id: '000002',
		description: 'Countdown Ponsonby',
		partnerId: PartnerIds.Woolworths,
		transactionDateTime: generateTransactionDate(0),
		bonusPoints: 30,
		basePoints: 70,
		basketKey: '',
		amount: 100,
		totalPoints: 100,
		isActivityDetailsAvailable: false,
		rewards: [
			{
				type: RewardType.Fuel,
				description: 'Nice! You earned 40 cents off per litre at bp',
			},
		],
	},
	{
		id: '000003',
		description: 'Ponsonby',
		partnerId: PartnerIds.Woolworths,
		transactionDateTime: generateTransactionDate(0),
		bonusPoints: 30,
		basePoints: 70,
		basketKey: '',
		amount: 100,
		totalPoints: 100,
		isActivityDetailsAvailable: false,
		redeemed: [
			{
				type: RedeemType.Voucher,
				description: 'You spent a $15 voucher',
			},
		],
	},
	{
		id: '000004',
		description: 'Countdown Ponsonby',
		partnerId: undefined,
		transactionDateTime: generateTransactionDate(0),
		bonusPoints: 0,
		basePoints: 34,
		basketKey: '',
		amount: 32.23,
		totalPoints: 34,
		isActivityDetailsAvailable: true,
	},
];

export const POINTS_DATA_LAST_MONTH: ActivityListResponse[] = [
	{
		id: '000004',
		description: 'Ponsonby',
		partnerId: PartnerIds.Woolworths,
		transactionDateTime: generateTransactionDate(-1),
		bonusPoints: 30,
		basePoints: 70,
		basketKey: '',
		amount: 100.15,
		totalPoints: 100,
		isActivityDetailsAvailable: false,
		rewards: [
			{
				type: RewardType.Fuel,
				description: 'Nice! You earned 40 cents off per litre at bp',
			},
		],
		redeemed: [
			{
				type: RedeemType.Voucher,
				description: 'You spent $60 in vouchers',
			},
		],
	},
	{
		id: '000005',
		description: 'bp Connect',
		partnerId: PartnerIds.BP,
		transactionDateTime: generateTransactionDate(-1),
		bonusPoints: 100,
		basePoints: 70,
		basketKey: '',
		amount: 67,
		totalPoints: 170,
		isActivityDetailsAvailable: false,
	},
	{
		id: '000006',
		description: 'Ponsonby',
		partnerId: PartnerIds.Woolworths,
		transactionDateTime: generateTransactionDate(-1),
		bonusPoints: 30,
		basePoints: 70,
		basketKey: '',
		amount: 100.15,
		totalPoints: 100,
		isActivityDetailsAvailable: false,
		redeemed: [
			{
				type: RedeemType.Fuel,
				description: 'You used your discount of 6 cents off per litre at BP',
			},
		],
		rewards: [
			{
				type: RedeemType.Fuel,
				description: 'Nice! You earned 40 cents off per litre at bp',
			},
		],
	},
];

export const POINTS_DATA_MONTH_BEFORE_LAST: ActivityListResponse[] = [
	{
		id: '000007',
		transactionDateTime: generateTransactionDate(-2),
		partnerId: PartnerIds.Woolworths,
		description: 'Points Received from card no. 123122',
		bonusPoints: 647,
		basePoints: 0,
		basketKey: '',
		amount: 0,
		totalPoints: 647,
		isActivityDetailsAvailable: false,
		rewards: [
			{
				type: 'Gift',
				description: 'Nice! You were gifted 647 Points',
			},
		],
	},
	{
		id: '000008',
		transactionDateTime: generateTransactionDate(-2),
		partnerId: PartnerIds.Woolworths,
		description: 'Points gifted to card no. 1231234',
		bonusPoints: 0,
		basePoints: 0,
		basketKey: '',
		amount: 0,
		totalPoints: -647,
		isActivityDetailsAvailable: false,
		redeemed: [
			{
				type: RedeemType.Gift,
				description: 'You gifted 647 Points to card no. 1231234',
			},
		],
	},
];

export const POINTS_DATA: ActivityListResponse[] = [...POINTS_DATA_THIS_MONTH, ...POINTS_DATA_LAST_MONTH, ...POINTS_DATA_MONTH_BEFORE_LAST];

const mapActivity = (item: ActivityListResponse): PointsTransactionTable => ({
	...item,
	partnerDetails: {
		partnerName: item.description,
		partnerId: item.partnerId,
		transactionDateTime: item.transactionDateTime,
	},
});

export const POINTS_DATA_THIS_MONTH_AS_TABLE: PointsTransactionTable[] = POINTS_DATA_THIS_MONTH.map(mapActivity);

export const POINTS_DATA_LAST_MONTH_AS_TABLE: PointsTransactionTable[] = POINTS_DATA_LAST_MONTH.map(mapActivity);

export const POINTS_DATA_MONTH_BEFORE_LAST_AS_TABLE: PointsTransactionTable[] = POINTS_DATA_MONTH_BEFORE_LAST.map(mapActivity);

export const POINTS_DATA_AS_TABLE: PointsTransactionTable[] = POINTS_DATA.map(mapActivity);

/**
 * Generate date for different months
 * @param {number} monthDiff
 * 0: current month
 * -1: last month
 * 1: next month
 * @return {*}  {string}
 */
function generateTransactionDate(monthDiff: number): string {
	const now = new Date();

	const desiredMonth = new Date(now.getFullYear(), now.getMonth() - monthDiff, 1);
	return desiredMonth.toISOString();
}

export const mockActivityListByMonth: ActivityListByMonth[] = [
	{
		columns: [...TABLE_COLUMNS_DEFAULT],
		activities: POINTS_DATA_THIS_MONTH_AS_TABLE,
	},
	{
		columns: [
			{
				displayName: 'Last Month',
				name: 'partnerDetails',
			},
			...TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS,
		],
		activities: POINTS_DATA_LAST_MONTH_AS_TABLE,
	},
	{
		columns: [
			{
				displayName: 'Aug 2023',
				name: 'partnerDetails',
			},
			...TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS,
		],
		activities: POINTS_DATA_MONTH_BEFORE_LAST_AS_TABLE,
	},
];
