import { Component, OnInit } from '@angular/core';
import { BreakpointService, ContentBaseComponent, DeviceType } from '@edr/shared';
import { PointsCardListComponent } from '../points-card-list/points-card-list.component';
import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { Observable } from 'rxjs';
import { PointsTableDesktopComponent } from '../points-table-desktop/points-table-desktop.component';
import { DETAILS_TABLE_COLUMNS } from './points-table-settings';
import { PointsTableStore } from './points-table.store';
import { SpinnerComponent } from '@edr/styleguide';

@Component({
	selector: 'edr-app-points-table',
	templateUrl: './points-table.component.html',
	styleUrls: ['./points-table.component.scss'],
	standalone: true,
	imports: [PointsCardListComponent, PointsTableDesktopComponent, SpinnerComponent, AsyncPipe, NgTemplateOutlet],
	providers: [PointsTableStore, DatePipe],
})
export class PointsTableComponent extends ContentBaseComponent implements OnInit {
	public pointsTableTemplateData$ = this.pointsTableStore.selectPointsTableTemplateData$;
	public detailsColumns = DETAILS_TABLE_COLUMNS;

	public isMobileDevice$: Observable<boolean>;

	constructor(private breakpointService: BreakpointService, private pointsTableStore: PointsTableStore) {
		super();
		this.isMobileDevice$ = this.breakpointService.breakpointMatchesCustom([DeviceType.Mobile]);
	}

	public ngOnInit(): void {
		this.pointsTableStore.fetchActivityList();
	}
}
