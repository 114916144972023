import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { Observable, switchMap } from 'rxjs';
import { BoostOfferResponse, BoostOffersResponse } from '@edr/bff-api-models';

@Injectable({
	providedIn: 'root',
})
export class BoostService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	public fetchBoostOffers(): Observable<BoostOffersResponse> {
		return this.appSettingsService.getEndpoint('boostOffers').pipe(switchMap((endPoint) => this.apiService.get<BoostOffersResponse>(endPoint)));
	}

	public boostOffers(ids: string[]): Observable<BoostOfferResponse> {
		return this.appSettingsService
			.getEndpoint('boostOffers')
			.pipe(switchMap((endpoint) => this.apiService.put<string[], BoostOfferResponse>(`${endpoint}/boost`, ids)));
	}
}
