import { ObjectValues } from '@edr/shared';

export const TooltipTrigger = {
	Hover: 'hover',
	Focus: 'focus',
} as const;
export type TooltipTrigger = ObjectValues<typeof TooltipTrigger>;

export const TooltipAlignment = {
	Start: 'start',
	Middle: 'middle',
	End: 'end',
} as const;
export type TooltipAlignment = ObjectValues<typeof TooltipAlignment>;

export const TooltipPosition = {
	Top: 'top',
	Left: 'left',
	Right: 'right',
	Bottom: 'bottom',
} as const;
export type TooltipPosition = ObjectValues<typeof TooltipPosition>;
