import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { MultiStepComponent } from '../multi-step/multi-step.component';
import { ComponentRefService } from '../../services';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-multi-step-item',
	templateUrl: './multi-step-item.component.html',
	styleUrls: ['./multi-step-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class MultiStepItemComponent extends ContentBaseComponent implements OnInit, OnDestroy {
	@Input() public id = '';
	@Input() public stepIndex = 0;
	public isActive = signal(false);

	constructor(private componentRefService: ComponentRefService) {
		super();
	}

	@Input() public set active(value: boolean) {
		this.isActive.set(value);
	}

	public ngOnInit(): void {
		if (!this.id) {
			throw new Error('ID must be set for MultiStepItemComponent');
		}
		this.componentRefService.register(this.id, this);
		this.getParent()?.updateTotalSteps();
	}

	public ngOnDestroy(): void {
		this.componentRefService.deregister(this.id, this);
	}

	public updateActiveStepIndex(stepIndex: number): void {
		this.isActive.set(+this.stepIndex === stepIndex);
	}

	private getParent(): MultiStepComponent | undefined {
		return this.componentRefService.getComponentRefs(this.id)?.find((ref) => ref instanceof MultiStepComponent);
	}
}
