import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cardNumber',
	standalone: true,
})
export class CardNumberPipe implements PipeTransform {
	public transform(value: number | undefined): string {
		//e.g. INPUT: 1234567890123, OUTPUT: 1 2345 6789 0123
		let strNum = String(value);
		strNum = ' '.repeat(4 - (strNum.length % 4)) + strNum;
		return (
			strNum
				.match(/[\d ]{1,4}/g)
				?.join(' ')
				.trim() || ''
		);
	}
}
