@if (!!text && !asButton && !isExternalLink()) {
<a [routerLink]="[linkUrl]" [target]="target">{{ text }}</a>
} @if (!!text && !asButton && isExternalLink()) {
<a [href]="linkUrl" [target]="target">{{ text }}</a>
} @if (!!text && asButton && !isExternalLink()) {
<a
	edr-button
	[routerLink]="[linkUrl]"
	[target]="target"
	[buttonSize]="size"
	[reversed]="buttonStyleReversed"
	[buttonStyle]="buttonStyle"
	[iconLeft]="iconLeft"
	[showLeftIcon]="showLeftIcon"
	[iconRight]="iconRight"
	[showRightIcon]="showRightIcon"
	>{{ text }}</a
>
} @if (!!text && asButton && isExternalLink()) {
<a
	edr-button
	[href]="linkUrl"
	[target]="target"
	[buttonSize]="size"
	[reversed]="buttonStyleReversed"
	[buttonStyle]="buttonStyle"
	[iconLeft]="iconLeft"
	[showLeftIcon]="showLeftIcon"
	[iconRight]="iconRight"
	[showRightIcon]="showRightIcon"
	>{{ text }}</a
>
} @if (isExternalLink() && asImage) {
<a [href]="linkUrl" [target]="target">
	<ng-content></ng-content>
</a>
} @if (!isExternalLink() && asImage) {
<a [routerLink]="[linkUrl]" [target]="target">
	<ng-content></ng-content>
</a>
} @if (!asImage) {
<ng-content></ng-content>
}
