import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApplyStylesDirective } from '../../directives';

import { IconProps } from '../../models';
import { CircleComponent } from '../circle/circle.component';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'edr-icon-circle',
	templateUrl: './icon-circle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ApplyStylesDirective, IconComponent, CircleComponent],
})
export class IconCircleComponent {
	@Input({ required: true }) public icon: IconProps | undefined;
}
