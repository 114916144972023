import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { BOOSTS_FEATURE_KEY, BoostOffersState, BoostsState, FirstTimeBoostState, OfferState } from './boosts.reducer';
import { OfferStatus } from '@edr/bff-api-models';

export const selectBoostsState = createFeatureSelector<BoostsState>(BOOSTS_FEATURE_KEY);

// first time boost
export const selectFirstTimeState = createSelector(selectBoostsState, (state: BoostsState) => state.firstTimeBoost);
export const selectHasBoosted = createSelector(selectFirstTimeState, (state: FirstTimeBoostState) => state.hasBoosted);

// boost offers
export const selectOfferState = createSelector(selectBoostsState, (state: BoostsState) => state.offersData);
export const selectHasOffers = createSelector(selectOfferState, (state: BoostOffersState) => !!state.offers?.length);
export const selectIsLoadingOffers = createSelector(selectOfferState, (state: BoostOffersState) => state.isLoading);
export const selectIsUpdatingOffers = createSelector(selectOfferState, (state: BoostOffersState) => state.isUpdating);
export const selectOffers = createSelector(selectOfferState, (state: BoostOffersState): OfferState[] | undefined => state.offers);
export const selectOffer = (
	id: string
): MemoizedSelector<object, OfferState | undefined, (offers: OfferState[] | undefined) => OfferState | undefined> =>
	createSelector(selectOffers, (offers: OfferState[] | undefined): OfferState | undefined => offers?.find((o) => o.offer.id === id));
export const selectBoostOffers = createSelector(selectOfferState, (state: BoostOffersState) =>
	state.offers.filter((item) => item.offer.displayLocation === 'PRODUCT_OFFERS')
);
export const selectOtherOffers = createSelector(selectOfferState, (state: BoostOffersState): OfferState[] =>
	state.offers.filter((item) => item.offer.displayLocation === 'OTHER_OFFERS')
);
export const selectOtherOffersNotCompleted = createSelector(selectOtherOffers, (offers: OfferState[]) =>
	offers.filter((item) => item.offer.offerStatus !== OfferStatus.Redeemed)
);
export const selectOtherOffersCompleted = createSelector(selectOtherOffers, (offers: OfferState[]) =>
	offers.filter((item) => item.offer.offerStatus === OfferStatus.Redeemed)
);
export const selectIsAllOffersBoosted = createSelector(
	selectOfferState,
	(state: BoostOffersState) => !!state.offers && state.offers.every((item) => item.offer.offerStatus !== OfferStatus.NotActivated)
);
export const selectNotActivatedOffers = createSelector(selectOfferState, (state: BoostOffersState) =>
	state.offers.filter((item) => item.offer.offerStatus === OfferStatus.NotActivated)
);
