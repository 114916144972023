// Defaults are defined in confluence: https://woolworths-agile.atlassian.net/wiki/spaces/LASE/pages/32509044054
export const PreferenceIds = {
	EDRAllowBoostsAndOffersEmail: 'EDRAllowBoostsAndOffersEmail',
	EDRCustomerFeedbackEmail: 'EDRCustomerFeedbackEmail',
	EDRInterestAlcoholEmail: 'EDRInterestAlcoholEmail',
	EDRAllowPartnerCommsCountdownEmail: 'EDRAllowPartnerCommsCountdownEmail',
	EDRAllowPartnerCommsBPEmail: 'EDRAllowPartnerCommsBPEmail',
	EDRAllowPartnerCommsVineOnlineEmail: 'EDRAllowPartnerCommsVineOnlineEmail',
	EDRAllowContactAll: 'EDRAllowContactAll',
	EDRCustomerAppStateAPI: 'EDRCustomerAppStateAPI',
	EDRFirstTimeBoost: 'EDRFirstTimeBoost',
	EDRTutorialViewState: 'EDRTutorialViewState',
	EDRRedemptionPreference: 'EDRRedemptionPreference',
	EDRSaveForOccasionDate: 'EDRSaveForOccasionDate',
};
