import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as PartnersActions from './partners.actions';
import { switchMap, catchError, of, map } from 'rxjs';
import { PartnersService } from '../../../core/services';

@Injectable()
export class PartnersEffects {
	public loadPartners$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PartnersActions.loadPartners),
			switchMap(() =>
				this.partnersService.fetchPartners().pipe(
					map((partnerResponse) => PartnersActions.loadPartnersSuccess({ partners: partnerResponse })),
					catchError(() => of(PartnersActions.loadPartnersFailure()))
				)
			)
		)
	);

	constructor(private actions$: Actions, private partnersService: PartnersService) {}
}
