export enum BarcodeFormat {
	CODE128 = 'CODE128',
	CODE128A = 'CODE128A',
	CODE128B = 'CODE128B',
	CODE128C = 'CODE128C',
	UPC = 'UPC',
	EAN13 = 'EAN13',
	EAN8 = 'EAN8',
	EAN5 = 'EAN5',
	EAN2 = 'EAN2',
	CODE39 = 'CODE39',
	ITF14 = 'ITF14',
	MSI = 'MSI',
	MSI10 = 'MSI10',
	MSI11 = 'MSI11',
	MSI1010 = 'MSI1010',
	MSI1110 = 'MSI1110',
	PHARMACODE = 'pharmacode',
	CODABAR = 'codabar',
}

export enum FontOptions {
	NONE = '',
	BOLD = 'bold',
	ITALIC = 'italic',
	BOLD_ITALIC = 'bold italic',
}

export enum TextAlign {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}

export enum TextPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
}
