import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { AddressDetailResponse, AddressSearchResponse } from '@edr/bff-api-models';

@Injectable({
	providedIn: 'root',
})
export class AddressFinderService {
	constructor(private appSettingsService: AppSettingsService, private apiService: ApiService) {}

	public getAutoComplete(partialAddress: string): Observable<AddressSearchResponse[]> {
		if (!partialAddress) {
			return of([]);
		}
		return this.appSettingsService
			.getEndpoint('addressLookupSearch')
			.pipe(switchMap((endPoint) => this.apiService.get<AddressSearchResponse[]>(`${endPoint}?query=${encodeURIComponent(partialAddress)}`)));
	}

	public getAddressDetail(id: string): Observable<AddressDetailResponse> {
		return this.appSettingsService
			.getEndpoint('addressLookupDetails')
			.pipe(switchMap((endPoint) => this.apiService.get<AddressDetailResponse>(`${endPoint}?id=${id}`)));
	}
}
