@if ({
	personalDetails: personalDetails$ | async,
}; as data) {
	<edr-modal [useCustomHeader]="true" [disableClose]="false" class="modal" (closeModal)="onCloseModal()">
		<div modal-header class="modal__header">
			@if (isError) {
				<edr-icon
					class="modal__header-error"
					size="8"
					color="alert--alert-yellow"
					name="alert-warning"
				/>
			}
			<div class="modal__header-title">
				<edr-heading [level]="3">{{ header }}</edr-heading>
			</div>
			<button type="button" class="modal__header-close" (click)="onCloseModal()">
				<edr-icon name="system-close" color="secondary--charcoal" size="5"></edr-icon>
			</button>
		</div>

		<div modal-content class="modal__content">
			@if (!isError) {
				<img ngSrc="/assets/images/reward-gifting-success.svg" width="220" height="120" alt="rewards-choice" />
			}
		
			<div [ngClass]="{'modal__content-error': isError}">
				<edr-heading data-testid="rewards-gifting-result-title" [level]="4">{{ title }}</edr-heading>
				<p data-testid="rewards-gifting-result-description" [innerHtml]="description"></p>
			</div>
		</div>

		<div modal-footer-secondary style="width: 100%;">
			<edr-button
				data-testid="cta-button"
				buttonStyle="secondary"
				[inline]="true"
				[disable]="false"
				(click)="onCtaClicked()"
				(keydown.enter)="onCtaClicked()"
				style="width: 100%; text-align: center;"
			>
				{{ ctaText }}
			</edr-button>
		</div>

		@if (isError) {
			<div modal-footer-primary style="width: 100%;">
				<edr-button
					data-testid="cancel-button"
					buttonStyle="secondary"
					[reversed]="true"
					[inline]="true"
					[disable]="false"
					(click)="onCloseModal()"
					(keydown.enter)="onCloseModal()"
					style="width: 100%; text-align: center;"
				>
					Cancel
				</edr-button>
			</div>
		}

	</edr-modal>
}