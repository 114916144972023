import { ComponentRef, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { CONTENT_MAPPING } from '../../constants/content-mapping.token';
import { ContentMapping } from '../../models/content-mapping.interface';
import { DynamicComponent } from '../../models/dynamic-component.interface';
import { WINDOW } from '@ng-web-apis/common';
import { CustomWindow } from '@edr/shared';

export type Content<T> = string | ComponentRef<T>;

@Injectable({
	providedIn: 'root',
})
export class DynamicContentService {
	constructor(@Inject(CONTENT_MAPPING) private contentMapping: ContentMapping, @Inject(WINDOW) private window: CustomWindow) {}

	public render(
		dynamicComponentList: DynamicComponent[],
		container: ViewContainerRef
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): { id: string; componentRef: ComponentRef<any>; componentNodes: Node[] }[][] {
		return dynamicComponentList.map((dynamicComponent: DynamicComponent) => this.createComponentTree(dynamicComponent, container));
	}

	private createComponentTree(
		dynamicComponent: DynamicComponent,
		container: ViewContainerRef
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): { id: string; componentRef: ComponentRef<any>; componentNodes: Node[] }[] {
		if (!dynamicComponent.children) {
			const myTextNode = dynamicComponent.textContent ? [this.resolveNgContent(dynamicComponent.textContent)] : [];
			return [this.renderComponent(dynamicComponent, container, myTextNode)];
		}

		const children = dynamicComponent.children.reduce((acc: Node[], child: DynamicComponent): Node[] => {
			const nodes = this.createComponentTree(child, container).find((item) => item.id === child.id)?.componentNodes || [];

			return [...acc, ...nodes];
		}, []);

		const textNode = dynamicComponent.textContent ? [this.resolveNgContent(dynamicComponent.textContent)] : [];
		return [this.renderComponent(dynamicComponent, container, [...textNode, ...children])];
	}

	private renderComponent(
		dynamicComponent: DynamicComponent,
		container: ViewContainerRef,
		childNodes: Node[]
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): { id: string; componentRef: ComponentRef<any>; componentNodes: Node[] } {
		const component = this.contentMapping[dynamicComponent.type].component;
		const componentRef = container.createComponent(component, { projectableNodes: [childNodes] });

		if (dynamicComponent.props) {
			const propsMapperFn = this.contentMapping[dynamicComponent.type].propsMapper;
			const props = (propsMapperFn && propsMapperFn(dynamicComponent.props)) || dynamicComponent.props;
			Object.entries(props).forEach(([key, value]) => {
				componentRef.setInput(key, value);
			});
		}
		const componentNode = this.resolveNgContent(componentRef);

		return { id: dynamicComponent.id, componentRef, componentNodes: [componentNode] };
	}

	private resolveNgContent<T>(content: Content<T>): Node {
		if (typeof content === 'string') {
			const element = this.window.document.createTextNode(content);
			return element;
		}

		return content.location.nativeElement;
	}
}
