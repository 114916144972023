import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AccordionComponent } from '../../accordion/accordion.component';
import { ComponentRefService } from '../../../services';
import { ContentBaseComponent, slugify } from '@edr/shared';
import { ActivatedRoute } from '@angular/router';
import { IconProps } from '../../../models';

/**
 * It's probably already about time to refactor this component as we now have a clear distinction between some
 * accordion-item variations: normal (with and without sub-accordion), card.
 * Now it's becoming a bit difficult to know how to configure the inputs for each variation.
 */
@Component({
	selector: 'edr-accordion-item-base',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class AccordionItemBaseComponent extends ContentBaseComponent implements OnInit, OnDestroy {
	@Input() public id = '';
	@Input() public heading: string | undefined;
	@Input() public deepLinkAnchorText = '';
	@HostBinding('attr.expanded') @Input() public expanded = false;
	@HostBinding('attr.isChildAccordion') @Input() public isChildAccordion = false;
	@HostBinding('attr.selected') @Input() public selected = false;
	@Input() public selectedIcon: IconProps | undefined = { name: 'alert-success', color: 'primary--orange', size: '8' };
	@HostBinding('attr.hasChildAccordion') @Input() public hasChildAccordion = false;
	@HostBinding('attr.isToggleDisabled') @Input() public isToggleDisabled = false;

	@Input()
	public get icon(): IconProps | undefined {
		const displaySelectedIcon = this.selectedIcon && this.selected;
		return displaySelectedIcon ? this.selectedIcon : this._icon;
	}

	public set icon(value: IconProps | undefined) {
		this._icon = value;
	}
	private _icon: IconProps | undefined;

	constructor(
		private cdr: ChangeDetectorRef,
		private componentRefService: ComponentRefService,
		private activatedRoute: ActivatedRoute
	) {
		super();
	}

	@HostBinding('class')
	public get anchorpoint(): string {
		return `anchor-point-${slugify(this.deepLinkAnchorText?.slice(0, 20) || '')}`;
	}

	public ngOnInit(): void {
		this.componentRefService.register(this.id, this);

		if (this.deepLinkAnchorText && this.activatedRoute.snapshot.fragment?.split('+').includes(slugify(this.deepLinkAnchorText.slice(0, 20) || ''))) {
			this.expand();
		}
	}

	public ngOnDestroy(): void {
		this.componentRefService.deregister(this.id, this);
	}

	public toggleAccordionItem(): void {
		if (this.isToggleDisabled) {
			return;
		}

		this.expanded ? this.collapse() : this.expand();
	}

	public collapse(): void {
		if (!this.expanded) {
			return;
		}
		if (!this.isChildAccordion) {
			this.getParentAccordion()?.collapseAllChildAccordionItems();
		}
		this.expanded = false;
		this.cdr.markForCheck();
	}

	public expand(): void {
		const accordion = this.getParentAccordion();
		if (!accordion?.multiExpand) {
			if (accordion?.hasViewInit) {
				this.getParentAccordion()?.collapseAll();
			}
		}
		this.expanded = true;
	}

	public updateToggleDisabled(setting: boolean): void {
		this.isToggleDisabled = setting;
		this.cdr.markForCheck();
	}

	private getParentAccordion(): AccordionComponent | undefined {
		return this.componentRefService.getComponentRefs(this.id)?.find((ref) => ref instanceof AccordionComponent);
	}
}
