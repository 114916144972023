import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	forwardRef,
} from '@angular/core';
import { Observable, Subscription, debounceTime, delay, distinctUntilChanged, map, startWith } from 'rxjs';
import { InputComponent } from '../input/input.component';
import { OptionComponent } from '../option/option.component';
import { OptionSelectedEvent } from '../option/option-selected-event.model';
import { DropdownService } from '../../services';
import { generateId } from '@edr/shared';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponent } from '../icon/icon.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'edr-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		DropdownService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AutocompleteComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [AsyncPipe, InputComponent, IconComponent, OptionComponent, SpinnerComponent],
})
export class AutocompleteComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
	@Input() public id: string = generateId();
	@Input() public placeholder = '';
	@Input() public isSearching = false;
	@Input() public searchText = '';
	@Input() public debounceTime = 250;
	@Input() public ariaLabel?: string;
	@Input() public required = false;
	@Input() public isError = false;
	@Input() public label?: string;
	@Input() public formControlName?: string;
	@Input() public name?: string;

	@Output() public searchTextChange = new EventEmitter<string>();
	@Output() public optionSelected = new EventEmitter<unknown>();
	@Output() public dropdownClosed = new EventEmitter<unknown>();

	@ViewChild('textInput') public textInput: InputComponent | undefined;
	@ViewChild('panelTemplateRef', { static: true }) public template: TemplateRef<unknown> | undefined;

	@ContentChildren(OptionComponent, { descendants: true }) public optionList: QueryList<OptionComponent> | undefined;
	public hasOptions$: Observable<boolean> | undefined;

	private subscriptions = new Subscription();
	private _disabled = false;

	constructor(private changeDetector: ChangeDetectorRef, private viewContainerRef: ViewContainerRef, public dropdownService: DropdownService) {}

	@Input()
	public get disabled(): boolean {
		return this._disabled;
	}

	public set disabled(value: boolean) {
		this._disabled = value;
		this.dropdownService.setDisabledState(value);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@Input()
	public get value(): unknown {
		return this.dropdownService.getSelectedOption()?.value ?? null;
	}

	public set value(value: unknown) {
		this.dropdownService.setSelectedOption(value);
		this.searchText = this.dropdownService.getSelectedOption()?.getOptionText() ?? '';
	}

	@HostListener('click')
	public handleHostClick(): void {
		this.textInput?.focus();
	}

	public ngAfterViewInit(): void {
		this.setupTextInputSubscriptions();

		// Initialize Dropdown service to manage events and dropdown panel
		if (this.textInput && this.template) {
			this.dropdownService.initialize({
				optionList: this.optionList,
				connectedElement: this.textInput.nativeElement,
				template: this.template,
				viewContainerRef: this.viewContainerRef,
				keyDownEventListener: this.textInput.keyDown,
				initialValue: this.value,
			});
		}

		// Subscribe to option selected
		this.subscriptions.add(
			this.dropdownService.optionSelected().subscribe((event) => {
				this.handleOptionSelected(event);
				this.changeDetector.markForCheck();
			})
		);

		// Subscribe to panel closed events
		this.subscriptions.add(
			this.dropdownService.panelClosed().subscribe(() => {
				this.handlePanelClosed();
			})
		);

		// Subscribe to options changes
		if (this.optionList) {
			this.hasOptions$ = this.optionList.changes.pipe(
				startWith(this.optionList),
				delay(0), // Known issue when subscribing to changes on @ContentChildren, otherwise we get ExpressionChangedAfterItHasBeenCheckedError and changes are not applied
				map((optionsList: QueryList<OptionComponent>) => optionsList.length > 0)
			);
		}
	}

	public ngOnDestroy(): void {
		this.dropdownService.destroy();
		this.subscriptions.unsubscribe();
	}

	public openDropdownPanel(): void {
		this.dropdownService.openPanel();
	}

	public handleBlurred(): void {
		// Call Forms onTouched event if inside form
		if (this.onFormControlTouched) {
			this.onFormControlTouched();
		}
	}

	public clearSelectedValue(): void {
		this.dropdownService.handleOptionSelected(null);
		this.searchText = '';
		this.searchTextChange.emit(this.searchText);
		this.changeDetector.markForCheck();
	}

	public writeValue(value: unknown): void {
		this.value = value;
	}

	public registerOnChange(fn: (_: unknown) => void): void {
		this.onFormControlChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onFormControlTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	private setupTextInputSubscriptions(): void {
		if (this.textInput) {
			// Subscribe to searchText change with debounce timer in order to emit to parent component
			this.subscriptions.add(
				this.textInput.valueChange
					.pipe(
						debounceTime(this.debounceTime),
						distinctUntilChanged((prev, curr) => curr.toLowerCase() === prev.toLowerCase() && !(this.searchText?.length > 0 && !curr))
					)
					.subscribe((newValue) => {
						this.searchText = newValue ?? '';
						this.searchTextChange.emit(this.searchText);
						this.dropdownService.openPanel();
						this.changeDetector.detectChanges();
					})
			);
		}
	}

	private handlePanelClosed(): void {
		this.searchText = this.dropdownService.getSelectedOption()?.getOptionText() ?? '';
		this.searchTextChange.emit(this.searchText);
		this.dropdownClosed.emit();
		this.changeDetector.detectChanges();
	}

	private handleOptionSelected(optionEvent: OptionSelectedEvent | null): void {
		if (!this.disabled) {
			this.searchText = optionEvent?.text ?? '';
			this.searchTextChange.emit(this.searchText);
			this.optionSelected.emit(optionEvent?.value);

			// Call Forms onChange event if inside form
			if (this.onFormControlChange) {
				this.onFormControlChange(optionEvent?.value);
			}
			if (optionEvent?.userInitiated && this.onFormControlTouched) {
				this.onFormControlTouched();
			}
		}
	}

	// Angular Forms Methods
	private onFormControlChange: (_: unknown) => void = () => null;
	private onFormControlTouched: () => void = () => null;
}
