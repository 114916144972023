import { Action, createReducer, on } from '@ngrx/store';
import * as PageContentActions from './page-content.actions';
import { FlexiblePageTemplateResponse } from '@edr/bff-api-models';

export const PAGE_CONTENT_STATE_KEY = 'pageContent';

export interface PageContentState {
	pageId: string;
	isLoading: boolean;
	content: FlexiblePageTemplateResponse | undefined;
	error: string | undefined;
}

export const defaultPageContentState: PageContentState = {
	pageId: '',
	isLoading: false,
	content: undefined,
	error: undefined,
};

const reducer = createReducer(
	defaultPageContentState,
	on(
		PageContentActions.loadPageContent,
		(state, action): PageContentState => ({ ...state, error: undefined, isLoading: true, pageId: action.pageId })
	),
	on(
		PageContentActions.loadPageContentSuccess,
		(state, action): PageContentState => ({ ...state, error: undefined, isLoading: false, content: action.content, pageId: action.pageId })
	),
	on(PageContentActions.loadPageContentFailure, (state, action): PageContentState => ({ ...state, error: action.error, isLoading: false }))
);

export function pageContentReducer(state: PageContentState | undefined, action: Action): PageContentState {
	return reducer(state, action);
}
