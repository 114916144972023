import { Injectable } from '@angular/core';
import { DevicePlatform } from '../../types/device-platform.type';
import { Platform } from '@angular/cdk/platform';

@Injectable({
	providedIn: 'root',
})
export class DevicePlatformService {
	constructor(public platform: Platform) {}
	public getPlatform(): DevicePlatform | undefined {
		if (this.platform.ANDROID) {
			return 'android';
		} else if (this.platform.IOS) {
			return 'ios';
		}
		return undefined;
	}
}
