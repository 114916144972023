<div (click)="toggleAccordionItem()" class="heading" [class.heading--has-description]="description" edrTrackNavClick="{{ heading }}">
	@if (icon?.name) {
		<div class="heading__pre-icon">
			<edr-icon-circle [icon]="icon"/>
		</div>
	}
	<div class="heading__text">
		<h5 [class.has-description]="description" edr-typography>{{ heading }}</h5>
		@if (description) {
			<p [innerHtml]="description"></p>
		}
	</div>
	@if (!isToggleDisabled) {
		<div class="heading__toggle-icon" [attr.hasDescription]="!!description">
			<edr-icon
				name="system-chevron"
				[color]="selected ? 'secondary--dark-orange-aa' : 'secondary--charcoal'"
				[rotate]="expanded ? '-180' : '0'"
				size="6"
				tabindex="0"
			/>
		</div>
	}
</div>

<div [class.expanded]="expanded" class="accordion-item__content">
	<ng-content></ng-content>
</div>
