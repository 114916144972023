import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Observable, concatMap, map } from 'rxjs';
import { ShortcodeService, SHORTCODE_SERVICE_TOKEN } from './shortcode.token';
import { DecimalPipe } from '@angular/common';

type Shortcodes = Record<string, (source: string) => Observable<string>>;

// <p>{{ "Hello! [[firstName]]," | edrShortcode | async }}</p>
@Pipe({ name: 'edrShortcode', pure: false, standalone: true })
export class ShortcodePipe implements PipeTransform {
	private shortcodeMappers: Shortcodes = {
		firstName: this.mapFirstName,
		totalPointsBalance: this.mapTotalPointsBalance,
	};

	constructor(@Inject(SHORTCODE_SERVICE_TOKEN) private shortcodeService: ShortcodeService, private decimalPipe: DecimalPipe) {}

	public transform(value: string): Observable<string> {
		return this.applyShortcodes(value);
	}

	private applyShortcodes(value: string): Observable<string> {
		return this.shortcodeMappers['firstName']
			.call(this, value)
			.pipe(concatMap((result) => this.shortcodeMappers['totalPointsBalance'].call(this, result)));
	}

	private mapFirstName(sourceValue: string): Observable<string> {
		const firstNameRegEx = new RegExp(/\[\[firstName\]\]/g);
		return this.shortcodeService.firstName$.pipe(
			map((firstName) => (firstName ? sourceValue.replace(firstNameRegEx, firstName) : sourceValue.replace(firstNameRegEx, '')))
		);
	}

	private mapTotalPointsBalance(sourceValue: string): Observable<string> {
		const totalPointsBalanceRegEx = new RegExp(/\[\[totalPointsBalance\]\]/g);
		return this.shortcodeService.totalPointsBalance$.pipe(
			map((points) => sourceValue?.replace(totalPointsBalanceRegEx, this.decimalPipe.transform(points) || '0'))
		);
	}
}
