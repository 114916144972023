import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';

export interface ConditionalDisplaySettings {
	isUserLoggedIn: boolean;
	isUserLoggedOut: boolean;
}

export const AUTH_STATUS_SERVICE_TOKEN = new InjectionToken<AuthStatusService>('AuthStatusService');
export interface AuthStatusService {
	isLoggedIn$: Observable<boolean>;
}

@Pipe({
	name: 'edrConditionalDisplay',
	standalone: true,
})
export class ConditionalDisplayPipe implements PipeTransform {
	constructor(@Inject(AUTH_STATUS_SERVICE_TOKEN) public authStatusService: AuthStatusService) {}

	public transform(conditions: ConditionalDisplaySettings | undefined): Observable<boolean> {
		if (!conditions) {
			return of(true);
		}
		if (conditions?.isUserLoggedIn || conditions?.isUserLoggedOut) {
			return this.authStatusService.isLoggedIn$.pipe(
				map((isLoggedIn) => (conditions.isUserLoggedIn && isLoggedIn) || (conditions.isUserLoggedOut && !isLoggedIn))
			);
		}
		return of(false);
	}
}
