import { Observable, of, Subscriber } from 'rxjs';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';

export function resizeObserver(element: Element, throttle = 150): Observable<ResizeObserverEntry[]> {
	if (typeof ResizeObserver === 'undefined') {
		return of([]);
	}

	return new Observable((subscriber: Subscriber<ResizeObserverEntry[]>) => {
		const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
			subscriber.next(entries);
		});

		observer.observe(element);
		return function unsubscribe() {
			observer.unobserve(element);
		};
	}).pipe(distinctUntilChanged(), throttleTime(throttle));
}
