/* eslint-disable @typescript-eslint/member-ordering */
import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { generateId } from '@edr/shared';

@Component({
	selector: 'edr-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => ToggleComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class ToggleComponent implements ControlValueAccessor {
	private _uniqueId = generateId();
	private _checked = false;
	private _disabled = false;

	@Input() public id: string = this._uniqueId;

	@Input()
	public get checked(): boolean {
		return this._checked;
	}
	public set checked(value: boolean) {
		if (value !== this.checked) {
			this._checked = value;
			this.cdr.markForCheck();
		}
	}

	@HostBinding('attr.disabled')
	@Input()
	public get disabled(): boolean {
		return this._disabled;
	}
	public set disabled(value: unknown) {
		const newValue = Boolean(value);

		if (newValue !== this._disabled) {
			this._disabled = newValue;
			this.cdr.markForCheck();
		}
	}

	@Output() public toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('input') private _inputElement: ElementRef<HTMLInputElement> | undefined;

	public get inputId(): string {
		return `${this.id || this._uniqueId}-input`;
	}

	/* eslint-disable @typescript-eslint/no-empty-function */
	private _controlValueAccessorChangeFn: (value: boolean) => void = () => {};
	private _onTouched: () => unknown = () => {};
	/* eslint-enable @typescript-eslint/no-empty-function */

	constructor(private cdr: ChangeDetectorRef) {}

	public registerOnChange(fn: (value: boolean) => void): void {
		this._controlValueAccessorChangeFn = fn;
	}

	public registerOnTouched(fn: () => unknown): void {
		this._onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	public writeValue(value: unknown): void {
		this.checked = !!value;
	}

	public toggle(): void {
		this.checked = !this.checked;
	}

	public onInputClick(event: Event): void {
		event.stopPropagation();

		if (!this.disabled) {
			this.toggle();

			this._emitChangeEvent();
		}
	}

	public onInteractionEvent(event: Event): void {
		// We always have to stop propagation on the change event.
		// Otherwise the change event, from the input element, will bubble up and
		// emit its event object to the `change` output.
		event.stopPropagation();
	}

	private _emitChangeEvent(): void {
		this._controlValueAccessorChangeFn(this.checked);
		this.toggleChange.emit(this.checked);

		// Assigning the value again here is redundant, but we have to do it in case it was
		// changed inside the `change` listener which will cause the input to be out of sync.
		if (this._inputElement) {
			this._inputElement.nativeElement.checked = this.checked;
		}
	}
}
