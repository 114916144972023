import { Component } from '@angular/core';
import { NotificationComponent } from '@edr/styleguide';

/**
 * This component is used as a health check & ready state indicator for Kubernetes.
 * Kubernetes will make a request to the /healthz and healthz/ready endpoints of the EDR web app
 * to see if the application has successfully deployed to the K8s cluster
 */
@Component({
	selector: 'edr-app-healthz',
	template: `
		<div class="health-notification">
			<edr-notification
				title="Everyday Rewards Health Check"
				description="All health checks passed successfully!"
				notificationType="success"
				ctaText="Go to Everyday Rewards"
				ctaLink="/"
				[canDismiss]="false"
			>
			</edr-notification>
		</div>
	`,
	styles: [
		`
			.health-notification {
				margin: var(--spacing-5);
			}
		`,
	],
	standalone: true,
	imports: [NotificationComponent],
})
export class HealthzComponent {}
