<edr-modal title="You just Boosted for the first time!" (closeModal)="closeModal()">
	<div modal-content class="modal-content">
		<img src="/assets/images/boost-1st.svg" alt="first time boost" />
		<div>
			<p>
				Get bonus points from this Boost by shopping the offer online or in-store with our partners. Scan or use your Everyday Rewards card at
				checkout to collect your points.
			</p>
		</div>
	</div>
	<button type="button" edr-button modal-footer-secondary (click)="closeModal()" buttonStyle="secondary" buttonSize="medium">Got it</button>
</edr-modal>
