import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { BreakpointService, ContentBaseComponent, EDRBreakpoint } from '@edr/shared';
import { delay } from 'rxjs';
import { LayoutGroupItemComponent } from '../layout-group-item/layout-group-item.component';
import { EDRGroupColumns, EDRGroupItemWidthType, getAllBreakpointsColumnConfig, getFixedWidthColumnsCount } from '../../../helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentRefService } from '../../services';
import { EDRSpacing } from '../../types';

const DEAFULT_GAP = '2';

/**
 * A layout component that displays a list of items in horizontal rows or vertical columns.
 *
 * id: pass the same unique id to the layout-group as to its layout-group-item children
 *
 * itemWidthType: Fixed
 * When itemWidthType it set to fixed, width of a child item will be determined by the width of the element that you stick in the layout-group-item component
 * or use fixedItemWidth if its set,
 * Make sure all items have the same width. The child items will be placed right next to each other so give them some padding.
 * - dynamicColumnConfig is not applicable
 * - dynamicSameWidth is not applicable
 *
 * itemWidthType: Dynamic
 * When set to 'dynamic', the width of the items will be set based on dynamicSameWidth.
 * - fixedItemWidth is not applicable
 *
 * fixedItemWidth
 * Use this in combination with itemWidthType: 'fixed' to set width for each child items.
 *
 * dynamicColumnConfig
 * Use this in combination with itemWidthType: 'dynamic' to determine how many items should be displayed for each breakpoint.
 * You can also pass in { xxs: 1, md: 4 }, values for the other breakpoints will be extrapolated from the provided values.
 */
export const DEFAULT_COLUMNS_CONFIG: EDRGroupColumns = { xxs: 1, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 };
@UntilDestroy()
@Component({
	selector: 'edr-layout-group',
	templateUrl: './layout-group.component.html',
	styleUrls: ['./layout-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class LayoutGroupComponent extends ContentBaseComponent implements AfterViewInit, OnChanges {
	@Input() public id = '';
	@Input() public itemWidthType: EDRGroupItemWidthType = 'fixed';
	@Input() public fixedItemWidth?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public rerenderOnChange: any;
	@Input() public gap: EDRSpacing = DEAFULT_GAP;
	@ViewChild('groupItemList', { read: ElementRef }) public groupItemList?: ElementRef<HTMLElement>;
	public columns: number | undefined;

	private _dynamicColumnConfig: EDRGroupColumns = DEFAULT_COLUMNS_CONFIG;

	constructor(private breakpointService: BreakpointService, private cdr: ChangeDetectorRef, private componentRefservice: ComponentRefService) {
		super();
	}

	public get dynamicColumnConfig(): EDRGroupColumns {
		return this._dynamicColumnConfig;
	}
	@Input() public set dynamicColumnConfig(value: Partial<EDRGroupColumns>) {
		this._dynamicColumnConfig = getAllBreakpointsColumnConfig(value);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	private get itemCount(): number {
		return this.getGroupItems()?.length || 0;
	}

	public ngAfterViewInit(): void {
		if (this.groupItemList) {
			this.onBreakpointChange();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ('rerenderOnChange' in changes) {
			// Using setTimeout because we need to make sure to call the initialise function in the next tick, after the layout-group items have been instantiated.
			setTimeout(() => {
				this.initialise(this.breakpointService.getCurrentBreakpoint());
			}, 1);
		}
	}

	private getGroupItems(): LayoutGroupItemComponent[] {
		return this.componentRefservice.getComponentRefs(this.id) || [];
	}

	private onBreakpointChange(): void {
		this.breakpointService
			.getActiveBreakpoint()
			.pipe(delay(0), untilDestroyed(this))
			.subscribe((breakpoint) => {
				this.initialise(breakpoint);
			});
	}

	private initialise(breakpoint: EDRBreakpoint): void {
		this.columns =
			this.itemWidthType === 'dynamic'
				? (this.dynamicColumnConfig && this.dynamicColumnConfig[breakpoint]) || 1
				: getFixedWidthColumnsCount(this.itemCount, this.groupItemList);

		this.getGroupItems().forEach((item) => {
			item.columns = this.columns;
			item.itemWidthType = this.itemWidthType;
			item.fixedWidth = this.fixedItemWidth;
		});
		this.cdr.markForCheck();
	}
}
