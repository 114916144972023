import { Inject, Injectable } from '@angular/core';
import { Client, createInstance, EventTags, OptimizelyDecision, OptimizelyUserContext, setLogLevel } from '@optimizely/optimizely-sdk';
import { FlagKey } from './flag-key.enum';
import { Observable, Subscription, filter, from, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { CustomWindow, LoggingService } from '@edr/shared';
import { FlagStore } from './flag.store';
import { WINDOW } from '@ng-web-apis/common';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { CookieService } from 'ngx-cookie-service';
import { AuthFacade, UserAuthData } from '../../auth/+state/auth.facade';

const OPTIMIZELY_TIMEOUT = 3000;
const NO_ACTIVE_VARIATION = 'off';

export interface FlagVariation {
	flagKey: FlagKey;
	variationKey: string;
}

export type Flag = {
	[key in FlagKey]?: string | null;
};

/**
 * This service implements Optimizely Feature Experimentation (previously Optimizely Full Stack)
 *
 * Usage:
 * 1. Setup your experiment in the Optimizely dashboard
 * 2. Add the key of your flag to the FlagKey enum
 * 3. In your component or whereever you need it, call one of the public methods of this class with the flag key to get the desired info
 *
 * Bucketing:
 * If the user matches the audience conditions, the user is bucketed based on their user id (which comes from the cookie 'appsettings-browserSessionId').
 * Once bucketed, the result is persisted on Optimizely's side so a user will always see the variation.
 *
 * Force bucketing:
 * When testing or developing an flag/experiment it can be useful to force yourself into a specific variation.
 * Add this query parameter to the url to force bucket yourself: ?force-variations=flagKey/variationKey,anotherFlagKey/control
 *
 * Debugging:
 * If you need to debug anything related to Optimizely you can customise the log level by passing in a query param.
 * The log levels are: NOTSET, DEBUG, INFO, WARNING, ERROR (default).
 * This will work in all environments: ?optimizely-log-level=DEBUG
 *
 * Pro tips:
 * - When setting up the code for your flag/experiment, write your code in such a way that it will be easy to clean up afterwards. also for
 *   someone who doesn't know anything about the experiment. Try to keep your experiment code as isolated as possible.
 * - Always use 'control' as the key for your control variation.
 * - Using the predefined 'Off' variation is tricky as it will set decision.enabled to false, thus potentially having uninteded effects on metrics.
 * - Use the word variation (and not variant) to avoid confusion and mix-ups. They mean the same thing, but just for consistency.
 * - Try to keep all experiment/flag data in the folder experiment-data so it's easier to clean up when the experiment is done.
 */
@Injectable({
	providedIn: 'root',
})
export class FlagService {
	private optimizelyClient: Client | null | undefined;
	private user: OptimizelyUserContext | null | undefined;
	private prevUserId: string | undefined;
	private scvId: string | undefined;

	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		private store: FlagStore,
		private loggingService: LoggingService,
		private cookieService: CookieService,
		private tealiumService: TealiumUtagService,
		private authFacade: AuthFacade
	) {}

	// Tech debt: https://woolworths-agile.atlassian.net/browse/CLSE-2477
	public initialize(optimizelyKey: string): Subscription | undefined {
		if (!optimizelyKey) {
			return undefined;
		}
		return this.authFacade.userAuthData$
			.pipe(
				tap((user: UserAuthData) => {
					this.scvId = user.scvId;
				}),
				tap(() => this.store.updateEnabled(true)),
				switchMap(() => {
					setLogLevel(this.getLogLevel());
					this.optimizelyClient = createInstance({
						sdkKey: optimizelyKey,
						eventBatchSize: 10,
						eventFlushInterval: 1000,
					});
					if (!this.optimizelyClient) {
						const errorMessage = 'Error creating optimizelyClient instance';
						this.handleError(errorMessage);
						return throwError(() => new Error(errorMessage));
					}
					return from(this.optimizelyClient.onReady({ timeout: OPTIMIZELY_TIMEOUT })).pipe(
						map(({ success, reason }) => {
							if (reason) {
								this.handleError(reason);
								throw new Error(reason);
							}
							return success;
						})
					);
				})
			)
			.subscribe({
				next: () => {
					const userId = this.cookieService.get('browserSessionId');
					this.user = this.optimizelyClient?.createUserContext(userId);
					this.user?.setAttribute('scvId', this.scvId);
					this.applyForcedVariations();
					this.store.updateInitialised();
				},
				error: (error: string) => {
					this.handleError(error);
					this.store.updateInitialised();
				},
			});
	}

	/**
	 * Returns 'off' when flag is not active or if Optimizely failed to initialise, otherwise returns the variation key
	 *
	 * Example usage:
	 * Component class:
	 * public myFlagVariation$: Observable<string> = this.flagService.getVariationKey('myFlagExperiment');
	 *
	 * Template:
	 * <ng-container *ngIf="myFlagVariation$ | async as myFlagVariation; else loading">
	 *   <div *ngIf="myFlagVariation === 'v1'">This is variation 1</div>
	 *   <div *ngIf="myFlagVariation === 'v2'">This is variation 2</div>
	 * 	 <div *ngIf="myFlagVariation === 'control' || myFlagVariation === 'off'">This is the control variation</div>
	 * </ng-container>
	 * <ng-template #loading>Optionally display content or spinner while loading<ng-template>
	 */
	public getVariationKey(flagKey: FlagKey): Observable<string | 'off'> {
		return this.getFlagDecision(flagKey).pipe(map((decision) => decision?.variationKey || NO_ACTIVE_VARIATION));
	}

	public isFlagEnabled(flagKey: FlagKey): Observable<boolean> {
		return this.getFlagDecision(flagKey).pipe(map((decision) => !!decision?.enabled));
	}

	public isInactiveOrControl(flagKey: FlagKey): Observable<boolean> {
		return this.getFlagDecision(flagKey).pipe(map((decision) => !decision?.variationKey || decision.variationKey === 'control'));
	}

	public someFlagVariationActive(flagKey: FlagKey, variationKeys: string[]): Observable<boolean> {
		return this.getFlagDecision(flagKey).pipe(map((decision) => !!decision?.variationKey && variationKeys.includes(decision.variationKey)));
	}

	/**
	 * Returns decision from state if it exists there, otherwise calls the decide method.
	 * Also makes sure the flag activation is tracked when decide is called.
	 * @param flagKey
	 * @returns OptimizelyDecision that contains bucketing information
	 * @returns null when the user is not bucketed for this flag (for example: when Optimizely failed to initialise or is not enabled)
	 */
	public getFlagDecision(flagKey: FlagKey): Observable<OptimizelyDecision | null> {
		if (!this.store.state$) {
			const decision = this.user?.decide(flagKey) || null;
			this.store.addFlag({ flagKey, decision });
			return of(decision);
		}
		return this.store.state$.pipe(
			filter((state) => state.initialised),
			take(1),
			map((state) => {
				if (!state.enabled || state.error) {
					return null;
				}
				if (!!state.flags && state.flags[flagKey]) {
					return state.flags[flagKey] as OptimizelyDecision | null;
				}
				const decision = this.user?.decide(flagKey) || null;
				if (decision?.variationKey) {
					this.trackFlagDecision({ flagKey, variationKey: decision.variationKey });
				}
				this.store.addFlag({ flagKey, decision });
				return decision;
			})
		);
	}

	/**
	 * Use this method to track any metrics for your experiment. This allows you to track the experiment results
	 * in the Optimizely dashboard.
	 */
	public trackEvent({ eventKey, eventTags }: { eventKey: string; eventTags?: EventTags }): void {
		if (!this.user?.getUserId()) {
			return;
		}
		this.optimizelyClient?.track(eventKey, this.user?.getUserId(), this.user?.getAttributes(), eventTags);
	}

	public getLogLevel(): number {
		const userDefinedLevel = this.getQueryParamValue('optimizely-log-level');
		switch (userDefinedLevel) {
			case 'NOTSET':
				return 0;
			case 'DEBUG':
				return 1;
			case 'INFO':
				return 2;
			case 'WARNING':
				return 3;
			default:
				return 4;
		}
	}

	private handleError(error: string): void {
		this.store.updateError(error);
		this.loggingService.error(error);
	}

	private applyForcedVariations(): void {
		this.getForcedVariations().forEach(({ flagKey, variationKey }) => {
			const decision = this.user?.decide(flagKey) || null;
			if (decision) {
				decision.variationKey = variationKey;
			}
			this.user?.setForcedDecision({ flagKey }, { variationKey });
			// need to set the forced flag to the store otherwise we won't get it back in the decision function - somehow the user object won't keep this value
			this.store.addFlag({ flagKey, decision });
		});
	}

	private getForcedVariations(): FlagVariation[] {
		return this.getQueryParamValue('force-variations')
			.split(',')
			.map((experimentAndVariation) => {
				if (experimentAndVariation) {
					this.loggingService.log(experimentAndVariation);
				}
				const arr = decodeURIComponent(experimentAndVariation).split('/');
				return { flagKey: arr[0] as FlagKey, variationKey: arr[1] };
			})
			.filter((forcedVariation) => Object.values(FlagKey).includes(forcedVariation.flagKey) && !!forcedVariation.variationKey);
	}

	private getQueryParamValue(param: string): string {
		return (
			this.window.location.search
				.slice(1)
				.split('&')
				.find((queryParam: string) => queryParam.startsWith(param)) || ''
		).replace(`${param}=`, '');
	}

	private trackFlagDecision({ flagKey, variationKey }: { flagKey: FlagKey; variationKey: string }): void {
		this.tealiumService.track('optimizely', { flagKey, variationKey });
	}
}
