import { Component, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'edr-error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.components.scss'],
	// The change detection strategy should not be OnPush,
	// as this will not allow us to receive "touched" and "dirty" events on the form control
	changeDetection: ChangeDetectionStrategy.Default,
	standalone: true,
	imports: [IconComponent],
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
	@Input() public controlName!: string;
	@Input() public controlLabel!: string;

	private _formControl: AbstractControl | null = null;
	private _subscriptions = new Subscription();
	private _formControlErrorMessages: string[] = [];
	private _formControlLabel = '';

	constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

	public get formControl(): AbstractControl | null {
		return this._formControl;
	}
	public get formControlErrorMessages(): string[] {
		return this._formControlErrorMessages;
	}

	public ngOnInit(): void {
		if (this.controlContainer && this.controlContainer.control && this.controlName) {
			this._formControl = this.controlContainer.control.get(this.controlName);
			if (this._formControl) {
				if (this.controlLabel) {
					this._formControlLabel = this.controlLabel;
				} else {
					// Create human readable form control label from formControlName by splitting on Capital Letters e.g. 'firstName' --> 'first name'
					this._formControlLabel =
						this.controlName
							.match(/(([a-z]+)|([A-Z][a-z]+))/g)
							?.map((x) => x.toLowerCase())
							?.join(' ') ?? '';
				}

				this._subscriptions.add(this._formControl.valueChanges.subscribe(this.calculateFormValueErrors.bind(this)));
				this.calculateFormValueErrors();
			}
		}
	}

	public ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	public calculateFormValueErrors(): void {
		if (!this._formControl || this._formControl?.valid) {
			this._formControlErrorMessages = [];
		} else {
			const newFormErrorMessages: string[] = [];
			if (this._formControl.hasError('required')) {
				newFormErrorMessages.push(`Please enter ${this._formControlLabel}.`);
			}
			if (this._formControl.hasError('minlength')) {
				const requiredLength = this._formControl.getError('minlength').requiredLength;
				newFormErrorMessages.push(`The minimum length for ${this._formControlLabel} is ${requiredLength}.`);
			}
			if (this._formControl.hasError('min')) {
				const minValue = this._formControl.getError('min').min;
				newFormErrorMessages.push(`The minimum ${this._formControlLabel} is ${minValue}.`);
			}
			if (this._formControl.hasError('maxlength')) {
				const requiredLength = this._formControl.getError('maxlength').requiredLength;
				newFormErrorMessages.push(`The maximum length for ${this._formControlLabel} is ${requiredLength}.`);
			}
			if (this._formControl.hasError('max')) {
				const maxValue = this._formControl.getError('max').max;
				newFormErrorMessages.push(`The maximum ${this._formControlLabel} is ${maxValue}.`);
			}
			if (this._formControl.hasError('email')) {
				newFormErrorMessages.push(`Please enter a valid ${this._formControlLabel}.`);
			}
			if (this._formControl.hasError('pattern')) {
				newFormErrorMessages.push(`The entered ${this._formControlLabel} is not valid.`);
			}

			this._formControlErrorMessages = newFormErrorMessages;
		}
	}
}
