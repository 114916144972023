<edr-app-header (skipToContent)="skipToContent()"></edr-app-header>
<router-outlet name="modal"></router-outlet>
<div class="float-container">
	<div id="main" #mainContent aria-label="Main page content" tabindex="-1">
		<router-outlet></router-outlet>
	</div>
	<edr-app-mandy-roundel></edr-app-mandy-roundel>
</div>

<edr-app-footer></edr-app-footer>

<edr-svg-definitions></edr-svg-definitions>

<ng-template #authErrorModal>
	<edr-modal title="There was a problem" (closeModal)="handleClose()">
		<p modal-content>We could not determine whether you are logged in or not.</p>
		<button type="button" edr-button (click)="handleLogin()" buttonSize="medium" modal-footer-primary>Login</button>
		<button type="button" edr-button buttonStyle="secondary" (click)="handleClose()" buttonSize="medium" modal-footer-secondary>Cancel</button>
	</edr-modal>
</ng-template>
