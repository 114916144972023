import { Pipe, PipeTransform } from '@angular/core';
import { documentToHtmlString, Next } from '@contentful/rich-text-html-renderer';
import { Document, BLOCKS, INLINES, Block, Inline, Node as ContentfulNode } from '@contentful/rich-text-types';
import { replaceShortCodes } from './short-codes/short-codes';
import { CtaPropsResponse, ImagePropsResponse } from '@edr/bff-api-models';

interface NodeDataTarget {
	contentTypeId: string;
	entryTitle: string;
}

export interface RichTextCtaImagePropsResponse extends ImagePropsResponse {
	image: {
		file: {
			url: string;
			details: {
				image: {
					height: number;
					width: number;
				};
			};
		};
	};
}

@Pipe({
	name: 'contentfulToHtml',
	standalone: true,
})
export class ContentfulToHtmlPipe implements PipeTransform {
	public transform(value: Document | string, ..._args: unknown[]): string {
		if (typeof value === 'string') {
			return value;
		}

		const options = {
			renderMark: {},
			renderNode: {
				[BLOCKS.PARAGRAPH]: (node: Block | Inline, next: Next) => `<p>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</p>`,
				[BLOCKS.HEADING_1]: (node: Block | Inline, next: Next) => `<h1>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</h1>`,
				[BLOCKS.HEADING_2]: (node: Block | Inline, next: Next) => `<h2>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</h2>`,
				[BLOCKS.HEADING_3]: (node: Block | Inline, next: Next) => `<h3>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</h3>`,
				[BLOCKS.HEADING_4]: (node: Block | Inline, next: Next) => `<h4>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</h4>`,
				[BLOCKS.HEADING_5]: (node: Block | Inline, next: Next) => `<h5>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</h5>`,
				[BLOCKS.HEADING_6]: (node: Block | Inline, next: Next) => `<h6>${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</h6>`,
				[INLINES.EMBEDDED_ENTRY]: (node: ContentfulNode) => this.buildEmbeddedEntryHtmlString(node),
				[INLINES.ASSET_HYPERLINK]: (node: Block | Inline, next: Next) =>
					`<a href="${node.data['target'].file.url}" target="_blank">${replaceShortCodes(next(node.content).replace(/\n/g, '<br/>'))}</a>`,
			},
		};
		return documentToHtmlString(value as Document, options);
	}

	private buildEmbeddedEntryHtmlString(node: ContentfulNode): string {
		const nodeTarget = node.data['target'] as NodeDataTarget;
		if (nodeTarget.contentTypeId === 'ctaProps') {
			return this.buildCtaHtmlString(nodeTarget as CtaPropsResponse);
		}

		return '';
	}

	private buildCtaHtmlString(ctaData: CtaPropsResponse): string {
		if (ctaData.image) {
			const ctaImageProps = ctaData.image as RichTextCtaImagePropsResponse;
			const url = ctaImageProps.image.file.url;
			const { height, width } = ctaImageProps.image.file.details.image;
			const imgHtmlString = `<img src="${url}" alt="${ctaData.image.altText}" height="${height}" width="${width}" />`;

			return `<a href="${ctaData.linkUrl}" target="${ctaData.target}">${imgHtmlString}</a>`;
		} else {
			const ctaBaseClass = 'cta-button';
			const ctaSize = `${ctaBaseClass}__size__${ctaData.size}`;
			let ctaStyle = `${ctaBaseClass}__style__${ctaData.style}`;

			if (ctaData.styleReversed) {
				ctaStyle += '__reversed';
			}

			return `<a class="${ctaBaseClass} ${ctaSize} ${ctaStyle} no-link" href="${ctaData.linkUrl}" target="${ctaData.target}">${ctaData.text}</a>`;
		}
	}
}
