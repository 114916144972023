import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as MemberActions from './profile.actions';
import * as AuthActions from '../../../auth/+state/auth.actions';
import { catchError, EMPTY, map, mergeMap, of, switchMap } from 'rxjs';
import { PersonalDetailsService } from '../../../../features/my-account/services';
import { Store } from '@ngrx/store';
import { selectAuthIsLoggedIn } from '../../../auth';
import { capitalize, LoggingService } from '@edr/shared';
import { MemberProfileResponse } from '@edr/bff-api-models';
import { selectMemberProfile } from './profile.selectors';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class ProfileEffects {
	public onLoadProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MemberActions.loadProfile),
			concatLatestFrom(() => this.store.select(selectAuthIsLoggedIn)),
			switchMap(([, isLoggedIn]) => {
				if (isLoggedIn) {
					return this.personalDetailsService.fetchCustomerProfile().pipe(
						map((profile: MemberProfileResponse) => ({
							...profile,
							firstName: capitalize(profile.firstName),
							lastName: capitalize(profile.lastName),
						})),
						mergeMap((payload) => [MemberActions.loadProfileSuccess({ profile: payload }), AuthActions.authInitialiseComplete()]),
						catchError((error) => {
							this.loggingService.error(error);
							return of(MemberActions.loadProfileFailure());
						})
					);
				}
				return EMPTY;
			})
		)
	);

	public onUpdateProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MemberActions.updateProfile),
			concatLatestFrom(() => this.store.select(selectMemberProfile)),
			switchMap(([action, profile]) => {
				if (!profile || action.fieldName === 'currentStaffMember') {
					return of(MemberActions.updateProfileFailure({}));
				}
				const updatedProfile: MemberProfileResponse = { ...profile };
				updatedProfile[action.fieldName] = action.value ?? '';
				return this.personalDetailsService.updateCustomerProfile(action.fieldName, action.value).pipe(
					map(() => MemberActions.updateProfileSuccess({ profile: updatedProfile, successMessage: action.successMessage })),
					catchError((error) => {
						this.loggingService.error(error);
						return of(MemberActions.updateProfileFailure({ errorStatusCode: error.status, errorCode: error.error.code }));
					})
				);
			})
		)
	);

	constructor(
		private personalDetailsService: PersonalDetailsService,
		private actions$: Actions,
		private store: Store,
		private loggingService: LoggingService
	) {}
}
