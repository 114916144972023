import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { HeaderComponentStore, HeaderState } from './header.component.store';
import { filter, Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCleanPath } from '../../../router.selector';
import { NavItem } from './header.interface';
import { EnvFacade } from '@edr/env';
import { selectAuthIsLoggedIn, userLogOut } from '../../auth';
import { AuthService } from '../../services';
import { WINDOW } from '@ng-web-apis/common';
import { CustomWindow } from '@edr/shared';
import { AsyncPipe } from '@angular/common';
import { ClickOutsideDirective, IconComponent, TypographyComponent } from '@edr/styleguide';
import { PrimaryNavigationComponent } from '../primary-navigation/primary-navigation.component';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'edr-app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [HeaderComponentStore],
	standalone: true,
	imports: [AsyncPipe, RouterLink, IconComponent, PrimaryNavigationComponent, TypographyComponent, ClickOutsideDirective],
})
export class HeaderComponent {
	@Output() public skipToContent = new EventEmitter<void>();
	public state$: Observable<HeaderState>;
	public isAuthenticated$: Observable<boolean>;
	public currentUrl$: Observable<string>;
	public activeSubNav$: Observable<NavItem | undefined>;
	public visiblePrimaryNavItems$: Observable<NavItem[]>;
	public visibleSecondaryNavItems$: Observable<NavItem[]>;
	public visibleAccountNavItems$: Observable<NavItem[]>;

	constructor(
		private headerStore: HeaderComponentStore,
		private store: Store,
		private envFacade: EnvFacade,
		private authService: AuthService,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.state$ = this.headerStore.selectState$;
		this.visiblePrimaryNavItems$ = this.headerStore.selectVisiblePrimaryNavItems$;
		this.visibleSecondaryNavItems$ = this.headerStore.selectVisibleSecondaryNavItems$;
		this.visibleAccountNavItems$ = this.headerStore.selectVisibleAccountNavItems$;
		this.isAuthenticated$ = this.store
			.select(selectAuthIsLoggedIn)
			.pipe(tap((isAuthenticated) => this.headerStore.updateIsAuthenticated(isAuthenticated)));
		this.currentUrl$ = this.store.select(selectCleanPath).pipe(filter(Boolean));
		this.headerStore.loadNavItems(this.currentUrl$);
		this.headerStore.handleBreakpointChange(this.envFacade.breakpointMatches(['xxs', 'xs', 'sm', 'md']));
		this.activeSubNav$ = this.headerStore.selectActiveSubNav$;
	}

	public toggleMyAccount(expand: boolean, event?: MouseEvent): void {
		event?.stopPropagation();
		this.headerStore.updateIsMyAccountExpanded(expand);
	}

	public toggleMyAccountMobile(expand: boolean): void {
		this.closeAllNavs();
		this.headerStore.updateIsMyAccountExpanded(expand);
	}

	public toggleMobileNav(expand: boolean, event?: MouseEvent): void {
		event?.stopPropagation();
		this.toggleMobileSecondaryNav(false);
		this.toggleMyAccount(false);
		this.headerStore.updateIsMobileNavExpanded(expand);
	}

	public toggleMobileSecondaryNav(expand: boolean, event?: MouseEvent): void {
		event?.stopPropagation();
		this.headerStore.updateIsMobileNavExpanded(false);
		this.headerStore.updateIsMobileSecondaryNavExpanded(expand);
	}

	public closeAllNavs(): void {
		this.toggleMyAccount(false);
		this.toggleMobileNav(false);
		this.toggleMobileSecondaryNav(false);
	}

	public handleLogin(): void {
		// Tech debt:
		// Login flow should be started by dispatching an event that has an optional param for the url to route to afterwards.
		// That url should be retrieved from the router state by default.
		this.authService.startLoginFlow(this.window.location.href).subscribe();
	}

	public handleLogout(): void {
		this.store.dispatch(userLogOut());
	}
}
