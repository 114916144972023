<div class="reward">
	<div class="reward__icon">
		<edr-icon [name]="iconName" [color]="isError ? 'extended--charcoal-light-50' : iconColor" [size]="(isSmallDevice$ | async) ? '6' : '10'" />
	</div>
	@if (!isError) {
		<div class="reward__text">
			@if (rewardLine) {
				<edr-heading [level]="4">{{ rewardLine }}</edr-heading>
			}
			<p edr-typography typographyAs="body--xxs">{{ textLine }}</p>
		</div>
	}

	@if (!isError) {
		<div class="reward__help-icon">
			@if (helpToolTip) {
				<edr-icon
					name="system-tooltip-help"
					color="secondary--charcoal"
					size="4"
					[edrTooltip]="helpToolTip.message"
					tooltipTypographyStyle="body--xxs"
					[tooltipAlignment]="helpToolTip.alignment"
					[tooltipTrigger]="helpToolTip.trigger"
					[tooltipPosition]="helpToolTip.position"
					[tooltipCtaText]="helpToolTip.ctaText"
					[tooltipCtaLink]="helpToolTip.ctaLink"
					[tooltipShowCloseButton]="(isSmallDevice$ | async) === true"
				/>
			}
		</div>
	}
</div>
