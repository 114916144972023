import { TrackingDirective } from './tracking.directive';
import { Directive, inject, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[edrTrackNavClick]',
	hostDirectives: [TrackingDirective],
	standalone: true,
})
export class TrackingNavigationDirective implements OnInit {
	private _trackContentText = '';
	private _trackEventDir = inject(TrackingDirective, { self: true });

	public get trackContentText(): string {
		return this._trackContentText;
	}
	@Input('edrTrackNavClick') public set trackContentText(value: string) {
		this._trackContentText = value;
	}

	public ngOnInit(): void {
		this._trackEventDir.trackingData = {
			tealium_event: 'select_content',
			content_type: 'Navigation',
			content_text: this.trackContentText,
			content_component: 'Accordion',
		};
	}
}
