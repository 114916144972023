import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommunicationPreferencesStore } from './communications-preferences.store';
import { Observable, filter } from 'rxjs';
import { ContentBaseComponent, notUndefined } from '@edr/shared';
import { PartnerPropResponse } from '@edr/bff-api-models';
import { EDRCommunicationPreferenceGroup } from './edr-communication-preference.model';
import { PartnersFacade } from '../../../features/partners/+state';
import {
	AccordionComponent,
	AccordionItemCardComponent,
	HeadingComponent,
	IconComponent,
	NotificationComponent,
	SpinnerComponent,
	ToggleComponent,
	TypographyComponent,
} from '@edr/styleguide';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'edr-app-communications-preferences',
	templateUrl: './communications-preferences.component.html',
	styleUrls: ['./communications-preferences.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CommunicationPreferencesStore],
	standalone: true,
	imports: [
		AccordionComponent,
		AccordionItemCardComponent,
		AsyncPipe,
		HeadingComponent,
		IconComponent,
		NgTemplateOutlet,
		NotificationComponent,
		SpinnerComponent,
		ToggleComponent,
		TypographyComponent,
	],
})
export class CommunicationsPreferencesComponent extends ContentBaseComponent implements OnInit {
	@Input() public preferenceGroupConfig: EDRCommunicationPreferenceGroup[] = [];

	public isLoading$: Observable<boolean> = this.store.isLoading$;
	public preferenceGroups$: Observable<EDRCommunicationPreferenceGroup[]> = this.store.preferenceGroups$;
	public readonly communicationPartners$ = this.partnersFacade.preferencePartners$.pipe(filter(notUndefined));

	constructor(private readonly store: CommunicationPreferencesStore, private partnersFacade: PartnersFacade) {
		super();
	}

	public preferenceChanged(id: string, value: boolean): void {
		this.store.updatePreference({ id, value });
	}

	public ngOnInit(): void {
		this.communicationPartners$.subscribe((partners) => {
			const preferenceGroups = this.mergePartnerPreferenceGroup(this.preferenceGroupConfig, partners);
			this.store.updatePreferenceGroups(preferenceGroups);
		});
	}

	private mergePartnerPreferenceGroup(
		preferenceGroups: EDRCommunicationPreferenceGroup[],
		edrPartners: PartnerPropResponse[]
	): EDRCommunicationPreferenceGroup[] {
		const partnerPreferenceGroup = preferenceGroups.find((x) => x.isPartnerPreferences);
		if (partnerPreferenceGroup) {
			partnerPreferenceGroup.preferences = [];
			edrPartners.forEach((edrPartner) => {
				if (edrPartner.communicationPreferenceId) {
					partnerPreferenceGroup.preferences.push({
						logo: {
							src: edrPartner.logo.url,
							altText: edrPartner.logo.altText,
						},
						id: edrPartner.communicationPreferenceId,
						value: false,
						title: edrPartner.name,
						isAlcohol: edrPartner.isAlcohol,
					});
				}
			});
		}
		return preferenceGroups;
	}
}
