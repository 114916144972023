@if (transaction) {
<div class="info">
	<div class="partner">
		<edr-circle size="10" backgroundColor="primary--white" borderColor="secondary--mid-grey" borderWidth="1px">
			@if (partnerLogo$ | async; as partnerLogo) {
			<img [src]="partnerLogo.src" [alt]="partnerLogo.alt" />
			}
		</edr-circle>
		<div class="partner__text">
			<edr-heading [level]="5"> {{ transaction.transactionDateTime | date : 'EEE dd MMM' }}</edr-heading>
			<p>{{ transaction.description }}</p>
		</div>
	</div>
	<div class="amount">
		<p class="info__title--light">Purchased</p>
		<p class="amount__value">{{ transaction.amount | amountDisplay : ActivityDetailsUomType.Dollars }}</p>
	</div>
	<div class="bonus-points">
		<p class="info__title">
			Bonus points
			<edr-icon
				name="system-tooltip-help"
				color="secondary--charcoal"
				size="4"
				edrTooltip="Bonus points can be collected through Boosts and other special offers."
				[tooltipShowCloseButton]="true"
				[tooltipTrigger]="TooltipTrigger.Focus"
				[tooltipPosition]="TooltipPosition.Top"
				[tooltipAlignment]="TooltipAlignment.Middle"
			></edr-icon>
		</p>
		<p class="bonus-points__value">{{ transaction.bonusPoints | pointsDisplay : 'bonusPoints' }}</p>
	</div>
	<div class="points">
		<p class="info__title">Points</p>
		@if (!!transaction.totalPoints) {
		<p [ngClass]="transaction.totalPoints < 0 ? 'points__value--negative' : 'points__value'">
			{{ transaction.totalPoints | pointsDisplay : 'points' }}
		</p>
		}
	</div>
</div>
@if (transaction.isActivityDetailsAvailable) {
<div class="points-breakdown">
	<edr-app-points-details-table [transaction]="transaction" [columnList]="detailsColumnsList"></edr-app-points-details-table>
</div>
} @if (transaction.redeemed?.length || transaction.rewards?.length) {
<div class="stripes">
	@for (redeemed of transaction.redeemed; track redeemed) {
	<div class="stripes__redeemed">
		<edr-stripe [text]="redeemed.description!" textColor="secondary--charcoal" backgroundColor="family--mint"></edr-stripe>
	</div>
	} @for (reward of transaction.rewards; track reward) {
	<div class="stripes__rewards">
		<edr-stripe [text]="reward.description!" textColor="primary--white" backgroundColor="primary--orange"></edr-stripe>
	</div>
	}
</div>
} }
