import { TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS } from '../points-table/points-table.mock';
import { PointsTransactionTable, PointTableColumn } from '../points-table/points-table.model';
import { PointsAndActivityService } from '../../../features/my-account/services/points-and-activity.service';
import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ActivityListResponse } from '@edr/bff-api-models';
import { LoggingService } from '@edr/shared';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { APP_ROUTES } from '../../../routes';
import { DatePipe } from '@angular/common';

export interface ActivityListByMonth {
	columns: PointTableColumn[];
	activities: PointsTransactionTable[];
}

export interface PointsTableState {
	activityListByMonth: ActivityListByMonth[];
	isLoading: boolean;
	hasError: boolean;
}

export const defaultState: PointsTableState = {
	activityListByMonth: [],
	isLoading: true,
	hasError: false,
};

@Injectable()
export class PointsTableStore extends ComponentStore<PointsTableState> {
	/** Selectors **/
	public readonly selectPointsTableTemplateData$ = this.select((state) => ({
		isLoading: state.isLoading,
		activityListByMonth: state.activityListByMonth,
		hasActivityList: state.activityListByMonth.some((activityList) => activityList.activities.length > 0),
	}));

	/** Updaters */
	public readonly updateActivityList = this.updater(
		(state, activityListByMonth: ActivityListByMonth[]): PointsTableState => ({
			...state,
			activityListByMonth: activityListByMonth,
			isLoading: false,
		})
	);

	/** Effects */
	public readonly fetchActivityList = this.effect((trigger$) =>
		trigger$.pipe(
			switchMap(() =>
				this.pointsAndActivityService.fetchActivityList().pipe(
					tapResponse(
						(activityList) => {
							const mappedActivityList = activityList.map(this.mapActivityList);
							const activityListByMonth = this.splitActivityListByMonth(mappedActivityList);

							this.updateActivityList(activityListByMonth);
						},
						(error) => {
							this.logger.error(error);
							this.router.navigate(['/', APP_ROUTES.error, '500']);
						}
					)
				)
			)
		)
	);

	constructor(
		private logger: LoggingService,
		private pointsAndActivityService: PointsAndActivityService,
		private router: Router,
		private datePipe: DatePipe
	) {
		super(defaultState);
	}

	private mapActivityList(activity: ActivityListResponse): PointsTransactionTable {
		return {
			...activity,
			partnerDetails: {
				partnerId: activity.partnerId,
				partnerName: activity.description,
				transactionDateTime: activity.transactionDateTime,
			},
		};
	}

	private splitActivityListByMonth(activityList: PointsTransactionTable[]): ActivityListByMonth[] {
		const activitiesByMonths: ActivityListByMonth[] = [];
		const uniqueTransactionMonths = activityList
			.map((activity) => {
				const date = new Date(activity.transactionDateTime as string);
				return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
			})
			.filter(this.uniqueMonth);

		uniqueTransactionMonths.forEach((month) => {
			const monthActivities = activityList.filter((activity) => {
				const date = new Date(activity.transactionDateTime as string);
				return date.getFullYear() === month.getFullYear() && date.getMonth() === month.getMonth();
			});

			activitiesByMonths.push({
				columns: [
					{
						displayName: this.getColumnNameForMonth(month),
						name: 'partnerDetails',
					},
					...TABLE_COLUMNS_WITHOUT_PARTNER_DETAILS,
				],
				activities: monthActivities,
			});
		});

		return activitiesByMonths;
	}

	private uniqueMonth(value: Date, index: number, array: Date[]): boolean {
		return array.findIndex((date) => date.getFullYear() === value.getFullYear() && date.getMonth() === value.getMonth()) === index;
	}

	private getColumnNameForMonth(month: Date): string {
		const now = new Date();
		const currentMonth = now.getMonth();
		const currentYear = now.getFullYear();

		if (month.getMonth() === currentMonth && month.getFullYear() === currentYear) {
			return 'This Month';
		}
		if (month.getMonth() === currentMonth - 1 && month.getFullYear() === currentYear) {
			return 'Last Month';
		}

		return this.datePipe.transform(month, 'MMM yyyy') ?? '';
	}
}
