import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pointsDisplay', standalone: true })
export class PointsDisplayPipe implements PipeTransform {
	public transform(value: number | undefined, columnName: 'points' | 'bonusPoints'): string {
		if (!value) {
			return `-`;
		}
		return (columnName === 'bonusPoints' ? '+' : '') + value + 'pts';
	}
}
