import { SHORT_CODE_DEFINITIONS } from '../short-codes';

/**
 * Replaces the `[anchor-point]` shortcode with `span` elements
 * @param content Content string containing shortcode to be replaced
 * @param regexMatch RegEx matches for the shortcode: `[anchor-point myUniqueAnchorPointName]`
 * @returns Hydrated content: `<span class="anchor-point-myUniqueAnchorPointName" />`
 */
export const replaceAnchorPointShortCode = (content: string, regexMatch: RegExpMatchArray): string => {
	if (!regexMatch.groups || !regexMatch.groups[SHORT_CODE_DEFINITIONS.CODE] || !regexMatch.groups[SHORT_CODE_DEFINITIONS.VALUE]) {
		return content;
	}

	return content.replace(
		`[${regexMatch.groups['code']} ${regexMatch.groups['value']}]`,
		`<span class="anchor-point-${regexMatch.groups['value'].toLowerCase()}" />`
	);
};
