import { ObjectValues } from '../helpers';

export const ImageFocusArea = {
	None: 'none',
	Center: 'center',
	Top: 'top',
	Right: 'right',
	Left: 'left',
	Bottom: 'bottom',
	TopRight: 'top_right',
	TopLeft: 'top_left',
	BottomRight: 'bottom_right',
	BottomLeft: 'bottom_left',
	Face: 'face',
	Faces: 'faces',
	Custom: 'custom',
} as const;

export type ImageFocusArea = ObjectValues<typeof ImageFocusArea>;
