import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProfileSelectors from './profile/profile.selectors';
import * as MemberSelectors from './redemption-preferences/redemption-preferences.selectors';
import * as ProfileActions from './profile/profile.actions';
import * as MemberActions from './redemption-preferences/redemption-preferences.actions';
import * as BalancesSelectors from './balances/balances.selectors';
import * as BalancesActions from './balances/balances.actions';
import { filter, map, Observable, switchMap, take } from 'rxjs';
import { BalancesState } from './balances';
import * as WelcomeTutorialSelectors from './welcome-tutorial/welcome-tutorial.selectors';
import * as WelcomeTutorialActions from './welcome-tutorial/welcome-tutorial.actions';
import { FlagKey, FlagService } from '../../services';
import { MemberRedemptionPreferencesResponse, RedemptionPreference } from '@edr/bff-api-models';

@Injectable({ providedIn: 'root' })
export class MemberFacade {
	// Profile
	public readonly profileState$ = this.store.select(ProfileSelectors.selectProfileState);
	public readonly memberProfile$ = this.store.select(ProfileSelectors.selectMemberProfile);
	public readonly isLoadingProfile$ = this.store.select(ProfileSelectors.selectIsLoadingProfile);
	public readonly isUpdatingProfile$ = this.store.select(ProfileSelectors.selectIsUpdatingProfile);

	// Balances
	public readonly balancesState$: Observable<BalancesState> = this.store.select(BalancesSelectors.selectBalancesState);
	public readonly totalPointsBalance$: Observable<number> = this.store.select(BalancesSelectors.selectTotalPointsBalance);

	// Rewards Choice Preferences
	public readonly redemptionPreferencesState$ = this.store.select(MemberSelectors.selectRedemptionPreferencesState);
	public readonly isLoadingRedemptionPreferences$ = this.store.select(MemberSelectors.selectIsLoadingRedemptionPreferences);
	public readonly redemptionPreference$: Observable<RedemptionPreference | undefined> = this.store.select(MemberSelectors.selectRedemptionPreference);
	public readonly saveForOccasionDate$: Observable<Date | string | undefined> = this.store.select(MemberSelectors.selectSaveForOccasionDate);
	public readonly isUpdatingRedemptionPreferences$: Observable<boolean> = this.store.select(MemberSelectors.selectIsUpdatingRedemptionPreferences);
	public readonly updatedRedemptionPreferences$: Observable<boolean> = this.store.select(MemberSelectors.selectUpdatedRedemptionPreferences);
	public readonly rewardsChoiceError$: Observable<unknown | undefined> = this.store.select(MemberSelectors.selectRedemptionPreferencesError);

	constructor(
		private readonly store: Store,
		private flagService: FlagService
	) {}

	public updateProfile(fieldName: ProfileActions.PersonalDetailFieldType, value: string | undefined, successMessage?: string): void {
		this.store.dispatch(ProfileActions.updateProfile({ fieldName, value, successMessage }));
	}

	public resetUpdateProfile(): void {
		this.store.dispatch(ProfileActions.resetUpdateProfile());
	}

	public conditionallyLoadBalances(forceLoad = false): void {
		this.balancesState$
			.pipe(
				take(1), // This will automatically unsubscribe after the first emission
				filter((balancesState) => forceLoad || (!balancesState.isLoading && !balancesState.value))
			)
			.subscribe(() => {
				this.store.dispatch(BalancesActions.loadBalances());
			});
	}

	public hasViewedTutorial(): Observable<boolean> {
		return this.store
			.select(WelcomeTutorialSelectors.selectWelcomeTutorialState)
			.pipe(filter(({ isLoading }) => !isLoading))
			.pipe(map(({ hasViewed }) => hasViewed));
	}

	public loadTutorialViewedState(): void {
		this.store.dispatch(WelcomeTutorialActions.loadTutorialViewed());
	}

	public updateTutorialViewed(hasViewed: boolean): void {
		this.store.dispatch(WelcomeTutorialActions.updateTutorialViewed({ hasViewed }));
	}

	public loadRedemptionPreferences(forceLoad = false): void {
		this.redemptionPreferencesState$
			.pipe(
				take(1), // This will automatically unsubscribe after the first emission
				filter(
					(redemptionPreferencesState) =>
						forceLoad ||
						(!redemptionPreferencesState.isLoadingRedemptionPreferences &&
							(!redemptionPreferencesState.redemptionPreference ||
								(redemptionPreferencesState.redemptionPreference === RedemptionPreference.SaveForOccasion &&
									redemptionPreferencesState.saveForOccasionDate === undefined)))
				),
				switchMap(() => this.flagService.isFlagEnabled(FlagKey.featureEdrSaveForOccasion))
			)
			.subscribe((saveForOccasionEnabled) => {
				if (saveForOccasionEnabled) {
					this.store.dispatch(MemberActions.loadRedemptionPreferences());
				} else {
					this.store.dispatch(
						MemberActions.loadRedemptionPreferencesSuccess({
							redemptionPreferences: {
								redemptionPreference: RedemptionPreference.SpendNow,
								saveForOccasionDate: undefined,
							},
						})
					);
				}
			});
	}

	public updateRedemptionPreferences(redemptionPreferences: MemberRedemptionPreferencesResponse): void {
		this.store.dispatch(MemberActions.updateRedemptionPreferences({ redemptionPreferences }));
	}

	public resetRedemptionPreferencesUpdatedState(): void {
		this.store.dispatch(MemberActions.resetUpdatedState());
	}
}
