<ul [attr.orientation]="orientation" [attr.disabled]="disabled" [attr.error]="error" [attr.size]="size">
	@for (option of options; track option) {
	<li>
		<input
			[id]="option.name"
			type="radio"
			[checked]="value === option.value"
			[value]="option.value"
			[name]="'radio-' + (formControlName || id)"
			(input)="onInput($event)"
			[disabled]="disabled"
		/>
		<label [for]="option.name" edr-typography typographyAs="body--xs">
			<span></span>
			{{ option.name }}
		</label>
	</li>
	}
</ul>
