import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadPageContent } from './page-content/page-content.actions';
import * as PageContentSelectors from './page-content/page-content.selectors';
import * as NavItemsSelectors from './nav-items/nav-items.selector';
import { loadNavItems } from './nav-items';

@Injectable({ providedIn: 'root' })
export class DynamicContentFacade {
	public pageContentState$ = this.store.select(PageContentSelectors.selectPageContentState);
	public pageContent$ = this.store.select(PageContentSelectors.selectPageContent);
	public pageContentIsReady$ = this.store.select(PageContentSelectors.selectPageContentIsReady);

	public footerNavItems$ = this.store.select(NavItemsSelectors.selectFooterNavItems);
	public headerNavItems$ = this.store.select(NavItemsSelectors.selectHeaderNavItems);

	constructor(private readonly store: Store) {}

	public loadPageContent(pageId: string, isPreview: boolean, verificationKey: string, env: string | null): void {
		this.store.dispatch(loadPageContent({ pageId, isPreview, verificationKey, env }));
	}

	public loadNavItems(): void {
		this.store.dispatch(loadNavItems());
	}
}
