import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AuthService, selectAuthHasLoaded } from '../../';
import { Observable, filter, map, switchMap } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';

export const signInResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot): Observable<boolean> => {
	const authService = inject(AuthService);
	const store = inject(Store);
	const window = inject<Window>(WINDOW);
	const redirectUrl = route.queryParams['r'] ?? window.location.origin;
	return store.select(selectAuthHasLoaded).pipe(
		filter(Boolean),
		switchMap(() => authService.startLoginFlow(redirectUrl)),
		map(() => true)
	);
};
