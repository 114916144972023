import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as BalancesActions from './balances.actions';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthIsLoggedIn } from '../../../auth';
import { BalancesService } from '../../../services';

@Injectable()
export class BalancesEffects {
	public loadBalances$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BalancesActions.loadBalances),
			concatLatestFrom(() => this.store.select(selectAuthIsLoggedIn)),
			filter(([, isLoggedIn]) => isLoggedIn),
			switchMap(() =>
				this.balancesService.fetchBalances().pipe(
					map((balances) => BalancesActions.loadBalancesSuccess({ balances })),
					catchError((error) => of(BalancesActions.loadBalancesFailure({ error })))
				)
			)
		)
	);

	constructor(private actions$: Actions, private store: Store, private balancesService: BalancesService) {}
}
