<edr-fireworks />
<edr-modal (closeModal)="closeModal()">
	<div modal-content class="modal-content">
		<img src="/assets/images/rewards-rocket.svg" alt="Rocket" />
		<div>
			<edr-heading [level]="3">Nice! You earned a $15 voucher.</edr-heading>
			<p>Your Rewards voucher is ready to be spent at any Woolworths or bp fuel station.</p>
		</div>
	</div>

	<button type="button" edr-button modal-footer-primary (click)="closeModal()" buttonSize="medium">Close</button>
</edr-modal>
