import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnInit } from '@angular/core';

import { ImageDimensions, ImageFocalPoint, StyleConfig } from '../../models';
import { ApplyStylesDirective } from '../../directives';
import {
	BreakpointService,
	ContentBaseComponent,
	DeviceType,
	ImageDeviceTransformConfig,
	ImageFormat,
	ImageResizeFit,
	ImageTransformConfig,
	ImageTransformerService,
} from '@edr/shared';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { take } from 'rxjs';

const DEFAULT_IMAGE_TRANSFORM_CONFIG: ImageTransformConfig = {
	format: ImageFormat.Avif,
	resizeFit: ImageResizeFit.Fill,
	quality: 100,
};

const PLACEHOLDER_IMAGE_QUALITY = 10;

@Component({
	selector: 'edr-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ApplyStylesDirective, NgTemplateOutlet, NgStyle, SpinnerComponent],
})
export class ImageComponent extends ContentBaseComponent implements OnInit {
	@HostBinding('class') public readonly hostClass = 'image';

	@Input({ required: true }) public src = '';
	@Input({ required: true }) public altText = '';
	@Input() public width: StyleConfig[] = [];
	@Input() public height: StyleConfig[] = [];
	@Input() public optimizationEnabled = true;
	@Input() public lazyLoad = true;

	/**
	 * The config per device for the image will take priority over the default options.
	 */
	@Input() public imageDeviceTransformConfig: ImageDeviceTransformConfig = ImageTransformerService.GetImageTransformConfig();

	/**
	 * A focal point is specific X & Y coordinates on the image that should
	 * be focused on when the image is cropped.
	 * This X & Y coordinates are based on the original image asset dimensions.
	 */
	@Input() public focalPoint?: ImageFocalPoint;

	/**
	 * The original image asset dimensions
	 */
	@Input() public imageAssetDimensions?: ImageDimensions;

	@HostBinding('attr.scale-to-container') @Input() public scaleToContainer = true;
	@HostBinding('attr.contain') @Input() public contain = true;

	public get styles(): StyleConfig[] {
		return [...this.width, ...this.height];
	}

	public placeholderSrc = '';
	public isLoaded = false;

	public desktopBreakpointWidth = 0;
	public tabletBreakpointWidth = 0;
	public mobileBreakpointWidth = 0;

	public desktopSrc = '';
	public tabletSrc = '';
	public mobileSrc = '';

	// TODO: Delete me
	public count = 1;

	public deviceFocalPoint?: ImageFocalPoint;

	/**
	 * We do not want to transform SVG images, since they are already optimized and vector based.
	 */
	public isSvg = false;

	/**
	 * If the browser doesn't support the image format provided by the Image Transformer, then this fallback image will be used.
	 */
	public fallbackSrc = '';

	private imageTransformerService = inject(ImageTransformerService);
	private breakpointService = inject(BreakpointService);
	private deviceType: DeviceType = DeviceType.Mobile;

	public ngOnInit(): void {
		this.isSvg = this.src.toLowerCase().includes('.svg');

		const deviceBreakpoints = this.breakpointService.getBreakpointsPerDeviceType();
		this.desktopBreakpointWidth = deviceBreakpoints?.find((entry) => entry.deviceType === DeviceType.Desktop)?.minWidth ?? 1440;
		this.tabletBreakpointWidth = deviceBreakpoints?.find((entry) => entry.deviceType === DeviceType.Tablet)?.minWidth ?? 768;
		this.mobileBreakpointWidth = deviceBreakpoints?.find((entry) => entry.deviceType === DeviceType.Mobile)?.minWidth ?? 320;

		this.breakpointService
			.getActiveCustomBreakpoint()
			.pipe(take(1))
			.subscribe((deviceType) => {
				this.deviceType = deviceType;
				this.initializeImageProperties();
			});
	}

	public onImageLoaded(): void {
		this.isLoaded = true;
	}

	private initializeImageProperties(): void {
		// Make sure all devices have a default configuration (if not explicitly set)
		if (!this.imageDeviceTransformConfig.desktop || !this.imageDeviceTransformConfig.tablet || !this.imageDeviceTransformConfig.mobile) {
			const defaultImageTransformConfig = ImageTransformerService.GetImageTransformConfig();

			if (!this.imageDeviceTransformConfig.desktop) {
				this.imageDeviceTransformConfig.desktop = { ...defaultImageTransformConfig.desktop };
			}

			if (!this.imageDeviceTransformConfig.tablet) {
				this.imageDeviceTransformConfig.tablet = { ...defaultImageTransformConfig.tablet };
			}

			if (!this.imageDeviceTransformConfig.mobile) {
				this.imageDeviceTransformConfig.mobile = { ...defaultImageTransformConfig.mobile };
			}
		}

		// Make sure the image aspect ration is adjusted based on the original image dimensions
		if (this.imageAssetDimensions && this.imageDeviceTransformConfig) {
			this.imageDeviceTransformConfig = this.imageTransformerService.adjustImageAspectRatio(
				this.imageAssetDimensions,
				this.imageDeviceTransformConfig
			);
		}

		this.placeholderSrc = this.getDeviceImageUrl(this.deviceType, PLACEHOLDER_IMAGE_QUALITY);

		this.desktopSrc = this.getDeviceImageUrl(DeviceType.Desktop);
		this.tabletSrc = this.getDeviceImageUrl(DeviceType.Tablet);
		this.mobileSrc = this.getDeviceImageUrl(DeviceType.Mobile);

		// The fallback image source is the default
		this.fallbackSrc = this.src;

		// Initialize the focal point if configured
		if (this.imageDeviceTransformConfig && this.focalPoint) {
			this.initializeFocalPoint(this.deviceType);
		}
	}

	private getDeviceImageUrl(deviceType: DeviceType, quality: number | undefined = undefined): string {
		if (!this.optimizationEnabled || this.isSvg) {
			return this.src;
		}

		let imageOptions = DEFAULT_IMAGE_TRANSFORM_CONFIG;

		if (this.imageDeviceTransformConfig) {
			switch (deviceType) {
				case DeviceType.Desktop:
					imageOptions = this.imageDeviceTransformConfig.desktop ?? imageOptions;
					break;
				case DeviceType.Tablet:
					imageOptions = this.imageDeviceTransformConfig.tablet ?? imageOptions;
					break;
				case DeviceType.Mobile:
					imageOptions = this.imageDeviceTransformConfig.mobile ?? imageOptions;
					break;
			}
		}

		if (quality) {
			imageOptions = { ...imageOptions, quality };
		}

		return this.imageTransformerService.transformImage(this.src, imageOptions);
	}

	/**
	 * Re-calculate the focal point adjustment required based on the image size
	 * and the original focal point values
	 * @param deviceType
	 * @private
	 */
	private initializeFocalPoint(deviceType: DeviceType): void {
		// TODO: CLSE-2480 - When implementing the focal point, will proobably need to adjust according to element size, using ViewChild
		if (!this.focalPoint || !this.imageDeviceTransformConfig) {
			return;
		}

		let deviceResizeProps: ImageTransformConfig | undefined;

		switch (deviceType) {
			case DeviceType.Desktop:
				deviceResizeProps = this.imageDeviceTransformConfig.desktop;
				break;
			case DeviceType.Tablet:
				deviceResizeProps = this.imageDeviceTransformConfig.tablet;
				break;
			case DeviceType.Mobile:
				deviceResizeProps = this.imageDeviceTransformConfig.mobile;
				break;
		}

		if (deviceResizeProps && deviceResizeProps.width) {
			// Get the ratio of the device width versus the actual focal point asset width
			let xRatio = 1;

			if (deviceResizeProps.width < this.focalPoint.assetWidth) {
				xRatio = deviceResizeProps.width / this.focalPoint.assetWidth;
			}

			// Adjust the original focal point x & Y values based on the ration and store in deviceFocalPoint
			this.deviceFocalPoint = {
				x: this.focalPoint.x * xRatio,
				y: this.focalPoint.y * xRatio,
				assetWidth: deviceResizeProps.width ?? 0,
				assetHeight: deviceResizeProps.height ?? 0,
			};
		}
	}
}
