import { Provider } from '@angular/core';
import {
	AccordionComponent,
	AccordionItemCardComponent,
	AccordionItemDefaultComponent,
	BannerComponent,
	ButtonComponent,
	CardComponent,
	CarouselComponent,
	CarouselItemComponent,
	CtaGroupComponent,
	HeadingComponent,
	IconComponent,
	ImageComponent,
	LayoutGroupComponent,
	LayoutGroupItemComponent,
	LinkComponent,
	NotificationComponent,
	MultiStepComponent,
	MultiStepItemComponent,
	PartnerComponent,
	SectionComponent,
} from '@edr/styleguide';
import { CONTENT_MAPPING } from '@edr/dc';
import { RichTextComponent } from '@edr/contentful';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { BoostOffersCarouselComponent, BoostOffersGridComponent, CardBoostComponent, WelcomeTutorialComponent } from '../../../features';
import {
	AccountSettingsComponent,
	CommunicationsPreferencesComponent,
	ContainerComponent,
	M2MGiftingComponent,
	EverydayRewardsCardComponent,
	PersonalDetailsComponent,
	PointsDashboardComponent,
	PointsTableComponent,
} from '@edr-core';
import { RewardsChoiceComponent } from '../rewards-choice/rewards-choice.component';

export const CONTENT_MAPPING_PROVIDER: Provider = {
	provide: CONTENT_MAPPING,
	useValue: {
		accordion: {
			component: AccordionComponent,
		},
		['accordion-item-default']: {
			component: AccordionItemDefaultComponent,
		},
		['accordion-item-card']: {
			component: AccordionItemCardComponent,
		},
		['account-settings']: {
			component: AccountSettingsComponent,
		},
		actionButton: {
			component: ActionButtonComponent,
		},
		banner: {
			component: BannerComponent,
		},
		button: {
			component: ButtonComponent,
		},
		['boost-offers-grid']: {
			component: BoostOffersGridComponent,
		},
		['boost-offers-carousel']: {
			component: BoostOffersCarouselComponent,
		},
		['boost-other-offers-carousel']: {
			component: BoostOffersCarouselComponent,
		},
		['boost-other-offers-grid']: {
			component: BoostOffersGridComponent,
		},
		['cta-group']: {
			component: CtaGroupComponent,
		},
		carousel: {
			component: CarouselComponent,
		},
		['carousel-item']: {
			component: CarouselItemComponent,
		},
		card: {
			component: CardComponent,
		},
		['card-boost']: {
			component: CardBoostComponent,
		},
		container: {
			component: ContainerComponent,
		},
		['everyday-rewards-card']: {
			component: EverydayRewardsCardComponent,
		},
		heading: {
			component: HeadingComponent,
		},
		icon: {
			component: IconComponent,
		},
		layoutGroup: {
			component: LayoutGroupComponent,
		},
		layoutGroupItem: {
			component: LayoutGroupItemComponent,
		},
		link: {
			component: LinkComponent,
		},
		notificationPanel: {
			component: NotificationComponent,
		},
		multiStep: {
			component: MultiStepComponent,
		},
		multiStepItem: {
			component: MultiStepItemComponent,
		},
		welcomeTutorial: {
			component: WelcomeTutorialComponent,
		},
		imageProps: {
			component: ImageComponent,
		},
		['partner']: {
			component: PartnerComponent,
		},
		['personal-details']: {
			component: PersonalDetailsComponent,
		},
		['points-dashboard']: {
			component: PointsDashboardComponent,
		},
		['points-table']: {
			component: PointsTableComponent,
		},
		['rich-text']: {
			component: RichTextComponent,
		},
		section: {
			component: SectionComponent,
		},
		m2mPointsGifting: {
			component: M2MGiftingComponent,
		},
		['communications-preferences']: {
			component: CommunicationsPreferencesComponent,
		},
		['rewards-choice']: {
			component: RewardsChoiceComponent,
		},
	},
};
