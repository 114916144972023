import { DynamicComponent } from '@edr/dc';
import { createId } from './mapper-helpers';
import { M2mPointsGiftingResponse } from '@edr/bff-api-models';

export function mapM2MPointsGifting(response: M2mPointsGiftingResponse): DynamicComponent {
	const id = createId(response.contentTypeId);

	return {
		id,
		type: 'm2mPointsGifting',
		props: {
			id,
			entryId: response.entryId,
			notificationSuccessTitle: response.successNotificationTitle,
			notificationSuccessMessage: response.successNotificationMessage,
			notificationFailedTitle: response.failureNotificationTitle,
			notificationFailedMessage: response.failureNotificationMessage,
			ctaText: response.ctaText,
			title: response.title,
			description: response.description.content,
		},
	};
}
