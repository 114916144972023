import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { REG_EX } from '../constants';

export class EDRValidators {
	public static mobilePhoneNumber(isRequired: boolean): ValidatorFn {
		return this.phoneNumber(isRequired, 'mobilePhoneNumber');
	}

	public static homePhoneNumber(): ValidatorFn {
		return this.phoneNumber(false, 'homePhoneNumber');
	}

	public static phoneNumber(isRequired: boolean, validationField: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const requiredCheckPass = (isRequired && control.value) || !isRequired ? true : false;
			const validValue = control.value ? new RegExp(REG_EX.PHONE).test(control.value) : true;

			return requiredCheckPass && validValue ? null : { [validationField]: true };
		};
	}
}
