import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as WelcomeTutorialActions from './welcome-tutorial.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { CustomerPreferencesService } from '../../../../features/my-account/services';
import { PreferenceIds } from '@edr/shared';

@Injectable()
export class WelcomeTutorialEffects {
	public loadTutorialViewed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WelcomeTutorialActions.loadTutorialViewed),
			switchMap(() =>
				this.customerPreferencesService.fetchCustomerPreferences([PreferenceIds.EDRTutorialViewState]).pipe(
					map((payload) => {
						const hasViewed = payload.find((preference) => preference.id === PreferenceIds.EDRTutorialViewState)?.value ?? false;
						return WelcomeTutorialActions.loadTutorialViewedComplete({ hasViewed });
					}),
					catchError(() => of(WelcomeTutorialActions.loadTutorialViewedFailed()))
				)
			)
		)
	);

	public updateTutorialViewed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WelcomeTutorialActions.updateTutorialViewed),
			switchMap((action) =>
				this.customerPreferencesService.updateCustomerPreferences([{ id: PreferenceIds.EDRTutorialViewState, value: action.hasViewed }]).pipe(
					map(() => WelcomeTutorialActions.updateTutorialViewedComplete({ hasViewed: action.hasViewed })),
					catchError(() => of(WelcomeTutorialActions.updateTutorialViewedFailed()))
				)
			)
		)
	);

	constructor(private actions$: Actions, private customerPreferencesService: CustomerPreferencesService, private store: Store) {}
}
