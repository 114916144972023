import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { AccordionItemBaseComponent } from '../accordion-item-base/accordion-item-base.component';
import { ComponentRefService } from '../../../services';
import { TrackingNavigationDirective } from '../../../directives';
import { IconComponent } from '../../icon/icon.component';

import { TypographyComponent } from '../../typography/typography.component';
import { ActivatedRoute } from '@angular/router';
import { IconCircleComponent } from '../../icon-circle/icon-circle.component';

@Component({
	selector: 'edr-accordion-item-default',
	templateUrl: './accordion-item-default.component.html',
	styleUrls: ['./accordion-item-default.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IconComponent, TrackingNavigationDirective, TypographyComponent, IconCircleComponent],
})
export class AccordionItemDefaultComponent extends AccordionItemBaseComponent {
	@HostBinding('attr.border') @Input() public border = false;
	@HostBinding('attr.borderRadius') @Input() public borderRadius = true;

	constructor(cdr: ChangeDetectorRef, componentRefService: ComponentRefService, activatedRoute: ActivatedRoute) {
		super(cdr, componentRefService, activatedRoute);
	}
}
