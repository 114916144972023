<table cdk-table [dataSource]="dataSource$" multiTemplateDataRows>
	<ng-container *ngFor="let column of columnList" [cdkColumnDef]="column.name">
		<th cdk-header-cell *cdkHeaderCellDef>
			{{ column.displayName }}
			<edr-icon
				*ngIf="column.name === 'bonusPoints'"
				name="system-tooltip-help"
				color="secondary--charcoal"
				size="4"
				edrTooltip="Bonus points can be collected through Boosts and other special offers."
				[tooltipTrigger]="TooltipTrigger.Hover"
				[tooltipPosition]="TooltipPosition.Right"
				[tooltipAlignment]="TooltipAlignment.Middle"
			></edr-icon>
		</th>
		<td cdk-cell *cdkCellDef="let transaction" [ngSwitch]="column.name">
			<div class="info-cell">
				<ng-container *ngSwitchCase="'partnerDetails'">
					<edr-circle size="10" backgroundColor="primary--white" borderColor="secondary--mid-grey" borderWidth="1px">
						@if (getLogo(transaction) | async; as partnerLogo) {
						<img [src]="partnerLogo.src" [alt]="partnerLogo.alt" />
						}
					</edr-circle>
					<div class="info-cell__text">
						<edr-heading [level]="5">
							{{ transaction.transactionDateTime | date : 'EEE dd MMM' }}
						</edr-heading>
						<p>
							{{ transaction.description }}
						</p>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="'amount'">
					<span class="info-cell__amount">
						{{ transaction[column.name] | amountDisplay : ActivityDetailsUomType.Dollars }}
					</span>
				</ng-container>
				<ng-container *ngSwitchCase="'bonusPoints'">
					<span class="info-cell__bonus-points">
						{{ transaction[column.name] | pointsDisplay : 'bonusPoints' }}
					</span>
				</ng-container>
				<ng-container *ngSwitchCase="'totalPoints'">
					<span [ngClass]="!!transaction[column.name] && transaction[column.name] < 0 ? 'info-cell__points--negative' : 'info-cell__points'">
						{{ transaction[column.name] | pointsDisplay : 'points' }}
					</span>
				</ng-container>
			</div>
		</td>
	</ng-container>

	<ng-container cdkColumnDef="pointsDetails">
		<td cdk-cell class="points-details-cell" [attr.colspan]="columnNameList.length" *cdkCellDef="let transaction">
			<div *ngIf="transaction">
				<edr-app-points-details-table [transaction]="transaction" [columnList]="detailsColumnsList"></edr-app-points-details-table>
			</div>
		</td>
	</ng-container>

	<ng-container cdkColumnDef="stripe">
		<td cdk-cell class="stripe-cell" [attr.colspan]="columnNameList.length" *cdkCellDef="let transaction">
			<div *ngFor="let redeemed of transaction.redeemed">
				<edr-stripe
					[text]="redeemed.description"
					textColor="secondary--charcoal"
					backgroundColor="family--mint"
					[iconLeft]="getRedeemedStripeIcon(redeemed.type)"
				>
				</edr-stripe>
			</div>
			<div *ngFor="let reward of transaction.rewards">
				<edr-stripe
					[text]="reward.description"
					textColor="primary--white"
					backgroundColor="primary--orange"
					iconLeftColor="extended--orange-light-60"
					[iconLeft]="getRewardStripeIcons(reward.type).left"
					[iconRight]="getRewardStripeIcons(reward.type).right"
				>
				</edr-stripe>
			</div>
		</td>
	</ng-container>

	<tr cdk-header-row *cdkHeaderRowDef="columnNameList"></tr>
	<tr cdk-row *cdkRowDef="let row; columns: columnNameList"></tr>
	<tr cdk-row *cdkRowDef="let row; columns: ['pointsDetails']; when: shouldShowPointsDetailsTable"></tr>
	<tr cdk-row *cdkRowDef="let row; columns: ['stripe']; when: shouldShowStripe"></tr>
</table>
