import { Injectable } from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { delay, of, switchMap, take, tap } from 'rxjs';
import { EnvFacade } from '@edr/env';
import { Modal, ModalServiceBase } from '@edr/styleguide';

const mobileAnimationClass = 'modal-mobile-slide-up';
const animationDuration = 250;

/**
 * The ModalService allows you to open a modal from anywhere in the app, both Routed or not,
 * although it is highly recommended to use a routed modal so the component can be lazy loaded.
 *
 * Upon navigating to a routed modal (<a [routerLink]="['/', { outlets: { modal: ['my-modal'] } }]">My modal</a>
 * or this.router.navigateByUrl('/(modal:other-modal)');) will open the modal instantly, effectively becoming the first modal in the queue,
 * leaving the other modals in the queue untouched.
 *
 * Opening a modal (routed or not) using the open() method will add it to the queue and open it after the current modal closes.
 * If it's a routed modall it will route to the modal url.
 *
 * Onlyl 1 modal can be open at any given time.
 */
@Injectable({
	providedIn: 'root',
})
export class ModalService implements ModalServiceBase {
	private modalQueue: Modal[] = [];
	private activeModalRef?: DialogRef<string, unknown>;
	private isOpeningRoutedModal = false;

	constructor(
		public dialog: Dialog,
		private readonly scrollStrategyOptions: ScrollStrategyOptions,
		private readonly router: Router,
		private envFacade: EnvFacade
	) {}

	public open(modal: Modal): void {
		this.modalQueue = [...this.modalQueue, modal];

		if (!this.activeModalRef) {
			this.openNextModal();
		}
	}

	public openModalRoute(modal: Modal): void {
		this.isOpeningRoutedModal = true;
		this.close();
		this.openDialog(modal);
		this.isOpeningRoutedModal = false;
	}

	public close(): void {
		this.closeAndAnimate();
	}

	public clearQueue(): void {
		this.modalQueue = [];
	}

	private openNextModal(): void {
		const nextModal = this.modalQueue.shift();
		if (!nextModal || this.isOpeningRoutedModal) {
			return;
		}

		if (nextModal.url) {
			this.router.navigateByUrl(nextModal.url as string);
			return;
		}
		this.openDialog(nextModal);
	}

	private openDialog(modal: Modal): void {
		this.activeModalRef = this.dialog.open<string>(modal.ref, {
			scrollStrategy: this.scrollStrategyOptions.block(),
			maxHeight: 'calc(100vh - var(--spacing-4))',
			panelClass: [modal.id, mobileAnimationClass],
			autoFocus: 'dialog',
			disableClose: true,
			...modal.config,
		});

		this.activeModalRef.backdropClick.pipe(take(1)).subscribe(() => {
			if (!modal.config?.disableClose) {
				this.closeAndAnimate();
			}
		});

		this.activeModalRef.closed.pipe(take(1)).subscribe(() => {
			this.activeModalRef = undefined;
			if (!!modal.url && !this.isOpeningRoutedModal) {
				// This routes to the parent route of a routed modal
				this.router.navigate(['../']);
			}
			this.openNextModal();
		});
	}

	private closeAndAnimate(): void {
		if (!this.activeModalRef) {
			return;
		}
		this.activeModalRef.disableClose = false;

		this.envFacade
			.breakpointMatches(['xxs', 'xs'])
			.pipe(
				take(1),
				switchMap((isMobileLayout) =>
					isMobileLayout
						? of(null).pipe(
								tap(() => this.activeModalRef?.removePanelClass(mobileAnimationClass)),
								delay(animationDuration)
						  )
						: of(null)
				)
			)
			.subscribe(() => {
				this.activeModalRef?.close();
			});
	}
}
