import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { NavItem } from './header.interface';
import { DynamicContentFacade } from '../../+state/dynamic-content';
import { NavigationItemResponse } from '@edr/bff-api-models';
import { slugify } from '@edr/shared';

export interface HeaderState {
	isMobileLayout: boolean;
	isMyAccountExpanded: boolean;
	isMobilePrimaryNavExpanded: boolean;
	isMobileSecondaryNavExpanded: boolean;
	isLoading: boolean;
	isAuthenticated: boolean;
	hasError: boolean;
	primaryNavItems: NavItem[];
	secondaryNavItems: NavItem[];
	myAccountNavItems: NavItem[];
}

export const defaultState: HeaderState = {
	isMobileLayout: true,
	isMyAccountExpanded: false,
	isMobilePrimaryNavExpanded: false,
	isMobileSecondaryNavExpanded: false,
	isLoading: true,
	isAuthenticated: false,
	hasError: false,
	primaryNavItems: [],
	secondaryNavItems: [],
	myAccountNavItems: [],
};

@Injectable()
export class HeaderComponentStore extends ComponentStore<HeaderState> {
	/**
	 * Selectors
	 */
	public readonly selectState$: Observable<HeaderState> = this.select((state) => state);
	public readonly selectVisiblePrimaryNavItems$: Observable<NavItem[]> = this.select((state) =>
		state.primaryNavItems.filter((item) => this.isVisibleNavItem(item, state))
	);
	public readonly selectVisibleSecondaryNavItems$: Observable<NavItem[]> = this.select((state) =>
		state.secondaryNavItems.filter((item) => this.isVisibleNavItem(item, state))
	);
	public readonly selectActiveSubNav$: Observable<NavItem | undefined> = this.select(({ primaryNavItems }) =>
		primaryNavItems.find((item) => item.isActive)
	);
	public readonly selectVisibleAccountNavItems$: Observable<NavItem[]> = this.select((state) =>
		state.myAccountNavItems.filter((item) => this.isVisibleNavItem(item, state))
	);

	/**
	 * Updaters
	 */
	public readonly updateIsAuthenticated = this.updater(
		(state, isAuthenticated: boolean): HeaderState => ({
			...state,
			isAuthenticated,
		})
	);

	public readonly updateNavItems = this.updater(
		(state: HeaderState, navItems: { primaryNavItems: NavItem[]; secondaryNavItems: NavItem[]; myAccountNavItems: NavItem[] }): HeaderState => ({
			...state,
			...navItems,
			isLoading: false,
			hasError: false,
		})
	);

	public readonly updateIsMobileLayout = this.updater(
		(state: HeaderState, isMobileLayout: boolean): HeaderState => ({
			...state,
			isMobileLayout,
		})
	);

	public readonly updateIsMobileNavExpanded = this.updater(
		(state: HeaderState, isMobilePrimaryNavExpanded: boolean): HeaderState => ({
			...state,
			isMobilePrimaryNavExpanded,
		})
	);

	public readonly updateIsMobileSecondaryNavExpanded = this.updater(
		(state: HeaderState, isMobileSecondaryNavExpanded: boolean): HeaderState => ({
			...state,
			isMobileSecondaryNavExpanded,
		})
	);

	public readonly updateIsMyAccountExpanded = this.updater(
		(state: HeaderState, isExpanded: boolean): HeaderState => ({
			...state,
			isMyAccountExpanded: isExpanded,
		})
	);

	public readonly updateErrorAndLoading = this.updater(
		(state: HeaderState, { hasError, isLoading }: { hasError: boolean; isLoading: boolean }): HeaderState => ({
			...state,
			hasError,
			isLoading,
		})
	);

	/**
	 * Effects
	 */
	public readonly loadNavItems = this.effect((activeUrl$: Observable<string>) =>
		combineLatest([
			activeUrl$,
			this.dynamicContentFacade.headerNavItems$.pipe(filter((nav): nav is NavigationItemResponse[] => !!nav && nav.length > 0)),
		]).pipe(
			map(([activeUrl, nav]) => this.mapToNavItems(activeUrl, nav)),
			map((navItems) => this.splitNavItems(navItems)),
			tapResponse(
				(navItems) => this.updateNavItems(navItems),
				() => {
					this.updateErrorAndLoading({ hasError: true, isLoading: false });
				}
			)
		)
	);

	public readonly handleBreakpointChange = this.effect((isMobileBreakpoint$: Observable<boolean>) =>
		isMobileBreakpoint$.pipe(
			tapResponse(
				(isMobileLayout: boolean) => this.updateIsMobileLayout(isMobileLayout),
				() => {
					/* fail silently */
				}
			)
		)
	);

	constructor(private dynamicContentFacade: DynamicContentFacade) {
		super(defaultState);
	}

	private mapToNavItems(activeUrl: string, navPages: NavigationItemResponse[]): NavItem[] {
		return navPages.map((navPage) => ({
			label: navPage.label || '',
			id: slugify(navPage.label || ''),
			url: navPage.url || '',
			...((navPage.onlyAuthenticatedUsers && { visibility: { isAuthenticated: navPage.onlyAuthenticatedUsers } }) || {}),
			isActive: activeUrl === navPage.url || !!navPage.children?.some((child) => activeUrl === child.url),
			children: navPage.children ? this.mapToNavItems(activeUrl, navPage.children) : undefined,
		}));
	}

	private splitNavItems(navItems: NavItem[]): { primaryNavItems: NavItem[]; secondaryNavItems: NavItem[]; myAccountNavItems: NavItem[] } {
		const primaryNavItems: NavItem[] = navItems.map(({ label, id, url, isActive, visibility }) => ({
			label,
			id,
			url,
			isActive,
			...(!!visibility && { visibility }),
		}));
		const secondaryNavItems: NavItem[] = navItems.find((item) => item.isActive)?.children || [];
		const myAccountNavItems: NavItem[] = navItems.find((item) => item.id === 'account')?.children || [];

		return { primaryNavItems, secondaryNavItems, myAccountNavItems };
	}

	/**
	 * This method checks if the nav item should be visible based on the visibility object, which is matched against properties in the header state
	 * Currently you can have visibility conditions set to { isAuthenticated: true } or { isAuthenticated: false, isMobileLayout: true }
	 */
	private isVisibleNavItem(navItem: NavItem, state: HeaderState): boolean {
		const conditionKeys = navItem.visibility ? (Object.keys(navItem.visibility) as (keyof HeaderState)[]) : [];
		return conditionKeys.filter((key) => state[key] !== (!!navItem.visibility && navItem.visibility[key])).length === 0;
	}
}
