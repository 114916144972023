<label [attr.for]="inputId">
	<span>{{ checked ? 'On' : 'Off' }}</span>
	<input
		type="checkbox"
		[id]="inputId"
		#input
		[attr.aria-checked]="checked"
		[disabled]="disabled"
		(click)="onInputClick($event)"
		(change)="onInteractionEvent($event)"
	/>
	<div class="toggle-wrapper" [ngClass]="{ 'toggle--checked': checked, 'toggle--disabled': disabled }">
		<svg class="toggle-circle" [attr.data-testid]="id + '-toggle'" viewBox="-1 -1 20 20" xmlns="http://www.w3.org/2000/svg">
			<circle cx="9" cy="9" r="9" fill="white" />
		</svg>
	</div>
</label>
