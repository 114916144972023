import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { DialogRef } from '@angular/cdk/dialog';
import {
	ButtonComponent,
	EDRColor,
	EDRIcon,
	FireworksComponent,
	HeadingComponent,
	IconCircleComponent,
	IconComponent,
	ModalComponent,
	SpinnerComponent,
} from '@edr/styleguide';
import { MemberFacade } from '../../member';
import { DateTime } from 'luxon';
import { filter, Observable, take, tap } from 'rxjs';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MemberProfileResponse, RedemptionPreference } from '@edr/bff-api-models';
import { HttpErrorResponse } from '@angular/common/http';

export interface RewardsChoiceConfirmationModalData {
	isCurrentPreference: boolean;
	redemptionPreference: RedemptionPreference;
	saveForOccasionDate: Date | undefined;
	iconName: EDRIcon | undefined;
}

@UntilDestroy()
@Component({
	selector: 'edr-app-rewards-choice-confirmation',
	templateUrl: './rewards-choice-confirmation-modal.component.html',
	styleUrls: ['./rewards-choice-confirmation-modal.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		FireworksComponent,
		ModalComponent,
		HeadingComponent,
		IconComponent,
		IconCircleComponent,
		AsyncPipe,
		SpinnerComponent,
		CommonModule,
		NgOptimizedImage,
	],
})
export class RewardsChoiceConfirmationModalComponent implements OnInit, OnDestroy {
	public description = '';
	public iconColor: EDRColor = 'extended--charcoal';
	public iconName: EDRIcon | undefined = 'rewards-dollar-sign';
	public title = '';
	public ctaText = '';
	public header = '';
	public profileName: string | undefined = undefined;
	public isError?: boolean;
	public data: RewardsChoiceConfirmationModalData;

	public personalDetails$: Observable<MemberProfileResponse | undefined>;
	public isUpdating$: Observable<boolean>;
	public updated$: Observable<boolean>;

	private readonly rewardsChoiceName: string;
	private readonly formattedDate: string;

	constructor(
		private modalService: ModalService,
		private dialogRef: DialogRef,
		private memberFacade: MemberFacade
	) {
		// Modal data
		this.data = this.dialogRef.config.data.modalData;
		this.iconName = this.data.iconName;
		this.rewardsChoiceName = this.data.redemptionPreference === RedemptionPreference.SpendNow ? 'Everyday Rewards vouchers' : 'Save for an occasion';
		this.formattedDate = this.data?.saveForOccasionDate
			? DateTime.fromJSDate(this.data.saveForOccasionDate).toFormat('dd MMM yyyy')
			: 'your selected date';

		// References to Redux store data
		this.personalDetails$ = this.memberFacade.memberProfile$.pipe(tap((profile) => (this.profileName = profile?.firstName)));
		this.isUpdating$ = this.memberFacade.isUpdatingRedemptionPreferences$;
		this.updated$ = this.memberFacade.updatedRedemptionPreferences$;
	}

	public ngOnInit(): void {
		this.showInitialModal();

		this.memberFacade.updatedRedemptionPreferences$
			.pipe(
				filter((updated) => Boolean(updated)),
				take(1)
			)
			.subscribe(() => {
				this.showUpdatedModal();
			});

		this.memberFacade.rewardsChoiceError$
			.pipe(
				untilDestroyed(this),
				filter((error) => !!error)
			)
			.subscribe((error: unknown) => {
				// Extract any custom error message from the error response
				let apiErrorMessage = '';
				const httpError = error as HttpErrorResponse;
				const errors = httpError.error?.errors ? Object.values(httpError.error.errors) : undefined;
				if (errors?.length) {
					apiErrorMessage = errors[0] as string;
				}

				this.showErrorModal(apiErrorMessage.toString());
			});
	}

	/**
	 * Reset the updated state, so that we can display the correct state on the next modal open
	 */
	public ngOnDestroy(): void {
		this.memberFacade.resetRedemptionPreferencesUpdatedState();
	}

	public onSwitchNow(): void {
		if (this.ctaText === 'Done') {
			this.onCloseModal();
			return;
		}

		this.memberFacade.updateRedemptionPreferences({
			redemptionPreference: this.data.redemptionPreference,
			saveForOccasionDate: this.data.saveForOccasionDate,
		});
	}

	public onCloseModal(): void {
		this.modalService.close();
	}

	private showErrorModal(errorMessage = ''): void {
		this.isError = true;

		if (errorMessage) {
			if (!errorMessage.endsWith('.')) {
				errorMessage = errorMessage += '.';
			}
			errorMessage += ' ';
		}

		this.header = 'Something went wrong';
		this.title = 'Your Rewards choice has not been updated.';
		this.description = `${errorMessage}Please try again later.`;
		this.ctaText = 'Try again';
	}

	private showInitialModal(): void {
		this.header = 'Confirm Rewards choice';
		this.title = `You’re changing your Rewards choice to ${this.rewardsChoiceName}`;
		this.ctaText = 'Confirm Rewards choice';

		switch (this.data.redemptionPreference) {
			case RedemptionPreference.SpendNow:
				this.description = 'Your current or future vouchers will be available to spend at a participating Everyday Rewards partner.';
				break;
			case RedemptionPreference.SaveForOccasion:
				this.description = `Your Everyday Rewards vouchers will be saved until <b>${this.formattedDate}</b>. During this time, gifting points will be unavailable. You can change your Rewards choice at any time.`;

				if (this.data.isCurrentPreference) {
					this.title = `You’re changing your date for ${this.rewardsChoiceName}`;
					this.ctaText = 'Confirm new date';
				}
				break;
		}
	}

	private showUpdatedModal(): void {
		switch (this.data.redemptionPreference) {
			case RedemptionPreference.SpendNow:
				{
					this.description = 'You can now collect and spend your vouchers at a participating Everyday Rewards partner.';
				}
				break;

			case RedemptionPreference.SaveForOccasion:
				{
					this.description = `Your Everyday Rewards vouchers will be available to spend from <b>${this.formattedDate}</b>.`;
				}
				break;
		}

		this.header = this.profileName ? `${this.profileName}, you’re all set!` : `You're all set!`;
		this.title = `Your Rewards choice has been changed to ${this.rewardsChoiceName}.`;
		this.ctaText = 'Done';
	}
}
