import { SHORT_CODE_DEFINITIONS } from '../short-codes';

/**
 * Replaces the `[align]` shortcode with `span` elements
 * @param content Content string containing shortcode to be replaced
 * @param regexMatch RegEx matches for the shortcode
 * @returns Hydrated content
 */
export const replaceAlignShortCode = (content: string, regexMatch: RegExpMatchArray): string => {
	if (!regexMatch.groups || !regexMatch.groups[SHORT_CODE_DEFINITIONS.CODE] || !regexMatch.groups[SHORT_CODE_DEFINITIONS.VALUE]) {
		return content;
	}
	return content
		.replace(`[${regexMatch.groups['code']} ${regexMatch.groups['value']}]`, `<span class="align--${regexMatch.groups['value']}">`)
		.replace(`[${regexMatch.groups['code']}-end]`, `</span>`);
};
