import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EDRColor, EDRIcon, EDRSpacing } from '../../types';
import { IconCircleComponent } from '../../components';
import { NgClass, NgOptimizedImage, NgStyle } from '@angular/common';
import { IconProps } from '../../models';

export interface IconCircleGroupOptions {
	iconName: EDRIcon;
	selectedIconColor?: EDRColor;
	selectedBackgroundColor?: EDRColor;
}

export interface IconCircleGroupEnrichedOption extends IconCircleGroupOptions {
	index: number;
	key: string;
	iconProps: IconProps;
	isSelected: boolean;
	zIndex: number;
}

export const DEFAULT_ICON_SIZE: EDRSpacing = '8';
export const DEFAULT_CIRCLE_SIZE: EDRSpacing = '16';

export const DEFAULT_SELECTED_ICON_SIZE: EDRSpacing = '12';
export const DEFAULT_SELECTED_CIRCLE_SIZE: EDRSpacing = '20';

export const DEFAULT_ICON_COLOR: EDRColor = 'extended--grey-light-50';
export const DEFAULT_CIRCLE_COLOR: EDRColor = 'extended--grey-light-80';

export const DEFAULT_SELECTED_ICON_COLOR: EDRColor = 'secondary--charcoal';
export const DEFAULT_SELECTED_CIRCLE_COLOR: EDRColor = 'family--mint';

@Component({
	selector: 'edr-icon-circle-group',
	templateUrl: './icon-circle-group.component.html',
	styleUrl: './icon-circle-group.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IconCircleComponent, NgClass, NgStyle, NgOptimizedImage],
})
export class IconCircleGroupComponent implements OnInit, OnChanges {
	@Input() public iconOptions: IconCircleGroupOptions[] = [];
	@Input() public selectedIconName?: EDRIcon;

	/**
	 * This is the enriched version of the iconOptions array that includes additional properties
	 * to help with rendering the component
	 */
	public enrichedOptions!: IconCircleGroupEnrichedOption[];

	public ngOnInit(): void {
		this.initEnrichedOptions();
	}

	/**
	 * Given the fact that we have custom logic to enrich our input properties,
	 * we need to execute this logic whenever the input properties change.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['iconOptions'] || changes['selectedIconName']) {
			this.initEnrichedOptions();
		}
	}

	/**
	 * Initializes the enrichedOptions array.  Additional properties include:
	 * - iconProps: IconProps object for the icon component.  This includes the name, color, and size of the icon.This includes the color and size of the circle
	 * - isSelected: boolean indicating if the icon is selected.
	 *               If a custom selectedIconColor or selectedBackgroundColor is provided, it will be used.
	 * - zIndex: number indicating the z-index of the icon.
	 *           The z-index will increment from 0 to the center and then again down to 0.
	 * @private
	 */
	private initEnrichedOptions(): void {
		this.enrichedOptions = this.iconOptions.map((option, index) => {
			const selectedColor = option.selectedIconColor ?? DEFAULT_SELECTED_ICON_COLOR;
			const selectedBackgroundColor = option.selectedBackgroundColor ?? DEFAULT_SELECTED_CIRCLE_COLOR;
			const isSelected = option.iconName === this.selectedIconName;

			const iconProps: IconProps = {
				name: option.iconName,
				color: isSelected ? selectedColor : DEFAULT_ICON_COLOR,
				size: isSelected ? DEFAULT_SELECTED_ICON_SIZE : DEFAULT_ICON_SIZE,
				circleColor: isSelected ? selectedBackgroundColor : DEFAULT_CIRCLE_COLOR,
				circleSize: isSelected ? DEFAULT_SELECTED_CIRCLE_SIZE : DEFAULT_CIRCLE_SIZE,
			};

			return {
				...option,
				index,
				key: `${option.iconName}-${index}`,
				iconProps,
				isSelected,
				zIndex: 0,
			};
		});

		// Rearrange the selected icon to be as close to the center as possible
		let centerIndex = Math.floor(this.enrichedOptions.length / 2);
		if (this.enrichedOptions.length % 2 === 0) {
			centerIndex--;
		}

		if (this.selectedIconName) {
			const selectedIconIndex = this.enrichedOptions.findIndex((option) => option.isSelected);

			// Check if we need to move the selected icon
			if (selectedIconIndex !== -1 && centerIndex !== selectedIconIndex) {
				const selectedIcon = this.enrichedOptions[selectedIconIndex];

				this.enrichedOptions.splice(selectedIconIndex, 1);
				this.enrichedOptions.splice(centerIndex, 0, selectedIcon);
			}
		}

		// Set the z-Index incrementing from 1 to center and then again down to 1
		// Note: We have to start at 1 here, to cater for even icon counts because we decrement the
		// centerIndex by one in this case and we can end up with negative z-indexes which hides one icons to the right of selected
		let zIndex = 1;
		this.enrichedOptions.forEach((option, index) => {
			option.zIndex = index < centerIndex ? zIndex++ : zIndex--;
		});
	}
}
