import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { generateId, InputType } from '@edr/shared';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, initialConfig, IConfig, InputTransformFn } from 'ngx-mask';

export interface MaskOptions {
	suffix?: string;
	prefix?: string;
	thousandSeparator?: string;
	decimalMarker?: '.' | ',' | ['.', ','];
	showMaskTyped?: boolean;
	shownMaskExpression?: string;
	specialCharacters?: string[] | readonly string[];
	hiddenInput?: boolean | undefined;
	allowNegativeNumbers?: boolean;
	inputTransformFn?: InputTransformFn;
	patterns?: Record<
		string,
		{
			pattern: RegExp;
			optional?: boolean;
			symbol?: string;
		}
	>;
}

@Component({
	selector: 'edr-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgxMaskDirective, NgxMaskPipe],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
		provideNgxMask(),
	],
})
export class InputComponent implements ControlValueAccessor {
	@Output() public valueChange = new EventEmitter<string>();
	@Output() public blurred = new EventEmitter<FocusEvent>();
	@Output() public focused = new EventEmitter<FocusEvent>();
	@Output() public keyUp = new EventEmitter<KeyboardEvent>();
	@Output() public keyDown = new EventEmitter<KeyboardEvent>();

	@Input() public id: string;
	@Input() public formControlName?: string;
	@Input() public name?: string;
	@Input() public label?: string;
	@Input() public subLabel?: string | null;
	@Input() public placeholder?: string = '';
	@Input() public type: InputType = 'text';
	@Input() public ariaLabel?: string;
	@Input() public isError = false;
	@Input() public required = false;
	@Input() public autocomplete?: string;
	@Input() public mask = '';

	@Input() public readOnly = false;
	@Input() public pattern = '';

	@ViewChild('input') private inputElementRef: ElementRef<HTMLInputElement> | undefined;
	private _uniqueId = generateId();
	private _disabled = false;
	private _inputValue?: string;
	private _maskOptions: IConfig = initialConfig;

	constructor(private elementRef: ElementRef<HTMLElement>) {
		this.id = this._uniqueId;
	}

	public get inputId(): string {
		return `${this.id || this._uniqueId}-input`;
	}

	public get hasLabel(): boolean {
		return this.label ? true : false;
	}

	public get hasSubLabel(): boolean {
		return this.subLabel ? true : false;
	}

	public get nativeElement(): HTMLElement {
		return this.elementRef.nativeElement;
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@HostBinding('attr.disabled')
	@Input()
	public get disabled(): boolean {
		return this._disabled;
	}
	public set disabled(value: unknown) {
		const newValue = Boolean(value);

		if (newValue !== this._disabled) {
			this._disabled = newValue;
		}
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@Input()
	public get value(): string {
		return this._inputValue ?? '';
	}

	public set value(value: string) {
		if (value !== this._inputValue) {
			this._inputValue = value;
		}
	}

	public focus(): void {
		this.inputElementRef?.nativeElement?.focus();
	}

	public onInput = (event: Event): void => {
		const target = event.target as HTMLInputElement;
		if (!this.disabled) {
			const returnValue = target?.value;
			this.valueChange.emit(returnValue);

			// Call Forms onChange event if inside form
			this._onChange(returnValue);
			this._onTouched();
		}
	};

	public onBlur = (event: FocusEvent): void => {
		this.blurred.emit(event);
		// Call Forms onTouched event if inside form
		if (this._onTouched) {
			this._onTouched();
		}
	};

	public onFocus = (event: FocusEvent): void => {
		this.focused.emit(event);
	};

	public onKeyUp = (event: KeyboardEvent): void => {
		this.keyUp.emit(event);
	};

	public onKeyDown = (event: KeyboardEvent): void => {
		this.keyDown.emit(event);
	};

	public writeValue(value: string): void {
		this.value = value;
	}

	public registerOnChange(fn: (_: string) => void): void {
		this._onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this._onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this._disabled = isDisabled;
	}

	@Input()
	public set maskOptions(options: MaskOptions) {
		this._maskOptions = {
			...this._maskOptions,
			...options,
		};
	}

	public get maskOptions(): IConfig {
		return this._maskOptions;
	}

	// Angular Forms Methods
	private _onChange: (_: string) => void = () => null;
	private _onTouched: () => void = () => null;
}
