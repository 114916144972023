import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_FEATURE_KEY, AppState } from './app.reducer';

export const selectAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const selectAppAppSettings = createSelector(selectAppState, (state: AppState) => state.appSettings);

export const selectAppIsLoading = createSelector(selectAppState, (state: AppState) => state.isLoading);

export const selectAppLoaded = createSelector(selectAppState, (state: AppState) => state.loaded);

export const selectAppError = createSelector(selectAppState, (state: AppState) => state.error);
