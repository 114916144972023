import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ApiService, AppSettingsService } from '../../../core';
import { CustomerProfileRequest, MemberProfileResponse } from '@edr/bff-api-models';
import { StatusCodeResult } from '../../../core/models/status-code-result';

@Injectable({ providedIn: 'root' })
export class PersonalDetailsService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	public fetchCustomerProfile(): Observable<MemberProfileResponse> {
		return this.appSettingsService.getEndpoint('customerProfile').pipe(switchMap((endPoint) => this.apiService.get<MemberProfileResponse>(endPoint)));
	}

	public updateCustomerProfile(fieldName: string, value: string | undefined): Observable<StatusCodeResult> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const patchRequest: any = {};
		patchRequest[fieldName] = value;
		patchRequest[`${fieldName}Specified`] = true;

		return this.appSettingsService
			.getEndpoint('customerProfile')
			.pipe(switchMap((endPoint) => this.apiService.patch<CustomerProfileRequest, StatusCodeResult>(endPoint, patchRequest)));
	}
}
