import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { of } from 'rxjs';
import { NotificationType } from '@edr/styleguide';
import { PageContentPersonalDetails } from './page-content-personal-details.model';
import { MemberFacade, PersonalDetailFieldType } from '../../member';

export interface PersonalDetailsState {
	pageContent: PageContentPersonalDetails | undefined;
	pageContentHasError: boolean;
	pageContentIsLoading: boolean;
	persistingData?: boolean;
	editedDetails: EditPersonalDetail[];
	currentEditedDetail?: EditPersonalDetail;
	detailToPersist?: EditPersonalDetail;
	notification?: EditPersonalDetailNotification;
	error?: unknown;
}

export interface EditPersonalDetail {
	fieldName: PersonalDetailFieldType;
	fieldValue?: string;
	editMode?: boolean;
	successMessage?: string;
}

export interface EditPersonalDetailNotification {
	notificationType: NotificationType;
	title: string;
	description?: string;
	canDismiss?: boolean;
	showIcon?: boolean;
}

export const defaultState: PersonalDetailsState = {
	pageContent: undefined,
	pageContentHasError: false,
	pageContentIsLoading: true,
	editedDetails: [],
	currentEditedDetail: undefined,
};

@Injectable()
export class PersonalDetailsStore extends ComponentStore<PersonalDetailsState> {
	/** Selectors **/
	public readonly pageContent$ = this.select((state) => state.pageContent);
	public readonly persistingData$ = this.select((state) => state.persistingData);
	public readonly editedDetails$ = this.select((state) => state.editedDetails);
	public readonly detailToPersist$ = this.select((state) => state.detailToPersist);
	public readonly notification$ = this.select((state) => state.notification);
	public readonly isLoading$ = this.select((state) => state.pageContentIsLoading);
	public readonly currentEditedDetail$ = this.select((state) => state.currentEditedDetail);

	public readonly updatePageContentError = this.updater(
		(state, hasError: boolean): PersonalDetailsState => ({ ...state, pageContentHasError: hasError, pageContentIsLoading: false })
	);

	public readonly setEditMode = this.updater((state, editMode: EditPersonalDetail): PersonalDetailsState => {
		const editedDetail = state.editedDetails.find((field) => field.fieldName === editMode.fieldName);

		if (!editedDetail) {
			state.editedDetails.push(editMode);
		} else {
			if (editMode.editMode !== undefined) {
				editedDetail.editMode = editMode.editMode;
			}
		}
		state.currentEditedDetail = editMode;
		return state;
	});

	public readonly resetEditState = this.updater(
		(state): PersonalDetailsState => ({
			...state,
			persistingData: false,
			currentEditedDetail: undefined,
			editedDetails: [],
			detailToPersist: undefined,
		})
	);

	public readonly setNotification = this.updater(
		(state, notification: EditPersonalDetailNotification | null): PersonalDetailsState => ({
			...state,
			detailToPersist: undefined,
			notification: !notification ? undefined : { ...notification },
		})
	);

	public readonly persistDetail = this.updater(
		(state, detailToPersist: EditPersonalDetail): PersonalDetailsState => ({
			...state,
			detailToPersist: detailToPersist,
			persistingData: true,
		})
	);

	/** Effects **/
	public readonly saveCustomerProfile = this.effect(() =>
		this.detailToPersist$.pipe(
			map((detailToPersist) => {
				if (!detailToPersist) {
					return of(null);
				}
				this.memberFacade.updateProfile(detailToPersist.fieldName, detailToPersist.fieldValue, detailToPersist.successMessage);
				return of(true);
			})
		)
	);

	constructor(private readonly memberFacade: MemberFacade) {
		super(defaultState);
	}
}
