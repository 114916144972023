import { Inject, Injectable } from '@angular/core';
import { WindowSizeService } from '../window-size/window-size.service';
import { EDRBreakpoint, EDRBreakpoints, CustomWindow, DeviceBreakpoints, DeviceType } from '../../typescript';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { CUSTOM_BREAKPOINTS } from './custom-breakpoints.token';

/**
 * This service uses the css variables defined in the document to determine the possible breakpoints,
 * then sorts them and matches these to the EDRBreakpoints.
 */
@Injectable({
	providedIn: 'root',
})
export class BreakpointService {
	private breakpointsSorted: [EDRBreakpoint, number][];

	constructor(
		@Inject(CUSTOM_BREAKPOINTS) private deviceBreakpoints: DeviceBreakpoints,
		private windowSizeService: WindowSizeService,
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(DOCUMENT) private document: Document
	) {
		this.breakpointsSorted = this.getBreakpoints();
	}

	public isCustomBreakpointActive(deviceType: DeviceType): Observable<boolean> {
		return this.getActiveCustomBreakpoint().pipe(map((activeDeviceType) => activeDeviceType === deviceType));
	}

	public breakpointMatchesCustom(customBreakpoints: string[]): Observable<boolean> {
		return this.getActiveCustomBreakpoint().pipe(map((currentBreakpoint) => customBreakpoints.includes(currentBreakpoint)));
	}

	public getActiveCustomBreakpoint(): Observable<DeviceType> {
		return this.getActiveBreakpoint().pipe(map((activeBreakpoint) => this.getCustomBreakpoint(activeBreakpoint)));
	}

	public getActiveBreakpoint(): Observable<EDRBreakpoint> {
		return this.windowSizeService.getWindowSize().pipe(
			map(({ width }) => this.matchBreakpoint(width)),
			distinctUntilChanged()
		);
	}

	public getCurrentBreakpoint(): EDRBreakpoint {
		return this.matchBreakpoint(this.windowSizeService.getCurrentWindowSize().width);
	}

	public getCurrentCustomBreakpoint(): DeviceType {
		return this.getCustomBreakpoint(this.getCurrentBreakpoint());
	}

	public isBreakpointActive(breakpoint: EDRBreakpoint): Observable<boolean> {
		return this.getActiveBreakpoint().pipe(map((currentBreakpoint) => currentBreakpoint === breakpoint));
	}

	public breakpointMatches(breakpoints: EDRBreakpoint[]): Observable<boolean> {
		return this.getActiveBreakpoint().pipe(map((currentBreakpoint) => breakpoints.includes(currentBreakpoint)));
	}

	/**
	 * This will return the min width breakpoint for each device type.
	 */
	public getBreakpointsPerDeviceType(): { deviceType: DeviceType; minWidth: number }[] | undefined {
		const deviceBreakpoints: { deviceType: DeviceType; minWidth: number }[] = [];

		this.breakpointsSorted.forEach((breakpoint) => {
			const deviceType = this.getCustomBreakpoint(breakpoint[0]);
			const existingDeviceBreakpoint = deviceBreakpoints.find((entry) => entry.deviceType === deviceType);
			if (!existingDeviceBreakpoint) {
				deviceBreakpoints.push({ deviceType, minWidth: breakpoint[1] });
			}
		});

		return deviceBreakpoints;
	}

	private matchBreakpoint(width: number): EDRBreakpoint {
		const matches = this.breakpointsSorted.filter((entry) => width >= entry[1]).reverse()[0];
		return matches?.length ? matches[0] : this.breakpointsSorted[0][0];
	}

	private getBreakpoints(): [EDRBreakpoint, number][] {
		const breakpoints: [EDRBreakpoint, number][] = EDRBreakpoints.map((breakpoint: EDRBreakpoint) => [
			breakpoint,
			Number(this.window.getComputedStyle(this.document.documentElement).getPropertyValue(`--breakpoint-${breakpoint}`)?.replace('px', '')),
		]);

		return breakpoints.sort((a, b) => a[1] - b[1]);
	}

	private getCustomBreakpoint(breakpoint: EDRBreakpoint): DeviceType {
		return Object.entries(this.deviceBreakpoints).filter(([_, value]) => value.includes(breakpoint))[0][0] as DeviceType;
	}
}
