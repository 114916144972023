import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';
import { PageMetaData } from './page-meta-data.interface';
import { CustomWindow } from '@edr/shared';

/**
 * This service is used to set meta data on a page for SEO purposes.
 */
@Injectable({
	providedIn: 'root',
})
export class MetaDataService {
	constructor(private title: Title, private meta: Meta, @Inject(WINDOW) private window: CustomWindow) {}

	public setPageMetaData(metaData: PageMetaData): void {
		this.setPageTitle(metaData.title);
		this.setMetaTitle(metaData.metaTitle || metaData.title);
		this.setMetaDescription(metaData.metaDescription);
		this.setMetaImage(metaData.metaImage);
		this.removeCanonical();
		this.setCanonicalUrl(metaData.canonicalPath);
		this.setIsIndexable(metaData.isIndexable);
		this.removeStructuredData();
		this.addStructuredData(metaData.structuredData);
		this.setLanguageAlternates(metaData.languageAlternates);

		/**
		 * Setting all indexable pages to Article schema by default as recommended by "Reason".
		 * Other pages than normal content pages should override this (blog posts, events, faqs, products, recipes, reviews, etc)
		 */
		if (this.getStructuredData('@type')) {
			return;
		}
		this.addStructuredData({
			'@type': 'Article',
		});
	}

	public addStructuredData(data?: Record<string, unknown>): void {
		if (!data) {
			return;
		}
		const existingData = this.getStructuredData();
		this.removeStructuredData();
		const script: HTMLScriptElement = this.window.document.createElement('script');
		script.type = 'application/ld+json';
		script.text = JSON.stringify({ '@context': 'https://schema.org', ...existingData, ...data });
		this.window.document.head.appendChild(script);
	}

	public setLanguageAlternates(alternates?: Record<string, string>): void {
		// Clear out any existing alternate links
		const existingAlternateLinks = this.window.document.head.querySelectorAll('link[rel="alternate"]');
		existingAlternateLinks.forEach((el) => {
			el.parentElement?.removeChild(el);
		});

		if (!alternates) {
			return;
		}

		for (const lang in alternates) {
			if (alternates[lang]) {
				const link: HTMLLinkElement = this.window.document.createElement('link');
				this.window.document.head.appendChild(link);
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', lang);
				link.setAttribute('href', alternates[lang]);
			}
		}
	}

	private setPageTitle(title: string): void {
		this.title.setTitle(title);
	}

	private setMetaTitle(title: string): void {
		this.meta.updateTag({ name: 'title', content: title });
		this.meta.updateTag({ property: 'og:title', content: title });
	}

	private setMetaDescription(description?: string): void {
		if (!description) {
			return;
		}
		this.meta.updateTag({ name: 'description', content: description });
		this.meta.updateTag({ property: 'og:description', content: description });
	}

	private setMetaImage(imageUrl?: string): void {
		if (!imageUrl) {
			return;
		}
		this.meta.updateTag({ property: 'og:image', content: imageUrl });
	}

	private setCanonicalUrl(path?: string): void {
		const url = path ? `${this.window.location.origin}${path}` : this.window.document.URL;
		this.addCanonicalUrl(url);
	}

	private addCanonicalUrl(url: string): void {
		const link: HTMLLinkElement = this.window.document.createElement('link');
		this.window.document.head.appendChild(link);
		link.setAttribute('rel', 'canonical');
		link.setAttribute('href', url);
	}

	private removeCanonical(): void {
		const canonical: HTMLLinkElement | null = this.window.document.head.querySelector("link[rel='canonical']");
		if (!canonical) {
			return;
		}
		this.window.document.head.removeChild(canonical);
	}

	private setIsIndexable(isIndexable = true): void {
		const content = isIndexable ? 'index, follow' : 'noindex, nofollow';
		this.meta.updateTag({ name: 'robots', content });
	}

	private removeStructuredData(): void {
		const script: HTMLScriptElement | null = this.window.document.head.querySelector('script[type="application/ld+json"]');
		if (!script) {
			return;
		}
		this.window.document.head.removeChild(script);
	}

	private getStructuredData(key?: string): Record<string, unknown> {
		const existingDataElement = this.window.document.head.querySelector('script[type="application/ld+json"]') as HTMLScriptElement | null;
		const existingData = existingDataElement && existingDataElement.text.length ? JSON.parse(existingDataElement.text) : {};
		return key ? existingData[key] : existingData;
	}
}
