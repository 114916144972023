import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as BoostsActions from './boosts.actions';
import { catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { CustomerPreferencesService } from '../../my-account/services';
import { BoostService, ModalService } from '../../../core/services';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectHasBoosted, selectNotActivatedOffers } from './boosts.selectors';
import { BoostFirstTimeModalComponent } from '../components/boost-first-time-modal/boost-first-time-modal.component';
import { notUndefined, PreferenceIds } from '@edr/shared';
import { APP_ROUTES } from '../../../routes';
import { Offer, OfferStatus } from '@edr/bff-api-models';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class BoostsEffects {
	public loadFirstTimeBoost$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoostsActions.loadFirstTimeBoost),
			switchMap(() =>
				this.customerPreferencesService.fetchCustomerPreferences([PreferenceIds.EDRFirstTimeBoost]).pipe(
					map((payload) => {
						const hasBoosted = payload.find((x) => x.id === PreferenceIds.EDRFirstTimeBoost)?.value ?? false;
						return BoostsActions.loadFirstTimeBoostComplete({ hasBoosted });
					}),
					catchError(() => of(BoostsActions.loadFirstTimeBoostFailed()))
				)
			)
		)
	);

	public updateFirstTimeBoost$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoostsActions.updateFirstTimeBoost),
			switchMap((action) =>
				this.customerPreferencesService.updateCustomerPreferences([{ id: PreferenceIds.EDRFirstTimeBoost, value: action.hasBoosted }]).pipe(
					map(() => BoostsActions.updateFirstTimeBoostComplete({ hasBoosted: action.hasBoosted })),
					catchError(() => of(BoostsActions.updateFirstTimeBoostFailed()))
				)
			)
		)
	);

	public loadBoostOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoostsActions.loadBoostOffers),
			switchMap(() =>
				this.boostService.fetchBoostOffers().pipe(
					map((response) =>
						BoostsActions.loadBoostOffersComplete({
							offers: (response.offers ?? []).sort(this.sortCompletedOffersLast),
						})
					),
					catchError(() => of(BoostsActions.loadBoostOffersFailed()))
				)
			)
		)
	);

	public activateBoostOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoostsActions.activateBoostOffer),
			concatLatestFrom(() => this.store.select(selectHasBoosted)),
			mergeMap(([action, hasBoostedBefore]) =>
				this.boostService.boostOffers([action.id]).pipe(
					tap(() => (hasBoostedBefore ? undefined : this.handFirstTimeBoosted())),
					map((response) => {
						if (response.result?.[0].offer.value) {
							return BoostsActions.activateBoostOfferComplete({ offer: response.result[0].offer.value });
						} else {
							this.router.navigate(['/', APP_ROUTES.error, '500']);
							return BoostsActions.activateBoostOfferFailed({ id: action.id });
						}
					}),
					catchError(() => {
						this.router.navigate(['/', APP_ROUTES.error, '500']);
						return of(BoostsActions.activateBoostOfferFailed({ id: action.id }));
					})
				)
			)
		)
	);

	public activateAllBoostOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoostsActions.activateAllBoostOffers),
			concatLatestFrom(() => combineLatest([this.store.select(selectHasBoosted), this.store.select(selectNotActivatedOffers)])),
			switchMap(([, [hasBoostedBefore, requestOffers]]) => {
				const requestIds = requestOffers.map((state) => state.offer.id);
				return this.boostService.boostOffers(requestIds).pipe(
					tap(() => (hasBoostedBefore ? undefined : this.handFirstTimeBoosted())),
					map((response) => {
						const offers = response.result?.map((result) => result.offer?.value).filter(notUndefined);
						if (offers) {
							return BoostsActions.activateAllBoostOffersComplete({ offers: offers });
						} else {
							this.router.navigate(['/', APP_ROUTES.error, '500']);
							return BoostsActions.activateAllBoostOffersFailed();
						}
					}),
					catchError(() => {
						this.router.navigate(['/', APP_ROUTES.error, '500']);
						return of(BoostsActions.activateAllBoostOffersFailed());
					})
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private customerPreferencesService: CustomerPreferencesService,
		private boostService: BoostService,
		private router: Router,
		private store: Store,
		private modalService: ModalService
	) {}

	private handFirstTimeBoosted(): void {
		this.modalService.clearQueue();
		this.modalService.open({
			ref: BoostFirstTimeModalComponent,
			id: 'boost-first-time-modal',
		});
		this.store.dispatch(BoostsActions.updateFirstTimeBoost({ hasBoosted: true }));
	}

	// TODO: CLSE-2733 - Remove FE sorting when sorting is taken care of in EDR services
	private sortCompletedOffersLast(a: Offer, b: Offer): number {
		if (a.status === OfferStatus.Redeemed && b.status !== OfferStatus.Redeemed) {
			return 1; // move offer towards the end if it is redeemed
		} else if (a.status !== OfferStatus.Redeemed && b.status === OfferStatus.Redeemed) {
			return -1; // keep offer at the beginning if not redeemed
		} else {
			return 0; // keep order the same in all other cases
		}
	}
}
