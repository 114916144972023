import { FlexiblePageTemplateResponse } from '@edr/bff-api-models';
import { createAction, props } from '@ngrx/store';
import { DYNAMIC_CONTENT_STATE_KEY } from '../dynamic-content.state-key';

export const loadPageContent = createAction(
	`[${DYNAMIC_CONTENT_STATE_KEY}] Load Page Content`,
	props<{ pageId: string; isPreview: boolean; verificationKey: string; env: string | null }>()
);

export const loadPageContentSuccess = createAction(
	`[${DYNAMIC_CONTENT_STATE_KEY}] Load Page Content Success`,
	props<{ pageId: string; content: FlexiblePageTemplateResponse }>()
);

export const loadPageContentFailure = createAction(`[${DYNAMIC_CONTENT_STATE_KEY}] Load Page Content Failure`, props<{ error: string }>());
