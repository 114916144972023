@if (name && size && color) {
<svg
	[edrApplyStyles]="[
		{ styleType: 'spacing', key: 'width', value: size },
		{ styleType: 'spacing', key: 'height', value: size },
		{ styleType: 'color', key: 'color', value: color }
	]"
	version="1.1"
	viewbox="0 0 32 32"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
>
	<use attr.xlink:href="#{{ name }}"></use>
</svg>
}
<ng-content></ng-content>
