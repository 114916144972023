import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRColor } from '../../types';
import { FlexAlign } from '../../types/flex-align.type';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplyStylesDirective } from '../../directives/apply-styles/apply-styles.directive';
import { mapToApplyStylingConfig } from '../../directives/apply-styles/apply-styles.helper';
import { CommonModule } from '@angular/common';
import { DeviceStyleConfig, StyleConfig } from '../../models';
import { ContentBaseComponent } from '@edr/shared';

/**
 * The section component is used to add the correct padding and margins to a row of content
 * and to define the correct max-width for content at each breakpoint.
 *
 * If you don't want a max-width for the content in the section, set the `fullWidthContent` input to true.
 * You will still get the correct padding at smaller breakpionts.
 *
 * To also disable the basic content padding, you can set rootPadding to false
 */
@UntilDestroy()
@Component({
	selector: 'edr-section',
	templateUrl: './section.component.html',
	styleUrls: ['./section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, ApplyStylesDirective],
})
export class SectionComponent extends ContentBaseComponent {
	@Input() public entryTitle: string | undefined;
	@Input() public backgroundColor: EDRColor | undefined;
	@Input() public paddingTop: DeviceStyleConfig | undefined;
	@Input() public paddingBottom: DeviceStyleConfig | undefined;

	@HostBinding('attr.fullWidthContent') @Input() public fullWidthContent = false;
	@HostBinding('attr.alignContent') @Input() public alignContent: FlexAlign | undefined;
	@HostBinding('attr.disablePaddingTop') @Input() public disablePaddingTop = false;
	@HostBinding('attr.disablePaddingBottom') @Input() public disablePaddingBottom = false;

	/**
	 * When true, padding is applied to breakpoints xxs, xs, sm, md so that the content doesn't touch the edge of the screen
	 */
	@HostBinding('attr.root-padding') @Input() public rootPadding = true;

	/**
	 * There's a difference in the max-width between standard content sections and the width of the
	 * container in which the banner:hero and the header/primary nav live. Control it with this toggle:
	 */
	@HostBinding('attr.is-standard-content-section') @Input() public isStandardContentSection = true;

	public get sectionStyles(): StyleConfig[] {
		const paddingTopStyleConfig = this.paddingTop ? mapToApplyStylingConfig('spacing', 'padding-top', this.paddingTop) : [];
		const paddingBottomStyleConfig = this.paddingBottom ? mapToApplyStylingConfig('spacing', 'padding-bottom', this.paddingBottom) : [];
		const backgroundColor: StyleConfig[] = this.backgroundColor
			? [
					{
						styleType: 'color',
						key: 'background-color',
						value: this.backgroundColor,
					},
			  ]
			: [];

		return [...paddingTopStyleConfig, ...paddingBottomStyleConfig, ...backgroundColor];
	}
}
