import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import * as MemberActions from './redemption-preferences.actions';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthIsLoggedIn } from '../../../auth';
import { RedemptionPreferencesService } from '../../../../features/my-account/services/redemption-preferences.service';

@Injectable()
export class RedemptionPreferencesEffects {
	public loadRedemptionPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MemberActions.loadRedemptionPreferences),
			concatLatestFrom(() => this.store.select(selectAuthIsLoggedIn)),
			filter(([, isLoggedIn]) => isLoggedIn),
			switchMap(() =>
				this.redemptionPreferenceService.fetchRedemptionPreferences().pipe(
					map((redemptionPreferences) => MemberActions.loadRedemptionPreferencesSuccess({ redemptionPreferences })),
					catchError((error) => of(MemberActions.loadRedemptionPreferencesFailure({ error })))
				)
			)
		)
	);

	public updateRedemptionPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MemberActions.updateRedemptionPreferences),
			concatLatestFrom(() => this.store.select(selectAuthIsLoggedIn)),
			filter(([, isLoggedIn]) => isLoggedIn),
			switchMap(([{ redemptionPreferences }]) =>
				this.redemptionPreferenceService.updateRedemptionPreferences(redemptionPreferences).pipe(
					map(() => MemberActions.updateRedemptionPreferencesSuccess({ redemptionPreferences })),
					catchError((error) => of(MemberActions.updateRedemptionPreferencesFailure({ error })))
				)
			)
		)
	);

	constructor(private actions$: Actions, private store: Store, private redemptionPreferenceService: RedemptionPreferencesService) {}
}
