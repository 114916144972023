export const APP_ROUTES = {
	home: '',
	privacyPolicy: 'privacy-policy',
	termsAndConditions: 'terms-and-conditions',
	boosts: 'boosts',
	help: 'help',
	contact: 'contact-us',
	error: 'error',
	partnerLink: 'partner-link',
	memberLink: 'member-link',
	login: 'login',
	howItWorks: 'how-it-works',
	howBoostsWork: 'how-boosts-work',
	app: 'app',
	account: {
		base: 'account',
		communicationPreferences: 'communications-preferences',
		everydayRewardsCard: 'card',
		personalDetails: 'personal-details',
		signupConfirmation: 'signup-confirmation',
		rewardsChoice: 'rewards-choice',
		accountSettings: 'account-settings',
		pointsAndActivity: 'points-and-activity',
	},
	partners: {
		base: 'partners',
		bp: 'bp',
		vineonline: 'vineonline',
		asb: 'asb',
		woolworthsNz: 'woolworths',
	},
	signIn: 'sign-in',
	preview: 'preview',
};

export interface AppRoutes {
	account: Record<string, string>;
	partners: Record<string, string>;
	home: string;
	privacyPolicy: string;
	termsAndConditions: string;
	boosts: string;
	help: string;
	contact: string;
	error: string;
	partnerLink: string;
	memberLink: string;
	howItWorks: string;
	howBoostsWork: string;
	app: string;
	signIn: string;
}
