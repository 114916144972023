@if (partnerProductBoost && { isSmallDevice: isSmallDevice$ | async }; as templateData) { @if (roundelText) {
<edr-roundel [roundelText]="roundelText" [size]="templateData.isSmallDevice === false || isCarouselItem ? 'lg' : 'sm'" />
}
<div class="card-boost__container">
	@if (partner$ | async; as partner) { @if (partner.offerCardLabelLogo.url) {
	<img class="card-boost__label" [src]="partner.offerCardLabelLogo.url" [alt]="partner.offerCardLabelLogo.altText" />
	} }
	<div class="card-boost__content" data-testid="boost-card">
		<div class="card-boost__imagery">
			<div class="card-boost__imagery-wrapper">
				<img data-testid="boost-image" [src]="partnerProductBoost?.product?.imageUrl" [alt]="partnerProductBoost?.product?.name" />
			</div>
			@if (partnerProductBoost?.isBoosted) {
			<edr-tag type="boost" class="card-boost__tag" size="medium" data-testid="boosted-tag">Boosted</edr-tag>
			}
		</div>
		<div class="card-boost__info">
			<div>
				<div #infoContainer class="card-boost__info-text">
					<edr-heading #heading [level]="templateData.isSmallDevice ? 5 : 4" class="card-boost__info-title" data-testid="boost-title">{{
						partnerProductBoost?.title || '' | truncate : 35
					}}</edr-heading>
					<p #description class="card-boost__info-description" data-testid="boost-description">
						<span edr-typography typographyAs="body--xs">
							@if (templateData.isSmallDevice === false || isCarouselItem) {
							{{ partnerProductBoost?.description?.list || '' | truncate : 120 }}
							} @else {
							{{ partnerProductBoost?.description?.listShort || '' | truncate : 50 }}
							}
						</span>
					</p>
				</div>
			</div>
			<div class="card-boost__info-footer">
				@if (partnerProductBoost?.limit || partnerProductBoost?.endDate) {
				<ul class="card-boost__info-limits">
					@if (partnerProductBoost?.limit) {
					<li>
						<div>
							<edr-icon name="alert-info-outlined" size="4" color="extended--charcoal-light-40"></edr-icon>
							<p data-testid="boost-limit">Limit {{ partnerProductBoost?.limit }} per customer</p>
						</div>
					</li>
					} @else {
					<li>
						<div>
							<edr-icon name="alert-info-outlined" size="4" color="extended--charcoal-light-40"></edr-icon>
							<p>Limit: none</p>
						</div>
					</li>
					} @if (partnerProductBoost?.endDate) {
					<li>
						<div>
							<edr-icon name="system-calendar" size="4" color="extended--charcoal-light-40"></edr-icon>
							<p data-testid="boost-end-date">Ends {{ partnerProductBoost?.endDate | date : 'dd MMM' }}</p>
						</div>
					</li>
					}
				</ul>
				}
				<div class="card-boost__actions">
					<button
						edr-link
						linkFont="FreshSans"
						size="small"
						(click)="openOfferModal()"
						class="card-boost__actions--secondary"
						data-testid="boost-details-link"
					>
						See details
					</button>
					@if (buttonCta && partnerProductBoost && !partnerProductBoost.isBoosted && !partnerProductBoost.isCompleted) {
					<button
						edr-button
						id="btn-boost"
						(click)="boostOffer(partnerProductBoost)"
						[buttonSize]="templateData.isSmallDevice ? 'small' : 'medium'"
						[spinnerHeight]="templateData.isSmallDevice ? '3' : '6'"
						class="card-boost__actions--primary"
						[buttonStyle]="buttonCta.style ?? 'primary'"
						[reversed]="buttonCta.styleReversed ?? false"
						[isLoading]="partnerProductBoost.isBoosting"
						[disabled]="partnerProductBoost.isBoosting"
						spinnerHeight="5"
						spinnerMarginHorizontal="1"
						data-testid="boost-button"
					>
						{{ buttonCta!.text }}
					</button>
					} @if (partnerProductBoost?.isBoosted && !partnerProductBoost?.isCompleted && buttonCta && buttonCta.linkUrl) {
					<edr-link
						id="btn-partner-shop"
						[asButton]="true"
						[buttonStyle]="buttonCta.style ?? 'secondary'"
						[buttonStyleReversed]="buttonCta.styleReversed ?? true"
						[size]="templateData.isSmallDevice ? 'small' : 'medium'"
						class="card-boost__actions--secondary"
						[linkUrl]="buttonCta.linkUrl"
						[text]="buttonCta!.text"
						data-testid="boost-shop-button"
						target="_blank"
					></edr-link>
					} @if (partnerProductBoost?.isCompleted) {
					<button
						edr-button
						[buttonSize]="templateData.isSmallDevice ? 'small' : 'medium'"
						class="card-boost__actions--primary"
						buttonStyle="primary"
						[disable]="true"
					>
						Completed
					</button>
					}
				</div>
			</div>
		</div>
	</div>
</div>
}
