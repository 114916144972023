import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { AuthService } from '../../services';
import { selectAuthState } from '../../auth';

export const loginGuard =
    ({ loggedInRoute }: { loggedInRoute: string }) =>
	(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
		const router = inject(Router);
		const store = inject(Store);
		const authService = inject(AuthService);
        const route = activatedRouteSnapshot;
		const window = inject(WINDOW);

        const queryParams = new URLSearchParams();

		if(route?.queryParams) {
			Object.entries(route.queryParams)
				.filter(([key, value]) => value && /^utm_|^returnUrl$/i.test(key))
				.forEach(([key, value]) => queryParams.append(key, value));
		}

		return store.select(selectAuthState).pipe(
			filter(({ initialising }) => !initialising),
			switchMap(({ isLoggedIn }) => {
				if (!isLoggedIn) {
                    const loginRedirectUrl = `${window.location.href}?${queryParams}`;
					return authService.startLoginFlow(loginRedirectUrl).pipe(map(() => false) // User is not logged in yet
					);
				}

				const loggedInRedirectUrl = `${loggedInRoute}?${queryParams}`;
				return of(router.parseUrl(loggedInRedirectUrl));
			})
		);
	};
