import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'everyday-rewards-ui-content-base',
	standalone: true,
	template: '',
})
export class ContentBaseComponent {
	@HostBinding('attr.data-contentful-entry-id') @Input() public entryId: string | undefined;
	@HostBinding('attr.data-contentful-field-id') public contentfulEntryId = 'entryTitle';

	@HostBinding('class') public get anchorLink(): string {
		return this.entryId !== undefined ? `anchor-point-${this.entryId}` : '';
	}
}
