import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { RewardsCardAddress } from '../../models';
import { AddressSearchResponse } from '@edr/bff-api-models';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AddressFinderService } from '../../../../core/services/address-finder/address-finder.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointService, CustomWindow, addressValidator, postcodeValidator } from '@edr/shared';
import {
	AutocompleteComponent,
	ButtonComponent,
	ErrorMessageComponent,
	IconComponent,
	InputComponent,
	ModalComponent,
	OptionComponent,
	RadioGroupComponent,
	SpinnerComponent,
	TypographyComponent,
} from '@edr/styleguide';

@UntilDestroy()
@Component({
	selector: 'edr-app-order-card-address-modal',
	templateUrl: './order-card-address-modal.component.html',
	styleUrls: ['./order-card-address-modal.component.scss'],
	standalone: true,
	imports: [
		AutocompleteComponent,
		ButtonComponent,
		ErrorMessageComponent,
		OptionComponent,
		IconComponent,
		InputComponent,
		ModalComponent,
		RadioGroupComponent,
		ReactiveFormsModule,
		SpinnerComponent,
		TypographyComponent,
	],
})
export class OrderCardAddressModalComponent implements OnInit, OnDestroy {
	@Input() public isLoading?: boolean;
	@Input() public title = '';
	@Input() public description = '';
	@Input() public buttonText?: string;
	@Output() public selectAddress = new EventEmitter<RewardsCardAddress>();
	public isManual = false;
	public searchText = '';
	public autocompleteOptions: AddressSearchResponse[] | undefined;
	public isSearching = false;
	public lookupError = false;
	// Used as a flag to clear the hidden address form if the user clears the autocomplete field
	public valueSelected = false;
	public showManualLink = false;
	public addressForm = this.fb.group({
		addressLine1: ['', [Validators.required, addressValidator]],
		addressLine2: ['', addressValidator],
		suburb: ['', addressValidator],
		city: ['', [Validators.required, addressValidator]],
		postcode: ['', [Validators.required, postcodeValidator]],
	});
	public savedWindowHeight = 0;

	private throttleTimeout: unknown = null;
	private throttleInterval = 500;

	constructor(
		private modalService: ModalService,
		private addressFinderService: AddressFinderService,
		private fb: NonNullableFormBuilder,
		@Inject(WINDOW) private window: CustomWindow,
		private breakpointService: BreakpointService,
		@Inject(PLATFORM_ID) private platformId: object
	) {}

	public ngOnInit(): void {
		this.savedWindowHeight = this.window.visualViewport?.height ?? 0;
		if (isPlatformBrowser(this.platformId) && this.window.visualViewport) {
			this.window.visualViewport.addEventListener('resize', this.throttleResizeEvent);
		}
	}

	public ngOnDestroy(): void {
		if (isPlatformBrowser(this.platformId) && this.window.visualViewport) {
			this.window.visualViewport.removeEventListener('resize', this.throttleResizeEvent);
		}
	}

	public throttleResizeEvent = (event: Event): void => {
		if (!this.throttleTimeout) {
			this.handleResizeEvent(event);

			this.throttleTimeout = setTimeout(() => {
				this.throttleTimeout = null;
			}, this.throttleInterval);
		}
	};

	public handleResizeEvent = (event: Event): void => {
		const windowHeightUpdated = (event.target as VisualViewport)?.height ?? 0;
		// See https://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element
		if (['xxs', 'xs'].includes(this.breakpointService.getCurrentBreakpoint()) && this.window.document.activeElement?.tagName === 'INPUT') {
			this.window.document.activeElement.scrollIntoView();
		}

		// Check if the window size has increased meaning the keyboard must have closed
		if (
			!this.valueSelected &&
			['xxs', 'xs'].includes(this.breakpointService.getCurrentBreakpoint()) &&
			windowHeightUpdated > this.savedWindowHeight
		) {
			this.showManualLink = true;
		}

		this.savedWindowHeight = windowHeightUpdated;
	};

	public closeModal(): void {
		this.modalService.close();
	}

	public handleSubmit(): void {
		this.addressForm.markAllAsTouched();
		if (!this.isManual && !this.addressForm.valid) {
			this.lookupError = true;
			return;
		}

		this.lookupError = false;

		if (!this.addressForm.valid) {
			return;
		}

		this.selectAddress.emit(this.addressForm.value as RewardsCardAddress);
	}

	public handleDropdownClosed(): void {
		if (!this.valueSelected && !['xxs', 'xs'].includes(this.breakpointService.getCurrentBreakpoint())) {
			this.showManualLink = true;
		}
	}

	public handleSearchTextChange(searchText: string): void {
		this.isSearching = true;
		if (this.valueSelected) {
			this.clearAddressForm();
			this.valueSelected = false;
		}

		if (!searchText) {
			this.autocompleteOptions = [];
			this.isSearching = false;
			this.searchText = '';
			return;
		}

		this.addressFinderService
			.getAutoComplete(searchText)
			.pipe(untilDestroyed(this))
			.subscribe((result) => {
				this.searchText = searchText;
				this.isSearching = false;
				if (result.length === 0 && !['xxs', 'xs'].includes(this.breakpointService.getCurrentBreakpoint())) {
					this.showManualLink = true;
				}
				return (this.autocompleteOptions = result);
			});
	}

	public optionSelected(option: unknown): void {
		if (!option) {
			this.valueSelected = false;
			this.clearAddressForm();
			return;
		}
		const selectedValue = option as string;
		this.addressFinderService
			.getAddressDetail(selectedValue)
			.pipe(untilDestroyed(this))
			.subscribe((result) => {
				this.valueSelected = true;
				this.showManualLink = false;
				this.addressForm.setValue({
					addressLine1: result.addressLine1,
					addressLine2: result.addressLine2 ?? '',
					suburb: result.suburb ?? '',
					city: result.city,
					postcode: result.postcode,
				});
			});
	}

	public setIsManualTrue(ev: Event): void {
		ev.preventDefault();
		this.addressForm.markAsUntouched();
		this.isManual = true;
	}

	private clearAddressForm(): void {
		this.addressForm.setValue({
			addressLine1: '',
			addressLine2: '',
			suburb: '',
			city: '',
			postcode: '',
		});
	}
}
