import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface StoredResponse {
	status: number;
	statusText: string;
	body?: unknown;
	error?: unknown;
}

/**
 * Should be rewritten as a function and provided in app.config.ts within provideHttpClient(withInterCeptors())
 */

/**
 * This interceptor act as a caching layer for all HTTP requests made while executing on the server (SSR).
 * To avoid duplicate HTTP calls (once on server, and then once when app is bootstrapped on client), we use
 * `TransferState` to bring the results of the HTTP calls made on the server, over to the client.
 * This will prevent duplicate HTTP calls, as well as give a performance boost.
 */
@Injectable({
	providedIn: 'root',
})
export class TransferStateInterceptor implements HttpInterceptor {
	constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: object) {}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (request.method !== 'GET') {
			return next.handle(request);
		}

		const url = new URL(request.url.startsWith('http') ? request.url : `https://www.everydayrewards.co.nz/${request.url}`);
		const key = makeStateKey<StoredResponse>(`${url.pathname}?${url.searchParams}`);

		if (isPlatformServer(this.platformId)) {
			return next.handle(request).pipe(
				tap((event) => {
					if (event instanceof HttpResponse) {
						this.transferState.set<StoredResponse>(key, {
							body: event.body,
							status: event.status,
							statusText: event.statusText,
						});
					}
				}),
				catchError((err) => {
					// Currently the only error responses we want to store are GET 404s
					if (err instanceof HttpErrorResponse && err.status === 404) {
						this.transferState.set<StoredResponse>(key, {
							error: err.error,
							status: err.status,
							statusText: err.statusText,
						});
					}
					return throwError(() => err);
				})
			);
		} else {
			const storedResponse = this.transferState.get<StoredResponse | null>(key, null);
			if (storedResponse) {
				if (storedResponse.status === 404) {
					// Throw an error like a normal 404 API call would
					return throwError(
						() =>
							new HttpErrorResponse({
								error: storedResponse.error,
								status: storedResponse.status,
								statusText: storedResponse.statusText,
							})
					);
				}

				const response = new HttpResponse({ body: storedResponse.body, status: storedResponse.status });
				this.transferState.remove(key);
				return of(response);
			} else {
				return next.handle(request);
			}
		}
	}
}
