import {
	BoostOtherOffersCarouselResponse,
	BoostOtherOffersGridResponse,
	EveryDayOfferGroupResponse,
	EverydayOfferResponse,
	OffersOverviewResponse,
} from '@edr/bff-api-models/esm';
import { CardBoostComponent } from '../../../../features/boosts';
import { PartnerProductBoost } from '@edr/styleguide';
import { DynamicComponent } from '@edr/dc';
import { mapToDynamicComponent } from '../dynamic-content.factory';
import { createId } from './mapper-helpers';
import { BoostOffersCarouselResponse } from '@edr/bff-api-models';

export function mapOffersOverview(response: OffersOverviewResponse): DynamicComponent {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const children = (response as any).content ? mapToDynamicComponent((response as any).content) : undefined;
	return {
		id: createId(response.contentTypeId),
		type: 'boost-offers-grid',
		props: {
			entryId: response.entryId,
			numberOfOffers: response.numberOfOffers,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			title: (response as any).title,
		},
		children,
	};
}

export function mapEverydayOffer(response: EverydayOfferResponse): DynamicComponent {
	const props = createEverydayOffer(response);
	return {
		id: createId(response.contentTypeId),
		type: 'card-boost',
		props: {
			...props,
			entryId: response.entryId,
		},
	};
}

export function createEverydayOffer(response: EverydayOfferResponse): Partial<CardBoostComponent> {
	const partnerProductBoost: PartnerProductBoost = {
		id: response.id,
		title: response.title,
		description: {
			listShort: response.descriptionShort,
			list: response.descriptionShort,
			detailed: response.descriptionFull,
		},
		termsAndConditions: response.termsAndConditions,
		product: {
			name: response.offerImage.altText,
			imageUrl: response.offerImage.url,
			imageLargeUrl: response.offerImage.url,
		},
		partnerId: response.partner?.id,
		isBoosted: false,
		isProductImage: response.isProductImage,
	} as PartnerProductBoost;

	const roundel = response.roundel
		? {
				line1: response.roundel?.line1,
				line2: response.roundel?.line2,
				line3: response.roundel?.line3,
		  }
		: undefined;

	return {
		isProductImage: false,
		partnerProductBoost,
		roundelText: roundel,
		// TODO this should be configurable in contentful not hard coded CLSE-1771 - for now we don't want to show a CTA on the BP fuel offer
		// buttonCta: {
		// 	text: 'Shop range',
		// 	linkUrl: '#?',
		// 	style: 'secondary',
		// 	styleReversed: true,
		// },
	};
}

export function mapBoostOffersCarousel(response: BoostOffersCarouselResponse): DynamicComponent {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const children = (response as any).content ? mapToDynamicComponent((response as any).content) : undefined;
	return {
		id: createId(response.contentTypeId),
		type: 'boost-offers-carousel',
		props: {
			entryId: response.entryId,
			numberOfOffers: response.numberOfOffers,
			numberOfOffersTablet: response.numberOfOffersTablet,
			numberOfOffersMobile: response.numberOfOffersMobile,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			title: (response as any).title,
		},
		children,
	};
}

export function mapBoostOtherOffersGrid(response: BoostOtherOffersGridResponse): DynamicComponent {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const children = (response as any).content ? mapToDynamicComponent((response as any).content) : undefined;
	const everydayOfferGroup = response.everyDayOfferGroup as EveryDayOfferGroupResponse;
	const offers = everydayOfferGroup.offers?.map((o) => createEverydayOffer(o));
	return {
		id: createId(response.contentTypeId),
		type: 'boost-other-offers-grid',
		props: {
			entryId: response.entryId,
			numberOfOffers: response.numberOfOffers,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			title: (response as any).title,
			everydayOffers: offers,
			isOtherOffers: true,
		},
		children,
	};
}

export function mapBoostOtherOffersCarousel(response: BoostOtherOffersCarouselResponse): DynamicComponent {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const children = (response as any).content ? mapToDynamicComponent((response as any).content) : undefined;
	const everydayOfferGroup = response.everyDayOfferGroup as EveryDayOfferGroupResponse;
	const offers = everydayOfferGroup.offers?.map((o) => createEverydayOffer(o));
	return {
		id: createId(response.contentTypeId),
		type: 'boost-other-offers-carousel',
		props: {
			entryId: response.entryId,
			numberOfOffers: response.numberOfOffers,
			numberOfOffersTablet: response.numberOfOffersTablet,
			numberOfOffersMobile: response.numberOfOffersMobile,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			title: (response as any).title,
			everydayOffers: offers,
			isOtherOffers: true,
		},
		children,
	};
}
