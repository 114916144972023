import {
	AccordionItemResponse,
	AccordionResponse,
	AccountSettingsResponse,
	BannerComponentResponse,
	BoostOffersCarouselResponse,
	BoostOtherOffersCarouselResponse,
	BoostOtherOffersGridResponse,
	CallToActionGroupResponse,
	CardComponentResponse,
	CarouselResponse,
	CommunicationsPreferencesResponse,
	ContainerResponse,
	CtaPropsResponse,
	EveryDayOfferGroupResponse,
	EverydayOfferResponse,
	EverydayRewardsCardResponse,
	HeightResponse,
	IconPropsResponse,
	ImagePropsResponse,
	LayoutGroupResponse,
	M2mPointsGiftingResponse,
	MarginPaddingResponse,
	MultiStepItemResponse,
	MultiStepResponse,
	NotificationPanelResponse,
	OffersOverviewResponse,
	PartnerPropResponse,
	PersonalDetailsResponse,
	PointsDashboardResponse,
	PointsTableResponse,
	PreferenceGroupResponse,
	PreferencePropsResponse,
	RewardsChoiceResponse,
	RichTextBlockResponse,
	SectionResponse,
	ShowConditionallyResponse,
	WelcomeTutorialResponse,
	WidthResponse,
} from '@edr/bff-api-models';
import { DynamicComponent } from '@edr/dc';
import { EDRBreakpoint, generateId, mapDeviceBreakpointToActualBreakpoints, notUndefined } from '@edr/shared';
import { CtaType, EDRColor, EDRIcon, EDRSpacing, IconRotate, ImageFocalPoint, StyleConfig, StyleType } from '@edr/styleguide';
import {
	createId,
	mapBoostOffersCarousel,
	mapBoostOtherOffersCarousel,
	mapBoostOtherOffersGrid,
	mapEverydayOffer,
	mapOffersOverview,
	mapWelcomeTutorial,
} from './mappers';
import { mapM2MPointsGifting } from './mappers/m2m-points-gifting-mapper';
import { ConditionalDisplaySettings } from '../../pipes';
import { EDRCommunicationPreference, EDRCommunicationPreferenceGroup } from '../communications-preferences';
import { RewardsChoiceIconOptions } from '../rewards-choice/rewards-choice.component';

export type ComponentResponse =
	| AccordionResponse
	| BannerComponentResponse
	| CallToActionGroupResponse
	| CtaPropsResponse
	| CardComponentResponse
	| ContainerResponse
	| IconPropsResponse
	| LayoutGroupResponse
	| NotificationPanelResponse
	| PartnerPropResponse
	| RichTextBlockResponse
	| SectionResponse
	| OffersOverviewResponse
	| BoostOffersCarouselResponse
	| EverydayOfferResponse
	| BoostOtherOffersCarouselResponse
	| BoostOtherOffersGridResponse
	| EveryDayOfferGroupResponse
	| PointsDashboardResponse
	| PointsTableResponse
	| EverydayRewardsCardResponse
	| PersonalDetailsResponse
	| AccountSettingsResponse
	| RewardsChoiceResponse;

export function mapToDynamicComponent(components: ComponentResponse[]): DynamicComponent[] {
	return components.map((component) => mapResponseType(component)).filter(notUndefined);
}

// Would need to also pass in the current tree to be able to check on the contentType of a parent (in this case to check whether an accordion item is a child accordion item)
// Passing in the current Dynamic Component tree instead of 'parentResponse' and 'parentId' will result in better/more readable/future proof code.
export function mapResponseType(response: ComponentResponse, parentResponse?: ComponentResponse, parentId?: string): DynamicComponent | undefined {
	switch (response.contentTypeId) {
		case 'accordion':
			return mapAccordion(response as AccordionResponse);
		case 'accordionItem':
			return mapAccordionItem(response as AccordionItemResponse, parentResponse as AccordionResponse | AccordionItemResponse, parentId);
		case 'accountSettings':
			return mapAccountSettings(response as AccountSettingsResponse);
		case 'everydayRewardsCard':
			return mapEDRCardComponent(response as EverydayRewardsCardResponse);
		case 'multiStep':
			return mapMultiStep(response as MultiStepResponse, parentId);
		case 'multiStepItem':
			return mapMultiStepItem(response as MultiStepItemResponse, parentId);
		case 'welcomeTutorial':
			return mapWelcomeTutorial(response as WelcomeTutorialResponse);
		case 'banner':
			return mapBanner(response as BannerComponentResponse);
		case 'imageProps':
			return mapImage(response as ImagePropsResponse);
		case 'boostOffersCarousel':
			return mapBoostOffersCarousel(response as BoostOffersCarouselResponse);
		case 'boostOtherOffersGrid':
			return mapBoostOtherOffersGrid(response as BoostOtherOffersGridResponse);
		case 'boostOtherOffersCarousel':
			return mapBoostOtherOffersCarousel(response as BoostOtherOffersCarouselResponse);
		case 'cardComponent':
			return mapCardComponent(response as CardComponentResponse);
		case 'callToActionGroup':
			return mapCTAGroupComponent(response as CallToActionGroupResponse);
		case 'carousel':
			return mapCarousel(response as CarouselResponse);
		case 'container':
			return mapContainerComponent(response as ContainerResponse);
		case 'ctaProps':
			return mapCTAComponent(response as CtaPropsResponse);
		case 'everydayOffer':
			return mapEverydayOffer(response as EverydayOfferResponse);
		case 'layoutGroup':
			return mapLayoutGroup(response as LayoutGroupResponse);
		case 'notificationPanel':
			return mapNotificationPanel(response as NotificationPanelResponse);
		case 'pointsDashboard':
			return mapPointsDashboard(response as PointsDashboardResponse);
		case 'richTextBlock':
			return mapRichTextBlock(response as RichTextBlockResponse);
		case 'offersOverview':
			return mapOffersOverview(response as OffersOverviewResponse);
		case 'partner':
			return mapPartner(response as PartnerPropResponse);
		case 'personalDetails':
			return mapPersonalDetails(response as PartnerPropResponse);
		case 'pointsTable':
			return mapPointsTable(response as PointsTableResponse);
		case 'section':
			return mapSection(response as SectionResponse);
		case 'm2mPointsGifting':
			return mapM2MPointsGifting(response as M2mPointsGiftingResponse);
		case 'communicationsPreferences':
			return mapCommunicationsPreferences(response as CommunicationsPreferencesResponse);
		case 'rewardsChoice':
			return mapRewardsChoice(response as RewardsChoiceResponse);
		default:
			return;
	}
}

export function mapRewardsChoice(response: RewardsChoiceResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'rewards-choice',
		props: {
			currentRewardsChoiceTitle: response.currentRewardsChoiceTitle,
			otherRewardsChoicesTitle: response.otherRewardsChoicesTitle,
			rewardsChoiceIconOptions: mapRewardsChoiceIconOptions(response),
			entryId: response.entryId,
			spendNow: {
				accordionItem: {
					...response.spendNow.accordionItem,
					...(response.spendNow.accordionItem?.icon && mapToComponentIconProps(response.spendNow.accordionItem?.icon)),
				},
				howItWorksCards: response.spendNow.accordionItem?.childItems,
				chooseCta: response.spendNow.chooseCta,
				selectedHeaderDescription: response.spendNow.selectedHeaderDescription,
			},
			saveForOccasion: {
				accordionItem: {
					...response.saveForOccasion.accordionItem,
					...(response.saveForOccasion.accordionItem?.icon && mapToComponentIconProps(response.saveForOccasion.accordionItem?.icon)),
				},
				howItWorksCards: response.saveForOccasion.accordionItem.childItems,
				chooseCta: response.saveForOccasion.chooseCta,
				maxMonthsInFuture: response.saveForOccasion.maxMonthsInFuture,
				selectedHeaderDescription: response.saveForOccasion.selectedHeaderDescription,
			},
		},
	};
}

function mapRewardsChoiceIconOptions(response: RewardsChoiceResponse): RewardsChoiceIconOptions[] {
	return [
		{
			redemptionPreference: response.spendNow.redemptionPreference,
			iconName: response.spendNow.iconCircleGroupOption.iconName as EDRIcon,
			selectedIconColor: response.spendNow.iconCircleGroupOption.selectedIconColor as EDRColor,
			selectedBackgroundColor: response.spendNow.iconCircleGroupOption.selectedBackgroundColor as EDRColor,
		},
		{
			redemptionPreference: response.saveForOccasion.redemptionPreference,
			iconName: response.saveForOccasion.iconCircleGroupOption.iconName as EDRIcon,
			selectedIconColor: response.saveForOccasion.iconCircleGroupOption.selectedIconColor as EDRColor,
			selectedBackgroundColor: response.saveForOccasion.iconCircleGroupOption.selectedBackgroundColor as EDRColor,
		},
	];
}

export function mapAccordion(response: AccordionResponse): DynamicComponent {
	// Everything in the accordion needs the same id
	const id = createId(response.contentTypeId);
	const children = response.accordionItems.map((item) => mapResponseType(item as ComponentResponse, response, id)) as DynamicComponent[];

	return {
		id,
		type: 'accordion',
		props: { id, entryId: response.entryId },
		children,
	};
}

/**
 * Take a structured icon Props response and map it to input property names components have
 * for icon properties.
 * @param icon
 */
const mapToComponentIconProps = (
	icon: IconPropsResponse
): {
	iconName: EDRIcon;
	iconSize: EDRSpacing;
	iconColor: EDRColor;
	iconCircleSize: EDRSpacing;
	iconCircleColor: EDRColor;
	rotate?: string;
} => ({
	iconName: <EDRIcon>icon.name,
	iconSize: <EDRSpacing>icon.size,
	iconColor: <EDRColor>icon.color,
	iconCircleSize: <EDRSpacing>(icon.circleSize || (Number(icon.size || 0) + 6).toString()),
	iconCircleColor: <EDRColor>icon.circleColor,
	rotate: <IconRotate>icon.rotate,
});

export function mapAccordionItem(
	response: AccordionItemResponse,
	parentResponse: AccordionResponse | AccordionItemResponse | undefined,
	parentId = ''
): DynamicComponent {
	const richTextContent: RichTextBlockResponse | undefined = response.content && {
		contentTypeId: 'richTextBlock',
		content: response.content,
		entryTitle: '',
		entryId: response.entryId,
	};
	const children = [...((richTextContent && [richTextContent]) || []), ...(response.childItems || [])]
		.map((child) => mapResponseType(child as ComponentResponse, response as ComponentResponse, parentId))
		.filter(notUndefined);
	return {
		id: `${response.contentTypeId}-${generateId()}`,
		type: response.accordionItemType === 'Card' ? 'accordion-item-card' : 'accordion-item-default',
		props: {
			id: parentId,
			entryId: response.entryId,
			deepLinkAnchorText: response.deepLinkAnchorText,
			heading: response.heading,
			expanded: response.expanded,
			selected: response.selected,
			...((response.description && { description: response.description }) || {}),
			hasChildAccordion: !!response.childItems?.some((item) => item.contentTypeId === 'accordionItem'),
			isChildAccordion: !!(parentResponse as AccordionItemResponse)?.childItems?.some((item) => item.contentTypeId === 'accordionItem'),
			isToggleDisabled: response.isToggleDisabled,
			icon: response.icon ?? {},
		},
		...((children && { children }) || {}),
	};
}

export function mapMultiStep(response: MultiStepResponse, parentId?: string): DynamicComponent {
	// Everythng in the multiStep needs the same id
	const id = parentId ?? createId(response.contentTypeId);
	const children = response.multiStepItems.map((item) => mapResponseType(item as ComponentResponse, response, id)) as DynamicComponent[];

	return {
		id,
		type: 'multiStep',
		props: {
			id,
			entryId: response.entryId,
			title: response.title,
		},
		children,
	};
}

export function mapMultiStepItem(response: MultiStepItemResponse, parentId = ''): DynamicComponent {
	const children = [response.content].map((item) => mapResponseType(item as ComponentResponse, response, parentId)) as DynamicComponent[];

	return {
		id: parentId,
		type: 'multiStepItem',
		props: {
			id: parentId,
			entryId: response.entryId,
			stepIndex: response.stepIndex,
			active: response.active,
		},
		...((children && { children }) || {}),
	};
}

export function mapRichTextBlock(response: RichTextBlockResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'rich-text',
		props: {
			entryId: response.entryId,
			document: response.content,
			hasGreyBackground: response.isTextDisplayedOnAGreyBackground,
		},
	};
}

export function mapSection(response: SectionResponse): DynamicComponent {
	const children = response.content ? mapToDynamicComponent(response.content) : undefined;

	return {
		id: createId(response.contentTypeId),
		type: 'section',
		props: {
			entryId: response.entryId,
			entryTitle: response.entryTitle,
			fullWidthContent: response.fullWidthContent,
			backgroundColor: response.backgroundColor,
			rootPadding: response.rootPadding,
			disablePaddingTop: response.disablePaddingTop,
			disablePaddingBottom: response.disablePaddingBottom,
			isStandardContentSection: typeof response.isStandardContentSection !== 'undefined' ? response.isStandardContentSection : true,
			paddingTop: {
				...(response.paddingTop?.desktop ? { desktop: response.paddingTop?.desktop } : {}),
				...(response.paddingTop?.tablet ? { tablet: response.paddingTop?.tablet } : {}),
				...(response.paddingTop?.mobile ? { mobile: response.paddingTop?.mobile } : {}),
			},
			paddingBottom: {
				...(response.paddingBottom?.desktop ? { desktop: response.paddingBottom?.desktop } : {}),
				...(response.paddingBottom?.tablet ? { tablet: response.paddingBottom?.tablet } : {}),
				...(response.paddingBottom?.mobile ? { mobile: response.paddingBottom?.mobile } : {}),
			},
		},
		...((children && { children }) || {}),
	};
}

function mapBanner(response: BannerComponentResponse): DynamicComponent {
	/**
	 * When an editor has selected to display one or more CTA's with a predefined action, we need to render the ActionButton component.
	 * This button has app-specific actions so can't live in the styleguide. So we need to render this manually.
	 */
	const children = response.content?.length
		? mapToDynamicComponent(response.content)
		: response.primaryCta || response.secondaryCta
			? mapToDynamicComponent([
					{
						contentTypeId: 'callToActionGroup',
						typeId: 'CallToActionGroup',
						entryTitle: 'string',
						ctaType: CtaType.Button,
						mobileCtaDirection: response.mobileCtaDirection,
						tabletCtaDirection: response.tabletCtaDirection,
						desktopCtaDirection: response.desktopCtaDirection,
						primary: response.primaryCta,
						secondary: response.secondaryCta,
					} as CallToActionGroupResponse,
				])
			: [];

	const headingLevel = response.titleHeadingLevel?.level;

	return {
		id: createId(response.contentTypeId),
		type: 'banner',
		children,
		props: {
			entryId: response.entryId,
			bannerType: response.bannerType,
			title: response.title,
			titleHeadingLevel: headingLevel ? Number(headingLevel) : headingLevel,
			preTitle: response.preTitle,
			description: response.description,
			color: response.color,
			backgroundColor: response.backgroundColor,
			contentAlignment: response.contentAlignment,
			reversed: !!response.reversed,
			icon: response.icon
				? {
						name: response.icon?.name,
						color: response.icon?.color,
						size: response.icon?.size,
					}
				: undefined,
			mediaAlignment: response.mediaAlignment,
			mediaAlwaysAtTop: response.mediaAlwaysAtTop,
			...(response.partner ? { partnerBadge: { src: response.partner?.offerCardLabelLogo.url, alt: response.partner?.name } } : {}),
			image: response.image
				? {
						url: response.image?.url,
						altText: response.image?.altText,
						scaleToContainer: response.image?.scaleToContainer,
						containImageWhenScalingToContainer: response.image?.containImageWhenScalingToContainer,
						focalPoint: mapFocalPoint(response.image),
						imageAssetDimensions: response.image?.assetWidth && {
							width: response.image.assetWidth,
							height: response.image.assetHeight,
						},
						imageDeviceTransformations: response.image?.imageDeviceTransformations,
						lazyLoad: response.image?.lazyLoad,
					}
				: undefined,
		},
	};
}

function mapLayoutGroup(response: LayoutGroupResponse): DynamicComponent {
	const id = createId(response.contentTypeId);
	const children = response.content?.map((child) => createLayoutGroupItem(child as ComponentResponse, id));

	return {
		id,
		type: 'layoutGroup',
		props: {
			id,
			entryId: response.entryId,
			itemWidthType: response.itemWidthType,
			fixedItemWidth: response.fixedItemWidth,
			dynamicColumnConfig: response.dynamicColumnConfig,
			...(response.gap ? { gap: response.gap } : {}),
		},
		...((children && { children }) || {}),
	};
}

function createLayoutGroupItem(response: ComponentResponse, id: string): DynamicComponent {
	const children = response ? mapToDynamicComponent([response]) : undefined;

	return {
		id,
		type: 'layoutGroupItem',
		props: {
			id,
			entryId: response.entryId,
		},
		...((children && { children }) || {}),
	};
}

function mapCardComponent(response: CardComponentResponse): DynamicComponent {
	const image = response.image && {
		...response.image,
		focalPoint: response.image && mapFocalPoint(response.image),
		imageAssetDimensions: response.image?.assetWidth && {
			width: response.image.assetWidth || 0,
			height: response.image.assetHeight || 0,
		},
		imageDeviceTransformations: response.image?.imageDeviceTransformations,
		lazyLoad: response.image?.lazyLoad,
	};
	const icon = response.icon && {
		name: response.icon.name,
		color: response.icon.color,
		size: response.icon.size,
	};
	const primaryCta = response.primaryCta;
	const secondaryCta = response.secondaryCta;
	const description = [
		...mapToDynamicComponent([
			{
				contentTypeId: 'richTextBlock',
				entryTitle: '',
				content: response.description,
			} as unknown as RichTextBlockResponse,
		]),
		...((response.content && mapToDynamicComponent(response.content)) || []),
	];

	return {
		id: createId(response.contentTypeId),
		type: 'card',
		props: {
			entryId: response.entryId,
			cardType: response.cardType,
			title: response.title,
			mediaAlignment: response.mediaAlignment,
			ctaType: response.ctaType,
			cardColor: response.cardColor,
			cardBorder: response.cardBorder,
			circleIconHidden: response.circleIconHidden,
			...((image && { image }) || {}),
			...((icon && { icon }) || {}),
			...((primaryCta && { primaryCta }) || {}),
			...((secondaryCta && { secondaryCta }) || {}),
		},
		children: description,
	};
}

function mapContainerComponent(response: ContainerResponse): DynamicComponent {
	const children = response.content ? mapToDynamicComponent(response.content) : undefined;
	const spacing = response.marginPadding ? { spacing: mapSpacing(response.marginPadding) } : {};
	const width = response.width ? { width: mapResponsiveWidth(response.width) } : {};
	const height = response.height ? { height: mapResponsiveHeight(response.height) } : {};
	const minHeight = response.minHeight ? { minHeight: mapResponsiveMinHeight(response.minHeight) } : {};
	const conditions = response.showConditionally ? mapShowConditionally(response.showConditionally) : undefined;

	return {
		id: createId(response.contentTypeId),
		type: 'container',
		props: {
			entryId: response.entryId,
			alignContent: response.alignment,
			flexDirection: response.direction ?? 'row',
			showOnlyOnPlatform: response.platform,
			content: children,
			conditions,
			...spacing,
			...width,
			...height,
			...minHeight,
		},
	};
}

function mapCTAComponent(response: CtaPropsResponse): DynamicComponent {
	const children = response.image ? [mapImage(response.image)] : undefined;

	const link = {
		type: 'link',
		props: {
			entryId: response.entryId,
			asImage: !!children,
			text: response.text,
			linkUrl: response.linkUrl,
			target: response.target,
			size: response.size,
			asButton: true,
			buttonStyle: response.style,
			buttonStyleReversed: response.styleReversed,
			iconRight: response.iconRight,
			showRightIcon: !!response.iconRight,
			iconLeft: response.iconLeft,
			showLeftIcon: !!response.iconLeft,
		},
		...((children && { children }) || {}),
	};

	const actionButton = {
		type: 'actionButton',
		props: {
			entryId: response.entryId,
			text: response.text,
			action: response.predefinedAction,
			size: response.size,
			buttonStyle: response.style,
			buttonStyleReversed: response.styleReversed,
			iconRight: response.iconRight,
			showRightIcon: !!response.iconRight,
			iconLeft: response.iconLeft,
			showLeftIcon: !!response.iconLeft,
		},
		...((children && { children }) || {}),
	};

	return {
		id: createId(response.contentTypeId),
		...(response.predefinedAction ? actionButton : link),
	};
}

function mapCTAGroupComponent(response: CallToActionGroupResponse): DynamicComponent {
	const callToActionButtons = [...(response.primary ? [response.primary] : []), ...(response.secondary ? [response.secondary] : [])];
	const children = callToActionButtons.length ? mapToDynamicComponent(callToActionButtons) : undefined;

	return {
		id: createId(response.contentTypeId),
		type: 'cta-group',
		...((children && { children }) || {}),
		props: {
			entryId: response.entryId,
			mobileCtaDirection: response.mobileCtaDirection,
			tabletCtaDirection: response.tabletCtaDirection,
			desktopCtaDirection: response.desktopCtaDirection,
		},
	};
}

function mapSpacing(marginPadding: MarginPaddingResponse[]): StyleConfig[] {
	return marginPadding.map((spacingConfig) => {
		const breakpoints =
			!spacingConfig.screenSize || spacingConfig.screenSize === 'all'
				? {}
				: { breakpoints: mapDeviceBreakpointToActualBreakpoints(spacingConfig.screenSize) };
		const entry: StyleConfig = {
			styleType: 'spacing' as StyleType,
			key: spacingConfig.type || 'margin',
			value: spacingConfig.spacing,
			...breakpoints,
		};
		return entry;
	});
}

function mapPointsDashboard(response: PointsDashboardResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'points-dashboard',
		props: {
			entryId: response.entryId,
			title: response.title,
			description: response.description,
			button: {
				text: response.primaryButton?.text,
				linkUrl: response.primaryButton?.linkUrl,
				size: response.primaryButton?.size,
				style: response.primaryButton?.style,
				asButton: true,
			},
		},
	};
}

function mapPartner(response: PartnerPropResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'partner',
		props: {
			entryId: response.entryId,
			partnerLogo: response.logo.url,
			partnerLink: response.link,
			partnerName: response.name,
		},
	};
}

function mapCarousel(response: CarouselResponse): DynamicComponent {
	const id = createId(response.contentTypeId);
	const children = response.carouselItems?.map((child) => createCarouselItem(child as ComponentResponse, id));
	const hasProvidedDynamicColumns = response.mobileColumns || response.tabletColumns || response.desktopColumns;

	return {
		id,
		type: 'carousel',
		props: {
			id,
			entryId: response.entryId,
			itemWidthType: response.itemWidthType || 'fixed',
			fixedItemWidth: response.itemWidth,
			enableNavigation: typeof response.enableNavigation === 'undefined' ? true : response.enableNavigation,
			...((hasProvidedDynamicColumns && {
				dynamicColumnConfig: {
					...((response.mobileColumns && { xxs: response.mobileColumns }) || {}),
					...((response.tabletColumns && { sm: response.tabletColumns }) || {}),
					...((response.desktopColumns && { xl: response.desktopColumns }) || {}),
				},
			}) ||
				{}),
		},
		...((children && { children }) || {}),
	};
}

function createCarouselItem(response: ComponentResponse, id: string): DynamicComponent {
	const children = mapToDynamicComponent([response]);

	return {
		id,
		type: 'carousel-item',
		props: {
			id,
			entryId: response.entryId,
		},
		...((children && { children }) || {}),
	};
}

function mapNotificationPanel(response: NotificationPanelResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'notificationPanel',
		props: {
			entryId: response.entryId,
			notificationType: response.notificationType,
			title: response.title,
			description: response.description,
			canDismiss: response.canDismiss,
			cta: response.cta,
			animate: response.animate,
			animationType: response.animationType,
			iconName: response.iconName,
			showIcon: response.showIcon,
			showInsideSection: response.showInsideSection,
			hasSectionPaddingTop: response.hasSectionPaddingTop,
		},
	};
}

function mapImage(response: ImagePropsResponse): DynamicComponent {
	const width = response.width ? { width: mapResponsiveWidth(response.width) } : {};
	const height = response.height ? { height: mapResponsiveHeight(response.height) } : {};
	return {
		id: createId(response.contentTypeId),
		type: 'imageProps',
		props: {
			entryId: response.entryId,
			src: response.url,
			altText: response.altText,
			...width,
			...height,
			scaleToContainer: response.scaleToContainer,
			contain: response.containImageWhenScalingToContainer,
			focalPoint: mapFocalPoint(response),
			imageAssetDimensions: response.assetWidth && {
				width: response.assetWidth,
				height: response.assetHeight,
			},
			imageDeviceTransformations: response.imageDeviceTransformations,
			lazyLoad: response.lazyLoad,
		},
	};
}

function mapPointsTable(response: PointsTableResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'points-table',
		props: {
			entryId: response.entryId,
		},
	};
}

function mapResponsiveWidth(widthResponse: WidthResponse[]): StyleConfig[] {
	return widthResponse.map((widthConfig) => {
		const breakpoints =
			!widthConfig.screenSize || widthConfig.screenSize.toLowerCase() === 'all'
				? {}
				: { breakpoints: mapDeviceBreakpointToActualBreakpoints(widthConfig.screenSize) };
		const widthAsPercentage = widthConfig.widthAsPercentage ? `${widthConfig.widthAsPercentage}%` : '';
		const widthAsSpacing = widthConfig.widthAsSpacing ? `var(--spacing-${widthConfig.widthAsSpacing})` : '';
		const entry: StyleConfig = {
			styleType: 'string',
			key: 'width',
			value: widthAsSpacing || widthAsPercentage,
			...breakpoints,
		};
		return entry;
	});
}

function mapResponsiveHeight(heightResponse: HeightResponse[]): StyleConfig[] {
	return heightResponse.map((heightConfig) => {
		const breakpoints =
			!heightConfig.screenSize || heightConfig.screenSize.toLowerCase() === 'all'
				? {}
				: { breakpoints: mapDeviceBreakpointToActualBreakpoints(heightConfig.screenSize) };
		const heightAsPercentage = heightConfig.heightAsPercentage ? `${heightConfig.heightAsPercentage}%` : '';
		const heightAsSpacing = heightConfig.heightAsSpacing ? `var(--spacing-${heightConfig.heightAsSpacing})` : '';
		const entry: StyleConfig = {
			styleType: 'string',
			key: 'height',
			value: heightAsSpacing || heightAsPercentage,
			...breakpoints,
		};
		return entry;
	});
}

function mapResponsiveMinHeight(minHeightResponse: HeightResponse[]): StyleConfig[] {
	return minHeightResponse.map((heightConfig) => {
		const breakpoints = heightConfig.breakPoint
			? { breakpoints: [heightConfig.breakPoint as EDRBreakpoint] }
			: !heightConfig.screenSize || heightConfig.screenSize.toLowerCase() === 'all'
				? {}
				: { breakpoints: mapDeviceBreakpointToActualBreakpoints(heightConfig.screenSize) };

		const heightAsPercentage = heightConfig.heightAsPercentage ? `${heightConfig.heightAsPercentage}%` : '';
		const heightAsSpacing = heightConfig.heightAsSpacing ? `var(--spacing-${heightConfig.heightAsSpacing})` : '';
		const heightAsViewHeight = heightConfig.heightAsViewHeight ? `${heightConfig.heightAsViewHeight}vh` : '';
		const entry: StyleConfig = {
			styleType: 'string',
			key: 'min-height',
			value: heightAsSpacing || heightAsPercentage || heightAsViewHeight,
			...breakpoints,
		};
		return entry;
	});
}

function mapShowConditionally(showConditionallyResponse: ShowConditionallyResponse): ConditionalDisplaySettings {
	return {
		isUserLoggedIn: showConditionallyResponse.userIsLoggedIn,
		isUserLoggedOut: showConditionallyResponse.userIsLoggedOut,
	};
}

function mapEDRCardComponent(response: EverydayRewardsCardResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'everyday-rewards-card',
		props: {
			entryId: response.entryId,
		},
	};
}

function mapPersonalDetails(response: EverydayRewardsCardResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'personal-details',
		props: {
			entryId: response.entryId,
		},
	};
}

function mapCommunicationsPreferences(response: CommunicationsPreferencesResponse): DynamicComponent {
	const preferenceGroupConfig = response.preferenceGroups ? response.preferenceGroups.map((item) => mapPreferenceGroups(item)) : [];
	return {
		id: createId(response.contentTypeId),
		type: 'communications-preferences',
		props: {
			entryId: response.entryId,
			preferenceGroupConfig,
		},
	};
}

function mapAccountSettings(response: AccountSettingsResponse): DynamicComponent {
	return {
		id: createId(response.contentTypeId),
		type: 'account-settings',
		props: {
			entryId: response.entryId,
		},
	};
}

function mapPreferenceGroups(response: PreferenceGroupResponse): EDRCommunicationPreferenceGroup {
	const preferences = response.preferences ? response.preferences.map((item) => mapPreference(item)) : [];

	return {
		title: response.title,
		description: response.description,
		isPartnerPreferences: response.isPartnerPreferences,
		preferences,
	};
}

function mapPreference(response: PreferencePropsResponse): EDRCommunicationPreference {
	return {
		id: response.communicationPreferenceId,
		title: response.title,
		description: response.description,
		iconName: response.icon?.name,
		reverseValue: response.displayReverseValue,
		value: false,
	};
}

function mapFocalPoint(image: ImagePropsResponse): ImageFocalPoint | undefined {
	let focalPoint: ImageFocalPoint | undefined;

	if (image.imageWithFocalPoint && image.imageWithFocalPoint.focalPoint && image.assetWidth && image.assetHeight) {
		focalPoint = {
			x: image.imageWithFocalPoint.focalPoint.x,
			y: image.imageWithFocalPoint.focalPoint.y,
			assetWidth: image.assetWidth,
			assetHeight: image.assetHeight,
		};
	}

	return focalPoint;
}
