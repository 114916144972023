import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { EDRColor, EDRHeadingLevel, EDRTypographyStyle, FlexDirectionEnum } from '../../types';
import { DeviceStyleConfig, IconProps, ImageProps } from '../../models';
import { BannerContentAlignment, BannerMediaAlignment, BannerType, CtaType } from '../../enums';
import { AsyncPipe } from '@angular/common';
import { HeadingComponent } from '../heading/heading.component';
import { IconComponent } from '../icon/icon.component';
import { TypographyComponent } from '../typography/typography.component';
import { ShortcodePipe } from '../../pipes';
import { ApplyStylesDirective } from '../../directives';
import { ContentBaseComponent, ImageDeviceTransformConfig, ImageTransformerService } from '@edr/shared';
import { ImageComponent } from '../image/image.component';

export class BannerDefaults {
	public static readonly BackgroundColor: EDRColor = 'family--pastels-light-honey';
	public static readonly TextColor: EDRColor = 'secondary--charcoal';
	public static readonly BannerType: BannerType = BannerType.Hero;
	public static readonly ContentAlignment: BannerContentAlignment = BannerContentAlignment.Left;
	public static readonly MediaAlignment: BannerMediaAlignment = BannerMediaAlignment.Middle;
	public static readonly CtaType = CtaType.Button;
	public static readonly MobileCtaDirection = FlexDirectionEnum.Column;
	public static readonly TabletCtaDirection = FlexDirectionEnum.Column;
	public static readonly DesktopCtaDirection = FlexDirectionEnum.Row;
}

export const heroBannerDefaultSpacing = {
	Top: { desktop: '6', tablet: '2', mobile: '0' } as DeviceStyleConfig,
	Bottom: { desktop: '6', tablet: '2', mobile: '0' } as DeviceStyleConfig,
};

@Component({
	selector: 'edr-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HeadingComponent, IconComponent, TypographyComponent, AsyncPipe, ShortcodePipe, ApplyStylesDirective, ImageComponent],
})
export class BannerComponent extends ContentBaseComponent implements OnChanges {
	/**
	 * The banner style which can be 'default', 'round-corners' or 'leaf'.  Defaults to 'default'.
	 */
	@Input() public bannerType = BannerDefaults.BannerType;

	/**
	 * The banner background color.  Defaults to 'family--mint'.
	 */
	@Input() public backgroundColor: EDRColor = BannerDefaults.BackgroundColor;

	/**
	 * The banner text color.  Defaults to 'white'.
	 */
	@Input() public color: EDRColor = BannerDefaults.TextColor;
	@Input() public preTitle = '';
	@Input() public title = '';
	@Input() public titleHeadingLevel?: EDRHeadingLevel | null;
	@Input() public description = '';

	/**
	 * The content alignment which can be 'left' or 'center'.  Defaults to 'left'.
	 */
	@Input() public contentAlignment = BannerDefaults.ContentAlignment;

	/**
	 * Image media
	 */
	@Input() public image?: ImageProps;

	/**
	 * Icon media (will be ignored if image is specified)
	 */
	@Input() public icon?: IconProps;

	/**
	 * The media vertical alignment which can be 'top', 'middle' or 'bottom'.  Defaults to 'middle'.
	 */
	@Input() public mediaAlignment = BannerDefaults.MediaAlignment;

	/**
	 * For banner variations where the image will always be rendered on top, regardless of the device size
	 */
	@Input() public mediaAlwaysAtTop = false;

	/**
	 * To render partner badge
	 */
	@Input() public partnerBadge?: { src: string; alt: string };

	/**
	 * Render the media & content info in reverse order when true
	 */
	@Input() public reversed = false;

	/**
	 * When the banner type is 'Card', then the button link background color will be white to match the content info
	 * background color, else use the use configured banner background color
	 */
	public linkBackgroundColor: EDRColor = BannerDefaults.BackgroundColor;

	/**
	 * For Hero banners, use 'sub-heading--1' Typography, for Leaf & Card banners, use 'body' Typography
	 */
	public descriptionTypography: EDRTypographyStyle = 'body';

	public imageDeviceTransformConfig: ImageDeviceTransformConfig = ImageTransformerService.GetSmallerImageTransformConfig();

	public get defaultTitleHeadingLevel(): EDRHeadingLevel {
		return this.bannerType === BannerType.Hero ? 1 : 2;
	}

	// public ngOnInit(): void {}

	public ngOnChanges(): void {
		this.linkBackgroundColor = this.bannerType === BannerType.Card ? 'primary--white' : this.backgroundColor;
		this.descriptionTypography = this.bannerType === BannerType.Hero ? 'sub-heading--1' : 'body';
	}
}
