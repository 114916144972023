@if (
	{
		saveForOccasionDate: (saveForOccasionDate$ | async) ?? undefined,
	};
	as data
) {
	@if (isLoading) {
		<edr-spinner>
			<edr-heading [level]="3">Loading</edr-heading>
		</edr-spinner>
	} @else {
		<edr-accordion-item-card
			id="rewards-choice"
			class="save-for-occasion__accordion-item"
			data-testid="save-for-occasion"
			[heading]="accordionItem.heading"
			[description]="description()"
			[icon]="icon"
			[selected]="isSelected"
		>
			<edr-section
				[fullWidthContent]="false"
				[disablePaddingTop]="true"
				[paddingBottom]="howItWorksTitleSpace"
				[rootPadding]="false"
				[isStandardContentSection]="true"
			>
				<h3>How it works</h3>
				<p></p>
			</edr-section>
			@for (saveForOccasionCard of this.howItWorksCards; track saveForOccasionCard) {
				<edr-section
					[fullWidthContent]="false"
					[disablePaddingTop]="true"
					[paddingBottom]="howItWorksCardSpace"
					[rootPadding]="false"
					[isStandardContentSection]="true"
				>
					<!-- Declare an edr-card component here and set all it's properties -->
					<edr-card
						[cardType]="saveForOccasionCard.cardType ?? 'icon'"
						[cardBorder]="saveForOccasionCard.cardBorder ?? 'none'"
						[icon]="saveForOccasionCard.icon"
						[title]="saveForOccasionCard.title ?? ''"
						[description]="saveForOccasionCard.description ?? '' | contentfulToHtml"
						[ctaType]="saveForOccasionCard.ctaType ?? 'link'"
						[primaryCta]="saveForOccasionCard.primaryCta"
						[secondaryCta]="saveForOccasionCard.secondaryCta"
					/>
				</edr-section>
			}

			<edr-section [disablePaddingTop]="true" [disablePaddingBottom]="true" [rootPadding]="false">
				<p class="save-for-occasion__accordion-item__date-title">Choose when your vouchers will be available</p>
				<edr-calendar
					[selectedDate]="data.saveForOccasionDate"
					[minDate]="minDate"
					[maxDate]="maxDate"
					(dateSelected)="onDateSelected($event)"
				></edr-calendar>

				<div class="save-for-occasion__accordion-item__cta">
					<edr-button
						[buttonStyle]="chooseCta.style ?? 'secondary'"
						[disable]="!hasDateChanged"
						[inline]="true"
						(click)="hasDateChanged === true && onSelectSaveForOccasion()"
						(keydown.enter)="hasDateChanged === true && onSelectSaveForOccasion()"
					>
						@if (isSelected) {
							<span>Change your date</span>
						} @else {
							<span>{{ chooseCta.text }}</span>
						}
					</edr-button>
				</div>
			</edr-section>
		</edr-accordion-item-card>
	}
}
