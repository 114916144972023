<ul
	aria-label="Primary navigation"
	role="menubar"
	[class.mobileLayout]="state?.isMobileLayout"
	[class.desktopLayout]="!state?.isMobileLayout"
	data-testid="primary-nav"
>
	@for (navItem of navItems; track navItem) {
	<li [class.active]="navItem.isActive" role="none" [attr.data-testid]="navItem.id">
		@if (navItem.id === 'account') { @if (state?.isMobileLayout && !navItem.isActive) {
		<button
			type="button"
			edr-typography
			typographyAs="body"
			[attr.ariaExpanded]="state?.isMyAccountExpanded"
			(click)="toggleMyAccountMenu(!state?.isMyAccountExpanded, 'mobile', $event)"
			class="nav__my-account-item"
		>
			{{ navItem.label }}
			<edr-icon name="system-chevron" [rotate]="state?.isMyAccountExpanded ? '180' : '0'" size="4" color="secondary--charcoal"></edr-icon>
		</button>
		}
		<!-- my account menu desktop -->
		@if (!state?.isMobileLayout) {
		<ng-container [class.active]="state?.isMyAccountExpanded">
			<button
				type="button"
				edr-typography
				typographyAs="body"
				[attr.ariaExpanded]="state?.isMyAccountExpanded"
				(click)="toggleMyAccountMenu(!state?.isMyAccountExpanded, 'desktop', $event)"
				class="nav__my-account-item"
			>
				{{ navItem.label }}
				<edr-icon name="system-chevron" [rotate]="state?.isMyAccountExpanded ? '180' : '0'" size="4" color="secondary--charcoal"></edr-icon>
			</button>
			@if (state?.isMyAccountExpanded) {
			<ul class="nav__my-account" (edrClickOutside)="closeAllNavs.emit()">
				@for (navItem of accountItems; track navItem) {
				<li class="nav__my-account-item" [class.active]="navItem.isActive" [attr.data-testid]="navItem.id">
					@if (!navItem.isActive) {
					<a [routerLink]="[navItem.url]" (click)="closeAllNavs.emit()" routerLinkActive="active" role="menuitem">
						{{ navItem.label }}
					</a>
					} @if (navItem.isActive) {
					<button edr-typography typographyAs="body" (click)="closeAllNavs.emit()" role="menuitem">
						{{ navItem.label }}
					</button>
					}
				</li>
				}
				<li class="nav__my-account-item nav__my-account-item--sign-out" data-testid="sign-out">
					<button type="button" (click)="logout.emit()" role="menuitem" class="sign-out">
						<edr-icon name="system-logout" size="4" color="secondary--charcoal"></edr-icon>
						Sign out
					</button>
				</li>
			</ul>
			}
		</ng-container>
		} } @else { @if (!navItem.isActive) {
		<a edr-typography typographyAs="body" [routerLink]="navItem.url" (click)="closeAllNavs.emit()" role="menuitem">
			{{ navItem.label }}
		</a>
		} @if (navItem.isActive) {
		<button edr-typography typographyAs="body" (click)="closeAllNavs.emit()" role="menuitem">{{ navItem.label }}</button>
		} }
	</li>
	} @if (!state?.isMobileLayout && !isAuthenticated) {
	<li>
		<button href="#sign-in-register" data-testid="sign-in-or-register" (click)="login.emit()" data-testid="sign-in-or-register-button">
			Sign in or register
		</button>
	</li>
	} @if (state?.isMobileLayout && isAuthenticated) {
	<li class="sign-out">
		<button href="#logout" (click)="logout.emit()" role="menuitem" edr-typography typographyAs="body" class="sign-out">
			<edr-icon name="system-logout" size="4" color="secondary--charcoal"></edr-icon>
			Sign out
		</button>
	</li>
	}
</ul>
