import { Component } from '@angular/core';
import { ModalService } from '../../../../core/services';
import { ButtonComponent, ModalComponent } from '@edr/styleguide';

@Component({
	selector: 'edr-app-boost-first-time-modal',
	templateUrl: './boost-first-time-modal.component.html',
	styleUrls: ['./boost-first-time-modal.component.scss'],
	standalone: true,
	imports: [ButtonComponent, ModalComponent],
})
export class BoostFirstTimeModalComponent {
	constructor(private modalService: ModalService) {}

	public closeModal(): void {
		this.modalService.close();
	}
}
