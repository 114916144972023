import { EDRTypographyStyle } from './../../types/typography-style.type';
/* eslint-disable @typescript-eslint/member-ordering */
import {
	ComponentType,
	FlexibleConnectedPositionStrategy,
	OriginConnectionPosition,
	Overlay,
	OverlayConnectionPosition,
	OverlayRef,
	ScrollDispatcher,
} from '@angular/cdk/overlay';
import { Directive, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BreakpointService, CustomWindow, DeviceType } from '@edr/shared';
import { WINDOW } from '@ng-web-apis/common';

import { fromEvent, merge, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { EDRColor, EDRIcon } from '../../types';
import { TooltipAlignment, TooltipPosition, TooltipTrigger } from '../../enums';

const VIEWPORT_MARGIN = 8;
const TOP_BOTTOM_POSITION: TooltipPosition[] = [TooltipPosition.Top, TooltipPosition.Bottom];
const LEFT_RIGHT_POSITION: TooltipPosition[] = [TooltipPosition.Left, TooltipPosition.Right];

@Directive({
	selector: '[edrTooltip]',
	standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy {
	@Input() public tooltipDisabled = false;
	@Input() public tooltipShowDelay = 0;
	@Input() public tooltipHideDelay = 0;

	public overlayRef: OverlayRef | null = null;

	private _tooltipMessage: string | TemplateRef<unknown> = '';
	private _tooltipTypographyStyle: EDRTypographyStyle = 'body--xs';
	private _tooltipTrigger: TooltipTrigger = TooltipTrigger.Focus;
	private _tooltipPosition?: TooltipPosition;
	private _tooltipAlignment: TooltipAlignment = TooltipAlignment.Start;
	private _tooltipIconName?: EDRIcon | undefined;
	private _tooltipIconColor?: EDRColor | undefined;
	private _tooltipCtaText?: string;
	private _tooltipCtaLink?: string;
	private _tooltipShowCloseButton = false;

	private isTooltipOpen = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private tooltipShowTimeout: any | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private tooltipHideTimeout: any | undefined;
	private portal: ComponentPortal<TooltipComponent> | null = null;
	private currentPosition?: TooltipPosition;
	private currentArrowOffset?: number;
	private tooltipInstance: TooltipComponent | null = null;
	private currentDeviceType: DeviceType | undefined;
	private readonly tooltipComponent: ComponentType<TooltipComponent> = TooltipComponent;

	private readonly subscriptions = new Subscription();
	private triggerEventSubscription = new Subscription();
	private readonly destroyed = new Subject<void>();

	constructor(
		private overlay: Overlay,
		public elementRef: ElementRef<HTMLElement>,
		private viewContainerRef: ViewContainerRef,
		private scrollDispatcher: ScrollDispatcher,
		private breakpointService: BreakpointService,
		private ngZone: NgZone,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.subscriptions.add(
			this.breakpointService.getActiveCustomBreakpoint().subscribe((deviceType) => {
				this.currentDeviceType = deviceType;
				this.subscribeToTriggerEvents();

				if (this.overlayRef) {
					this.overlayRef.updatePosition();
				}
			})
		);
	}

	public get tooltipMessage(): string | TemplateRef<unknown> {
		return this._tooltipMessage;
	}
	@Input('edrTooltip') public set tooltipMessage(value: string | TemplateRef<unknown>) {
		this._tooltipMessage = value;
		this.updateTooltipComponentProps();
	}

	public get tooltipTypographyStyle(): EDRTypographyStyle {
		return this._tooltipTypographyStyle;
	}
	@Input() public set tooltipTypographyStyle(value: EDRTypographyStyle) {
		this._tooltipTypographyStyle = value;
		this.updateTooltipComponentProps();
	}

	public get tooltipIconName(): EDRIcon | undefined {
		return this._tooltipIconName;
	}
	@Input() public set tooltipIconName(value: EDRIcon | undefined) {
		this._tooltipIconName = value;
		this.updateTooltipComponentProps();
	}

	public get tooltipIconColor(): EDRColor | undefined {
		return this._tooltipIconColor;
	}
	@Input() public set tooltipIconColor(value: EDRColor | undefined) {
		this._tooltipIconColor = value;
		this.updateTooltipComponentProps();
	}

	public get tooltipCtaText(): string | undefined {
		return this._tooltipCtaText;
	}
	@Input() public set tooltipCtaText(value: string | undefined) {
		this._tooltipCtaText = value;
		this.updateTooltipComponentProps();
	}

	public get tooltipCtaLink(): string | undefined {
		return this._tooltipCtaLink;
	}
	@Input() public set tooltipCtaLink(value: string | undefined) {
		this._tooltipCtaLink = value;
		this.updateTooltipComponentProps();
	}

	public get tooltipTrigger(): TooltipTrigger {
		return this._tooltipTrigger;
	}
	@Input() public set tooltipTrigger(trigger: TooltipTrigger) {
		this._tooltipTrigger = trigger;
		this.subscribeToTriggerEvents();
	}

	public get tooltipPosition(): TooltipPosition | undefined {
		return this._tooltipPosition;
	}
	@Input() public set tooltipPosition(value: TooltipPosition | undefined) {
		this._tooltipPosition = value;

		if (this.overlayRef) {
			this.updateOverlayPositionStrategy(this.overlayRef);
			this.overlayRef.updatePosition();
		}
	}

	public get tooltipAlignment(): TooltipAlignment {
		return this._tooltipAlignment;
	}
	@Input() public set tooltipAlignment(value: TooltipAlignment) {
		this._tooltipAlignment = value;

		if (this.overlayRef) {
			this.updateOverlayPositionStrategy(this.overlayRef);
			this.overlayRef.updatePosition();
		}
	}

	public get tooltipShowCloseButton(): boolean {
		return this._tooltipShowCloseButton;
	}
	@Input() public set tooltipShowCloseButton(value: boolean) {
		this._tooltipShowCloseButton = value;
		this.updateTooltipComponentProps();
	}

	public ngOnInit(): void {
		// Set tab index to 0, to allow element to receive focus by click or tab.
		// 0 will make browser automatically calculate appropriate tab index
		this.elementRef.nativeElement.tabIndex = 0;
	}
	public ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.triggerEventSubscription.unsubscribe();
		this.destroyed.next();
		this.detachTooltip();
		if (this.tooltipHideTimeout) {
			clearTimeout(this.tooltipHideTimeout);
		}
		if (this.tooltipShowTimeout) {
			clearTimeout(this.tooltipShowTimeout);
		}
	}

	public showTooltip(delayOverride?: number): void {
		if (this.tooltipShowTimeout) {
			clearTimeout(this.tooltipShowTimeout);
		}

		if (this.tooltipDisabled || !this.tooltipMessage || this.isTooltipOpen) {
			return;
		}

		this.tooltipShowTimeout = setTimeout(() => {
			const overlayRef = this.createOverlay();
			this.detachTooltip();
			this.portal = this.portal || new ComponentPortal(this.tooltipComponent, this.viewContainerRef);

			const instance = (this.tooltipInstance = overlayRef.attach(this.portal).instance);
			instance.dismissed.pipe(takeUntil(this.destroyed)).subscribe((event) => {
				const isFocusTrigger = this.tooltipTrigger === TooltipTrigger.Focus || this.currentDeviceType === DeviceType.Mobile;
				const targetEvent = isFocusTrigger ? 'blur' : 'mouseleave';
				if (!event || event === targetEvent) {
					this.detachTooltip();
				}
			});
			this.updateTooltipComponentProps();

			this.isTooltipOpen = true;
			this.tooltipShowTimeout = undefined;
		}, delayOverride ?? this.tooltipShowDelay);
	}

	public hideTooltip(delayOverride?: number): void {
		if (this.tooltipHideTimeout) {
			clearTimeout(this.tooltipHideTimeout);
		}

		if (!this.isTooltipOpen) {
			return;
		}

		this.tooltipHideTimeout = setTimeout(() => {
			this.detachTooltip();

			// Force a blur if the host element has focus to re-enable show trigger
			if (this.window.document.activeElement === this.elementRef.nativeElement) {
				this.elementRef.nativeElement.blur();
			}

			this.tooltipHideTimeout = undefined;
		}, delayOverride ?? this.tooltipHideDelay);
	}

	public updateTooltipComponentProps(): void {
		if (!this.tooltipInstance) {
			return;
		}

		this.tooltipInstance.message = this.tooltipMessage;
		this.tooltipInstance.typographyStyle = this.tooltipTypographyStyle;
		this.tooltipInstance.iconName = this.tooltipIconName;
		this.tooltipInstance.iconColor = this.tooltipIconColor;
		this.tooltipInstance.ctaText = this.tooltipCtaText;
		this.tooltipInstance.ctaLink = this.tooltipCtaLink;
		this.tooltipInstance.showCloseButton = this.tooltipShowCloseButton;
		this.tooltipInstance.markForCheck();

		// Update position after message changed
		this.ngZone.onMicrotaskEmpty.pipe(take(1), takeUntil(this.destroyed)).subscribe(() => {
			if (this.tooltipInstance && this.overlayRef) {
				this.overlayRef.updatePosition();
			}
		});
	}

	public detachTooltip(): void {
		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.overlayRef.detach();
		}

		this.tooltipInstance = null;
		this.currentPosition = undefined;
		this.currentArrowOffset = undefined;
		this.isTooltipOpen = false;
	}

	public isHostElementVisible(): boolean {
		const hostElementBox = this.elementRef.nativeElement.getBoundingClientRect();
		const hostElementTop = hostElementBox.top;
		const hostElementBottom = (this.window.visualViewport?.height ?? 0) - (hostElementTop + hostElementBox.height);

		return hostElementTop >= VIEWPORT_MARGIN && hostElementBottom >= VIEWPORT_MARGIN;
	}

	public subscribeToTriggerEvents(): void {
		this.triggerEventSubscription.unsubscribe();

		const isFocusTrigger = this.tooltipTrigger === TooltipTrigger.Focus || this.currentDeviceType === DeviceType.Mobile;
		const triggerStartEventName = isFocusTrigger ? 'focus' : 'mouseenter';
		const triggerEndEventName = isFocusTrigger ? 'blur' : 'mouseleave';
		const subscription = merge(
			fromEvent(this.elementRef.nativeElement, triggerStartEventName) as Observable<MouseEvent>,
			fromEvent(this.elementRef.nativeElement, triggerEndEventName) as Observable<MouseEvent>
		).subscribe((event) => {
			if (event.type === triggerStartEventName) {
				this.showTooltip();
			} else if (event.type === triggerEndEventName) {
				const relatedTarget = event.relatedTarget as HTMLElement;
				const tooltipElement = this.tooltipInstance?.elementRef?.nativeElement;
				if (!relatedTarget?.offsetParent || (relatedTarget !== tooltipElement && relatedTarget?.offsetParent !== tooltipElement)) {
					this.hideTooltip();
				}
			}
		});

		this.triggerEventSubscription = subscription;
	}

	private createOverlay(): OverlayRef {
		if (this.overlayRef) {
			const existingStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

			if (existingStrategy._origin instanceof ElementRef) {
				return this.overlayRef;
			}

			this.detachTooltip();
		}

		const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

		const strategy = this.overlay
			.position()
			.flexibleConnectedTo(this.elementRef)
			.withFlexibleDimensions(false)
			.withViewportMargin(VIEWPORT_MARGIN)
			.withScrollableContainers(scrollableAncestors);

		strategy.positionChanges.pipe(takeUntil(this.destroyed)).subscribe((change) => {
			this.updateTooltipPosition();

			// Check if host element is visible and close if not
			if (this.tooltipInstance && (change.scrollableViewProperties.isOverlayClipped || !this.isHostElementVisible())) {
				this.ngZone.run(() => {
					this.hideTooltip(0);
				});
			}
		});

		this.overlayRef = this.overlay.create({
			positionStrategy: strategy,
			panelClass: 'tooltip-panel',
			scrollStrategy: this.overlay.scrollStrategies.reposition({ scrollThrottle: 20 }),
		});
		this.updateOverlayPositionStrategy(this.overlayRef);

		return this.overlayRef;
	}

	private updateOverlayPositionStrategy(overlayRef: OverlayRef): void {
		const position = overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
		const originPosition = this.getOriginPosition();
		const overlayPosition = this.getOverlayPosition();

		const tooltipPosition = this.getTooltipPosition();
		const offsetX = tooltipPosition === TooltipPosition.Left ? -12 : tooltipPosition === TooltipPosition.Right ? 12 : 0;
		const offsetY = tooltipPosition === TooltipPosition.Top ? -12 : tooltipPosition === TooltipPosition.Bottom ? 12 : 0;
		position
			.withPositions([{ ...originPosition, ...overlayPosition }])
			.withDefaultOffsetX(offsetX)
			.withDefaultOffsetY(offsetY);
	}

	private getOriginPosition(): OriginConnectionPosition {
		const position = this.getTooltipPosition();
		let originPosition: OriginConnectionPosition;

		if (TOP_BOTTOM_POSITION.includes(position)) {
			originPosition = {
				originX: this.tooltipAlignment === TooltipAlignment.Start ? 'start' : this.tooltipAlignment === TooltipAlignment.Middle ? 'center' : 'end',
				originY: position === TooltipPosition.Top ? 'top' : 'bottom',
			};
		} else if (LEFT_RIGHT_POSITION.includes(position)) {
			originPosition = {
				originX: position === TooltipPosition.Left ? 'start' : 'end',
				originY: this.tooltipAlignment === TooltipAlignment.Start ? 'top' : this.tooltipAlignment === TooltipAlignment.Middle ? 'center' : 'bottom',
			};
		} else {
			throw new Error(`Origin position is not implemented for tooltip position: [${position}]!`);
		}

		return originPosition;
	}

	private getOverlayPosition(): OverlayConnectionPosition {
		const position = this.getTooltipPosition();
		let overlayPosition: OverlayConnectionPosition;

		if (TOP_BOTTOM_POSITION.includes(position)) {
			overlayPosition = {
				overlayX: this.tooltipAlignment === TooltipAlignment.Start ? 'start' : this.tooltipAlignment === TooltipAlignment.Middle ? 'center' : 'end',
				overlayY: position === TooltipPosition.Top ? 'bottom' : 'top',
			};
		} else if (LEFT_RIGHT_POSITION.includes(position)) {
			overlayPosition = {
				overlayX: position === TooltipPosition.Left ? 'end' : 'start',
				overlayY: this.tooltipAlignment === TooltipAlignment.Start ? 'top' : this.tooltipAlignment === TooltipAlignment.Middle ? 'center' : 'bottom',
			};
		} else {
			throw new Error(`Overlay position is not implemented for tooltip position: [${position}]!`);
		}

		return overlayPosition;
	}

	/** Updates the class on the overlay panel based on the current position of the tooltip. */
	private updateTooltipPosition(): void {
		let newPosition: TooltipPosition = this.tooltipPosition ?? this.currentPosition ?? TooltipPosition.Right;
		let newArrowOffset = 0;

		const overlayBoundingBox = this.overlayRef?.overlayElement.getBoundingClientRect();
		const originBoundingBox = this.elementRef.nativeElement.getBoundingClientRect();
		const tooltipArrowSize = this.tooltipInstance?.getArrowSize();

		// Ensure tooltip fits position
		const windowWidth = this.window.visualViewport?.width ?? 0;
		const windowHeight = this.window.visualViewport?.height ?? 0;
		const overlayWidth = overlayBoundingBox?.width ?? 0;
		const overlayHeight = overlayBoundingBox?.height ?? 0;

		if (newPosition === TooltipPosition.Right && windowWidth - originBoundingBox.right - VIEWPORT_MARGIN - overlayWidth < 0) {
			newPosition = TooltipPosition.Left;
		} else if (newPosition === TooltipPosition.Left && originBoundingBox.left - VIEWPORT_MARGIN - overlayWidth < 0) {
			newPosition = TooltipPosition.Right;
		} else if (newPosition === TooltipPosition.Top && originBoundingBox.top - VIEWPORT_MARGIN - overlayHeight < 0) {
			newPosition = TooltipPosition.Bottom;
		} else if (newPosition === TooltipPosition.Bottom && windowHeight - originBoundingBox.bottom - VIEWPORT_MARGIN - overlayHeight < 0) {
			newPosition = TooltipPosition.Top;
		}

		// Adjust arrow alignment offset
		if (LEFT_RIGHT_POSITION.includes(newPosition)) {
			const topDiff = originBoundingBox.top - (overlayBoundingBox?.top ?? 0);
			newArrowOffset = topDiff + originBoundingBox.height / 2 - (tooltipArrowSize?.height ?? 0) / 2;
		} else {
			const leftDiff = originBoundingBox.left - (overlayBoundingBox?.left ?? 0);
			newArrowOffset = leftDiff + originBoundingBox.width / 2 - (tooltipArrowSize?.width ?? 0) / 2;
		}

		if (this.tooltipInstance && (newPosition !== this.currentPosition || newArrowOffset !== this.currentArrowOffset)) {
			this.ngZone.run(() => {
				setTimeout(() => {
					if (this.tooltipInstance) {
						this.tooltipInstance.position = newPosition;
						this.tooltipInstance.arrowOffset = newArrowOffset;
						this.tooltipInstance.markForCheck();
					}
				});
			});

			this.currentPosition = newPosition;
			this.currentArrowOffset = newArrowOffset;
			if (this.overlayRef) {
				this.updateOverlayPositionStrategy(this.overlayRef);
			}
		}
	}

	private getTooltipPosition(): TooltipPosition {
		return this.currentPosition ?? this.tooltipPosition ?? TooltipPosition.Right;
	}
}
