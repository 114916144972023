import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { EditPersonalDetail, PersonalDetailsStore } from './personal-details.store';
import { ContentBaseComponent, EDR_CONSTANTS, EDRValidators, FieldNames, FormFields, notUndefined } from '@edr/shared';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalService } from '@edr-core';
import { MemberProfileResponse } from '@edr/bff-api-models';
import {
	ButtonComponent,
	ErrorMessageComponent,
	HeadingComponent,
	InputComponent,
	ModalComponent,
	NotificationComponent,
	SectionComponent,
	SpinnerComponent,
} from '@edr/styleguide';
import { MemberFacade, PersonalDetailFieldType } from '../../member';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { APP_ROUTES } from '../../../routes';

@Component({
	selector: 'edr-app-personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [PersonalDetailsStore],
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		RouterLink,
		DatePipe,
		NgTemplateOutlet,
		ReactiveFormsModule,
		SectionComponent,
		NotificationComponent,
		HeadingComponent,
		InputComponent,
		ErrorMessageComponent,
		ButtonComponent,
		SpinnerComponent,
		ModalComponent,
	],
})
export class PersonalDetailsComponent extends ContentBaseComponent {
	public editedDetails$ = this.store.editedDetails$;
	public notification$ = this.store.notification$;
	public dateFormat = EDR_CONSTANTS.dateFormat;
	public persistingData$ = this.store.persistingData$;
	public isLoading$ = this.store.isLoading$;
	public personalDetails$;
	public isLoadingProfile$ = this.memberFacade.isLoadingProfile$;
	public isEditable$: Observable<boolean>;
	public editPersonalDetailsForm: FormGroup;
	public contactRoute = APP_ROUTES.contact;

	private formFields: FormFields<MemberProfileResponse> = {
		email: [null, [Validators.required, Validators.email]],
		homePhoneNumber: [null, [EDRValidators.homePhoneNumber()]],
		mobilePhoneNumber: [null, [EDRValidators.mobilePhoneNumber(false)]],
	};

	private formErrorMessages = {
		mobilePhoneNumber: 'Please enter a valid mobile phone number',
		homePhoneNumber: 'Please enter a valid home phone number',
		email: 'Please enter a valid email address',
	};

	constructor(
		private readonly store: PersonalDetailsStore,
		private fb: FormBuilder,
		public modalService: ModalService,
		private changeDetector: ChangeDetectorRef,
		private memberFacade: MemberFacade
	) {
		super();
		this.personalDetails$ = this.memberFacade.memberProfile$.pipe(filter(notUndefined));
		this.editPersonalDetailsForm = this.fb.group(this.formFields);
		this.personalDetails$.subscribe((personalDetails) => {
			FieldNames(this.formFields).forEach((fieldName) => {
				this.editPersonalDetailsForm.patchValue({
					[fieldName]: personalDetails[fieldName],
				});

				if (fieldName === 'mobilePhoneNumber' && personalDetails[fieldName]) {
					this.editPersonalDetailsForm.get(fieldName)?.setValidators([EDRValidators.mobilePhoneNumber(true)]);
				}
			});
		});
		this.store.resetEditState();

		this.isEditable$ = this.store.currentEditedDetail$.pipe(map((detail) => !detail?.editMode));

		this.detectUpdateProfile();
	}

	public editClicked(fieldName: PersonalDetailFieldType): void {
		this.isEditable$.pipe(take(1)).subscribe((isEditable) => {
			if (isEditable) {
				this.store.setEditMode({ fieldName, editMode: true });
			}
		});
	}

	public cancelClicked(fieldName: PersonalDetailFieldType): void {
		this.store.setEditMode({ fieldName, editMode: false });
		this.dismissNotification();
		this.resetForm();
	}

	public async submitForm(fieldName: PersonalDetailFieldType): Promise<void> {
		if (!(await this.hasChanged())) {
			this.resetForm();
			this.store.setEditMode({ fieldName, editMode: false });
			return;
		}

		this.dismissNotification();
		this.changeDetector.detectChanges();
		this.editPersonalDetailsForm.markAllAsTouched();

		const field = this.editPersonalDetailsForm.get(fieldName);
		const successMessages: Partial<Record<PersonalDetailFieldType, string>> = {
			email: 'Email address updated',
			homePhoneNumber: 'Home phone number updated',
			mobilePhoneNumber: 'Mobile number updated',
		};

		const persistDetail = this.getUpdatingDetails(fieldName, field?.value, successMessages[fieldName]);
		this.store.persistDetail(persistDetail);
	}

	public getEditMode(fieldName: PersonalDetailFieldType): Observable<boolean> {
		return this.editedDetails$.pipe(map((details) => details.find((detail) => detail.fieldName === fieldName)?.editMode ?? false));
	}

	public dismissNotification(): void {
		this.store.setNotification(null);
	}

	public getValidationErrorMessage(fieldName: PersonalDetailFieldType): string | null {
		if (!this.editPersonalDetailsForm.valid) {
			const field = this.editPersonalDetailsForm.get(fieldName);

			return Object.keys(field?.errors ?? {}) ? this.formErrorMessages[fieldName as keyof typeof this.formErrorMessages] : null;
		}
		return null;
	}

	public isFieldValid(fieldName: PersonalDetailFieldType): boolean {
		const field = this.editPersonalDetailsForm.get(fieldName);
		return !field?.getError(fieldName);
	}

	public handleSaveEmail(ref: TemplateRef<Element>): () => void {
		return async () => {
			if (!this.editPersonalDetailsForm.get('email')?.valid) {
				return;
			}
			if (!(await this.hasChanged())) {
				this.resetForm();
				this.store.setEditMode({ fieldName: 'email', editMode: false });
				return;
			}
			this.modalService.open({ ref: ref, id: 'confirm-email-modal' });
		};
	}

	public async saveEmail(): Promise<void> {
		await this.submitForm('email');
		this.modalService.close();
	}

	/**
	 * Used to save phone numbers
	 */
	public handleSave(fieldName: PersonalDetailFieldType): () => void {
		return async () => {
			if (!this.editPersonalDetailsForm.get(fieldName)?.valid) {
				return;
			}

			await this.submitForm(fieldName);
		};
	}

	public handleClose(): void {
		this.modalService.close();
	}

	public readonly getPersonalDetails = async (): Promise<MemberProfileResponse> => await firstValueFrom(this.personalDetails$);

	private async resetForm(): Promise<void> {
		const personalDetails = await this.getPersonalDetails();
		const currentFieldValues = {
			email: personalDetails.email,
			homePhoneNumber: personalDetails.homePhoneNumber,
			mobilePhoneNumber: personalDetails.mobilePhoneNumber,
		};
		this.editPersonalDetailsForm.reset(currentFieldValues);
	}

	private async hasChanged(): Promise<boolean> {
		const personalDetails = await this.getPersonalDetails();
		let hasChange = false;
		FieldNames(this.formFields).forEach((fieldName) => {
			hasChange = hasChange || this.editPersonalDetailsForm.value[fieldName] !== personalDetails[fieldName];
		});
		return hasChange;
	}

	private getUpdatingDetails(
		fieldName: PersonalDetailFieldType,
		fieldValue: string | undefined,
		successMessage: string | undefined
	): EditPersonalDetail {
		return {
			fieldName,
			fieldValue,
			editMode: true,
			successMessage,
		};
	}

	// detecting to profile update state in global store
	private detectUpdateProfile(): void {
		this.memberFacade.profileState$
			.pipe(filter(({ isUpdating }) => notUndefined(isUpdating)))
			.subscribe(({ isUpdating, error, successMessage, errorStatusCode, errorCode }) => {
				if (!isUpdating) {
					this.memberFacade.resetUpdateProfile();
					this.store.resetEditState();
					if (!error) {
						this.store.setNotification({
							title: successMessage ?? 'Your details have been updated',
							notificationType: 'success',
							canDismiss: true,
						});
					} else {
						const waitBeforeRetry = 2;
						const tooManyRequests = 429;
						if (errorStatusCode === tooManyRequests && errorCode === waitBeforeRetry) {
							this.store.setNotification({
								title: "Your details weren't updated",
								description: 'Please try again after 15 minutes',
								notificationType: 'error',
							});
						} else {
							this.store.setNotification({
								title: "Your details weren't updated",
								description: 'There was an error connecting to the server. Please try again later',
								notificationType: 'error',
							});
						}
					}
				}
			});
	}
}
