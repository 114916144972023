import { DEFAULT_ROUTER_FEATURENAME, RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cleanPath } from '@edr/shared';

export const {
	selectCurrentRoute, // select the current route
	selectFragment, // select the current route fragment
	selectQueryParams, // select the current route query params
	selectQueryParam, // factory function to select a query param
	selectRouteParams, // select the current route params
	selectRouteParam, // factory function to select a route param
	selectRouteData, // select the current route data
	selectRouteDataParam, // factory function to select a route data param
	selectUrl, // select the current url
	selectTitle, // select the title if available
} = getRouterSelectors();

export const selectRouterState = createFeatureSelector<RouterReducerState>(DEFAULT_ROUTER_FEATURENAME);
export const selectBasePath = createSelector(selectRouterState, (state: RouterReducerState) => state?.state.root.firstChild?.routeConfig?.path);
export const selectCleanPath = createSelector(selectUrl, (path) => cleanPath(path));
