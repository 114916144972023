import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Can be used to dynamically add type checked color or spacing to an element.
 *
 */

@Directive({
	selector: '[edrClickOutside]',
	standalone: true,
})
export class ClickOutsideDirective {
	@Output() public edrClickOutside = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) { }

	@HostListener('document:click', ['$event.target'])
	public onClick(target: HTMLElement): void {
		const clickedInside = this.elementRef.nativeElement.contains(target);

		if (!clickedInside) {
			this.edrClickOutside.emit();
		}
	}
}
