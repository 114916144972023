import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicContentService } from '../../services';
import { DynamicComponent } from '../../models';

@Component({
	selector: 'edr-dc-dynamic-content',
	templateUrl: './dynamic-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class DynamicContentComponent implements OnChanges {
	@ViewChild('target', { static: true, read: ViewContainerRef }) public target!: ViewContainerRef;
	@Input() public content?: DynamicComponent[] | null = [];

	constructor(private dynamicContentService: DynamicContentService) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!('content' in changes)) {
			return;
		}

		if (!this.content) {
			return;
		}

		if (JSON.stringify(changes['content'].currentValue) === JSON.stringify(changes['content'].previousValue)) {
			return;
		}

		this.dynamicContentService.render(this.content, this.target);
	}
}
