import { Injectable } from '@angular/core';
import { NotificationType } from '@edr/styleguide';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { CustomerPreferencesService, RewardsCardResponse, RewardsCardService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectQueryParams } from '../../../../router.selector';
import { LoggingService, PreferenceIds } from '@edr/shared';

export interface CardModel {
	cardNumber: number;
	barcodeImage?: string;
}

export interface SignupConfirmationState {
	rewardsCard: CardModel | undefined;
	notification?: SignupConfirmationNotification;
	isMobileLayout: boolean;
	loadingComplete: boolean;
}

export interface SignupConfirmationNotification {
	notificationType: NotificationType;
	title: string;
	description?: string;
	canDismiss?: boolean;
	showIcon?: boolean;
}

const defaultResponse: CardModel = {
	cardNumber: 0,
	barcodeImage: '',
};
export const defaultState: SignupConfirmationState = {
	rewardsCard: defaultResponse,
	isMobileLayout: true,
	loadingComplete: false,
};

@Injectable()
export class SignupConfirmationStore extends ComponentStore<SignupConfirmationState> {
	/**
	 * Selectors
	 */
	public readonly rewardsCard$ = this.select((state) => state.rewardsCard);
	public readonly notification$ = this.select((state) => state.notification);
	public readonly queryParams$ = this.store.select(selectQueryParams);

	/**
	 * Updaters
	 */
	public readonly updateIsMobileLayout = this.updater(
		(state, isMobileLayout: boolean): SignupConfirmationState => ({
			...state,
			isMobileLayout,
		})
	);

	public readonly updateLoadingComplete = this.updater(
		(state, updatedLoading: boolean): SignupConfirmationState => ({
			...state,
			loadingComplete: updatedLoading,
		})
	);

	public readonly updateRewardsCardDetails = this.updater(
		(state, details: RewardsCardResponse): SignupConfirmationState => ({
			...state,
			rewardsCard: details.BarcodeCardResponse
				? {
						cardNumber: details.BarcodeCardResponse.cardNumber,
						barcodeImage: details.BarcodeCardResponse.barcodeImage,
				  }
				: details.CardResponse && details.CardResponse.cardNumber
				? { cardNumber: details.CardResponse?.cardNumber }
				: undefined,
		})
	);

	public readonly updateNotification = this.updater(
		(state, notification: SignupConfirmationNotification | null): SignupConfirmationState => ({
			...state,
			notification: !notification
				? undefined
				: {
						...notification,
				  },
		})
	);

	/**
	 * Effects
	 */
	public readonly handleBreakpointChange = this.effect((isMobileBreakpoint$: Observable<boolean>) =>
		isMobileBreakpoint$.pipe(
			tapResponse(
				(isMobileLayout: boolean) => this.updateIsMobileLayout(isMobileLayout),
				() => {
					/* fail silently */
				}
			)
		)
	);

	public readonly fetchRewardsCard = this.effect(() =>
		this.rewardsCardService.fetchRewardsCard().pipe(
			tapResponse(
				(details: RewardsCardResponse) => {
					this.updateLoadingComplete(true);
					this.updateRewardsCardDetails(details);
				},
				(err: HttpErrorResponse) => {
					this.updateLoadingComplete(true);
					if (err.status === 404) {
						this.updateNotification({
							title: 'Unassigned Card Number',
							description: 'A card number has not yet been linked to your account. Please contact our support team for assistance.',
							notificationType: 'error',
						});
						return;
					}

					this.updateNotification({
						title: 'Oops... Looks like something went wrong',
						description: 'Please refresh the page to try again or contact our support team for assistance.',
						notificationType: 'error',
					});
				}
			)
		)
	);

	public readonly updateFirstLogin = this.effect(() =>
		this.customerPreferencesService.updateCustomerPreferences([{ id: PreferenceIds.EDRCustomerAppStateAPI, value: true }]).pipe(
			tapResponse(
				() => of(true),
				(error: HttpErrorResponse) => {
					this.loggingService.log(error);
				}
			)
		)
	);

	constructor(
		private readonly rewardsCardService: RewardsCardService,
		private customerPreferencesService: CustomerPreferencesService,
		private readonly loggingService: LoggingService,
		private store: Store
	) {
		super(defaultState);
	}
}
