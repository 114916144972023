import { Action, createReducer, on } from '@ngrx/store';
import {
	authInitialise,
	authInitialiseComplete,
	authInitialiseFailed,
	fetchAuthStateComplete,
	fetchAuthStateFailed,
	userLoggedOut,
} from './auth.actions';

export interface AuthState {
	initialising: boolean;
	hasLoaded: boolean;
	isLoggedIn: boolean;
	hasError: boolean;
	scvId?: string;
}

export const authInitialState: AuthState = {
	initialising: false,
	hasLoaded: false,
	isLoggedIn: false,
	hasError: false,
};

const _authReducer = createReducer(
	authInitialState,
	on(userLoggedOut, (state: AuthState): AuthState => ({ ...state, initialising: false, isLoggedIn: false, hasLoaded: true })),
	on(authInitialise, (state: AuthState): AuthState => ({ ...state, initialising: true, isLoggedIn: false })),
	on(
		authInitialiseComplete,
		(state: AuthState): AuthState => ({
			...state,
			initialising: false,
		})
	),
	on(authInitialiseFailed, (state: AuthState): AuthState => ({ ...state, initialising: false, hasLoaded: true, hasError: true })),
	on(
		fetchAuthStateComplete,
		(state: AuthState, { isAuthenticated, scvId }): AuthState => ({
			...state,
			isLoggedIn: isAuthenticated !== undefined ? isAuthenticated : true,
			hasLoaded: true,
			hasError: false,
			scvId,
		})
	),
	on(fetchAuthStateFailed, (state: AuthState): AuthState => ({ ...state, initialising: false, hasLoaded: true, hasError: true }))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
	return _authReducer(state, action);
}
