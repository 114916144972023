@if (
	{
		isLoading: (isLoading$ | async) ?? false,
		redemptionPreference: (redemptionPreference$ | async) ?? 'SpendNow',
	};
	as data
) {
	@if (data.isLoading) {
		<edr-spinner>
			<edr-heading [level]="3">Loading</edr-heading>
		</edr-spinner>
	} @else {
		<edr-section
			class="rewards-choice__header-section"
			[paddingTop]="howItWorksTitleSpace"
			[fullWidthContent]="false"
			[disablePaddingTop]="true"
			[rootPadding]="false"
			[isStandardContentSection]="true"
			[paddingBottom]="howItWorksTitleSpace"
		>
			@if ((saveForOccasionFeatureFlag$ | async) === true && rewardsChoiceIconOptions) {
				<div
					class="rewards-choice__header-section__icon-circle-group"
					[ngClass]="{
						'rewards-choice__header-section__icon-circle-group--pair': rewardsChoiceIconOptions.length === 2,
					}"
				>
					<edr-icon-circle-group
						data-testid="rewards-choice-icon-circle-group"
						[iconOptions]="rewardsChoiceIconOptions"
						[selectedIconName]="selectedIconName()"
					/>
				</div>
			}
			<edr-heading class="rewards-choice__header-section__heading" [level]="4">{{ currentRewardsChoiceTitle }}</edr-heading>
		</edr-section>

		<edr-accordion id="rewards-choice" [multiExpand]="false">
			@if (data.redemptionPreference === 'SpendNow') {
				<ng-container *ngTemplateOutlet="spendNowTemplate"></ng-container>
			} @else if (data.redemptionPreference === 'SaveForOccasion') {
				<ng-container *ngTemplateOutlet="saveForOccasionTemplate"></ng-container>
			}

			@if ((saveForOccasionFeatureFlag$ | async) === true) {
				<edr-section
					[paddingTop]="howItWorksTitleSpace"
					[fullWidthContent]="false"
					[disablePaddingTop]="true"
					[rootPadding]="false"
					[isStandardContentSection]="true"
					[paddingBottom]="howItWorksTitleSpace"
				>
					<edr-heading [level]="4">{{ otherRewardsChoicesTitle }}</edr-heading>
				</edr-section>

				@if (data.redemptionPreference !== 'SaveForOccasion') {
					<ng-container *ngTemplateOutlet="saveForOccasionTemplate"></ng-container>
				}
				@if (data.redemptionPreference !== 'SpendNow') {
					<ng-container *ngTemplateOutlet="spendNowTemplate"></ng-container>
				}
			}
		</edr-accordion>

		<ng-template #saveForOccasionTemplate>
			<edr-app-save-for-occasion
				[maxMonthsInFuture]="saveForOccasion.maxMonthsInFuture"
				[howItWorksCards]="saveForOccasion.howItWorksCards"
				[chooseCta]="saveForOccasion.chooseCta"
				[accordionItem]="saveForOccasion.accordionItem"
				[isLoading]="data.isLoading"
				[isSelected]="data.redemptionPreference === 'SaveForOccasion'"
				[selectedHeaderDescription]="saveForOccasion.selectedHeaderDescription"
			/>
		</ng-template>

		<ng-template #spendNowTemplate>
			<edr-app-spend-now
				[accordionItem]="spendNow.accordionItem"
				[howItWorksCards]="spendNow.howItWorksCards"
				[chooseCta]="spendNow.chooseCta"
				[isLoading]="data.isLoading"
				[isSelected]="data.redemptionPreference === 'SpendNow'"
				[selectedHeaderDescription]="spendNow.selectedHeaderDescription"
			/>
		</ng-template>
	}
}
