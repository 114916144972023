import { createAction, props } from '@ngrx/store';
import { MemberProfileResponse } from '@edr/bff-api-models';

export type PersonalDetailFieldType = keyof MemberProfileResponse;

export const loadProfile = createAction('[Member] Load Profile');
export const loadProfileSuccess = createAction('[Member] Load Profile Success', props<{ profile: MemberProfileResponse }>());
export const loadProfileFailure = createAction('[Member] Load Profile Failure');

export const updateProfile = createAction(
	'[Member] Update Profile',
	props<{ fieldName: PersonalDetailFieldType; value: string | undefined; successMessage?: string }>()
);
export const updateProfileSuccess = createAction(
	'[Member] Update Profile Success',
	props<{ profile: MemberProfileResponse; successMessage?: string }>()
);
export const updateProfileFailure = createAction('[Member] Update Profile Failure', props<{ errorStatusCode?: number, errorCode?: number }>());
export const resetUpdateProfile = createAction('[Member] Reset Update Profile');
