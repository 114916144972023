<div class="question">
	<button type="button" edr-button buttonStyle="link" (click)="togglePointsDetailsTable()">
		<edr-icon [rotate]="showPointsDetails ? '180' : '0'" name="system-chevron" size="4" color="secondary--charcoal"></edr-icon>
		<span class="question__text">Transaction details</span>
	</button>
</div>
<ng-container *ngIf="showPointsDetails">
	<ng-container *ngIf="(isLoading$ | async) === false; else loadingTemplate">
		<!-- Desktop View -->
		<ng-container *ngIf="(isMobileDevice$ | async) === false">
			<table cdk-table [dataSource]="transactionsDataSource$" multiTemplateDataRow>
				<ng-container *ngFor="let column of columnList" [cdkColumnDef]="column.name">
					<th cdk-header-cell *cdkHeaderCellDef>{{ column.displayName }}</th>
					<td cdk-cell *cdkCellDef="let element" [ngSwitch]="column.name">
						<div class="cell">
							<span *ngSwitchCase="'amount'" class="cell__amount">{{ element.amount | amountDisplay : element.uom }}</span>
							<span *ngSwitchCase="'totalPoints'" class="cell__points">
								{{ element.basePoints + element.bonusPoints | pointsDisplay : 'points' }}
							</span>
							<span *ngSwitchCase="'bonusPoints'" class="cell__bonus-points">
								{{ element.bonusPoints | pointsDisplay : 'bonusPoints' }}
							</span>
							<span *ngSwitchCase="'description'" class="cell__description" [innerHTML]="element.description"></span>
						</div>
					</td>
				</ng-container>
				<tr cdk-row *cdkRowDef="let row; columns: columnNameList"></tr>
			</table>
		</ng-container>

		<!-- Mobile View -->
		<ng-container *ngIf="isMobileDevice$ | async">
			<div class="details-grid" *ngFor="let transaction of transactionsDataSource$ | async">
				<div class="description" [innerHTML]="transaction.description"></div>
				<div class="amount">
					<p class="amount__value">{{ transaction.amount | amountDisplay : transaction.uom }}</p>
				</div>
				<div class="bonus-points">
					<p class="bonus-points__value">{{ transaction.bonusPoints | pointsDisplay : 'bonusPoints' }}</p>
				</div>
				<div class="points">
					<p [ngClass]="transaction.basePoints + transaction.bonusPoints < 0 ? 'points__value--negative' : 'points__value'">
						{{ transaction.basePoints + transaction.bonusPoints | pointsDisplay : 'points' }}
					</p>
				</div>
			</div>
		</ng-container>

		<!-- Stripes (ONLY CPL Redeemed Vouchers will be displayed here) -->
		<ng-container *ngIf="transactionRedeemed$ | async as transactionRedeemed">
			<edr-stripe
				*ngFor="let redeemed of transactionRedeemed"
				[text]="redeemed.description!"
				textColor="secondary--charcoal"
				backgroundColor="family--mint"
				[iconLeft]="(isMobileDevice$ | async) === false ? 'rewards-fuel' : undefined"
			>
			</edr-stripe>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #loadingTemplate>
	<edr-spinner>
		<div class="placeholder"></div>
	</edr-spinner>
</ng-template>
