import { Routes } from '@angular/router';
import { authCallbackResolver } from './core/auth/resolvers/auth-callback/auth-callback.resolver';
import { HealthzComponent, PageLoaderComponent } from './core/components';
import { partnerLinkGuard } from './core/guards/partner-link/partner-link.guard';
import { loginGuard } from './core/guards/login/login.guard';
import { isAuthenticatedUserGuard, isPreviewAuthenticationRequired } from './core/guards';
import { memberLinkResolver } from './features/partners';
import { queryParamResolver, signInResolver } from './core/resolvers';
import { errorRoutes } from './features/error/error.routes';
import { APP_ROUTES } from './routes';
import { contentfulConstants } from '@edr/shared';
import { SignupConfirmationPageComponent } from './features';

// Used for Kubernetes health checks
const kubernetesRoutes = [
	{
		path: 'healthz',
		component: HealthzComponent,
	},
	{
		path: 'healthz/ready',
		component: HealthzComponent,
	},
	{
		path: 'livez',
		component: HealthzComponent,
	},
	{
		path: 'readyz',
		component: HealthzComponent,
	},
];

export const appRoutes: Routes = [
	...kubernetesRoutes,
	{
		// Used for Authentication callback to ensure Auth Cookies was correctly set before navigating to home page
		path: 'authc',
		component: PageLoaderComponent,
		resolve: { data: authCallbackResolver },
	},
	{
		path: APP_ROUTES.partnerLink,
		loadComponent: () => import('./core/components/page-loader/page-loader.component').then((res) => res.PageLoaderComponent),
		pathMatch: 'full',
		canActivate: [partnerLinkGuard()],
	},
	{
		path: APP_ROUTES.memberLink,
		loadComponent: () => import('./core/components/page-loader/page-loader.component').then((res) => res.PageLoaderComponent),
		pathMatch: 'full',
		canActivate: [isAuthenticatedUserGuard({ startLoginFlow: true })],
		resolve: { data: memberLinkResolver },
	},
	{
		path: APP_ROUTES.signIn,
		loadComponent: () => import('./core/components/page-loader/page-loader.component').then((res) => res.PageLoaderComponent),
		pathMatch: 'full',
		resolve: { data: signInResolver },
	},
	{
		path: APP_ROUTES.preview,
		loadComponent: () => import('./core/components/dynamic-page/dynamic-page.component').then((res) => res.DynamicPageComponent),
		pathMatch: 'prefix',
		resolve: {
			pageId: queryParamResolver(contentfulConstants.queryParams.pageId),
			verificationKey: queryParamResolver(contentfulConstants.queryParams.verificationKey),
			env: queryParamResolver(contentfulConstants.queryParams.env),
		},
		canActivate: [isPreviewAuthenticationRequired],
		data: { isPreview: true },
	},
	{
		//TODO: CLSE-2626 - move this hard coded route and the signup confirmation page to Contentful
		path: `${APP_ROUTES.account.base}/${APP_ROUTES.account.signupConfirmation}`,
		component: SignupConfirmationPageComponent,
		canActivate: [isAuthenticatedUserGuard({ startLoginFlow: true })],
	},
	{
		path: APP_ROUTES.login,
		loadComponent: () => import('./core/components/page-loader/page-loader.component').then((res) => res.PageLoaderComponent),
		pathMatch: 'full',
		canActivate: [loginGuard({ loggedInRoute: APP_ROUTES.home })],
	},
	...errorRoutes,
];
