import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as PageContentActions from './page-content.actions';
import { switchMap, catchError, of, map, withLatestFrom, tap } from 'rxjs';
import { ContentService, MetaDataService, mapSEOMetaData } from '../../../services';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../../../routes';

@Injectable()
export class PageContentEffects {
	public loadPageContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PageContentActions.loadPageContent),
			switchMap((action) =>
				action.isPreview
					? this.contentService.fetchPagePreview(action.pageId, action.verificationKey, action.env).pipe(withLatestFrom([action]))
					: this.contentService.fetchPage(action.pageId).pipe(withLatestFrom([action]))
			),
			tap(([{ seoMetadata }]) => this.metaDataService.setPageMetaData(mapSEOMetaData(seoMetadata))),
			map(([content, action]) => PageContentActions.loadPageContentSuccess({ content, pageId: action.pageId })),
			catchError((error) => {
				this.router.navigate(['/', APP_ROUTES.error, '500']);
				return of(PageContentActions.loadPageContentFailure({ error }));
			})
		)
	);

	constructor(
		private contentService: ContentService,
		private actions$: Actions,
		private router: Router,
		private metaDataService: MetaDataService
	) {}
}
