import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PartnersState } from './partners.reducer';
import { Observable, filter, map, of, take } from 'rxjs';
import * as PartnersSelectors from './partners.selectors';
import * as PartnersActions from './partners.actions';
import { PartnerPropResponse } from '@edr/bff-api-models';
import { PointsTransactionTable } from '../../../core/components/points-table/points-table.model';
import { PARTNER_CONSTANTS } from '@edr/shared';

@Injectable({ providedIn: 'root' })
export class PartnersFacade {
	public readonly partnersState$: Observable<PartnersState> = this.store.select(PartnersSelectors.selectPartnersState);
	public readonly isLoading$: Observable<boolean> = this.store.select(PartnersSelectors.selectIsLoadingPartners);
	public readonly partners$: Observable<PartnerPropResponse[] | undefined> = this.store.select(PartnersSelectors.selectPartners);
	public readonly preferencePartners$: Observable<PartnerPropResponse[] | undefined> = this.store.select(PartnersSelectors.selectPreferencePartners);

	constructor(private readonly store: Store) {}

	public loadPartners(): void {
		this.partnersState$
			.pipe(
				take(1),
				filter((partnersState) => !partnersState.isLoading && !partnersState.value)
			)
			.subscribe(() => {
				this.store.dispatch(PartnersActions.loadPartners());
			});
	}

	public getPartnerLogo(transaction: PointsTransactionTable | undefined): Observable<{ src: string; alt: string }> {
		const defaultRewardsLogo = '/assets/images/partners/edr-rewards-logo.svg';
		const defaultLogo = { src: defaultRewardsLogo, alt: 'Partner Logo' };
		if (!transaction || !transaction.partnerId) {
			return of(defaultLogo);
		}

		return this.partners$.pipe(
			map((partners) => {
				const logo = { ...defaultLogo };
				const woolworthsPartnersAU = PARTNER_CONSTANTS.woolworthsPartnersAU.includes(transaction.partnerId ?? '');

				// If the partner is a WoolworthsAU partner, use the Woolworths logo
				const partnerId = woolworthsPartnersAU ? PARTNER_CONSTANTS.woolworthsNZ : transaction.partnerId;

				// If the partner is found in the partners list, use the logo from the partners list
				const localPartner = partners?.find((partner) => partner.id === partnerId);
				if (localPartner) {
					logo.src = localPartner.logo?.url ?? defaultRewardsLogo;
					logo.alt = localPartner.logo?.altText ?? 'Partner Logo';
					return logo;
				}

				// If the partner is not found in the partners list, use the partnerImageUrl from the transaction
				logo.src = transaction.partnerImageUrl ?? defaultRewardsLogo;
				return logo;
			})
		);
	}

	public getPartnerById(partnerId: string): Observable<PartnerPropResponse | undefined> {
		return this.store.select(PartnersSelectors.selectPartnerById(partnerId));
	}
}
