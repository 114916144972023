import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as NavItemsActions from './nav-items.actions';
import { switchMap, catchError, of, map } from 'rxjs';
import { ContentService } from '../../../services';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../../../routes';

@Injectable()
export class NavItemsEffects {
	public loadNavItems$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NavItemsActions.loadNavItems),
			switchMap(() => this.contentService.fetchNavItems()),
			map((content) => NavItemsActions.loadNavItemsSuccess({ content })),
			catchError((error) => {
				this.router.navigate(['/', APP_ROUTES.error, '500']);
				return of(NavItemsActions.loadNavItemsFailure({ error }));
			})
		)
	);

	constructor(private contentService: ContentService, private actions$: Actions, private router: Router) {}
}
