import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';

export const SHORTCODE_SERVICE_TOKEN = new InjectionToken<ShortcodeService>('ShortcodeService');

export interface ShortcodeService {
	firstName$: Observable<string | undefined>;
	totalPointsBalance$: Observable<number | undefined>;
}

export class MockShortcodeService implements ShortcodeService {
	public firstName$: Observable<string | undefined> = of('FIRST NAME');
	public totalPointsBalance$: Observable<number | undefined> = of(6000);
}
