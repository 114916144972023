import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, debounceTime, fromEvent, map, startWith } from 'rxjs';
import { CustomWindow } from '../../typescript';

const DEBOUNCE = 100;

@Injectable({
	providedIn: 'root',
})
export class WindowSizeService {
	// eslint-disable-next-line @typescript-eslint/ban-types
	constructor(@Inject(WINDOW) private window: CustomWindow) {}

	public getWindowSize(): Observable<{ width: number; height: number }> {
		return fromEvent(this.window, 'resize').pipe(
			debounceTime(DEBOUNCE),
			map(() => ({
				width: this.window.innerWidth,
				height: this.window.innerHeight,
			})),
			startWith({
				width: this.window.innerWidth,
				height: this.window.innerHeight,
			})
		);
	}

	public getCurrentWindowSize(): { width: number; height: number } {
		return {
			width: this.window.innerWidth,
			height: this.window.innerHeight,
		};
	}
}
