import { SHORT_CODE_DEFINITIONS } from '../short-codes';

const SPACING_ATTRIBUTES = {
	DIRECTION: 'direction',
	VALUE: 'value',
	HORIZONTAL: 'h',
	VERTICAL: 'v',
};

/**
 * Replaces the `[spacing]` shortcode with `span` elements
 * @param content Content string containing shortcode to be replaced
 * @param regexMatch RegEx matches for the shortcode
 * @returns Hydrated content
 */
export const replaceSpacingShortCode = (content: string, regexMatch: RegExpMatchArray): string => {
	if (!regexMatch.groups || !regexMatch.groups[SHORT_CODE_DEFINITIONS.CODE] || !regexMatch.groups[SHORT_CODE_DEFINITIONS.VALUE]) {
		return content;
	}

	const code = regexMatch.groups[SHORT_CODE_DEFINITIONS.CODE].toLowerCase();
	const rawValue = regexMatch.groups[SHORT_CODE_DEFINITIONS.VALUE].toLowerCase();

	// rawValue will either just be number (then apply to horizontal), or `h-SPACING v-SPACING` (then apply to which one is supplied)
	const attributesRegex = new RegExp(
		`(?<${SPACING_ATTRIBUTES.DIRECTION}>(${SPACING_ATTRIBUTES.VERTICAL}|${SPACING_ATTRIBUTES.HORIZONTAL}))-(?<${SPACING_ATTRIBUTES.VALUE}>[0-9]{1,})`,
		'g'
	);
	const attributeMatches = Array.from(rawValue.matchAll(attributesRegex));
	const horizontalMatch = attributeMatches.find((x) => x.groups && x.groups[SPACING_ATTRIBUTES.DIRECTION] === SPACING_ATTRIBUTES.HORIZONTAL);
	const verticalMatch = attributeMatches.find((x) => x.groups && x.groups[SPACING_ATTRIBUTES.DIRECTION] === SPACING_ATTRIBUTES.VERTICAL);

	let horizontalSpacing: string | null = null;
	let verticalSpacing: string | null = null;
	if (horizontalMatch?.groups && horizontalMatch.groups[SPACING_ATTRIBUTES.VALUE]) {
		horizontalSpacing = horizontalMatch.groups[SPACING_ATTRIBUTES.VALUE];
	}
	if (verticalMatch?.groups && verticalMatch.groups[SPACING_ATTRIBUTES.VALUE]) {
		verticalSpacing = verticalMatch.groups[SPACING_ATTRIBUTES.VALUE];
	}
	if (attributeMatches.length === 0) {
		horizontalSpacing = rawValue;
	}

	let updatedContent = content;
	const classList: string[] = [];
	if (horizontalSpacing) {
		classList.push(`sc__${code}__${SPACING_ATTRIBUTES.HORIZONTAL}__${horizontalSpacing}`);
	}
	if (verticalSpacing) {
		classList.push(`sc__${code}__${SPACING_ATTRIBUTES.VERTICAL}__${verticalSpacing}`);
	}

	const replacementValue = `<span class="sc__${code} ${classList.join(' ')}"></span>`;
	while (updatedContent.indexOf(regexMatch[0]) >= 0) {
		updatedContent = updatedContent.replace(regexMatch[0], replacementValue);
	}

	return updatedContent;
};
