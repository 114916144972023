import { ButtonComponent } from '@edr/styleguide';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { BreakpointService, CustomWindow, DeviceType } from '@edr/shared';
import { AppSettingsService } from '../../services';
import { WINDOW } from '@ng-web-apis/common';

@UntilDestroy()
@Component({
	selector: 'edr-app-mandy-roundel',
	templateUrl: './mandy-roundel.component.html',
	styleUrls: ['./mandy-roundel.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent, AsyncPipe],
})
export class MandyRoundelComponent implements AfterViewInit, OnDestroy {
	public isVisible$ = new BehaviorSubject<boolean>(true);
	public chatWidgetIsActive = false;
	private observer: IntersectionObserver | null = null;

	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		private breakpointService: BreakpointService,
		private appSettingsService: AppSettingsService,
		private renderer2: Renderer2,
		private cdr: ChangeDetectorRef
	) {}

	public ngAfterViewInit(): void {
		this.loadChatWidget();
		this.window.document.addEventListener('chatWidget:close', () => this.chatWidgetClosed());
		this.setupFooterObserver();
	}

	public ngOnDestroy(): void {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	public loadChatWidget(): void {
		this.appSettingsService
			.getSettings()
			.pipe(untilDestroyed(this))
			.subscribe((appSettings) => {
				const script = this.renderer2.createElement('script');
				script.type = `text/javascript`;
				script.text = `
		window.chatWidget = {
			vaHeaderScript: function () {
				try {
					var widgetSrc = "${appSettings.mandyUrl}";
					// Ensure this gets added just once
					if(!document.querySelector('script[src=' + '"' + widgetSrc + '"]')) {
						var script = document.createElement('script');
						script.type = 'text/javascript';
						script.src = widgetSrc;
						script.async = false;
						script.defer = true;
						script.onload = function() {
							window.chatWidget.bootstrapWithClean();
						};
						document.head.appendChild(script);
					}
				} catch (err) {
					console.warn('chatWidgetBootstrap header code. Error: ', err);
				}
			}
		};
		
		if (document.readyState === 'complete') {
			window.chatWidget.vaHeaderScript();
		} else {
			window.addEventListener ? 
				window.addEventListener('load', window.chatWidget.vaHeaderScript, false) : 
				window.attachEvent('onload', window.chatWidget.vaHeaderScript);
		}
		`;
				this.renderer2.appendChild(this.window.document.head, script);
			});
	}

	public showChatWidget(count: number): void {
		if (count < 5 && typeof this.window.chatWidget?.onStartChat !== 'function') {
			setTimeout(() => {
				this.showChatWidget(count++);
			}, 1000);

			return;
		}

		const setFullScreen = this.breakpointService.getCurrentCustomBreakpoint() === DeviceType.Mobile;
		this.chatWidgetIsActive = true;

		this.window.chatWidget?.onStartChat({ setFullScreen: setFullScreen });

		this.cdr.markForCheck();
	}

	public chatWidgetClosed(): void {
		this.chatWidgetIsActive = false;
		this.cdr.markForCheck();
	}

	public setupFooterObserver(): void {
		const footer = this.window.document.querySelector('edr-app-footer') as HTMLElement;
		const chatButton = this.window.document.querySelector('edr-app-mandy-roundel') as HTMLElement;

		if (footer === null || chatButton === null) {
			return;
		}

		this.observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						chatButton.style.position = 'absolute';
						chatButton.style.float = 'right';
						const lastSectionElement = this.window.document.querySelector('edr-section:last-of-type > section') as HTMLElement;
						if (lastSectionElement) {
							lastSectionElement.style.paddingBottom = 'calc(var(--spacing-20) + var(--spacing-2))';
						}
					} else {
						chatButton.style.position = 'fixed';
						chatButton.style.right = '0';
					}
				});
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			}
		);

		this.observer.observe(footer);
	}
}
