<edr-modal class="gifting-form-modal" title="Gift points to another member" (closeModal)="handleClose()">
	<div modal-content>
		<p class="gifting-form-modal__subtitle">
			You can choose to give your points to another Everyday Rewards member. All you need is their membership number and we’ll do the rest.
		</p>
		<form [formGroup]="giftingForm" id="gifting-form">
			<div class="gifting-form-modal__input">
				<edr-input
					name="cardNumber"
					label="Enter a valid Everyday Rewards card number"
					formControlName="cardNumber"
					mask="0000 0000 0000"
					[maskOptions]="{
						showMaskTyped: true,
						prefix: '9 ',
					}"
				></edr-input>
				@if (cardNumberErrorMessage) {
					<edr-error-message>{{ cardNumberErrorMessage }}</edr-error-message>
				}
			</div>
			<div class="gifting-form-modal__input">
				<edr-input
					name="points"
					label="How many points do you want to give them?"
					[subLabel]="
						'You have ' +
						(totalPointsBalance$ | async | number) +
						(totalPointsBalance$ | async | i18nPlural: { '=1': ' point', other: ' points' }) +
						' available'
					"
					type="number"
					placeholder="0"
					formControlName="points"
				>
					<edr-icon inputPrefix name="rewards" color="extended--charcoal-light-40" size="3"></edr-icon>
				</edr-input>
				@if (pointsErrorMessage) {
					<edr-error-message>{{ pointsErrorMessage }}</edr-error-message>
				}
			</div>
		</form>
	</div>
	<edr-button type="button" buttonSize="medium" buttonStyle="secondary" [reversed]="true" modal-footer-secondary (click)="handleClose()"
		>Cancel</edr-button
	>
	<edr-button type="submit" buttonSize="medium" buttonStyle="primary" modal-footer-primary (click)="handleSubmit()">Gift your points</edr-button>
</edr-modal>
