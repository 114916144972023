import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { OptimizelyDecision } from '@optimizely/optimizely-sdk';
import { FlagKey } from './flag-key.enum';

export type Flag = {
	[key in FlagKey]?: OptimizelyDecision | null;
};

export interface FlagState {
	enabled: boolean;
	initialised: boolean;
	flags: Flag | undefined;
	error: string | undefined;
}

export const defaultState: FlagState = {
	enabled: false,
	initialised: false,
	flags: undefined,
	error: undefined,
};

@Injectable({
	providedIn: 'root',
})
export class FlagStore extends ComponentStore<FlagState> {
	public readonly selectFlags$ = this.select((state) => state.flags);

	public readonly updateInitialised = this.updater((state): FlagState => ({ ...state, initialised: true, error: undefined }));
	public readonly updateEnabled = this.updater((state, enabled: boolean): FlagState => ({ ...state, enabled }));
	public readonly addFlag = this.updater(
		(state, { flagKey, decision }: { flagKey: FlagKey; decision: OptimizelyDecision | null }): FlagState => ({
			...state,
			flags: { ...state.flags, [flagKey]: decision },
		})
	);
	public readonly updateError = this.updater((state, error: string): FlagState => ({ ...state, error }));

	constructor() {
		super(defaultState);
	}
}
