<div (click)="toggleAccordionItem()" class="heading" edrTrackNavClick="{{ heading }}">
	@if (icon) {
		<div class="heading__pre-icon">
			<edr-icon [name]="icon.name" [size]="icon.size" [color]="icon.color" [rotate]="icon.rotate" />
		</div>
	}
	<div class="heading__text">
		<h5 edr-typography>{{ heading }}</h5>
	</div>
	@if (!isToggleDisabled) {
		<div class="heading__toggle-icon">
			<edr-icon name="system-chevron" color="secondary--charcoal" [rotate]="expanded ? '-180' : '0'" size="6" tabindex="0"></edr-icon>
		</div>
	}
</div>

<div [class.expanded]="expanded" class="accordion-item__content">
	<ng-content></ng-content>
</div>
