<ul class="carousel__list" #carouselItemList [attr.data-item-width-type]="itemWidthType" [class.has-controls]="!!pages.length">
	<ng-content></ng-content>
</ul>

@if (enableNavigation && !!pages.length) {
<div class="carousel__controls">
	@if (pages.length > 1) {
	<nav role="navigation" aria-label="Carousel pagination" class="carousel__pagination">
		<ul role="list">
			@for (p of pages; track p; let i = $index) {
			<li class="carousel__pagination-item" [ngClass]="{ 'carousel__pagination-item--active': i === currentPage }">
				<button type="button" role="button" [attr.aria-label]="'Show page ' + i + ' of ' + pages.length" (click)="paginationClick(i, false)"></button>
			</li>
			}
		</ul>
	</nav>
	} @if (pages.length) {
	<div class="carousel__nav">
		<edr-carousel-button
			color="primary--orange"
			[disabled]="currentPage === 0"
			rotate="90"
			ariaLabel="Previous"
			class="carousel__nav--prev"
			(click)="paginationClick(currentPage - 1, currentPage === 0)"
		></edr-carousel-button>
		<edr-carousel-button
			color="primary--orange"
			[disabled]="currentPage + 1 === pages.length"
			rotate="270"
			ariaLabel="Next"
			class="carousel__nav--next"
			(click)="paginationClick(currentPage + 1, currentPage + 1 === pages.length)"
		></edr-carousel-button>
	</div>
	}
</div>
}
