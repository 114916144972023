import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { Observable, switchMap } from 'rxjs';
import { BalanceResponseV2 } from '@edr/bff-api-models';

@Injectable({
	providedIn: 'root',
})
export class BalancesService {
	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService
	) {}

	public fetchBalances(): Observable<BalanceResponseV2> {
		return this.appSettingsService.getEndpoint('balances', 2).pipe(switchMap((endPoint) => this.apiService.get<BalanceResponseV2>(endPoint)));
	}
}
