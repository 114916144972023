<edr-modal
	class="gifting-confirmation-modal"
	title="Are you sure?"
	icon="alert-warning"
	iconColor="alert--alert-yellow"
	[disableClose]="pointsGifting.isLoading"
	(closeModal)="handleClose()"
>
	<div modal-content>
		<p class="gifting-confirmation-modal__subtitle">
			We’ll send {{ giftingPoints }} Everyday Rewards {{ giftingPoints | i18nPlural : { '=1': 'point', other: 'points' } }} to card number:
		</p>
		<p class="gifting-confirmation-modal__subtitle">
			<b>{{ giftingCardNumberFormatted }}</b>
		</p>
		<p>Are you sure you'd like to go ahead?</p>
	</div>
	<edr-button
		type="button"
		buttonSize="medium"
		buttonStyle="secondary"
		[reversed]="true"
		[disable]="pointsGifting.isLoading"
		modal-footer-secondary
		(click)="handleCancel()"
		>Cancel</edr-button
	>
	<edr-button
		type="button"
		buttonSize="medium"
		buttonStyle="secondary"
		loadingColor="secondary--mid-grey"
		modal-footer-primary
		[isLoading]="pointsGifting.isLoading"
		(click)="handleSubmit()"
		>Yes, go ahead</edr-button
	>
</edr-modal>
