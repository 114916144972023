import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

export const authFeatureName = 'auth';
export const selectAuthState = createFeatureSelector<AuthState>(authFeatureName);
export const selectAuthIsLoggedIn = createSelector(selectAuthState, (state) => state.isLoggedIn);
export const selectUserAuthData = createSelector(selectAuthState, (state) => ({
	isLoggedIn: state.isLoggedIn,
	scvId: state.scvId,
	hasError: state.hasError,
}));
export const selectAuthHasLoaded = createSelector(selectAuthState, (state) => state.hasLoaded);
export const selectAuthInitialising = createSelector(selectAuthState, (state) => state.initialising);
export const selectAuthHasError = createSelector(selectAuthState, (state) => state.hasError);
