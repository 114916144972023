import { Cta, Offer, OfferStatus } from '@edr/bff-api-models';
import { CtaProps, EDRProduct, PartnerProductBoost, RoundelText } from '@edr/styleguide';
import { OfferState } from '../+state';

export interface BoostProps {
	partnerProductBoost: PartnerProductBoost;
	buttonCta: CtaProps;
	roundelText?: RoundelText;
}

export function mapBoostCardProps(offerState: OfferState): BoostProps {
	const product = mapProduct(offerState.offer);
	const isBoosted = hasBeenBoosted(offerState.offer);
	const buttonCta = mapButtonCta(isBoosted, offerState.offer.cta);
	const [limit, amountBought, endDate] = getRedemptionProps(offerState.offer);

	return {
		partnerProductBoost: {
			id: offerState.offer.id,
			campaignId: offerState.offer.campaignId,
			title: offerState.offer.heading,
			description: {
				list: offerState.offer.description?.list ?? '',
				listShort: offerState.offer.description?.listShort ?? '',
				detailed: offerState.offer.description?.detailed ?? '',
			},
			termsAndConditions: offerState.offer.termsAndConditions,
			product,
			partnerId: offerState.offer.divisionPartnerIds ? offerState.offer.divisionPartnerIds[0] : undefined,
			limit,
			amountBought,
			isCompleted: offerState.offer.offerStatus === OfferStatus.Redeemed,
			endDate,
			isBoosted,
			isBoosting: offerState.isBoosting,
			hasError: offerState.hasError,
			isProductImage: offerState.offer.displayLocation === 'PRODUCT_OFFERS',
		},
		buttonCta,
		roundelText: offerState.offer?.roundel ? ({ ...offerState.offer?.roundel } as RoundelText) : undefined,
	};
}

function mapProduct(offer: Offer): EDRProduct {
	return {
		name: offer.backgroundImage?.altText ?? '',
		imageUrl: offer.backgroundImage?.desktop ?? '',
		imageLargeUrl: offer.backgroundImage?.desktop ?? '',
	};
}

function hasBeenBoosted(offer: Offer): boolean {
	return offer.offerStatus === OfferStatus.Activated || offer.offerStatus === OfferStatus.Redeemed;
}

function mapButtonCta(isBoosted: boolean, offerCta: Cta | undefined): CtaProps {
	const linkUrl = offerCta?.destinationUrl ?? '';
	const buttonCtaProps: Record<string, CtaProps> = {
		boosted: { text: offerCta?.label ?? 'Shop', style: 'secondary', styleReversed: true, linkUrl: linkUrl },
		noBoosted: { text: 'Boost', style: 'primary', styleReversed: false, linkUrl: linkUrl },
	};
	return isBoosted ? buttonCtaProps['boosted'] : buttonCtaProps['noBoosted'];
}

function getRedemptionProps(offer: Offer): [number, number, Date] {
	let limit = 0;
	let amountBought = 0;
	let endDate: Date = new Date();
	if (offer.redemption) {
		limit = offer.redemption.total;
		amountBought = offer.redemption.total - offer.redemption.remaining;
		endDate = offer.redemption.endDate as Date;
	}
	return [limit, amountBought, endDate];
}
