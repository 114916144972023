export const AnchorTargetMap = {
	/**
	 * This will open the link in a new tab
	 */
	blank: '_blank',
	/**
	 * This will open the link in the same tab
	 */
	self: '_self',
	/**
	 * This will open the link in the parent frame
	 */
	parent: '_parent',
	/**
	 * This will open the link in the full body of the window
	 */
	top: '_top',
} as const;

export type AnchorTarget = (typeof AnchorTargetMap)[keyof typeof AnchorTargetMap];
