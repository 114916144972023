import { AsyncPipe, SlicePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { HeadingComponent, LayoutGroupComponent, LayoutGroupItemComponent, SpinnerComponent } from '@edr/styleguide';
import { map, Observable, of } from 'rxjs';
import { CardBoostComponent } from '../card-boost/card-boost.component';
import { mapBoostCardProps } from '../../utils';
import { BoostsFacade } from '../../+state';
import { NoBoostOffersComponent } from '../no-boost-offers/no-boost-offers.component';
import { ContentBaseComponent } from '@edr/shared';

@Component({
	selector: 'edr-app-boost-offers-grid',
	templateUrl: 'boost-offers-grid.component.html',
	styleUrls: ['boost-offers-grid.component.scss'],
	standalone: true,
	imports: [
		LayoutGroupComponent,
		LayoutGroupItemComponent,
		CardBoostComponent,
		NoBoostOffersComponent,
		SpinnerComponent,
		HeadingComponent,
		SlicePipe,
		AsyncPipe,
	],
})
export class BoostOffersGridComponent extends ContentBaseComponent implements OnInit {
	@Input() public numberOfOffers?: number;
	@Input() public title: string | undefined;
	@Input() public isOtherOffers = false;
	@Input() public everydayOffers: Partial<CardBoostComponent>[] | undefined;

	public boostOffers$: Observable<Partial<CardBoostComponent>[] | undefined> = of(undefined);
	public otherBoostOffersCompleted$: Observable<Partial<CardBoostComponent>[] | undefined> = of([]);
	public isLoadingOffers$;

	constructor(private boostsFacade: BoostsFacade) {
		super();
		this.isLoadingOffers$ = this.boostsFacade.isLoadingOffers$;
	}

	public ngOnInit(): void {
		this.boostsFacade.loadBoostOffers();
		if (!this.isOtherOffers) {
			this.boostsFacade.loadHasBoostedBefore();
			this.boostOffers$ = this.boostsFacade.boostOffers$.pipe(map((offers) => offers?.map(mapBoostCardProps)));
		} else {
			this.boostOffers$ = this.boostsFacade.otherOffersNotCompleted$.pipe(map((offers) => offers?.map(mapBoostCardProps)));
			this.otherBoostOffersCompleted$ = this.boostsFacade.otherOffersCompleted$.pipe(map((offers) => offers?.map(mapBoostCardProps)));
		}
	}

	public identify(index: number, item: Partial<CardBoostComponent>): string {
		return item.partnerProductBoost?.id ?? `${index}`;
	}

	public activateBoostOffer(id: string): void {
		this.boostsFacade.activateBoostOffer(id);
	}

	public numberOfEverydayOffers(numberOfOffers: number, numberOfOffersToShow: number): number {
		if (numberOfOffersToShow === 0) {
			return 999;
		}
		return numberOfOffersToShow > numberOfOffers ? numberOfOffersToShow - numberOfOffers : 0;
	}
}
