import { createAction, props } from '@ngrx/store';
import { MemberRedemptionPreferencesResponse } from '@edr/bff-api-models';

export const loadRedemptionPreferences = createAction('[Member] Load Redemption Preferences');
export const loadRedemptionPreferencesSuccess = createAction(
	'[Member] Load Redemption Preferences Success',
	props<{
		redemptionPreferences: MemberRedemptionPreferencesResponse;
	}>()
);
export const loadRedemptionPreferencesFailure = createAction('[Member] Load Redemption Preferences Failure', props<{ error: unknown }>());

export const updateRedemptionPreferences = createAction(
	'[Member] Update Redemption Preferences',
	props<{
		redemptionPreferences: MemberRedemptionPreferencesResponse;
	}>()
);
export const updateRedemptionPreferencesSuccess = createAction(
	'[Member] Update Redemption Preferences Success',
	props<{
		redemptionPreferences: MemberRedemptionPreferencesResponse;
	}>()
);
export const updateRedemptionPreferencesFailure = createAction('[Member] Update Redemption Preferences Failure', props<{ error: unknown }>());

export const resetUpdatedState = createAction('[Member] Reset Updated Redemption Preferences State');
