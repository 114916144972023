import { Component, Input } from '@angular/core';
import { PointTableColumn, PointsTransactionTable } from '../points-table/points-table.model';
import { PointsCardComponent } from './points-card/points-card.component';

import { TABLE_COLUMNS_DEFAULT } from '../points-table/points-table.mock';

@Component({
	selector: 'edr-app-points-card-list',
	templateUrl: './points-card-list.component.html',
	styleUrls: ['./points-card-list.component.scss'],
	standalone: true,
	imports: [PointsCardComponent],
})
export class PointsCardListComponent {
	@Input() public dataSource: PointsTransactionTable[] = [];
	@Input() public columnList: PointTableColumn[] = [...TABLE_COLUMNS_DEFAULT];
}
