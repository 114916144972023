import { ElementRef } from '@angular/core';
import { EDRBreakpoint, EDRBreakpoints } from '@edr/shared';

export type EDRGroupColumns = { [K in EDRBreakpoint]: number };
export type EDRGroupItemWidthType = 'dynamic' | 'fixed';

/**
 * Get the amount of columns to render if the item width is fixed
 * @param itemCount - The number of items to render
 * @param itemsContainerElement - The element that contains the items
 */
export function getFixedWidthColumnsCount(itemCount: number, itemsContainerElement: ElementRef<HTMLElement> | undefined): number {
	if (!itemsContainerElement) {
		return 1;
	}
	const scrollWidth = itemsContainerElement.nativeElement.scrollWidth;
	const itemWidth = scrollWidth / itemCount;
	return itemsContainerElement.nativeElement.clientWidth / itemWidth;
}

/**
 * Take a partial column config and return a full column config which will include a column config for every single breakpoint
 * @param columnConfig The partial column config
 */
export function getAllBreakpointsColumnConfig(columnConfig: Partial<EDRGroupColumns>): EDRGroupColumns {
	const breakpointsDescending = [...EDRBreakpoints].reverse();

	function findNextBreakpointWithColumnsValue(breakpointIndex: number): number {
		const nextBreakpointIndex = breakpointIndex + 1;
		if (nextBreakpointIndex < breakpointsDescending.length) {
			return columnConfig[breakpointsDescending[nextBreakpointIndex]] ?? findNextBreakpointWithColumnsValue(nextBreakpointIndex);
		}

		return columnConfig[breakpointsDescending[breakpointsDescending.length - 1]] ?? 0;
	}

	return breakpointsDescending.reduce(
		(acc, curr, index) => ({ ...acc, [curr]: columnConfig[curr] ?? findNextBreakpointWithColumnsValue(index) }),
		{} as { [K in EDRBreakpoint]: number }
	);
}
