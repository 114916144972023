import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function regexValidator(reg: RegExp | string, errorName = 'pattern'): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (typeof reg === 'string') {
			reg = new RegExp(reg);
		}
		const valid = reg.test(control.value);
		return !valid ? { [errorName]: { value: control.value } } : null;
	};
}
