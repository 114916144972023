import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { CONTENT_MAPPING_PROVIDER } from './core/components/dynamic-page/content-mapping.provider';
import { CUSTOM_BREAKPOINTS_PROVIDER } from './config/custom-breakpoints.provider';
import { APP_SETTINGS, AppShortcodeService, ModalService, startupServiceProvider } from './core/services';
import { AppSettings } from './app.settings';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { CredentialInterceptor, TransferStateInterceptor } from './core/interceptors';
import { DevicePlatformService, MODAL_SERVICE_TOKEN, SHORTCODE_SERVICE_TOKEN } from '@edr/styleguide';
import { AUTH_STATUS_SERVICE_TOKEN } from './core/pipes';
import { Action, ActionReducer, combineReducers, provideStore } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { SharedModule } from '@edr/shared';
import { provideEffects } from '@ngrx/effects';
import { appRoutes } from './app.routes';
import * as fromAuth from './core/auth/+state';
import * as fromEnv from '@edr/env';
import * as fromPartners from './features/partners/+state';
import * as fromApp from './core/+state/app';
import * as fromRouter from './core/+state/router';
import * as fromBoosts from './features/boosts/+state';
import * as fromMember from './core/member';
import * as fromDynamicContent from './core/+state/dynamic-content';
import { ngrxDevtools } from '../environments/store-dev-tools-import';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const effects = [
	fromAuth.AuthEffects,
	fromPartners.PartnersEffects,
	fromApp.AppEffects,
	fromBoosts.BoostsEffects,
	fromMember.ProfileEffects,
	fromMember.BalancesEffects,
	fromMember.WelcomeTutorialEffects,
	fromMember.RedemptionPreferencesEffects,
	fromRouter.RouterEffects,
	fromDynamicContent.PageContentEffects,
	fromDynamicContent.NavItemsEffects,
];

const state = {
	router: routerReducer,
	[fromAuth.authFeatureName]: fromAuth.authReducer,
	[fromEnv.ENV_FEATURE_KEY]: fromEnv.envReducer,
	[fromPartners.PARTNERS_STATE_KEY]: fromPartners.partnersReducer,
	[fromApp.APP_FEATURE_KEY]: fromApp.appReducer,
	[fromBoosts.BOOSTS_FEATURE_KEY]: fromBoosts.boostsReducer,
	[fromMember.MEMBER_FEATURE_KEY]: combineReducers({
		[fromMember.PROFILE_STATE_KEY]: fromMember.memberProfileReducer,
		[fromMember.BALANCES_STATE_KEY]: fromMember.memberBalancesReducer,
		[fromMember.WELCOME_TUTORIAL_STATE_KEY]: fromMember.welcomeTutorialReducer,
		[fromMember.REDEMPTION_PREFERENCES_STATE_KEY]: fromMember.redemptionPreferencesReducer,
	}) as unknown as ActionReducer<unknown, Action>,
	[fromDynamicContent.DYNAMIC_CONTENT_STATE_KEY]: combineReducers({
		[fromDynamicContent.PAGE_CONTENT_STATE_KEY]: fromDynamicContent.pageContentReducer,
		[fromDynamicContent.NAV_ITEMS_STATE_KEY]: fromDynamicContent.navItemsReducer,
	}),
};

const storeConfig = {
	metaReducers: [],
	runtimeChecks: {
		strictActionImmutability: true,
		strictStateImmutability: true,
	},
};

export const appConfig: ApplicationConfig = {
	providers: [
		provideClientHydration(),
		provideRouter(appRoutes),
		provideStore(state, storeConfig),
		ngrxDevtools,
		provideEffects(effects),
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
		importProvidersFrom(SharedModule),
		provideRouterStore(),
		DecimalPipe,
		startupServiceProvider,
		DevicePlatformService,
		CONTENT_MAPPING_PROVIDER,
		CUSTOM_BREAKPOINTS_PROVIDER,
		{ provide: LOCALE_ID, useValue: 'en-NZ' },
		{ provide: APP_SETTINGS, useValue: AppSettings },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CredentialInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TransferStateInterceptor,
			multi: true,
		},
		{
			provide: SHORTCODE_SERVICE_TOKEN,
			useClass: AppShortcodeService,
		},
		{
			provide: MODAL_SERVICE_TOKEN,
			useExisting: ModalService,
		},
		{
			provide: AUTH_STATUS_SERVICE_TOKEN,
			useClass: fromAuth.AuthFacade,
		},
		provideAnimationsAsync('animations'),
	],
};
