/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { DevModeService } from '../dev-mode/dev-mode.service';

@Injectable({
	providedIn: 'root',
})
export class LoggingService {
	constructor(private devModeService: DevModeService) {}

	public log(message: any): void {
		if (this.shouldSuppressLogging()) {
			return;
		}

		// eslint-disable-next-line no-console
		console.log(message);
	}

	public warn(message: any): void {
		if (this.shouldSuppressLogging()) {
			return;
		}

		// eslint-disable-next-line no-console
		console.warn(message);
	}

	public error(message: any): void {
		if (this.shouldSuppressLogging()) {
			return;
		}

		// eslint-disable-next-line no-console
		console.error(message);
	}

	private shouldSuppressLogging(): boolean {
		const runningInTest = typeof process !== 'undefined' && !!process?.env?.['JEST_WORKER_ID'];
		return runningInTest || !this.devModeService.isDevMode();
	}
}
