import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { PARTNERS_STATE_KEY, PartnersState } from './partners.reducer';
import { PartnerPropResponse } from '@edr/bff-api-models';

export const selectPartnersState = createFeatureSelector<PartnersState>(PARTNERS_STATE_KEY);
export const selectPartners = createSelector(selectPartnersState, (state: PartnersState) => state.value ?? []);
export const selectPartnerById = (partnerId: string): MemoizedSelector<object, PartnerPropResponse | undefined> =>
	createSelector(selectPartners, (partners: PartnerPropResponse[]) => partners?.find((partner) => partner.id === partnerId));
export const selectPreferencePartners = createSelector(selectPartnersState, (state: PartnersState) =>
	state.value?.filter((partner) => partner.isDisplayPreference && partner.communicationPreferenceId)
);
export const selectIsLoadingPartners = createSelector(selectPartnersState, (state) => state.isLoading);
