@if (isLoading) {
	<edr-spinner>
		<edr-heading [level]="3">Loading</edr-heading>
	</edr-spinner>
} @else {
	<edr-accordion-item-card
		data-testid="spend-now"
		id="rewards-choice"
		[heading]="accordionItem.heading"
		[description]="description()"
		[icon]="icon"
		[selected]="isSelected"
	>
		<edr-section
			[fullWidthContent]="false"
			[disablePaddingTop]="true"
			[rootPadding]="false"
			[isStandardContentSection]="true"
			[paddingBottom]="howItWorksTitleSpace"
		>
			<h3>How it works</h3>
		</edr-section>

		@for (spendNowCard of howItWorksCards; track spendNowCard) {
			<edr-section
				[fullWidthContent]="false"
				[disablePaddingTop]="true"
				[paddingBottom]="howItWorksCardSpace"
				[rootPadding]="false"
				[isStandardContentSection]="true"
				class="rewards-choice-spend-now-how-it-works-section"
			>
				<!-- Declare an edr-card component here and set all it's properties -->
				<edr-card
					[cardType]="spendNowCard.cardType ?? 'icon'"
					[cardBorder]="spendNowCard.cardBorder ?? 'none'"
					[icon]="spendNowCard.icon"
					[title]="spendNowCard.title ?? ''"
					[description]="spendNowCard.description ?? '' | contentfulToHtml"
					[ctaType]="spendNowCard.ctaType ?? 'link'"
					[primaryCta]="spendNowCard.primaryCta"
					[secondaryCta]="spendNowCard.secondaryCta"
				/>
			</edr-section>
		}
		@if (!isSelected) {
			<edr-section [disablePaddingTop]="true" [disablePaddingBottom]="true" [rootPadding]="false">
				<div class="rewards-choice-cta">
					<edr-button [buttonStyle]="chooseCta.style || 'primary'" [inline]="true" (click)="onSelectSpendNow()" (keydown.enter)="onSelectSpendNow()">
						{{ chooseCta.text }}
					</edr-button>
				</div>
			</edr-section>
		}
	</edr-accordion-item-card>
}
