import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { DialogRef } from '@angular/cdk/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ButtonComponent, FireworksComponent, HeadingComponent, ModalComponent } from '@edr/styleguide';
import { APP_ROUTES } from '../../../routes';

@UntilDestroy()
@Component({
	selector: 'edr-app-rewards-voucher-modal',
	templateUrl: './rewards-voucher-modal.component.html',
	styleUrls: ['./rewards-voucher-modal.component.scss'],
	standalone: true,
	imports: [ButtonComponent, FireworksComponent, ModalComponent, HeadingComponent],
})
export class RewardsVoucherModalComponent implements OnInit {
	public rewardsPageLink = APP_ROUTES.partners.base;

	constructor(private modalService: ModalService, private dialogRef: DialogRef) {}

	public ngOnInit(): void {
		this.dialogRef.closed.subscribe(() => this.dialogRef.config.data.closeCallback());
	}

	public closeModal(): void {
		this.modalService.close();
	}
}
