import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { PhysicalCardRequestReason } from '../../models';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent, ModalComponent, RadioGroupComponent, TypographyComponent } from '@edr/styleguide';

@Component({
	selector: 'edr-app-order-card-reason-modal',
	templateUrl: './order-card-reason-modal.component.html',
	styleUrls: ['./order-card-reason-modal.component.scss'],
	standalone: true,
	imports: [ButtonComponent, ModalComponent, RadioGroupComponent, ReactiveFormsModule, TypographyComponent],
})
export class OrderCardReasonModalComponent {
	@Output() public selectReason = new EventEmitter<PhysicalCardRequestReason>();

	public reasonOptions = [
		{ name: 'My card was lost or stolen and I need to replace it', value: PhysicalCardRequestReason.lostOrStolen },
		{ name: 'My card is damaged', value: PhysicalCardRequestReason.damaged },
	];

	public reasonForm = this.fb.group({
		reason: this.fb.control<PhysicalCardRequestReason>(PhysicalCardRequestReason.lostOrStolen, Validators.required),
	});

	constructor(private modalService: ModalService, private fb: NonNullableFormBuilder) {}

	public closeModal(): void {
		this.modalService.close();
	}

	public handleSubmit(): void {
		this.selectReason.emit(this.reasonForm.value.reason);
	}
}
