import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	Output,
	OnDestroy,
	OnInit,
	computed,
	signal,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ComponentRefService, MODAL_SERVICE_TOKEN, ModalServiceBase } from '../../services';
import { ProgressIndicatorComponent } from '../progress-indicator/progress-indicator.component';
import { ModalComponent } from '../modal/modal.component';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { TypographyComponent } from '../typography/typography.component';
import { MultiStepItemComponent } from '../multi-step-item/multi-step-item.component';
import { IconComponent } from '../icon/icon.component';
import { IconProps } from '../../models';
import { delay, Observable, of } from 'rxjs';
import { ShortcodePipe } from '../../pipes';
import { BreakpointService, ContentBaseComponent, EDRBreakpoint } from '@edr/shared';

/**
 * Example usage:
 * <edr-multi-step id="myId123">
 *  <edr-multi-step-item id="myId123" stepIndex="0">
 *    <div>Step item 1 content</div>
 *  </edr-multi-step-item>
 *  <edr-multi-step-item id="myId123"  stepIndex="1">
 *   <div>Step item 2 content</div>
 *  </edr-multi-step-item>
 *  <edr-multi-step-item id="myId123"  stepIndex="2">
 *   <div>Step item 3 content</div>
 *  </edr-multi-step-item>
 * </edr-multi-step>
 */

@UntilDestroy()
@Component({
	selector: 'edr-multi-step',
	styleUrls: ['./multi-step.component.scss'],
	templateUrl: './multi-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ProgressIndicatorComponent,
		ModalComponent,
		NgTemplateOutlet,
		ButtonComponent,
		TypographyComponent,
		MultiStepItemComponent,
		IconComponent,
		AsyncPipe,
		ShortcodePipe,
	],
})
export class MultiStepComponent extends ContentBaseComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() public id = '';
	@Input() public title = '';
	@Input() public completeActionTitle = 'Get started';
	@Output() public completeAction = new EventEmitter<void>();
	@Output() public skipAction = new EventEmitter<void>();

	public activeStepIndex = signal(0);
	public totalSteps = signal(0);
	public backActionEnabled = computed(() => this.activeStepIndex() > 0 && this.activeStepIndex() < this.totalSteps());
	public nextActionEnabled = computed(() => this.activeStepIndex() < this.totalSteps() - 1);
	public completeActionEnabled = computed(() => this.activeStepIndex() === this.totalSteps() - 1);
	public skipActionEnabled = computed(() => this.activeStepIndex() < this.totalSteps() - 1);
	public progressIndicator = computed(() => +(((this.activeStepIndex() + 1) / this.totalSteps()) * 100).toFixed(2));
	public activeBreakpoint$: Observable<EDRBreakpoint>;

	public nextActionIconRight: IconProps = {
		name: 'system-chevron',
		size: '3',
		color: 'primary--white',
		rotate: '270',
	};
	public backActionIconRight: IconProps = {
		name: 'system-chevron',
		size: '3',
		color: 'secondary--charcoal',
		rotate: '90',
	};

	constructor(
		@Inject(MODAL_SERVICE_TOKEN) private modalService: ModalServiceBase,
		private componentRefService: ComponentRefService,
		private breakpointService: BreakpointService
	) {
		super();
		this.activeBreakpoint$ = this.breakpointService.getActiveBreakpoint();
	}

	public ngOnInit(): void {
		if (!this.id) {
			throw new Error('ID must be set for MultiStepComponent');
		}
		this.componentRefService.register(this.id, this);
		this.updateTotalSteps();
	}

	public ngOnDestroy(): void {
		this.componentRefService.deregister(this.id, this);
	}

	public ngAfterViewInit(): void {
		this.delegateActiveStepIndex();
	}

	public close(): void {
		this.modalService?.close();
	}

	public backActionHandler(): void {
		if (this.activeStepIndex() > -1) {
			this.activeStepIndex.update((value) => value - 1);
			this.delegateActiveStepIndex();
		}
	}

	public skipActionHandler(): void {
		this.close();
		this.skipAction.emit();
	}

	public nextActionHandler(): void {
		if (this.activeStepIndex() < this.totalSteps() - 1) {
			this.activeStepIndex.update((value) => value + 1);
			this.delegateActiveStepIndex();
		}
	}

	public completeActionHandler(): void {
		this.close();
		this.completeAction.emit();
	}

	public updateTotalSteps(): void {
		this.totalSteps.set(this.getMultiStepItemList().length);
	}

	private delegateActiveStepIndex(): void {
		of(true)
			.pipe(delay(1))
			.subscribe(() => {
				this.getMultiStepItemList().forEach((multiStepItem) => {
					multiStepItem.updateActiveStepIndex(this.activeStepIndex());
				});
			});
	}

	private getMultiStepItemList(): MultiStepItemComponent[] {
		return this.componentRefService.getComponentRefs(this.id)?.filter((item) => item !== this) || [];
	}
}
