<div [attr.data-media-alignment]="mediaAlignment">
	@if (cardType === 'icon' && circleIconHidden && icon && !image) {
		<edr-icon [name]="icon.name" [color]="icon.color" [size]="icon.size"></edr-icon>
	}
	@if ({ isSmallerThanDesktop: isSmallerThanDesktopBreakpoint$ | async }; as breakpoint) {
		@if (cardType === 'icon' && icon && !image && !circleIconHidden) {
			<edr-icon-circle [icon]="getIcon(!!breakpoint.isSmallerThanDesktop)" />
		}
	}
</div>
<div class="card-body" [ngClass]="{ animate: animation }" [style.background-color]="cardType !== 'leaf' ? cardBackgroundColor : null">
	@if (icon || image) {
		<edr-card-media>
			@if (cardType !== 'icon') {
				@if (image) {
					<edr-image
						[src]="cardImage.url"
						[altText]="cardImage.altText || title"
						[style.width]="cardImage.width ?? 'auto'"
						[imageDeviceTransformConfig]="imageDeviceTransformConfig"
						[scaleToContainer]="cardImage.scaleToContainer ?? true"
						[contain]="cardImage.containImageWhenScalingToContainer ?? true"
						[focalPoint]="cardImage.focalPoint"
						[imageAssetDimensions]="image.imageAssetDimensions"
						[lazyLoad]="cardImage.lazyLoad ?? true"
					/>
				} @else if (icon) {
					<edr-icon [name]="cardIcon.name" [color]="cardIcon.color" [size]="cardIcon.size" />
				}
			}
		</edr-card-media>
	}
	@if (title) {
		<edr-card-title>
			{{ title }}
		</edr-card-title>
	}
	@if (description) {
		<edr-card-description>
			<div [innerHTML]="description"></div>
		</edr-card-description>
	}

	<div class="content">
		<ng-content></ng-content>
	</div>

	@if (primaryCta || secondaryCta) {
		<edr-card-actions>
			@if (primaryCta) {
				<edr-link
					size="small"
					[text]="primaryCta.text"
					[linkUrl]="primaryCta.linkUrl"
					[target]="primaryCta.target || '_self'"
					[asButton]="ctaType === 'button'"
					[buttonStyle]="primaryCta.style || 'primary'"
					[buttonStyleReversed]="!!primaryCta.styleReversed"
					[iconLeft]="primaryCta.iconLeft"
					[showLeftIcon]="!!primaryCta.iconLeft"
					[iconRight]="primaryCta.iconRight"
					[showRightIcon]="!!primaryCta.iconRight"
					[attr.data-testid]="'primary-' + ctaType"
				/>
			}
			@if (secondaryCta) {
				<edr-link
					size="small"
					[text]="secondaryCta.text"
					[linkUrl]="secondaryCta.linkUrl"
					[target]="secondaryCta.target || '_self'"
					[asButton]="ctaType === 'button'"
					[buttonStyle]="secondaryCta.style || 'secondary'"
					[buttonStyleReversed]="!!secondaryCta.styleReversed"
					[iconLeft]="secondaryCta.iconLeft"
					[showLeftIcon]="!!secondaryCta.iconLeft"
					[iconRight]="secondaryCta.iconRight"
					[showRightIcon]="!!secondaryCta.iconRight"
					[attr.data-testid]="'secondary-' + ctaType"
				/>
			}
		</edr-card-actions>
	}
</div>
