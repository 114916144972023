import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRTypographyStyle } from '../../types/typography-style.type';

@Component({
	selector: 'edr-typography, [edr-typography]',
	templateUrl: './typography.component.html',
	styleUrls: ['./typography.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class TypographyComponent {
	@HostBinding('attr.typographyAs') @Input() public typographyAs: EDRTypographyStyle | undefined;
}
