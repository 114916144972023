import { Route } from '@angular/router';
import { isAuthenticatedUserGuard, isFlagVariationActiveGuard } from '../../guards';
import { FlexibleRouteResponse, PathMatch } from '@edr/bff-api-models';
import { FlagKey } from '../optimizely';

export function mapContentfulRoutesConfig(routesConfig: FlexibleRouteResponse[]): Route[] {
	return routesConfig.map(mapRouteConfig);
}

function mapRouteConfig(routeConfig: FlexibleRouteResponse): Route {
	const authGuard = routeConfig.authentication.required
		? [isAuthenticatedUserGuard({ startLoginFlow: routeConfig.authentication.startLoginFlow, redirectPath: '' })]
		: [];
	const flagGuard = routeConfig.featureFlagKey
		? [isFlagVariationActiveGuard({ flagKey: routeConfig.featureFlagKey as FlagKey, variationKeys: ['on'] })]
		: [];
	const guardList = [...authGuard, ...flagGuard];
	const guards = (guardList.length && { [routeConfig.guardType || 'canActivate']: guardList }) || {};
	const loadComponent =
		(routeConfig.isActive &&
			!routeConfig.children.length && {
				loadComponent: () => import('../../components/dynamic-page/dynamic-page.component').then((res) => res.DynamicPageComponent),
			}) ||
		{};
	const data = routeConfig.isActive && !routeConfig.children.length ? { data: { pageId: routeConfig.pageId } } : {};
	const parentRouteConfig = routeConfig.children.length ? [getParentRouteConfig(routeConfig)] : [];
	const children = routeConfig.children.length ? [...parentRouteConfig, ...mapContentfulRoutesConfig(routeConfig.children)] : [];

	return {
		path: routeConfig.slug ?? '',
		pathMatch: (routeConfig.pathMatch || 'prefix') as PathMatch,
		...loadComponent,
		...data,
		...guards,
		children,
	} as Route;
}

/**
 * If the route has children and has an associated page (isActive is true), then the route should be part of its own children
 * If the route has children but doesn't have an associated page, then the route should redirect to the first of its' children
 */
function getParentRouteConfig(routeConfig: FlexibleRouteResponse): Route {
	return routeConfig.isActive
		? mapRouteConfig({ ...routeConfig, slug: '', children: [] })
		: {
				path: '',
				redirectTo: routeConfig.children[0].slug,
				pathMatch: 'prefix',
		  };
}
