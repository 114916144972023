import { ModalService } from '../../../services/modal/modal.service';
import { DialogRef } from '@angular/cdk/dialog';
import {
	ButtonComponent,
	HeadingComponent,
	IconComponent,
	ModalComponent,
} from '@edr/styleguide';
import { MemberFacade } from '../../../member';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MemberProfileResponse } from '@edr/bff-api-models';
import { Observable, tap } from 'rxjs';
import { CommonModule, NgOptimizedImage } from '@angular/common';

export interface M2MGiftingResultModalComponentData {
	isSuccess: boolean | undefined;
	pointsGifted: number | undefined;
	tryAgain: ()=>void;
}

@UntilDestroy()
@Component({
	selector: 'edr-app-m2m-gifting-result-modal',
	templateUrl: './m2m-gifting-result-modal.component.html',
	styleUrls: ['./m2m-gifting-result-modal.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		HeadingComponent,
		IconComponent,
		ModalComponent,
		CommonModule,
		NgOptimizedImage,
	],
})
export class M2MGiftingResultModalComponent implements OnInit {
	public description = '';
	public title = '';
	public header = '';
	public ctaText = '';
	public isError?: boolean;
	public data: M2MGiftingResultModalComponentData;

	public personalDetails$: Observable<MemberProfileResponse | undefined> | undefined;

	constructor(private modalService: ModalService, private dialogRef: DialogRef, private memberFacade: MemberFacade) {
		this.data = this.dialogRef.config.data.modalData;

		if (this.data.isSuccess) {
			this.personalDetails$ = this.memberFacade.memberProfile$.pipe(tap((profile) => (this.setSuccessHeader(profile?.firstName))));
		}
	}

	public ngOnInit(): void {
		if(this.data.isSuccess) {
			this.showSuccessModal();
		} else {
			this.showErrorModal();
		}
	}

	public onCloseModal(): void {
		this.modalService.close();
	}

	public onCtaClicked(): void {
		this.modalService.close();

		if(this.ctaText === "Try again") {
			this.data.tryAgain();
		}
	}

	private showSuccessModal(): void {
		this.isError = false;

		this.setSuccessHeader(undefined);
		this.title = `Thanks for gifting your ${this.getPointsText()}.`;
		this.description = `You helped your loved one get <b>${this.data.pointsGifted} ${this.getPointsText()}</b> closer to their next reward.`;

		this.ctaText = "Done";
	}

	private showErrorModal(): void {
		this.isError = true;

		this.header = 'Something went wrong';
		this.title = `Your Rewards ${this.getPointsText()} has not been gifted.`;
		this.description = `Please try again later.`;
		this.ctaText = "Try again";
	}

	private setSuccessHeader(profileName: string | undefined): void {
		this.header = profileName ? `${profileName}, you’re all set!` : `You're all set!`;
	}

	private getPointsText(): string {
		return `point${this.data?.pointsGifted as number > 1 ? 's' : ''}`;
	}
}
