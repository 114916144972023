import { SeoMetadataResponse } from '@edr/bff-api-models';
import { PageMetaData } from './page-meta-data.interface';

export function mapSEOMetaData(seoMetaData: SeoMetadataResponse): PageMetaData {
	return {
		title: seoMetaData.pageTitle,
		metaTitle: seoMetaData.metaTitle,
		metaDescription: seoMetaData.metaDescription,
		metaImage: seoMetaData.metaImageUrl,
		canonicalPath: seoMetaData.canonicalPath,
		isIndexable: seoMetaData.isIndexable,
		structuredData: seoMetaData.structuredData,
		languageAlternates: seoMetaData.languageAlternates,
	};
}
