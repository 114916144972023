import { ModalService } from '../modal/modal.service';
import { RewardsVoucherModalComponent } from '../../components';
import { BehaviorSubject, Observable, combineLatest, filter, map, of, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { MemberFacade } from '../../member';
import { RedemptionPreference } from '@edr/bff-api-models';

export const EDR_VOUCHERS_COUNT_STORAGE_ID = 'edr-vouchersCount';

@Injectable({ providedIn: 'root' })
export class RewardsVoucherService {
	public $isRewardsVoucherModalOpen = new BehaviorSubject(false);
	public isRewardsVoucherModalOpen$ = this.$isRewardsVoucherModalOpen.asObservable();
	private redeemableRewardsVoucherCount: Observable<number>;

	constructor(private modalService: ModalService, private localStorage: LocalStorageService, private memberFacade: MemberFacade) {
		this.redeemableRewardsVoucherCount = this.memberFacade.balancesState$.pipe(map((state) => state.value?.voucherCount ?? -1));
	}

	public tryOpenCelebratoryModal(): void {
		combineLatest([
			this.redeemableRewardsVoucherCount,
			of(parseInt(this.localStorage.getItem(EDR_VOUCHERS_COUNT_STORAGE_ID) || '0', 10)),
			this.memberFacade.redemptionPreference$.pipe(map((redemptionPreference) => redemptionPreference === RedemptionPreference.SpendNow)),
		])
			.pipe(
				tap(([redeemableVouchersCount]) => {
					if (redeemableVouchersCount !== -1) {
						this.localStorage.setItem(EDR_VOUCHERS_COUNT_STORAGE_ID, redeemableVouchersCount.toString());
					}
				}),
				filter(([redeemableVouchersCount, storedVouchersCount, isSpendNow]) => redeemableVouchersCount > storedVouchersCount && isSpendNow),
				take(1)
			)
			.subscribe(() => {
				this.openRewardsVoucherModal();
				this.$isRewardsVoucherModalOpen.next(true);
			});
	}

	public openRewardsVoucherModal(): void {
		this.modalService.open({
			ref: RewardsVoucherModalComponent,
			id: 'rewards-voucher-modal',
			config: { data: { closeCallback: this.closeRewardsVoucherModal.bind(this) } },
		});
	}

	private closeRewardsVoucherModal(): void {
		this.$isRewardsVoucherModalOpen.next(false);
	}
}
