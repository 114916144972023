import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CtaProps, IconProps, ImageProps } from '../../../models';
import { EDRColor, EDRSpacing } from '../../../types';
import { CardMediaAlignment, CardType, CtaType } from '../../../enums';
import { AsyncPipe, NgClass } from '@angular/common';
import { LinkComponent } from '../../link/link.component';
import { CardActionsComponent } from '../children/card-actions.component';
import { CardDescriptionComponent } from '../children/card-description.component';
import { CardTitleComponent } from '../children/card-title.component';
import { CardMediaComponent } from '../children/card-media.component';
import { IconComponent } from '../../icon/icon.component';
import { IconCircleComponent } from '../../icon-circle/icon-circle.component';
import { BreakpointService, ContentBaseComponent, DeviceType, ImageDeviceTransformConfig, ImageTransformerService } from '@edr/shared';
import { map, Observable } from 'rxjs';
import { ImageComponent } from '../../image/image.component';

export type EDRCardColor = EDRColor | 'transparent';
export type EDRCardBorder = 'default' | 'none';

@Component({
	selector: 'edr-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgClass,
		LinkComponent,
		CardActionsComponent,
		CardDescriptionComponent,
		CardTitleComponent,
		CardMediaComponent,
		IconComponent,
		IconCircleComponent,
		AsyncPipe,
		ImageComponent,
	],
})
export class CardComponent extends ContentBaseComponent {
	@Input() public cardColor: EDRCardColor = 'primary--white';
	@HostBinding('class.animate') @Input() public animation = false;
	@HostBinding('attr.card-border') @Input() public cardBorder: EDRCardBorder = 'default';
	@HostBinding('attr.card-type') @Input() public cardType: CardType = CardType.LargeMedia;
	/**
	 * The card main title (can contain basic HTML tags).
	 */
	@Input() public title = '';

	/**
	 * The card description (can contain basic HTML tags).
	 */
	@Input() public description = '';

	/**
	 * The image URL
	 */
	@Input() public image?: ImageProps;

	/**
	 * The icon name.  If image is specified, then this will be ignored.
	 */
	@Input() public icon?: IconProps;

	/**
	 * The media vertical alignment which can be 'top', 'middle' or 'bottom'.  Defaults to 'middle'.
	 */
	@Input() public mediaAlignment: CardMediaAlignment = CardMediaAlignment.Middle;

	/**
	 * The CTA type which can be 'button' or 'link'.  Defaults to 'button'.
	 */
	@Input() public ctaType: CtaType = CtaType.Button;

	/**
	 * The primary CTA details.
	 */
	@Input() public primaryCta?: CtaProps;

	/**
	 * The secondary CTA details.
	 */
	@Input() public secondaryCta?: CtaProps;

	/**
	 * Hides the circle icon when true. By default, the circle icon is shown and has size calculated via getCircleSize().
	 */
	@Input() public circleIconHidden = false;

	public isSmallerThanDesktopBreakpoint$: Observable<boolean>;

	public imageDeviceTransformConfig: ImageDeviceTransformConfig = ImageTransformerService.GetSmallerImageTransformConfig();

	constructor(private breakpointService: BreakpointService) {
		super();

		this.isSmallerThanDesktopBreakpoint$ = this.breakpointService
			.isCustomBreakpointActive(DeviceType.Desktop)
			.pipe(map((isDesktopBreakpoint) => !isDesktopBreakpoint));
	}

	@HostBinding('style.background-color')
	public get cardBackgroundColor(): string {
		return this.cardColor === 'transparent' ? this.cardColor : `var(--color-${this.cardColor})`;
	}

	public get cardIcon(): IconProps {
		return this.icon || { name: 'rewards-rocket', color: 'primary--orange', size: '10' };
	}

	public get cardImage(): ImageProps {
		return this.image || { url: 'assets/images/card-story-demo-image.webp', altText: 'EDR Card' };
	}

	public getIcon(isSmallerThanDesktopBreakpoint: boolean): IconProps {
		return {
			...this.icon,
			color: this.icon?.color ?? 'primary--orange',
			size: this.getIconSize(isSmallerThanDesktopBreakpoint),
			circleSize: this.getCircleSize(isSmallerThanDesktopBreakpoint),
			circleColor: this.icon?.circleColor ?? 'extended--orange-light-90',
		} as IconProps;
	}

	// This will override size if circleSize is not set
	private getIconSize(isSmallerThanDesktopBreakpoint: boolean): EDRSpacing {
		if (this.icon?.circleSize && this.icon?.size) {
			return this.icon.size;
		}
		// // Default settings if circleSize is not set. (Partners Page card icon)
		return isSmallerThanDesktopBreakpoint ? '6' : '8';
	}

	private getCircleSize(isSmallerThanDesktopBreakpoint: boolean): EDRSpacing {
		if (this.icon?.circleSize && this.icon?.size) {
			return this.icon.circleSize;
		}

		// // Default settings if circleSize is not set. (Partners Page card icon)
		return this.icon?.circleSize ?? isSmallerThanDesktopBreakpoint ? '12' : '18';
	}
}
