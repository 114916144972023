import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ApiService, AppSettingsService } from '../../../core';
import { MemberPreferencesResponse, PreferenceResponse } from '@edr/bff-api-models';

@Injectable({
	providedIn: 'root',
})
export class CustomerPreferencesService {
	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	public fetchCustomerPreferences(preferenceIds: string[]): Observable<PreferenceResponse[]> {
		const params = new URLSearchParams();
		params.append('preferenceIds', preferenceIds.toString());
		const queryParams = Array.from(params).length ? `?${params}` : '';
		return this.appSettingsService.getEndpoint('customerPreferences').pipe(
			switchMap((endPoint) => this.apiService.get<MemberPreferencesResponse>(`${endPoint}${queryParams}`)),
			map((customerPreferences) => customerPreferences.preferences)
		);
	}

	public updateCustomerPreferences(preferences: PreferenceResponse[]): Observable<PreferenceResponse[]> {
		if (!preferences || preferences.length < 1) {
			return new Observable<PreferenceResponse[]>((sub) => sub.next([]));
		}

		return this.appSettingsService.getEndpoint('customerPreferences').pipe(
			switchMap((endPoint) => this.apiService.patch<MemberPreferencesResponse, MemberPreferencesResponse>(endPoint, { preferences })),
			map((customerPreferences) => customerPreferences.preferences)
		);
	}
}
