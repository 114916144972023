import { Component } from '@angular/core';
import { SpinnerComponent } from '@edr/styleguide';

/**
 * This is just an empty page that shows a spinner.
 * This should only be used where the spinner should take up the entire page e.g. resolvers, main page load etc.
 */
@Component({
	selector: 'edr-app-page-loader',
	template: '<edr-spinner />',
	styleUrls: ['./page-loader.component.scss'],
	standalone: true,
	imports: [SpinnerComponent],
})
export class PageLoaderComponent {}
