import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService } from '../../../core/services';
import { ActivityDetailsResponse, ActivityListResponse } from '@edr/bff-api-models';
import { Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PointsAndActivityService {
	constructor(private readonly apiService: ApiService, private readonly appSettingsService: AppSettingsService) {}

	public fetchActivityList(): Observable<ActivityListResponse[]> {
		return this.appSettingsService.getEndpoint('activityList').pipe(switchMap((endPoint) => this.apiService.get<ActivityListResponse[]>(endPoint)));
	}

	public fetchActivityDetails(activityId: string, transactionReference: string): Observable<ActivityDetailsResponse> {
		return this.appSettingsService
			.getEndpoint('activityDetails')
			.pipe(
				switchMap((endPoint) =>
					this.apiService.get<ActivityDetailsResponse>(`${endPoint}?activityId=${activityId}&basketKey=${transactionReference}`)
				)
			);
	}
}
