@if (
	{
		showCardOrderNotification: showCardOrderNotification$ | async,
		state: state$ | async,
		cardRequestReason: cardRequestReason$ | async,
		isLoading: isLoading$ | async,
	};
	as templateData
) {
	@if (!templateData.isLoading) {
	} @else {
		<edr-spinner><h3 edr-typography>Loading</h3></edr-spinner>
	}
	@if (templateData.showCardOrderNotification) {
		<edr-notification
			[showInsideSection]="true"
			[hasSectionPaddingTop]="false"
			backgroundColor="extended--grey-light-90"
			[canDismiss]="false"
			[notificationType]="templateData.cardRequestReason === 'lostOrStolen' ? 'alert' : 'success'"
			[title]="templateData.cardRequestReason === 'new' ? 'Your new card is on the way' : 'Your card is on the way'"
			(dismissed)="resetCardOrder()"
		>
			@if (templateData.cardRequestReason !== 'new') {
				<p description edr-typography typographyAs="body--xs">
					Your previous card has been cancelled and you've been issued a new card number. If your card hasn't arrived after 10 working days, please
					contact us on <a href="tel:0800 969 337">0800 969 337</a>.
				</p>
			}
			@if (templateData.cardRequestReason === 'new') {
				<p description edr-typography typographyAs="body--xs">
					While you wait, you can scan your digital card to start collecting points right away. If your card hasn't arrived after 10 working days,
					please contact us on <a href="tel:0800 969 337">0800 969 337</a>.
				</p>
			}
		</edr-notification>
	}
	@if (templateData.state?.isCardOrderError) {
		<edr-notification
			class="rewards-card__notification"
			[hasSectionPaddingTop]="false"
			[showInsideSection]="true"
			backgroundColor="extended--grey-light-90"
			notificationType="error"
			title="Your card wasn't ordered"
			[canDismiss]="false"
		>
			<p description edr-typography typographyAs="body--xs">
				We were unable to order your card. Please <a [routerLink]="['/', contactRoute]">contact us</a> for help.
			</p>
		</edr-notification>
	}
	<edr-section backgroundColor="extended--grey-light-90">
		@if (templateData.state?.rewardsCard?.cardNumber ?? templateData.state?.rewardsCardCmm?.cardNumber; as cardNumber) {
			<div class="rewards-card">
				<div class="rewards-card__child rewards-card__content">
					<div class="rewards-card__content-card">
						<img
							class="card-image"
							[src]="(showStaffCard$ | async) ? '/assets/images/everyday-rewards-card-plus.svg' : '/assets/images/everyday-rewards-card.svg'"
							alt="Everyday Rewards Logo"
						/>
					</div>
					<div class="rewards-card__content-barcode">
						<div class="barcode-details-container">
							<div class="barcode">
								@if ((useNewBarcode$ | async) === true) {
									<img alt="Card Barcode" [src]="templateData.state?.rewardsCard?.barcodeImage" />
								} @else {
									<edr-barcode
										[value]="cardNumber.toString()"
										[height]="185"
										[width]="3"
										[marginTop]="-10"
										[marginBottom]="-10"
										[displayValue]="false"
									/>
								}
							</div>
							<div edr-typography typographyAs="heading--5" class="card-information__details">
								<div class="card-information__details-panel">
									<div class="card-details">Card Number:</div>
									<div data-testid="card-number">
										{{ templateData.state?.rewardsCard?.cardNumber ?? templateData.state?.rewardsCardCmm?.cardNumber }}
									</div>
								</div>
								<div class="card-information__details-panel">
									<div class="card-details" edr-typography typographyAs="heading--5">CVV:</div>
									<div>{{ templateData.state?.rewardsCard?.cvv ?? templateData.state?.rewardsCardCmm?.cvv }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="rewards-card__child rewards-card__background"></div>
				<edr-accordion id="card-page">
					@for (item of getCardActions(templateData.state?.physicalCardRequestStatus?.cardOrderStatus); track item) {
						<edr-accordion-item-card
							id="card-page"
							[heading]="item.title"
							[icon]="{ name: item.icon, size: '5', color: 'primary--orange', circleSize: '10' }"
							iconColor="primary--orange"
							[expanded]="true"
							[class]="item.id"
						>
							@if (item.description) {
								<p>{{ item.description }}</p>
							}
							<edr-button (click)="handleAccordionAction(item.id, item.addressFinder, item.orderSuccess)">{{ item.button.label }}</edr-button>
						</edr-accordion-item-card>
					}
				</edr-accordion>
			</div>
		}
	</edr-section>
}

<ng-template #modalReplacementReason>
	<edr-app-order-card-reason-modal (selectReason)="handleSelectReason($event)"></edr-app-order-card-reason-modal>
</ng-template>

<ng-template #modalAddressForm>
	@if (addressFinderContent$ | async; as content) {
		<edr-app-order-card-address-modal
			(selectAddress)="handleSelectAddress($event)"
			[title]="content.title"
			[description]="content.description"
			[buttonText]="content.buttonText"
			[isLoading]="!!(isOrderingCard$ | async)"
		>
		</edr-app-order-card-address-modal>
	}
</ng-template>

<ng-template #modalReplacementSuccessful>
	@if (orderSuccessContent$ | async; as content) {
		<edr-app-order-card-successful-modal
			[title]="content.title"
			[subTitle]="content.subTitle ?? ''"
			[description]="content.description"
		></edr-app-order-card-successful-modal>
	}
</ng-template>
