<div class="banner" [edrApplyStyles]="[{ styleType: 'color', key: 'backgroundColor', value: backgroundColor }]" [attr.data-banner-type]="bannerType">
	<div class="banner__body" [attr.data-reversed]="reversed" [attr.data-banner-type]="bannerType" [attr.data-media-at-top]="mediaAlwaysAtTop">
		<div class="banner__body-info" [edrApplyStyles]="[{ styleType: 'color', key: 'color', value: color }]" [attr.data-banner-type]="bannerType">
			<div class="banner__body-info-content" [attr.data-content-alignment]="contentAlignment">
				@if (partnerBadge) {
					<div>
						<img class="banner__partner" [src]="partnerBadge.src" [alt]="partnerBadge.alt" />
					</div>
				}
				@if (preTitle) {
					<p class="banner__pre-title" edr-typography typographyAs="heading--4">{{ preTitle | edrShortcode | async }}</p>
				}
				<edr-heading
					class="banner__title"
					[level]="titleHeadingLevel || defaultTitleHeadingLevel"
					[style]="defaultTitleHeadingLevel === 1 ? 'heading--1' : 'heading--2'"
					[text]="title"
				></edr-heading>
				@if (description) {
					<p class="banner__description" edr-typography [typographyAs]="descriptionTypography" [innerHTML]="description"></p>
				}
				<!-- The call to actions will be rendered below -->
				<ng-content></ng-content>
			</div>
		</div>
		@if (image || icon) {
			<div
				class="banner__body-media"
				[attr.data-content-alignment]="contentAlignment"
				[attr.data-media-at-top]="mediaAlwaysAtTop"
				[attr.data-media-alignment]="mediaAlignment"
			>
				<div>
					@if (image) {
						<edr-image
							[src]="image.url"
							[altText]="image.altText || title"
							[style.width]="image.width ?? 'auto'"
							[imageDeviceTransformConfig]="imageDeviceTransformConfig"
							[focalPoint]="image.focalPoint"
							[scaleToContainer]="image.scaleToContainer ?? true"
							[contain]="image.containImageWhenScalingToContainer ?? true"
							[imageAssetDimensions]="image.imageAssetDimensions"
							[lazyLoad]="image.lazyLoad ?? true"
						/>
					}
				</div>
				@if (icon && !image) {
					<edr-icon [name]="icon.name" [color]="icon.color" [size]="icon.size" />
				}
			</div>
		}
	</div>
</div>
