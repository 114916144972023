/**
 * Takes a string like:
 * '/?myParam=yes&otherParam=no'
 * and returns:
 * { myParam: 'yes', otherParam: 'no' }
 *
 * The param string should already be encoded (e.g. encodeURIComponent('?r=info/help')
 */

export function stringToQueryParams(paramString: string): Record<string, string> | null {
	const queryParams: Record<string, string> = {};
	for (const [key, value] of new URLSearchParams(paramString).entries()) {
		queryParams[key] = value;
	}
	return (Object.keys(queryParams).length && queryParams) || null;
}
