import { createReducer, on, Action } from '@ngrx/store';

import * as EnvActions from './env.actions';
import { EDRBreakpoint } from '@edr/shared';

export const ENV_FEATURE_KEY = 'env';

export interface EnvState {
	breakpoint: EDRBreakpoint | undefined;
}

export interface EnvPartialState {
	readonly [ENV_FEATURE_KEY]: EnvState;
}

export const initialEnvState: EnvState = {
	breakpoint: undefined,
};

const reducer = createReducer(
	initialEnvState,
	on(EnvActions.updateEnvBreakpoint, (state, { breakpoint }): EnvState => ({ ...state, breakpoint }))
);

export function envReducer(state: EnvState | undefined, action: Action): EnvState {
	return reducer(state, action);
}
