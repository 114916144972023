import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID, makeStateKey, StateKey, TransferState } from '@angular/core';
import { REQUEST } from '../../../../express.tokens';
import { Store } from '@ngrx/store';
import { Request } from 'express';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoggingService } from '@edr/shared';
import { loadAppSettingsSuccess } from '../../../core/+state/app/app.actions';
import { ApiService } from '../api/api.service';
import { selectAppState } from '../../../core/+state/app/app.selectors';
import { SystemFileService } from '../system-file/system-file.service';

interface PartnerCallbackUrls {
	asb: string;
	bp: string;
	teamBenefits: string;
	vineOnline: string;
}

@Injectable({
	providedIn: 'root',
})
export class AppSettings {
	public apiUrl = '';
	public baseUrl = '/api/v1/';
	public baseUrlV2 = '/api/v2/';
	public baseUrlWithoutVersion = '/api/v';
	public endpoints: Record<string, string> = {};
	public partnerCallbackUrls: PartnerCallbackUrls = {
		asb: '',
		bp: '',
		teamBenefits: '',
		vineOnline: '',
	};
	public cdxQAAccessKey: string | null = null;
	public ssuBaseUrl? = '';
	public optimizelyKey = '';
	public tealiumAccount = '';
	public tealiumProfile = '';
	public tealiumEnvironment = '';
	public mandyUrl = '';
}

export const APP_SETTINGS = new InjectionToken<AppSettings>('AppSettings');

export const API_URL = new InjectionToken('api_url');

export const APP_SETTINGS_PROVIDER = {
	provide: APP_SETTINGS,
	useClass: AppSettings,
};

export const REQUEST_PROVIDER = {
	provide: REQUEST,
	useValue: { headers: {}, body: {} } as Request,
};

@Injectable({
	providedIn: 'root',
})
export class AppSettingsService {
	private appSettingsState$ = this.store.select(selectAppState);
	private _settingsSnapshot?: AppSettings;

	constructor(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		@Optional() @Inject(APP_SETTINGS) private _settings: AppSettings,
		@Inject(PLATFORM_ID) private platformId: object,
		@Optional() @Inject(API_URL) private injectedApiUrl: string,
		private apiService: ApiService,
		@Optional() private transferState: TransferState = new TransferState(),
		private store: Store,
		private systemFileService: SystemFileService,
		private loggingService: LoggingService
	) { }

	public get settingsSnapshot(): AppSettings | undefined {
		return this._settingsSnapshot;
	}

	// TODO: Simplify loading of app settings.  Refer to this example: https://lucasarcuri.com/blog/angular-load-config-file-before-app-starts/
	public getSettings(): Observable<AppSettings> {
		return this.appSettingsState$.pipe(
			filter((settings) => settings.loaded),
			map(({ appSettings }) => appSettings)
		);
	}

	public initialize(): Observable<AppSettings> {
		return this.getSettingsFromInjectedJson();
	}

	public getEndpoint = (endpoint: string, apiVersion = 1): Observable<string> =>
		this.getSettings().pipe(
			switchMap((appSettings) => this.getBase().pipe(map((baseUrl) => ({ appSettings, baseUrl })))),
			map(({ appSettings, baseUrl }) => {
				const qualifiedEndpoint = appSettings.endpoints[endpoint];

				if (!qualifiedEndpoint) {
					this.loggingService.error(`${endpoint} is an invalid endpoint`);
					return '';
				}

				return `${baseUrl}${appSettings.baseUrlWithoutVersion || '/api/v'}${apiVersion}/${qualifiedEndpoint}`;
			}),
			take(1)
		);

	private getBase(): Observable<string> {
		return this.getSettings().pipe(
			map((appSettings) => {
				let baseUrl = appSettings.apiUrl;
				if (isPlatformServer(this.platformId) && !appSettings.apiUrl) {
					baseUrl = this.injectedApiUrl;
				}

				if (baseUrl && baseUrl.endsWith('/')) {
					baseUrl = baseUrl.substring(0, baseUrl.length - 1);
				}
				return baseUrl;
			})
		);
	}

	private getSettingsFromInjectedJson(): Observable<AppSettings> {
		let settings$: Observable<AppSettings>;
		const settingsPath = 'assets/settings.json';

		const key: StateKey<AppSettings | null> = makeStateKey<AppSettings | null>(settingsPath);

		if (isPlatformServer(this.platformId)) {
			// load settings.json from filesystem
			const fullPath = this.systemFileService.buildPathFromWorkingDir('dist/apps/everyday-rewards/browser/assets/settings.json');
			const settingsJson = JSON.parse(this.systemFileService.readFileUTF8(fullPath));

			// save a client side copy of the settings.json before modifying for absolute url on server
			settingsJson.apiUrl = this.injectedApiUrl;
			this.transferState.set(key, { ...settingsJson });
			settings$ = of(settingsJson);
		} else {
			// get settings from transfer state if available
			const storedResponse = this.transferState?.get<AppSettings | null>(key, null);
			if (storedResponse) {
				const response = storedResponse;
				this.transferState.remove(key);
				settings$ = of(response);
			} else {
				settings$ = this.apiService.get('/assets/settings.json');
			}
		}

		const injectedAppSettings: AppSettings = this._settings ?? {};

		return settings$.pipe(
			tap((appSettings) => {
				this._settingsSnapshot = appSettings;
				this.store.dispatch(
					loadAppSettingsSuccess({
						appSettings: {
							...appSettings,
							...injectedAppSettings,
						},
					})
				);
			})
		);
	}
}
