import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { selectQueryParams } from '../../../router.selector';
import { LoggingService } from '@edr/shared';

export type EDRAccountSettingsPasswordResetState = 'none' | 'success' | 'failed';

export interface AccountSettingsState {
	passwordResetState: EDRAccountSettingsPasswordResetState;
	isLoading: boolean;
}

export const defaultState: AccountSettingsState = {
	passwordResetState: 'none',
	isLoading: true,
};

@Injectable()
export class AccountSettingsStore extends ComponentStore<AccountSettingsState> {
	/** Selectors */
	public readonly queryParams$ = this.store.select(selectQueryParams);

	/** Updaters */
	public readonly updateIsLoading = this.updater(
		(state, data: { isLoading: boolean }): AccountSettingsState => ({
			...state,
			isLoading: data.isLoading,
		})
	);

	public readonly updatePasswordResetState = this.updater(
		(state, passwordResetState: EDRAccountSettingsPasswordResetState): AccountSettingsState => ({
			...state,
			passwordResetState,
		})
	);

	/** Effects */
	public readonly determinePasswordResetState = this.effect(() =>
		this.queryParams$.pipe(
			tapResponse(
				(queryParams) => {
					const successQueryParam = (queryParams ?? {})['success'];
					const newPasswordResetState: EDRAccountSettingsPasswordResetState =
						successQueryParam === 'true' ? 'success' : successQueryParam === 'false' ? 'failed' : 'none';
					this.updatePasswordResetState(newPasswordResetState);
				},
				(err) => {
					this.logger.error(err);
				}
			)
		)
	);

	constructor(private readonly store: Store, private logger: LoggingService) {
		super(defaultState);
	}
}
