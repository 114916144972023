/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export const XRequestedWith = 'EverydayRewards.WebApp';
export const baseHeaders = {
	'Content-Type': 'application/json',
	'X-Requested-With': XRequestedWith,
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
};

export default class CustomEncoder extends HttpUrlEncodingCodec {
	public override encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	public override encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}

export interface HttpArgs {
	params?: Record<string, any>;
	headers?: Record<string, string>;
}

// Dynatrace RUM object name to attach to headers
// eslint-disable-next-line no-var
declare var dT_: any;

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(private http: HttpClient) {
		// Initialize Dynatrace header tracking
		if (typeof dT_ != 'undefined' && dT_.initAngularNg) {
			dT_.initAngularNg(http, HttpHeaders);
		}
	}

	public get<T>(endpoint: string, args?: HttpArgs): Observable<T> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);

		return this.http.get<T>(endpoint, {
			params,
			headers: this.buildHeaders(headers),
			withCredentials: true,
		});
	}

	public post<TBody, TResponse>(endpoint: string, body: TBody, args?: HttpArgs): Observable<TResponse> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);

		const httpOptions = {
			headers: this.buildHeaders(headers),
			params,
		};

		return this.http.post<TResponse>(`${endpoint}`, body, httpOptions);
	}

	public put<TBody, TResponse>(endpoint: string, body: TBody, args?: HttpArgs): Observable<TResponse> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);

		const httpOptions = {
			headers: this.buildHeaders(headers),
			params,
		};

		return this.http.put<TResponse>(`${endpoint}`, body, httpOptions);
	}

	public patch<TBody, TResponse>(endpoint: string, body: TBody, args?: HttpArgs): Observable<TResponse> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);

		const httpOptions = {
			headers: this.buildHeaders(headers),
			params,
		};

		return this.http.patch<TResponse>(`${endpoint}`, body, httpOptions);
	}

	public delete<T>(endpoint: string, args?: HttpArgs): Observable<T> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);

		const httpOptions = {
			headers: this.buildHeaders(headers),
			params,
			withCredentials: true,
		};

		return this.http.delete<T>(`${endpoint}`, httpOptions);
	}

	public getBlob(endpoint: string, args?: HttpArgs): Observable<HttpResponse<Blob>> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);

		return this.http.get<Blob>(endpoint, {
			params,
			headers: this.buildHeaders(headers),
			withCredentials: true,
			responseType: 'blob' as 'json',
			observe: 'response',
		});
	}

	public buildHeaders(headers?: Record<string, string>): HttpHeaders {
		return new HttpHeaders({ ...baseHeaders, ...(headers || {}) });
	}

	public parseParams(params: Record<string, any> | undefined): HttpParams {
		const flatParams = params ? this.flatten(params) : {};
		const cleanParams = this.clean(flatParams);
		const parsedDates = this.parseDates(cleanParams);

		return new HttpParams({
			fromObject: { ...parsedDates },
			encoder: new CustomEncoder(),
		});
	}

	/**
	 * Takes an object with empty or null items and removes them
	 *
	 * @param o: Object
	 */
	public clean = (o: Record<string, any>): Record<string, any> => {
		Object.keys(o).forEach((k) => !o[k] && delete o[k]);
		return o;
	};

	/**
	 * Takes a nested object and flattens it into an array
	 *
	 * @param o: Object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public flatten = (o: any): [] =>
		Object.assign(
			{},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			...(function _flatten(internalO): any {
				if (!internalO) {
					return [];
				}
				return [].concat(
					...Object.keys(internalO).map((k) =>
						typeof internalO[k] === 'object' && !Array.isArray(internalO[k]) && !(internalO[k] instanceof Date)
							? _flatten(internalO[k])
							: { [k]: internalO[k] }
					)
				);
			})(o)
		);

	/**
	 * Converts any dates into the object into UTC ISO strings
	 *
	 * @param o: Object
	 */
	public parseDates = (o: Record<string, any>): Record<string, any> => {
		Object.keys(o).forEach((k) => {
			o[k] = o[k] instanceof Date ? new Date(o[k]).toISOString() : o[k];
		});

		return o;
	};
}
