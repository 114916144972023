import { Pipe, PipeTransform } from '@angular/core';
import { AnchorTarget } from '../../typescript';

const linkShortCodeRegex = /\[(.*?)\]\((.*?)\)/g;

@Pipe({
	name: 'linkShortCode',
	standalone: true,
})
export class LinkShortCodePipe implements PipeTransform {
	public transform(value: string, target: AnchorTarget = '_self'): string {
		const results = value.matchAll(linkShortCodeRegex);
		return Array.from(results).reduce(
			(acc, curr) => acc.replace(`[${curr[1]}](${curr[2]})`, `<a target="${target}" href="${curr[2]}">${curr[1]}</a>`),
			value
		);
	}
}
