import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as BoostsSelectors from './boosts.selectors';
import * as BoostsActions from './boosts.actions';
import { Observable, filter, map, take } from 'rxjs';
import { notUndefined } from '@edr/shared';
import { OfferState } from './boosts.reducer';

@Injectable({ providedIn: 'root' })
export class BoostsFacade {
	public firstTimeState$ = this.store.select(BoostsSelectors.selectFirstTimeState);
	public hasBoosted$ = this.store.select(BoostsSelectors.selectHasBoosted);
	public offerState$ = this.store.select(BoostsSelectors.selectOfferState);
	public isLoadingOffers$ = this.store.select(BoostsSelectors.selectIsLoadingOffers);
	public isUpdatingOffers$ = this.store.select(BoostsSelectors.selectIsUpdatingOffers);
	public hasOffers$ = this.store.select(BoostsSelectors.selectHasOffers);
	public boostOffers$: Observable<OfferState[]> = this.store.select(BoostsSelectors.selectBoostOffers);
	public otherOffers$ = this.store.select(BoostsSelectors.selectOtherOffers);
	public otherOffersCompleted$ = this.store.select(BoostsSelectors.selectOtherOffersCompleted);
	public otherOffersNotCompleted$ = this.store.select(BoostsSelectors.selectOtherOffersNotCompleted);
	public isAllOffersBoosted$ = this.store.select(BoostsSelectors.selectIsAllOffersBoosted);

	constructor(private readonly store: Store) {}

	public hasBoosted(): Observable<boolean> {
		return this.store
			.select(BoostsSelectors.selectFirstTimeState)
			.pipe(filter(({ isLoading }) => !isLoading))
			.pipe(map(({ hasBoosted }) => hasBoosted));
	}

	public updateFirstTimeBoost(hasBoosted: boolean): void {
		this.store.dispatch(BoostsActions.updateFirstTimeBoost({ hasBoosted }));
	}

	public loadHasBoostedBefore(): void {
		this.firstTimeState$.pipe(take(1)).subscribe(({ isLoading, hasLoaded }) => {
			if (isLoading || hasLoaded) {
				return;
			}
			this.store.dispatch(BoostsActions.loadFirstTimeBoost());
		});
	}

	public getBoostOfferById(id: string): Observable<OfferState> {
		return this.store.select(BoostsSelectors.selectOffer(id)).pipe(filter(notUndefined));
	}

	public loadBoostOffers(): void {
		this.offerState$.pipe(take(1)).subscribe(({ isLoading, hasLoaded }) => {
			if (isLoading || hasLoaded) {
				return;
			}
			this.store.dispatch(BoostsActions.loadBoostOffers());
		});
	}

	public activateBoostOffer(id: string): void {
		this.store.dispatch(BoostsActions.activateBoostOffer({ id }));
	}

	public activateAllBoostOffers(): void {
		this.store.dispatch(BoostsActions.activateAllBoostOffers());
	}
}
