@for (option of enrichedOptions; track option.key; let i = $index) {
	<div class="icon-circle-option"
		 [ngClass]="{
		 	'icon-circle-option__selected': option.isSelected,
		 	'icon-circle-option__left-adjust': i > 0,
		 	'icon-circle-option__with-shadow' : i > 0 && i < enrichedOptions.length - 1
		 }"
		 [ngStyle]="{'zIndex': option.zIndex}"
	>
		@if (option.isSelected) {
			<div class="icon-circle-option__stars-left">
				@if (enrichedOptions.length > 2) {
					<img ngSrc="assets/images/stars-cyan.svg" fill alt="Selected rewards choice stars"
						 data-testid="icon-circle-group-selected-left-stars" />
				}
			</div>
		}

		<edr-icon-circle
			[icon]="option.iconProps"
		/>

		@if (option.isSelected) {
			<div class="icon-circle-option__stars-right">
				<img ngSrc="assets/images/stars-yellow.svg" width="56" height="34" alt="Selected rewards choice stars"
					 data-testid="icon-circle-group-selected-right-stars" />
			</div>
		}
	</div>
}
