import { AsyncPipe, DecimalPipe, I18nPluralPipe, NgTemplateOutlet, isPlatformServer } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnChanges,
	OnInit,
	PLATFORM_ID,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { resizeObserver } from '@edr/shared';
import { Observable, animationFrameScheduler, endWith, interval, map, switchMap, take } from 'rxjs';
import { TooltipProps } from '../../models/tooltip-props.model';
import { ApplyStylesDirective, TooltipDirective } from '../../directives';
import { IconComponent } from '../icon/icon.component';
import { SvgDefinitionsComponent } from '../svg-definitions/svg-definitions.component';
import { TypographyComponent } from '../typography/typography.component';

const minTextWidthBreakpoint = 350;

@Component({
	selector: 'edr-points-dial',
	templateUrl: './points-dial.component.html',
	styleUrls: ['./points-dial.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AsyncPipe,
		ApplyStylesDirective,
		IconComponent,
		NgTemplateOutlet,
		DecimalPipe,
		I18nPluralPipe,
		SvgDefinitionsComponent,
		TooltipDirective,
		TypographyComponent,
	],
})
export class PointsDialComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() public pointsBalance = 0;
	@Input() public totalPointsRequired = 2000;
	@Input() public helpToolTip?: TooltipProps;
	@HostBinding('attr.isError') @Input() public isError = false;

	@ViewChild('progressDial') public progressDial: ElementRef | undefined;

	public readonly outerCircleDashedStroke: string;

	public animatingCount$: Observable<number> | undefined;
	public inView$ = new EventEmitter<boolean>();

	public minTextWidthBreakpointReached$: Observable<boolean> | undefined;

	private readonly _outerCircleTotal = 75;
	private readonly _outerCircleOffset = 25;

	constructor(@Inject(PLATFORM_ID) private platformId: object) {
		this.outerCircleDashedStroke = `${this._outerCircleTotal},${this._outerCircleOffset}`;
	}

	public get pointsRemaining(): number {
		return this.totalPointsRequired - this.pointsBalance;
	}

	public get dashedStrokeValues(): string {
		let filledTotal = 0;

		if (this.pointsBalance > this.totalPointsRequired) {
			filledTotal = 100;
		}

		filledTotal = this.pointsBalance / this.totalPointsRequired;
		filledTotal = filledTotal > 1 ? 1 : filledTotal;

		const innerStrokeSize = filledTotal * this._outerCircleTotal;

		return `${innerStrokeSize},${100 - innerStrokeSize}`;
	}

	public ngOnInit(): void {
		this.setupErrorPoints();

		if (this.noIntersectionObserver()) {
			this.inView$.next(true);
			this.animatingCount$ = this.setupCounterAnimation();
			return;
		}

		// wait until its in-view then switch map to the animation events
		this.animatingCount$ = this.inView$.pipe(switchMap(() => this.setupCounterAnimation()));
	}

	public ngAfterViewInit(): void {
		if (this.progressDial?.nativeElement) {
			this.minTextWidthBreakpointReached$ = resizeObserver(this.progressDial?.nativeElement).pipe(
				map((entries) => entries[0].contentRect.width < minTextWidthBreakpoint)
			);
		}

		if (this.noIntersectionObserver()) {
			return;
		}

		const threshold = 0.5; // when 20% of the dial is in view start animating
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.inView$.next(true);
						observer.disconnect();
					}
				});
			},
			{ threshold }
		);
		observer.observe(this.progressDial?.nativeElement);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['pointsBalance'] && !changes['pointsBalance'].isFirstChange()) {
			this.animatingCount$ = this.setupCounterAnimation();
		}
	}

	public noIntersectionObserver(): boolean {
		return isPlatformServer(this.platformId);
	}

	private setupCounterAnimation(): Observable<number> {
		const animationTime = 2000; // ms
		const intervalTime = 13; // runs every 13ms

		// number of updates required to keep it within the desired animation time
		const numberOfUpdates = animationTime / intervalTime;

		return interval(intervalTime, animationFrameScheduler).pipe(
			map((frameNumber) => {
				// creates an ease from 0 - 1 based on the interval
				let t = frameNumber / numberOfUpdates;
				const ease = --t * t * t + 1; // ease out cubic function

				return Math.round(this.pointsBalance * ease);
			}),
			take(numberOfUpdates),
			endWith(this.pointsBalance)
		);
	}

	private setupErrorPoints(): void {
		// set default point values when its error
		if (this.isError) {
			this.pointsBalance = 1400;
			this.totalPointsRequired = 2000;
		}
	}
}
