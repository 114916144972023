import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { FlexiblePageTemplateResponse, FlexibleRoutesResponse, NavigationResponse } from '@edr/bff-api-models';
import { Route } from '@angular/router';
import { mapContentfulRoutesConfig } from './content.factory';

@Injectable({
	providedIn: 'root',
})
export class ContentService {
	private contentEndpoint$: Observable<string>;
	private pageCache = new Map<string, FlexiblePageTemplateResponse>();

	constructor(
		private apiService: ApiService,
		appSettingsService: AppSettingsService
	) {
		this.contentEndpoint$ = appSettingsService.getEndpoint('content');
	}

	public fetchPage(pageId: string): Observable<FlexiblePageTemplateResponse> {
		const cachedPage = this.pageCache.get(pageId);
		return cachedPage
			? of(cachedPage)
			: this.contentEndpoint$.pipe(
					switchMap((endpoint) => this.apiService.get<FlexiblePageTemplateResponse>(`${endpoint}/flexible-page?pageId=${pageId}`)),
					tap((response) => this.pageCache.set(pageId, response))
				);
	}

	public fetchPagePreview(pageId: string, verificationKey: string, env: string | null): Observable<FlexiblePageTemplateResponse> {
		const cachedPage = this.pageCache.get(pageId);
		return cachedPage
			? of(cachedPage)
			: this.contentEndpoint$.pipe(
					switchMap((endpoint) =>
						this.apiService.get<FlexiblePageTemplateResponse>(
							`${endpoint}/flexible-page-preview?pageId=${pageId}&verificationKey=${verificationKey}${env ? '&env=' + env : ''}`
						)
					),
					tap((response) => this.pageCache.set(pageId, response))
				);
	}

	public fetchNavItems(): Observable<NavigationResponse[]> {
		return this.contentEndpoint$.pipe(switchMap((endpoint) => this.apiService.get<NavigationResponse[]>(`${endpoint}/nav-items`)));
	}

	public fetchRoutes(): Observable<Route[]> {
		return this.contentEndpoint$.pipe(
			switchMap((endpoint) => this.apiService.get<FlexibleRoutesResponse>(`${endpoint}/flexible-routes`)),
			map((response) => mapContentfulRoutesConfig(response.routes))
		);
	}
}
