import { Component, Input } from '@angular/core';
import { ButtonComponent, EDRButtonSize, EDRButtonStyle } from '@edr/styleguide';
import { BoostsFacade } from '../../+state';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'edr-app-boost-all-button',
	templateUrl: './boost-all-button.component.html',
	styleUrls: ['./boost-all-button.component.scss'],
	standalone: true,
	imports: [ButtonComponent, AsyncPipe],
})
export class BoostAllButtonComponent {
	@Input() public text = '';
	@Input() public size: EDRButtonSize = 'medium';
	@Input() public buttonStyle: EDRButtonStyle = 'primary';
	@Input() public reversed = false;

	public isAllOffersBoosted$: Observable<boolean>;
	public isUpdatingOffers$: Observable<boolean>;

	constructor(private boostsFacade: BoostsFacade) {
		this.isAllOffersBoosted$ = this.boostsFacade.isAllOffersBoosted$;
		this.isUpdatingOffers$ = this.boostsFacade.isUpdatingOffers$;
	}

	public boostAll(): void {
		this.boostsFacade.activateAllBoostOffers();
	}
}
