@if (rewardsCard$ | async; as rewardsCard) {
<section>
	@if (loadingComplete$ | async) {
	<div class="page-layout">
		@if (notification$ | async; as notification) {
		<edr-notification
			[notificationType]="notification.notificationType"
			[title]="notification.title"
			[description]="notification.description ?? ''"
			[canDismiss]="notification.canDismiss ?? false"
			(dismissed)="dismissNotification()"
		></edr-notification>
		}
		<div class="sc-card">
			<div class="sc-card__section">
				<div class="sc-card__row">
					<img class="sc-card__logo" src="/assets/images/everyday-rewards-signup-splash.svg" alt="Everyday Rewards Splash" />
				</div>
				<div class="sc-card__row">
					<edr-heading [level]="2">You're all set</edr-heading>
				</div>
			</div>
			<div class="sc-card__section">
				<p>Welcome to Everyday Rewards! You can start collecting points right away, using your digital Rewards Card.</p>
			</div>
			<!-- Desktop/Tablet -->
			@if ((isMobileLayout$ | async) === false) {
			<div>
				<div class="sc-card__section sc-card__section--card-details">
					<div>
						<img class="sc-card__logo" src="/assets/images/everyday-rewards-logo-mark.svg" alt="Everyday Rewards Logo Mark" />
					</div>
					<div>
						<edr-heading [level]="2" class="sc-card__sub-heading" edr-typography>Your Everyday Rewards Card number is</edr-heading>
						<edr-heading [level]="3" class="sc-card__card-number" edr-typography>{{ rewardsCard.cardNumber | cardNumber }}</edr-heading>
					</div>
				</div>
				<div class="sc-card__section">
					<p>You can always find your Rewards Card under 'Account' on the Everyday Rewards website.</p>
				</div>
				<div class="sc-card__section sc-card__section--cta">
					<edr-button class="sc-card__button--cta" buttonStyle="primary" buttonSize="medium" (click)="handleCta()"
						>Explore Everyday Rewards</edr-button
					>
				</div>
			</div>
			}
		</div>
		<!-- Mobile -->
		@if (isMobileLayout$ | async) {
		<div class="sc-card">
			<div class="sc-card__section sc-card__section--card-details">
				<div>
					<img class="sc-card__logo" src="/assets/images/everyday-rewards-logo-mark.svg" alt="Everyday Rewards Logo Mark" />
				</div>
				<div>
					<edr-heading [level]="2" class="sc-card__sub-heading" edr-typography>Your Everyday Rewards Card number is</edr-heading>
					<edr-heading [level]="4" class="sc-card__card-number" edr-typography>{{ rewardsCard.cardNumber | cardNumber }}</edr-heading>
				</div>
			</div>
			<div class="sc-card__section sc-card__section--barcode">
				@if ((useNewBarcode$ | async) === true) {
				<img [src]="rewardsCard.barcodeImage" alt="Card Barcode" />
				} @else {
				<edr-barcode [value]="rewardsCard.cardNumber.toString() || ''"></edr-barcode>
				}
			</div>
			<div class="sc-card__section">
				<p>You can always find your Rewards Card under 'Account' on the Everyday Rewards website.</p>
			</div>
			<div class="sc-card__section sc-card__section--cta">
				<edr-button class="sc-card__button--cta" buttonStyle="primary" buttonSize="medium" (click)="handleCta()">Explore Everyday Rewards</edr-button>
			</div>
		</div>
		}
	</div>
	} @else {
	<edr-spinner><edr-heading [level]="3">Loading</edr-heading></edr-spinner>
	}
</section>
}
