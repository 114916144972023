import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EDRRadioButtonOption, EDRRadioSize } from './radio-group.model';
import { EDROrientation } from '../../types/orientation.type';

export const RADIO_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => RadioGroupComponent),
	multi: true,
};

let nextUniqueId = 0;

/**
 * This component can be handled the same as a native input
 * Create your form with the formBuilder and use the formControlName directive
 * Usage: <form [formGroup]="myForm">
 * <edr-radio-group formControlName="customRadio" [options]="myoptions"></edr-radio-group>
 * </form>
 */
@Component({
	selector: 'edr-radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.scss'],
	providers: [RADIO_VALUE_ACCESSOR],
	standalone: true,
	imports: [],
})
export class RadioGroupComponent implements ControlValueAccessor {
	@Input() public formControlName = '';
	@Input() public options: EDRRadioButtonOption[] = [];
	@Input() public orientation: EDROrientation = 'vertical';
	@Input() public size: EDRRadioSize = 'small';
	@Input() public error = false;
	@Output() public valueChange = new EventEmitter<string>();

	public id = nextUniqueId++;
	public disabled = false;

	private inputValue?: string;

	public get value(): string {
		return this.inputValue ?? '';
	}

	public set value(value: string) {
		if (value !== this.inputValue) {
			this.inputValue = value;
		}
	}

	public onInput = (event: Event): void => {
		const value = (event.target as HTMLInputElement)?.value;
		this.writeValue(value);
		this.valueChange.emit(value);
		this._onChange(value);
		this._onTouched();
	};

	public writeValue(value: string): void {
		this.value = value;
	}

	public registerOnChange(fn: (_: string) => void): void {
		this._onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this._onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	// Angular Forms Methods
	private _onChange: (_: string) => void = () => null;
	private _onTouched: () => void = () => null;
}
