import { MemberFacade } from '../../member/+state/member.facade';
import { CircleComponent, MODAL_SERVICE_TOKEN, ModalServiceBase, NotificationComponent } from '@edr/styleguide';
import { ButtonComponent } from '@edr/styleguide';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { HeadingComponent, IconComponent } from '@edr/styleguide';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RichTextComponent } from '@edr/contentful';
import { Document } from '@contentful/rich-text-types';
import { BalancesState } from '../../member/+state/balances';
import { PointsGiftingWithDetails } from './m2m-gifting-confirmation-modal/m2m-gifting-confirmation.store';
import { M2MGiftingFormModalComponent } from './m2m-gifting-form-modal/m2m-gifting-form-modal.component';
import { M2MGiftingResultModalComponent, M2MGiftingResultModalComponentData } from './m2m-gifting-result-modal/m2m-gifting-result-modal.component';
import { BehaviorSubject, map, take } from 'rxjs';
import { ContentBaseComponent } from '@edr/shared';
import { RedemptionPreference } from '@edr/bff-api-models';

@UntilDestroy()
@Component({
	selector: 'edr-app-m2m-gifting',
	templateUrl: './m2m-gifting.component.html',
	styleUrls: ['./m2m-gifting.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IconComponent, HeadingComponent, ButtonComponent, CircleComponent, NotificationComponent, RichTextComponent, AsyncPipe],
})
export class M2MGiftingComponent extends ContentBaseComponent {
	@Input() public id = '';
	@Input() public title = '';
	@Input() public ctaText = '';
	@Input() public description: Document | undefined;
	@Input() public notificationSuccessTitle = '';
	@Input() public notificationSuccessMessage = '';
	@Input() public notificationFailedTitle = '';
	@Input() public notificationFailedMessage = '';

	public pointsBalance = '0';
	public pointsGifting: PointsGiftingWithDetails = { isLoading: false, succeeded: undefined };
	public isSavingForOccasion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(MODAL_SERVICE_TOKEN) private modalService: ModalServiceBase,
		private changeDetector: ChangeDetectorRef,
		private memberFacade: MemberFacade,
		private decimalPipe: DecimalPipe
	) {
		super();
		this.memberFacade.conditionallyLoadBalances(true);

		this.memberFacade.balancesState$.pipe(untilDestroyed(this)).subscribe((balanceState: BalancesState) => {
			this.pointsBalance = this.decimalPipe.transform(balanceState.value?.totalPointsBalance || '0') as string;
		});

		this.memberFacade.redemptionPreference$
			.pipe(map((redemptionPreference) => redemptionPreference === RedemptionPreference.SaveForOccasion))
			.subscribe((isSavingForOccasion) => this.isSavingForOccasion$.next(isSavingForOccasion));
	}

	public get notificationSuccessMessageFormatted(): string {
		const giftingPointsRegEx = new RegExp(/\[\[giftingPoints\]\]/g);
		const pointsPluralRegEx = new RegExp(/\[\[point\|plural:giftingPoints\]\]/g);
		const giftingCardNumberRegEx = new RegExp(/\[\[giftingCardNumber\]\]/g);

		return this.notificationSuccessMessage
			.replace(giftingPointsRegEx, this.decimalPipe.transform(this.pointsGifting.giftingPoints) || '')
			.replace(pointsPluralRegEx, this.pointsGifting.giftingPoints === 1 ? 'point' : 'points')
			.replace(giftingCardNumberRegEx, this.formatGiftingCardNumber(this.pointsGifting.giftingCardNumber));
	}

	public formatGiftingCardNumber(cardNumber: string | undefined): string {
		return cardNumber ? cardNumber.replace(/(\d{1})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4') : '';
	}

	public pointsGiftingSubmitHandler(pointsGifting: PointsGiftingWithDetails): void {
		if (pointsGifting.succeeded !== undefined) {
			this.pointsGifting = {
				...this.pointsGifting,
				...pointsGifting,
			};

			if (this.pointsGifting.succeeded) {
				this.pointsBalance = this.decimalPipe.transform(this.pointsGifting.pointsBalance) as string;
			}

			const modalData: M2MGiftingResultModalComponentData = {
				isSuccess: this.pointsGifting.succeeded,
				pointsGifted: this.pointsGifting.giftingPoints,
				tryAgain: () => {
					this.modalService.open({
						ref: M2MGiftingFormModalComponent,
						id: 'm2m-gifting-form-modal',
						config: {
							data: {
								pointsGiftingSubmitHandler: this.pointsGiftingSubmitHandler.bind(this),
							},
						},
					});
				},
			};

			this.modalService.open({
				ref: M2MGiftingResultModalComponent,
				id: 'm2m-gifting-result-modal',
				config: {
					data: { modalData },
				},
			});

			this.changeDetector.detectChanges();
		}
	}

	public openGiftingFormModal(): void {
		this.isSavingForOccasion$.pipe(take(1)).subscribe((isSavingForOccasion) => {
			if (isSavingForOccasion) {
				return;
			}

			// Reset the points gifting state
			this.pointsGifting = { isLoading: false, succeeded: undefined, error: '' };

			// open gifting form modal
			this.modalService.open({
				ref: M2MGiftingFormModalComponent,
				id: 'm2m-gifting-form-modal',
				config: {
					data: {
						pointsGiftingSubmitHandler: this.pointsGiftingSubmitHandler.bind(this),
					},
				},
			});
		});

		this.changeDetector.detectChanges();
	}
}
