import { createReducer, on, Action } from '@ngrx/store';

import * as WelcomeTutorialActions from './welcome-tutorial.actions';

export const WELCOME_TUTORIAL_STATE_KEY = 'welcomeTutorial';

export interface WelcomeTutorialState {
	hasViewed: boolean;
	isLoading: boolean;
	hasLoaded: boolean;
	hasError: boolean;
}

export const initialWelcomeTutorialState: WelcomeTutorialState = {
	hasViewed: false,
	isLoading: false,
	hasLoaded: false,
	hasError: false,
};

const reducer = createReducer(
	initialWelcomeTutorialState,
	on(
		WelcomeTutorialActions.loadTutorialViewed,
		(state: WelcomeTutorialState): WelcomeTutorialState => ({
			...state,
			hasViewed: false,
			isLoading: true,
			hasLoaded: false,
			hasError: false,
		})
	),
	on(
		WelcomeTutorialActions.loadTutorialViewedComplete,
		(state: WelcomeTutorialState, { hasViewed }): WelcomeTutorialState => ({
			...state,
			hasViewed,
			isLoading: false,
			hasLoaded: true,
			hasError: false,
		})
	),
	on(
		WelcomeTutorialActions.loadTutorialViewedFailed,
		(state: WelcomeTutorialState): WelcomeTutorialState => ({
			...state,
			isLoading: false,
			hasLoaded: false,
			hasError: true,
		})
	),
	on(
		WelcomeTutorialActions.updateTutorialViewed,
		(state: WelcomeTutorialState): WelcomeTutorialState => ({
			...state,
			isLoading: true,
			hasLoaded: false,
		})
	),
	on(
		WelcomeTutorialActions.updateTutorialViewedComplete,
		(state: WelcomeTutorialState, { hasViewed }): WelcomeTutorialState => ({
			...state,
			hasViewed,
			isLoading: false,
			hasLoaded: true,
			hasError: false,
		})
	),
	on(
		WelcomeTutorialActions.updateTutorialViewedFailed,
		(state: WelcomeTutorialState): WelcomeTutorialState => ({
			...state,
			isLoading: false,
			hasLoaded: false,
			hasError: true,
		})
	)
);

export function welcomeTutorialReducer(state: WelcomeTutorialState | undefined, action: Action): WelcomeTutorialState {
	return reducer(state, action);
}
