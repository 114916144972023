import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ModalService } from '@edr-core';
import { BreakpointService, ContentBaseComponent, TruncatePipe } from '@edr/shared';
import {
	ButtonComponent,
	CtaProps,
	HeadingComponent,
	IconComponent,
	LinkComponent,
	PartnerProductBoost,
	RoundelComponent,
	RoundelText,
	TagComponent,
	TypographyComponent,
} from '@edr/styleguide';
import { Observable, Subject, of } from 'rxjs';
import { BoostModalComponent } from '../boost-modal/boost-modal.component';
import { PartnerPropResponse } from '@edr/bff-api-models';
import { PartnersFacade } from '../../../partners/+state';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'edr-app-card-boost',
	templateUrl: './card-boost.component.html',
	styleUrls: ['./card-boost.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		IconComponent,
		TagComponent,
		RoundelComponent,
		HeadingComponent,
		LinkComponent,
		DatePipe,
		ButtonComponent,
		AsyncPipe,
		JsonPipe,
		TypographyComponent,
		TruncatePipe,
	],
})
export class CardBoostComponent extends ContentBaseComponent implements OnInit, AfterViewChecked {
	@ViewChild('infoContainer', { read: ElementRef }) public infoContainerRef: ElementRef<HTMLDivElement> | undefined;
	@ViewChild('heading', { read: ElementRef }) public headingRef: ElementRef<HTMLDivElement> | undefined;
	@ViewChild('description', { read: ElementRef }) public descriptionRef: ElementRef<HTMLDivElement> | undefined;

	/**
	 * isProductImage: product images are cropped, standalone products. When false, the image can be stretched to fill the container
	 */
	@Input() public isProductImage = false;
	/**
	 * An offer can be an Everyday offer or a Boost offer.
	 * Everyday offers cannot be boosted.
	 */
	@Input() public isEverydayOffer = true;
	@Input() public partnerProductBoost?: PartnerProductBoost;
	@Input() public buttonCta: CtaProps | undefined;
	@Input() public roundelText: RoundelText | undefined;

	@HostBinding('attr.isCarouselItem') @Input() public isCarouselItem = false;
	@Output() public seeDetails = new EventEmitter<{
		partnerProductBoost: PartnerProductBoost | undefined;
		isProductImage: boolean;
		buttonCta: CtaProps | undefined;
		boostOffer: EventEmitter<PartnerProductBoost>;
	}>();
	@Output() public boost = new EventEmitter<PartnerProductBoost>();

	public isMobileDevice$: Observable<boolean>;
	public isSmallDevice$: Observable<boolean>;
	public $infoContainerRef = new Subject<HTMLDivElement>();
	public partner$: Observable<PartnerPropResponse | undefined> = of(undefined);

	constructor(
		private breakpointService: BreakpointService,
		private modalService: ModalService,
		private partnersFacade: PartnersFacade,
		private activatedRoute: ActivatedRoute
	) {
		super();
		this.isMobileDevice$ = this.breakpointService.breakpointMatchesCustom(['mobile']);
		this.isSmallDevice$ = this.breakpointService.breakpointMatches(['xxs', 'xs', 'sm', 'md']);
	}

	@HostBinding('class') public get class(): string {
		const isCompletedClass = this.partnerProductBoost?.isCompleted ? 'completed' : '';
		const anchorPointClass = this.partnerProductBoost?.id ? `anchor-point-${this.partnerProductBoost.id}` : '';
		return `card-boost ${anchorPointClass} ${isCompletedClass}`;
	}

	@HostBinding('attr.isBoosted')
	public get isBoosted(): boolean {
		return this.partnerProductBoost?.isBoosted ?? false;
	}

	@HostBinding('attr.fullWidthImage')
	public get fullWidthImage(): boolean {
		return !this.isProductImage;
	}

	public ngOnInit(): void {
		if (this.partnerProductBoost?.partnerId) {
			this.partner$ = this.partnersFacade.getPartnerById(this.partnerProductBoost.partnerId);
		}
		if (this.partnerProductBoost?.id && this.activatedRoute.snapshot.fragment?.includes(this.partnerProductBoost.id)) {
			this.openOfferModal();
		}
	}

	public ngAfterViewChecked(): void {
		const infoContainer = this.infoContainerRef?.nativeElement;
		if (!infoContainer) {
			return;
		}
		this.$infoContainerRef.next(infoContainer);
	}

	public boostOffer(partnerProductBoost: PartnerProductBoost): void {
		if (partnerProductBoost.isBoosting || partnerProductBoost.isBoosted) {
			return;
		}
		this.boost.emit(partnerProductBoost);
	}

	public openOfferModal(): void {
		this.modalService.open({
			ref: BoostModalComponent,
			id: 'boost-modal',
			config: {
				data: {
					isEverydayOffer: this.isEverydayOffer,
					boostProps: {
						partnerProductBoost: this.partnerProductBoost,
						buttonCta: this.buttonCta,
						roundelText: this.roundelText,
					},
					isProductImage: this.isProductImage,
				},
			},
		});
	}
}
