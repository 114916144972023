import { Inject, Injectable } from '@angular/core';
import { NavigationSkipped, Router, Event } from '@angular/router';
import { CustomWindow } from '@edr/shared';
import { WINDOW } from '@ng-web-apis/common';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnchorPointService {
  constructor(private router: Router, @Inject(WINDOW) private window: CustomWindow) { }

  public scrollToAnchorPointOnNavigationSkippedEvent(): void {
    const isNavigationSkippedEvent = (x: Event): x is NavigationSkipped => x instanceof NavigationSkipped;

    this.router.events.pipe(filter(isNavigationSkippedEvent)).subscribe((event) => {
      const anchorPointHash = event.url.split('#')[1] || '';
      if (anchorPointHash) {
        this.scrollToAnchorPoint(anchorPointHash);
      }
    });
  }

  public scrollToAnchorPoint(hashFragment: string): void {
    const element = this.window.document.querySelector(`.anchor-point-${hashFragment}`);

    if(element) {
        const scrollOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center', inline: 'nearest' };
        const resizeObserver = new ResizeObserver(() => {
          element.scrollIntoView(scrollOptions);
        });

        resizeObserver.observe(element);
        setTimeout(() => {
          resizeObserver.unobserve(element);
         }, 1000);  // give enough time for the size of the element to stabilize

        element.scrollIntoView(scrollOptions);
      }
    }
}
