import { createReducer, on, Action } from '@ngrx/store';

import * as AppActions from './app.actions';
import { AppSettings } from '../..';

export const APP_FEATURE_KEY = 'app';

export interface AppState {
	appSettings: AppSettings;
	isLoading: boolean;
	loaded: boolean;
	error?: string | null;
}

export interface AppPartialState {
	readonly [APP_FEATURE_KEY]: AppState;
}

export const initialAppState: AppState = {
	appSettings: {
		apiUrl: '',
		baseUrl: '/api/v1/',
		baseUrlV2: '/api/v2/',
		baseUrlWithoutVersion: '/api/v',
		endpoints: {},
		partnerCallbackUrls: {
			asb: '',
			bp: '',
			teamBenefits: '',
			vineOnline: '',
		},
		cdxQAAccessKey: null,
		ssuBaseUrl: '',
		optimizelyKey: '',
		tealiumAccount: '',
		tealiumProfile: '',
		tealiumEnvironment: '',
		mandyUrl: '',
	},
	isLoading: false,
	loaded: false,
};

const reducer = createReducer(
	initialAppState,
	on(AppActions.initAppSettings, (state): AppState => ({ ...state, loaded: false, error: null, isLoading: true })),
	on(AppActions.loadAppSettingsSuccess, (state, { appSettings }): AppState => ({ ...state, loaded: true, isLoading: false, appSettings })),
	on(AppActions.loadAppSettingsFailure, (state, { error }): AppState => ({ ...state, isLoading: false, loaded: true, error }))
);

export function appReducer(state: AppState | undefined, action: Action): AppState {
	return reducer(state, action);
}
