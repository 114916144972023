import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { REG_EX, regexValidator } from '@edr/shared';
import {
	ButtonComponent,
	ModalComponent,
	InputComponent,
	ErrorMessageComponent,
	IconComponent,
	MODAL_SERVICE_TOKEN,
	ModalServiceBase,
} from '@edr/styleguide';
import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe, DecimalPipe, I18nPluralPipe } from '@angular/common';
import { M2MGiftingConfirmationModalComponent } from '../m2m-gifting-confirmation-modal/m2m-gifting-confirmation-modal.component';
import { Observable } from 'rxjs';
import { MemberFacade } from '../../../member';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'edr-app-m2m-gifting-form-modal',
	templateUrl: './m2m-gifting-form-modal.component.html',
	styleUrls: ['./m2m-gifting-form-modal.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		ReactiveFormsModule,
		ButtonComponent,
		InputComponent,
		ErrorMessageComponent,
		ModalComponent,
		IconComponent,
		I18nPluralPipe,
		AsyncPipe,
		DecimalPipe,
	],
})
export class M2MGiftingFormModalComponent implements OnInit {
	public pointsBalance = 0;
	public submitButtonClicked = false;
	public totalPointsBalance$: Observable<number>;

	public giftingForm = this.formBuilder.group({
		cardNumber: [undefined as unknown, [Validators.required, regexValidator(REG_EX.REWARDS_CARD_NUMBER, 'invalid')]],
		points: [undefined as unknown, [Validators.required, regexValidator(/^[1-9]\d*$/, 'invalid')]],
	});

	constructor(
		private formBuilder: NonNullableFormBuilder,
		@Inject(MODAL_SERVICE_TOKEN) private modalService: ModalServiceBase,
		private dialogRef: DialogRef,
		private memberFacade: MemberFacade
	) {
		this.totalPointsBalance$ = this.memberFacade.totalPointsBalance$;
		this.totalPointsBalance$.pipe(untilDestroyed(this)).subscribe((balance: number) => {
			this.pointsBalance = balance;
			this.giftingForm.get('points')?.addValidators([Validators.max(balance)]);
		});
	}

	public get cardNumberErrorMessage(): string | null {
		const cardNumberControl = this.giftingForm.get('cardNumber');
		if (cardNumberControl?.touched && this.submitButtonClicked) {
			if (cardNumberControl?.hasError('required')) {
				return 'Please enter card number';
			}
			if (cardNumberControl?.hasError('invalid')) {
				return 'The entered card number is invalid';
			}
		}
		return null;
	}

	public get pointsErrorMessage(): string | null {
		const pointsControl = this.giftingForm.get('points');

		if (pointsControl?.touched && this.submitButtonClicked) {
			if (pointsControl?.hasError('required')) {
				return 'Please enter points';
			}
			if (pointsControl?.hasError('max')) {
				return "You don't have that many points available";
			}
			if (pointsControl?.hasError('invalid')) {
				return 'The entered points is invalid';
			}
		}
		return null;
	}

	public get giftingCardNumber(): string {
		const cardNumberValue = this.giftingForm.get('cardNumber')?.value ?? '';
		return cardNumberValue ? (cardNumberValue as string).replace(/\s/g, '') : '';
	}

	public get giftingPoints(): number {
		const pointsValue = this.giftingForm.get('points')?.value;
		return pointsValue ? parseInt(pointsValue as string, 10) : 0;
	}

	public ngOnInit(): void {
		// load values from previous modal
		if (this.dialogRef.config.data.giftingPoints) {
			this.giftingForm.patchValue({ points: this.dialogRef.config.data.giftingPoints });
		}
		if (this.dialogRef.config.data.giftingCardNumber) {
			this.giftingForm.patchValue({ cardNumber: this.dialogRef.config.data.giftingCardNumber });
		}
	}

	public handleSubmit(): void {
		this.submitButtonClicked = true;
		this.giftingForm.markAllAsTouched();
		if (this.giftingForm.valid) {
			this.handleClose();
			this.openGiftingConfirmationModal();
		}
	}

	public handleClose(): void {
		this.modalService.close();
	}

	private openGiftingConfirmationModal(): void {
		this.modalService.open({
			ref: M2MGiftingConfirmationModalComponent,
			id: 'm2m-gifting-confirmation-modal',
			config: {
				disableClose: true,
				data: {
					giftingPoints: this.giftingPoints,
					giftingCardNumber: this.giftingCardNumber,
					pointsGiftingSubmitHandler: this.dialogRef.config.data.pointsGiftingSubmitHandler,
					pointsBalance: this.pointsBalance,
				},
			},
		});
	}
}
