import { ObjectValues } from '@edr/shared';

export const CardType = {
	LargeMedia: 'large-media',
	SmallMedia: 'small-media',
	Leaf: 'leaf',
	Icon: 'icon',
} as const;

export type CardType = ObjectValues<typeof CardType>;
