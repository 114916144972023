import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type RoundelSize = 'xs' | 'sm' | 'md' | 'lg';
export interface RoundelText {
	line1: string;
	line2: string;
	line3: string;
}

@Component({
	standalone: true,
	selector: 'edr-roundel',
	templateUrl: './roundel.component.html',
	styleUrls: ['./roundel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundelComponent {
	@HostBinding('attr.size') @Input() public size = 'lg';
	@Input() public roundelText?: RoundelText;
	@Input() public imagePath = '/assets/illustrations/rocket-outlined.svg';
}
